import React, { Component } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { dataURItoBlob } from '../../utils/utilityfunctions'
import Card from '@material-ui/core/Card';

class CameraComponent extends Component {
  state = { open: false, imageURI: this.props.imageURI || null }
  onTakePhoto(imageURI) {
    this.props.handleChange(this.props.fieldID, imageURI);
    this.setState({ open: false, imageURI: imageURI, loading: false });
    this.props.uploadToStorage(dataURItoBlob(imageURI))
  }

  imageUpload = (e) => {
    let uploadImage = e.target.files[0] // upload the first file only
    if (!(uploadImage instanceof Blob)) {
      // console.log('uploadImage instanceof Blob', uploadImage instanceof Blob);
      // console.log('uploadImage typeof', typeof uploadImage);
      // console.log('uploadImage', uploadImage);
      this.setState({ loading: false })
    }// true
    if (uploadImage) {
      let classThis = this;
      let reader = new FileReader();
      reader.onload = (readerEvent) => (classThis.setState({ imageURI: readerEvent.target.result, loading: false }))
      reader.readAsDataURL(uploadImage);
      this.props.uploadToStorage(uploadImage, uploadImage /** URI*/);
      this.props.handleChange(this.props.fieldID, uploadImage);
    }
  }

  onCameraError(error) { console.log('onCameraError' + error); }

  onCameraStart(stream) { console.log('onCameraStart', stream); }

  onCameraStop() {
    console.log('onCameraStop');
  }

  render() {

    const CameraRender = () =>
      <Card style={{ margin: 0, padding: 0 }}>
        <div style={{ position: 'relative' }}>
          {this.props.focusBorder && <div style={{
            zIndex: 1, position: "absolute", top: 0, left: 0, display: 'inline-flex', width: "100%", height: "100%",
            border: "100px solid rgba(0, 0, 0, .5)"
          }} />}
          <Camera
            onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
            idealFacingMode={this.props.facingModes === 'user' ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
            idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.99}
            isMaxResolution={true}
            isImageMirror={this.props.facingModes === 'user' ? true : false}
            isSilentMode={true}
            isDisplayStartCameraError={true}
            isFullscreen={true}
            sizeFactor={1}
          />
        </div>
      </Card >
    return (
      <div style={{ textAlign: "left" }}>
        {
          (!this.state.open && this.state.imageURI) &&
          <img src={this.state.imageURI}
            alt="Captured Data"
            style={{
              width: "100%",
              maxWidth: 1000,
              maxHeight: "90vh",
              height: "auto",
              alignSelf: "left",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "8px"
            }} />
        }

        <Grid container>
          <Grid item xs >
            {
              // !this.state.loading &&
              <div style={{ textAlign: 'left' }}>
                <Button variant="outlined" style={{ margin: 8 }} id={this.props.id} name={this.props.name}
                  onClick={() => this.setState({ open: true, loading: true })} >
                  {!this.state.imageURI ? "Launch Camera" : "Re-take Photo"}
                  <PhotoCamera style={{ marginLeft: 8 }} /></Button>
                {this.props.allowUpload &&
                  <Button variant="outlined" component="label" style={{ margin: 8 }} >
                    {!this.state.imageURI ? "Upload Image" : "Re-upload Image"}
                    <CloudUpload style={{ marginLeft: 8 }} />
                    <input type="file" accept="image/gif,image/png,image/jpeg,image/jpg,image/bmp"
                      onInput={(e) => { this.setState({ loading: true }); this.imageUpload(e) }}
                      style={{ display: "none" }} />
                  </Button>}
              </div>}
            <Grid item xs>
              {!(//If not iPhone use fullscreen dialog. If iphone use inscreen since bottom bar covers camera button
                (/iPad|iPhone|iPod/g.test(navigator.userAgent) ||
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
              ) ?
                <Dialog fullScreen open={this.state.open} onClose={() => this.setState({ open: false })} >
                  {this.state.open && <CameraRender />}
                </Dialog>
                : this.state.open && <CameraRender />
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default CameraComponent;