import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import MaterialTable from 'material-table'
import moment from 'moment'
import Loader from '../../standard/Loader'
import Grid from '@material-ui/core/Grid';
import { tableIcons, getTableColumnStyle } from '../../../utils/tableUtil'
import { noPaddingStyleForLastColumn, noPaddingStyleForTable, centerStyleForTable, secondaryBackgroundWhiteText, primaryBackgroundWhiteText } from '../../../utils/styleclasses'
import TaskCardContainer from '../../tasks/TaskCardContainer'
import { Typography } from '@material-ui/core'
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import AppRenderDialog from '../../appviewer/AppRenderDialog'
import { submitApp } from '../../../store/actions/formActions'

const TaskSearchTable = ({ taskResults, users, taskQueryStatus, searchName, profile, submitTaskApp }) => {

  console.log('TaskSearchTable props', taskResults);
  const [selectedTask, setSelectedTask] = React.useState({ taskID: null });
  const [selectedApp, setSelectedApp] = React.useState(null);

  if (typeof taskResults === 'undefined' || typeof users === 'undefined') return <Loader />

  console.log('TaskSearchTable taskResults', taskResults);
  const data = (taskResults) ?
    Object.entries(taskResults).map(([key, value]) => { return { ...value, taskID: key } }).filter(task => task.taskID)
      .map(({
        activationTime, lastActiveDay, appAssigned, appName, assignedToUID, editedAt, expiryTime, taskName, orgID, parentActivityID, scheduleID, numberOfSubmissions, submitIn, submitted, submittedAt, submittedBy, taskPriority, taskType, taskYearMonthDay, taskID, }) => {

        return {
          lastActiveDay, appAssigned, appName, assignedToUID, taskName, orgID, parentActivityID, scheduleID, numberOfSubmissions, submitIn, submitted, submittedBy, taskPriority, taskType, taskYearMonthDay, taskID,
          activationTime: activationTime && activationTime.toDate && moment(activationTime.toDate()).format('YYYY-MMM-DD h:mm A'),
          assignedToUsers: assignedToUID && JSON.stringify(
            assignedToUID.map(
              uid => {
                const user = users.find(user => user.uid === uid);
                return user ? user.firstName + ' ' + user.lastName : ''
              })
          ).replace(/]*"*\[*/gi, ""),
          editedAt: editedAt && editedAt.toDate && moment(editedAt.toDate()).format('YYYY-MMM-DD h:mm A'),
          expiryTime: expiryTime && expiryTime.toDate && moment(expiryTime.toDate()).format('YYYY-MMM-DD h:mm A'),
          submittedAt: submittedAt && submittedAt.toDate && moment(submittedAt.toDate()).format('YYYY-MMM-DD h:mm A'),
        }
      }
      )
    : []

  const columnWith50Width = getTableColumnStyle({ width: 50, textAlign: 'center' })
  const centerAlign = getTableColumnStyle({ textAlign: 'center', maxWidth: 60 })

  return (
    <div style={{ display: 'block', width: "100%" }}>
      <Grid container>
        <Grid item sm={12} md={7} style={{ paddingLeft: 8, paddingRight: 8 }}>

          {taskQueryStatus && <MaterialTable
            onRowClick={(event, rowData) => setSelectedTask({ taskID: rowData.taskID })}
            icons={tableIcons}
            options={{
              exportButton: true, exportAllData: true, pageSize: 10, padding: "dense",
              minBodyHeight: window.innerHeight - 295,
              rowStyle: (rowData) => (selectedTask && rowData.taskID === selectedTask.taskID) ? secondaryBackgroundWhiteText : null,
              headerStyle: primaryBackgroundWhiteText,
            }}
            title={
              <Typography color="primary" variant="h5">
                {searchName ? searchName : `Task Search Results ${(data.length === 500) ? "(Maximum number of rows reached)" : ""}`}
              </Typography>}
            columns={[
              { title: "Task ID", field: "taskID", ...noPaddingStyleForTable, hidden: true },
              { title: "Task Name", field: "taskName", ...getTableColumnStyle({ minWidth: 500, textAlign: 'left', paddingLeft: 8 }), },
              { title: "Activation Time", field: "activationTime", ...columnWith50Width, },
              { title: "App Assigned", field: "appAssigned", ...noPaddingStyleForTable, hidden: true },
              { title: "App Name", field: "appName", ...noPaddingStyleForTable, hidden: true },
              { title: "Edited At", field: "editedAt", ...noPaddingStyleForTable, hidden: true },
              { title: "Scheduled", field: "numberOfSubmissions", ...centerStyleForTable, },
              { title: "No. Of Submits", field: "submitted", ...centerAlign },
              { title: "Submitted Time", field: "submittedAt", ...columnWith50Width, },
              { title: "Priority", field: "taskPriority", ...centerAlign },
              {
                title: "Type", field: "taskType", ...noPaddingStyleForLastColumn,
                render: rowData => rowData.taskType === 'userTask' ? 'User Assigned' : 'Scheduled'
              },
            ]}
            data={data}
          />}
          {/* {(data.length === 500) && <Typography style={{ textAlign: 'right', marginRight: 8 }}>Maximum number of rows reached. </Typography>} */}
        </Grid>
        <Grid item sm={12} md={5} style={{ paddingLeft: 8, paddingRight: 8 }}>
          <div className="slimscroll"
            style={{ overflowY: 'scroll', maxHeight: window.innerHeight - 174, }}>
            {taskResults && selectedTask.taskID && taskResults[selectedTask.taskID] ?
              <TaskCardContainer task={{ ...taskResults[selectedTask.taskID], taskID: selectedTask.taskID }} setSelectedApp={setSelectedApp} editUsers={true} />
              : <EmptyImagePlaceholder
                message="Select a task to view details"
                icon={<CheckBoxOutlineBlank color="primary" style={{ marginRight: 8, fontSize: 40 }} />} />
            }
          </div>
        </Grid>
      </Grid>
      {selectedApp && <AppRenderDialog app={selectedApp} profile={profile} setSelectedApp={setSelectedApp}
        submitApp={(appSubmission) => submitTaskApp(appSubmission, { taskID: selectedApp.taskID, taskName: selectedApp.taskName })} open={true} />}
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitTaskApp: (appSubmission, task) => dispatch(submitApp(appSubmission, task)),
  }
}

const mapStateToProps = (state) => {
  return {
    taskQueryStatus: state.firestore.status.requested.scheduledTaskResults,
    taskResults: { ...state.firestore.data.scheduledTaskResults, ...state.firestore.data.tasksCreatedByUser },
    users: state.firestore.data.users && Object.values(state.firestore.data.users),
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(({ appID, uid, startDate, endDate, profile, rowLimit }) => {
    let whereClause = []
    if (appID && appID !== 'all') whereClause.push(['appAssigned', '==', appID])
    if (uid && uid !== 'all') whereClause.push(['assignedToUID', 'array-contains', uid])
    if (startDate) whereClause.push(['activationTime', '>', startDate.toDate ? startDate.toDate() : startDate])
    if (endDate) whereClause.push(['activationTime', '<', endDate.toDate ? endDate.toDate() : endDate])
    console.log('TaskSearchTable', { whereClause });
    return [
      {
        collection: 'organizations', doc: profile.orgID, subcollections: [{
          collection: 'tasks',
          where: [
            ...whereClause,
            ['taskType', 'in', ['timeTask', 'periodTask']]
          ],
          orderBy: ['activationTime', 'desc'],
        }], limit: 200, storeAs: 'scheduledTaskResults'
      },
      {
        collection: 'organizations', doc: profile.orgID, subcollections: [{
          collection: 'tasks',
          where: [
            ...whereClause,
            ['taskType', '==', 'userTask'],
            ['taskByUID', '==', profile.uid],
          ],
          orderBy: ['activationTime', 'desc'],
        }], limit: 200, storeAs: 'tasksCreatedByUser'
      },
      // {
      //   collection: 'organizations', doc: profile.orgID, subcollections: [{
      //     collection: 'tasks',
      //     where: [...whereClause,
      //     ['taskType', '==', 'userTask'],
      //     ['assignedToUID', 'array-contains', profile.uid]
      //     ],
      //     orderBy: ['activationTime', 'desc'],
      //   }], limit: 200, storeAs: 'tasksAssignedToUser'
      // },
      { collection: 'users', where: [['orgID', '==', profile.orgID]], storeAs: 'users' }
    ]
  })
)(TaskSearchTable)