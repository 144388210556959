import React from 'react'
import { ResponsiveHeatMap } from '@nivo/heatmap'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const HeatMapComponent = (props) => {
  const keys = [...new Set(props.data.map(row => row.x))];
  const displayData = [...new Set(props.data.map(row => {
    const xDataOnly = props.data.filter(xData => xData.y === row.y)
    return {
      y: row.y,
      ...Object.assign({}, ...keys.map(x => { return { [x]: 0 } })),
      ...Object.assign({}, ...xDataOnly.map(xData => { return { [xData.x]: xData[props.value1Label] } }))
    }
  }))];

  const uniqueDisplayData = displayData.filter((object, index) => index === displayData.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object)));

  // console.log('HeatMapComponent', uniqueDisplayData,  keys);
  return <ResponsiveHeatMap
    padding={4}
    // height={uniqueDisplayData.length < keys.length ? uniqueDisplayData.length * 100 : keys.length * 250}
    // width={uniqueDisplayData.length > keys.length ? keys.length * 100 : null}
    data={uniqueDisplayData}
    keys={keys}
    indexBy="y"

    margin={props.isSmallScreen ? { top: 0, right: 5, left: 0, bottom: 0 }
      : { top: 100, right: 100, bottom: 60, left: 100 }}
    forceSquare={true}
    axisTop={{ orient: 'top', tickSize: 0, tickPadding: 5, tickRotation: -30, legend: '', legendOffset: 36 }}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      orient: 'left',
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    cellOpacity={.75}
    colors={'nivo'}
    cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
    labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
    defs={[
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(0, 0, 0, 0.1)',
        rotation: -45,
        lineWidth: 4,
        spacing: 7
      }
    ]}
    fill={[{ id: 'lines' }]}
    animate={true}
    motionStiffness={80}
    motionDamping={9}
    hoverTarget="cell"
    cellHoverOthersOpacity={0.25}
    theme={{
      axis: {
        ticks: {
          text: {
            fontSize: props.isSmallScreen ? 8 : 14,
          }
        }
      },
      labels: {
        text: {
          fontSize: 16,

        }
      },
    }}
  />
}


export default HeatMapComponent