import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { DynamicComponent } from '../../../utils/constants';
import ListItemsSearchSelector from '../../standard/ListItemsSearchSelector';
import MapLocationPicker from '../../standard/MapLocationPicker';
// import { GoogleMapEmbedFromLocation } from '../../standard/MapPopover';

export const selectEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState((props.value) || '');
  const [elementRef, setElementRef] = useState(null);
  const optionListID = props.colDef && props.colDef.cellEditorParams && props.colDef.cellEditorParams.optionListID


  useImperativeHandle(ref, () => ({
    getValue: () => value,
    // afterGuiAttached: () => setValue(props.value),
    focusIn: () => {
      if (elementRef) { elementRef.focus(); elementRef.select(); }
      return true
    }
  }))

  if (optionListID)
    return <ListItemsSearchSelector
      disabled={false}
      setElementRef={setElementRef}
      optionListID={optionListID}
      value={[value || null]}
      onChange={optionLabelAndValue => setValue(optionLabelAndValue ? optionLabelAndValue.value : null)}
    />
  else return value ? value : null
});



export const checkBoxEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || false);
  const [elementRef, setElementRef] = useState(null);
  useImperativeHandle(ref, () => ({ getValue: () => value, focusIn: () => { if (elementRef) { elementRef.focus(); elementRef.select(); } return true } }))

  return <Checkbox checked={value} color='secondary' style={{ marginLeft: 17 }} onClick={(e) => setValue(e.target.checked)} inputRef={r => r && setElementRef(r)} />
});


export const numberEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || null);
  const [elementRef, setElementRef] = useState(null);
  useImperativeHandle(ref, () => ({ getValue: () => value, focusIn: () => { if (elementRef) { elementRef.focus(); elementRef.select(); } return true } }))

  return <TextField inputProps={{ type: 'number' }} value={value} color='secondary'
    onChange={(e) => {
      console.log('renderTableCellEditors numberEditor', e.target.value);
      if (!Number.isNaN(Number.parseFloat(e.target.value)) || (!e.target.value || e.target.value.length === 0)) setValue(e.target.value)
    }}
    inputRef={r => r && setElementRef(r)} />
});

export const dateTimeEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || null);

  const [elementRef, setElementRef] = useState(null);

  useImperativeHandle(ref, () => ({ getValue: () => value, focusIn: () => { if (elementRef) { elementRef.focus(); elementRef.select(); } return true } }))

  let type = props.column.colDef.type;
  let format;
  if (type === 'date') { format = "yyyy-MMM-dd" }
  else if (type === 'datetime') { format = "yyyy-MMM-dd h:mm a" }
  let views = (type === 'monthYear') ? ['year', 'month'] : ((type === 'year') ? ['year'] : null)
  const DateTimeComponent = DynamicComponent[(type === 'monthYear' || type === 'year') ? 'date' : type];

  console.log('renderTableCellEditors datevalue', value, new Date(props.value));
  return <MuiPickersUtilsProvider utils={DateFnsUtils}
    style={{ width: "100%", padding: 8, marginTop: 8 }}>
    <DateTimeComponent
      {...views ? { views } : null}
      {...format ? { format } : null}
      clearable
      inputRef={ref => ref && setElementRef(ref)}
      style={{ width: "100%" }}
      onChange={setValue}
      value={value ? ((value.toDate && value.toDate()) || new Date(value)) : null} />
  </MuiPickersUtilsProvider>
});


export const locationEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || '');

  useImperativeHandle(ref, () => ({ isPopup: () => true, getValue: () => value, }))

  return <div style={{ height: "100%", width: '100%', padding: 8 }}>
    <MapLocationPicker height='200px' zoom={15} location={value} handleSave={(location) => { setValue(location); }} onChange={(location) => { setValue(location); }} /></div>;
});