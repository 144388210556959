import React, { PureComponent } from 'react';
import { Typography } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, Tooltip, } from 'recharts';

export default class VerticalBarChartComponent extends PureComponent {

  render() {
    const name1Label = this.props.name1Label;

    let maxLength = this.props.data.length > 0 ?
      this.props.data.reduce(function (a, b) { return a[name1Label] && b[name1Label] && (a[name1Label].length > b[name1Label].length) ? a : b; }) : 10
    maxLength = maxLength[name1Label] ? maxLength[name1Label].length : 10

    return (
      this.props.data.length === 0 ?
        <Typography style={{ textAlign: "center", flex: 1, }}>No Data</Typography> :
        <BarChart
          key={'BarChart' + JSON.stringify(this.props.layout)}
          // width={this.props.width || 300}
          height={Math.max(this.props.height, 300, this.props.data.length * 50)}
          width={this.props.width}
          // height={this.props.data.length * 50}
          data={this.props.data}
          layout="vertical"
          margin={this.props.isSmallScreen ? { top: 0, right: 5, left: 0, bottom: 0 }
            : { top: 10, right: 30, left: 10, bottom: 10, }}>
          <XAxis type="number"
            tickLine={false} height={120} />
          <YAxis dataKey={name1Label} tickLine={false} interval={0} type="category"
            width={maxLength * 8} textAnchor="end" tick={this.props.isSmallScreen ? { fontSize: 9 } : { fontSize: 12 }} />
          <Tooltip />
          {/**Map for multiple bars */}
          <Bar dataKey={this.props.value1Label} fill={this.props.color || "#8884d8"} />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
    );
  }
}
