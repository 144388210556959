import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import themes from './utils/themes';
import './App.css'
import { createMuiTheme, /**useTheme */ } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import SignIn from './components/auth/SignIn'
import { ifOnlineAttemptPendingUploads } from './utils/firebaseUtil'
import ForceAppUpdate from './components/standard/ForceAppUpdate'
import LoadingSplashScreen from './components/layout/LoadingSplashScreen';
import AppUpdate from './components/standard/AppUpdate';

import DashboardDirect from './components/dashboard/DashboardDirect'
import MobileHomePage from './components/home/MobileHomePage'
import AppViewerTab from './components/appviewer/AppViewerTab'
import NewOrgCreator from './components/auth/NewOrgCreator'
import NewsFeed from './components/newsfeed/NewsFeed'
import TaskListTabs from './components/tasks/TaskListTabs'
import AppRenderContainer from './components/appviewer/AppRenderContainer'
import AppSignedInRoutesContainer from './components/layout/AppSignedInRoutesContainer'
import Dashboard from './components/dashboard/Dashboard'
import { useMediaQuery, useTheme } from '@material-ui/core';
import MobilePagesRenderContainer from './components/pages/MobilePagesRenderContainer';

// const AppSignedInRoutesContainer = lazy(() => import('./components/layout/AppSignedInRoutesContainer'))
// const DashboardDirect = lazy(() => import('./components/dashboard/DashboardDirect'))
// const MobileHomePage = lazy(() => import('./components/home/MobileHomePage'))
// const AppViewerTab = lazy(() => import('./components/appviewer/AppViewerTab'))
// const NewOrgCreator = lazy(() => import('./components/auth/NewOrgCreator'))
// const NewsFeed = lazy(() => import('./components/newsfeed/NewsFeed'))
// const TaskListTabs = lazy(() => import('./components/tasks/TaskListTabs'))
// const AppRenderContainer = lazy(() => import('./components/appviewer/AppRenderContainer'))
// const Dashboard = lazy(() => import('./components/dashboard/Dashboard'))

const InboxFeedDialog = lazy(() => import('./components/newsfeed/InboxFeedDialog'))
const WebHomePage = lazy(() => import('./components/pages/WebHomePage'))
const AppBuilderTabsContainer = lazy(() => import('./components/appbuilder/AppBuilderTabsContainer'))
const AppBuilderAppList = lazy(() => import('./components/appbuilder/AppBuilderAppList'))
const UpdateProfile = lazy(() => import('./components/auth/ProfileTabs'))
const GroupPage = lazy(() => import('./components/groups/GroupPage'))
const ResourcePage = lazy(() => import('./components/resources/ResourcePage'))
const UsersRolesTabs = lazy(() => import('./components/roles/GroupsUserRolesTabs'))
const AppReports = lazy(() => import('./components/reports/submissions/AppReports'))
const TaskSearch = lazy(() => import('./components/reports/tasks/TaskSearch'))
const CardBuilder = lazy(() => import('./components/dashboardbuilder/CardBuilder'))
const MarketplaceTabContainer = lazy(() => import('./components/marketplace/MarketplaceTabContainer'))
const Scheduler = lazy(() => import('./components/scheduler/Scheduler'))
const SupportPage = lazy(() => import('./components/support/SupportPage'))
const GalleryViewer = lazy(() => import('./components/reports/activityfeed/ActivityGalleryViewer'))
const MapViewer = lazy(() => import('./components/reports/activityfeed/ActivityMapViewer'))
const OrgSettingTabs = lazy(() => import('./components/auth/OrgSettingTabs'))

const theme = createMuiTheme(themes, { ...themes.overrides });

function App() {
  const isSmallScreen = (window.innerWidth < 825);
  useMediaQuery(useTheme().breakpoints.down('md'));// Reloads entire app 1 time
  localStorage.setItem('isSmallScreen', isSmallScreen);

  window.addEventListener('online', ifOnlineAttemptPendingUploads);
  ifOnlineAttemptPendingUploads();

  return (
    <ThemeProvider theme={theme}>
      <ForceAppUpdate />
      <BrowserRouter>
        <Switch>
          <Route path='/signin' component={SignIn}></Route>
          <Route path='/neworg' component={NewOrgCreator}></Route>
          <Route path='/dashboarddirect/:orgID/:uid/:dashboardID/:dashboardCardID' component={DashboardDirect}></Route>
          <Route path='/apprender/:appID' component={AppRenderContainer}></Route>
          <AppSignedInRoutesContainer>
            <AppUpdate />
            <Route path='/appviewer' component={AppViewerTab}></Route>
            <Route path='/mobilehomepage' component={MobileHomePage}></Route>
            <Route path='/newsfeed' component={NewsFeed}></Route>
            <Route path='/tasklist' component={TaskListTabs}></Route>
            <Route path='/newsfeed/:id' component={NewsFeed}></Route>
            <Route path='/dashboard' component={Dashboard}></Route>
            <Route path='/mobilepages' component={MobilePagesRenderContainer}></Route>
            <Suspense fallback={<LoadingSplashScreen message="Loading App" />}>
              <Route exact path='/' component={isSmallScreen ? MobileHomePage : WebHomePage}></Route>
              <Route exact path='/pages/:pageID/:tab' component={WebHomePage}></Route>
              {/* <Route path='/dashboardsinglecard/:orgID/:uid/:dashboardID/:dashboardCardID' component={DashboardDirect} /> */}
              <Route path='/inbox/:type/:updatedByUID/:referenceID' component={InboxFeedDialog}></Route>
              <Route path='/webhomepage' component={WebHomePage}></Route>
              <Route path='/appbuilderapplist' component={AppBuilderAppList}></Route>
              <Route path='/appbuilder/:appID' component={AppBuilderTabsContainer}></Route>
              <Route path='/appreports' component={AppReports}></Route>
              <Route path='/querytasks' component={TaskSearch}></Route>
              <Route path='/dashboardbuilder' component={CardBuilder}></Route>
              <Route path='/groups' component={GroupPage}></Route>
              <Route path='/resources' component={ResourcePage}></Route>
              <Route path='/updateprofile' component={UpdateProfile}></Route>
              <Route path='/useradmin' component={UsersRolesTabs}></Route>
              <Route path='/marketplace' component={MarketplaceTabContainer}></Route>
              <Route path='/scheduler' component={Scheduler}></Route>
              <Route path='/supportpage' component={SupportPage}></Route>
              <Route path='/gallery' component={GalleryViewer}></Route>
              <Route path='/mapviewer' component={MapViewer}></Route>
              <Route path='/orgsettings' component={OrgSettingTabs}></Route>
            </Suspense>
          </AppSignedInRoutesContainer>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;