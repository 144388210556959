import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from './Loader'
import StandardSearchSelector from './StandardSearchSelector'
import ViewAgenda from '@material-ui/icons/ViewAgenda';
import EmptyImagePlaceholder from './EmptyImagePlaceholder'

const CardSearchSelector = ({ cards, inputLabel, keepFirstValue, style, quickEdit, selectedValues, handleSave }) => {
  // console.log('CardSearchSelector rendered', { selectedValues });

  if (typeof cards === 'undefined') return <Loader />

  const cardsArray = cards && Object.entries(cards).filter(([id, card]) => card && card.cardID !== 'cardBuilder')
    .map(([id, card]) => ({
      label: card.cardName,
      value: id,
      // ...card
    }))
  console.log('CardSearchSelector', cardsArray);

  if (typeof cardsArray === 'undefined') return <Loader />
  else if (cardsArray === null || cardsArray.length === 0) return <EmptyImagePlaceholder message='No Cards Created Yet' imageType='office' />
  else return <StandardSearchSelector
    id='cardSearchSelector'
    multiple={false}
    inputLabel={inputLabel || <ViewAgenda style={{ marginRight: 4, }} />}
    allValuesAndLabels={cardsArray.map(value => value)}
    keepFirstValue={keepFirstValue || false} style={style}
    quickEdit={quickEdit} selectedValues={selectedValues.map(values => values)}
    handleSave={handleSave} />
}

const mapStateToProps = (state) => {
  return {
    cards: state.firestore.data.cards,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'cards', orderBy: ['cardName', 'asc'] }], storeAs: 'cards'
      },]
  }
  )
)(CardSearchSelector)