import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import TaskList from './TaskList'

const ExpiredTaskListContainer = ({ expiredTasksData }) => {

  const expiredTasks = expiredTasksData && Object.entries(expiredTasksData)
    .map(([key, value]) => { return { ...value, taskID: key } })
    .filter(task => task.taskType)

  const taskList = expiredTasks && expiredTasks.length > 0 ? expiredTasks : [];

  return <TaskList taskList={taskList} noResults={expiredTasks === null} />
}

const mapStateToProps = (state) => {
  return {
    expiredTasksData: state.firestore.data.expiredTasks,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'tasks' }],
        where: [
          ['expired', '==', true],
          ['assignedToUID', 'array-contains', props.profile.uid],
        ],
        limit: props.rowLimit || 5,
        orderBy: [['expiryTime', 'desc']], storeAs: 'expiredTasks'
      },
    ]
  }
  )
)(ExpiredTaskListContainer)