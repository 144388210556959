import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Settings from '@material-ui/icons/Settings';
import { TabPanel } from '../standard/TabPanel';
import { displayLeft } from '../../utils/styleclasses';
import { EmbedIframe } from './EmbedIframe';
import PageSettings from './PageSettings';
import { primary } from '../../utils/themes';
import tinycolor from "tinycolor2";
import GridTabContainer from '../grid/GridTabContainer';

export default function SubPage({ page, setPage, updatePage, isPageEditor, refreshPageCards, color }) {

  const [tabValue, setTabValue] = React.useState(1);
  const [tabViewed, setTabViewed] = React.useState({ 1: true });

  const handleChange = (event, newValue) => { setTabValue(newValue); if (!tabViewed[newValue]) setTabViewed({ ...tabViewed, [newValue]: true }) }
  const currentColor = color || primary;
  // const cardStyle = { overflowY: 'scroll', height: window.innerHeight - 150, margin: 8 };
  const tabs = page && page.tabs && Array.isArray(page.tabs) ? page.tabs : [];
  console.log('SubPage', { page });
  return <div>
    <AppBar position="sticky" style={{
      marginTop: 8, backgroundColor: currentColor,
    }}>
      <div style={displayLeft}>
        <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {<Tab style={{ padding: 0, width: 50, minWidth: 50 }} label={<Settings />} value={0} />}
          {tabs.map((row, i) => <Tab key={row.tabID} value={i + 1} style={{ minWidth: 100 }}
            label={row.tabLabel} />)}
        </Tabs>
      </div>
    </AppBar>
    {page && isPageEditor && tabValue === 0 && <PageSettings {...{ isPageEditor, isMyPage: false, tabValue, selectedPage: page, setSelectedPage: setPage, tabs, updatePage, pages: [page], deletePage: null, isSubPage: true }} />}
    {tabs.map((row, i) =>
      <TabPanel key={row.tabID} value={tabValue} index={i + 1}>
        {row.tabType === 'embed' && row.embedType === 'link' && row.embedLink && <EmbedIframe src={row.embedLink} />}
        {row.tabType === 'page' && <SubPage
          isPageEditor={isPageEditor}
          color={tinycolor(currentColor).brighten(5).toHexString()}
          page={row.subPage || { pageID: row.tabID, tabs: [] }}
          setPage={subPage => setPage({ ...page, tabs: page.tabs.map(row2 => row2.tabID === row.tabID ? { ...row2, subPage } : row2) })}
          updatePage={updatePage}
        />}
        {row.tabType === 'grid' && tabViewed[i + 1]
          && <GridTabContainer
            tabID={row.tabID}
            isPageEditor={isPageEditor}
            refreshPageCards={refreshPageCards}
            gridLayout={row.gridLayout}
            gridItems={row.gridItems}
            saveGrid={(saveObject) => {
              const updatedPage = { ...page, tabs: page.tabs.map(tab => tab.tabID === row.tabID ? { ...tab, ...saveObject } : tab) };
              console.log('SubPage layout', updatedPage);

              updatePage(updatedPage, page.pageID); setPage(updatedPage)
            }}
          />}

      </TabPanel>)}
  </div>
}



