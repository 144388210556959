import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CreateTask from '../tasks/CreateTask';
import HelpWidget from '../support/HelpWidget';
import { Chip } from '@material-ui/core';
import { displayLeft } from '../../utils/styleclasses';
import WhatsappIcon from '../img/Whatsapp-icon.png'


export default function FloatingSpeedDial(props) {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  let actions = [
    { icon: <CreateTask handleClick={handleClose} style={{}} />, name: 'Create a new task' },
    { icon: <HelpWidget />, name: 'Contact Support Chat' },
  ];

  if (props.canAddUsers) {
    actions.push({
      icon: <a href={`https://api.whatsapp.com/send?text=Sign up for Opstack at https://app.opstack.co/signin?orgID=${props.profile.orgID} Our Org ID is ${props.profile.orgID}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', textAlign: 'left' }} >
        <Chip color='primary' label={<span style={displayLeft} ><img src={WhatsappIcon} alt="Whatsapp" width={22} style={{ marginRight: 4, }} />Add User</span>} /></a>, name: 'Invite New Users'
    })
  }

  return (
    <SpeedDial
      direction={props.direction || 'up'}
      style={{ position: 'absolute', bottom: 16, right: 16, marginRight: 16, ...props.style }}
      ariaLabel="SpeedDial" name='homePageSpeedDial'
      icon={<SpeedDialIcon onClick={handleOpen} />}
      onClose={handleClose}
      open={open}
    >
      {actions.map((action) => (<SpeedDialAction key={action.name}
        FabProps={{
          variant: 'extended',
          style: {
            boxShadow: "none",
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          }
        }}
        icon={action.icon}
        tooltipTitle={action.name}
        // tooltipOpen
        open={false}
        onClick={handleClose}
      />
      ))}
    </SpeedDial>
  );
}

