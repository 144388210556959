import React from 'react'
import Loader from './Loader'
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import { displayLeft } from '../../utils/styleclasses'
import { makeStyles } from "@material-ui/core/styles";

const StandardSearchSelector = ({ selectedValues, quickEdit, style, keepFirstValue, handleSave, allValuesAndLabels, inputLabel, noEdit, id, multiple, fieldLabel, placeholder, returnWithLabels }) => {

  const [values, setValues] = React.useState(selectedValues || []);
  const [editOpen, setEditOpen] = React.useState(quickEdit || false);
  const useStyles = makeStyles(theme => ({ inputRoot: { color: (style && style.color) || null, padding: 0 } }));
  const classes = useStyles();

  React.useEffect(() => {
    if (JSON.stringify(selectedValues) !== JSON.stringify(values)) {
      setValues(selectedValues);
      console.log('StandardSearchSelector Render - selectedValues', selectedValues, values);

    }
  }, [selectedValues])

  // React.useEffect(() => setValues(selectedValues), [selectedValues])

  const onSave = (saveValues) => {
    const saveList = [...new Set([keepFirstValue ? selectedValues[0] : null, ...saveValues])].filter(row => row);
    // console.log('StandardSearchSelector handleSave', { saveList });
    handleSave(saveList, saveList.map(uid => allValuesAndLabels.find(row => row.value === uid)).filter(row => row));
  }

  if (typeof allValuesAndLabels === 'undefined' || !allValuesAndLabels || allValuesAndLabels.length === 0) return <Loader />

  const selectedValuesAndLabels =
    values && values.length > 0 ? values.map(value =>
      allValuesAndLabels.find(row => row.value === value)).filter(row => row) : []

  return (
    <div style={{ ...displayLeft, width: '100%', }}>
      {inputLabel}
      {((!editOpen && !quickEdit) || noEdit) &&
        <span style={{ ...displayLeft, }}>
          <Typography>
            {selectedValuesAndLabels.map((option, i) => option.label + ((i === selectedValuesAndLabels.length - 1) ? "" : ", "))}
          </Typography>
          {!noEdit && <Edit style={{ fontSize: 20, marginLeft: 8 }} onClick={() => setEditOpen(true)} />}
        </span>
      }
      {editOpen && !noEdit &&
        <span style={{ ...displayLeft, ...style || { width: '100%' } }}>
          {allValuesAndLabels.length > 0 &&
            <Autocomplete
              classes={classes}
              id={id}
              name={id}
              disableClearable={keepFirstValue}
              multiple={multiple === false ? false : true}
              filterSelectedOptions={true}
              options={allValuesAndLabels}
              onChange={(event, value) => {
                console.log(' StandardSearchSelector onChange', { value });
                if (!(keepFirstValue && value.length === 0)) {
                  const newSelectedValues = value && value.map ?
                    (returnWithLabels ? value : value.map(listValue => listValue.value)) : [value]
                  quickEdit ? onSave(newSelectedValues) : setValues(newSelectedValues)
                }
              }}
              getOptionLabel={option => option.label}
              value={multiple === false ? (selectedValuesAndLabels[0] || null) : selectedValuesAndLabels}
              renderTags={
                (value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip color="primary" variant="outlined" label={option.label} {...getTagProps({ index })}
                      disabled={keepFirstValue && (option.value === selectedValues[0])} />
                  ))
                }
              }
              style={{ width: '100%', ...style }}
              renderInput={params => (
                <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} label={fieldLabel || null} variant="standard"
                  placeholder={placeholder || ""} style={{ minWidth: "200px", width: '100%', }} />
              )}
            />
          }
          {!quickEdit &&
            <Check style={{ fontSize: 24, marginLeft: 8 }} onClick={() => { onSave(values); setEditOpen(false) }} />}
        </span>
      }
    </div>
  )
}

export default StandardSearchSelector