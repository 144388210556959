import React from 'react'
import ActivityCard from '../newsfeed/ActivityCard'
import ParentActivityCard from './SingleActivityCard'
import { getFirestore } from 'redux-firestore'
import { LinearProgress } from '@material-ui/core'

const SingleActivityCard = ({ activityID, activityReference, hideFooter, summary, hideParent, footerOptions }) => {
  // console.log('$$$ SingleActivityCard with Parent Activity ID$$$', props);
  const [activity, setActivity] = React.useState(0);
  // const firestore = getFirestore();

  if (activityReference && !activity) {
    console.log("Loading from activityReference");
    activityReference.get().then(doc => setActivity({ ...doc.data(), activityID: doc.id }))
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (activityID && !activityReference && !activity) {
        console.log("Loading from activityID");
        const firestore = getFirestore();
        const result = await firestore.collection(`organizations/${localStorage.getItem('orgID')}/activityfeed`).doc(activityID).get();
        setActivity({ ...result.data(), activityID: result.id });
        // console.log(' SingleActivityCard useEffect', activityID, result.data())
      }
    };
    fetchData();
  }, [activityID, activityReference, activity]);

  return <div>
    {activity ? <ActivityCard activity={activity} cardMargin={0} hideFooter={!!hideFooter}
      activityID={activity.activityID || activity.id} summary={summary} footerOptions={footerOptions} />
      : <LinearProgress />
    }
    {
      (activity.parentActivityID || activity.parentActivityReference) && !hideParent &&
      <div>
        <ParentActivityCard style={{ margin: 0 }} hideFooter={true}
          displayActivities={1} type="all" activityID={!activity.parentActivityReference && activity.parentActivityID} activityReference={activity.parentActivityReference}
        />
      </div>
    }
  </div>
}

export default SingleActivityCard