import React from 'react'
import TitleAppBar from '../../standard/TitleAppBar';
import CheckCircle from '@material-ui/icons/CheckCircle';
import QueryBuilderBar from '../QueryBuilderBar'
import TaskSearchTable from './TaskSearchTable';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';

export default function TaskSearch({ search, embedView }) {
  const [reportQuery, setReportQuery] = React.useState(search || {});
  console.log('TaskSearch', { search });
  return <div>
    {!embedView && <TitleAppBar icon={<CheckCircle />}>Task Search</TitleAppBar>}
    <QueryBuilderBar setReportQuery={setReportQuery} disableAllUsers={false} usersRequired={true} allApps={true} embedView={embedView} contentDisplayType="tasks" reportQuery={reportQuery} />
    {reportQuery.appID ? <TaskSearchTable {...reportQuery} /> : <EmptyImagePlaceholder message="Your task search results will appear here" imageType="bench" />}
  </div >
}