import React from 'react'
import TitleAppBar from '../../standard/TitleAppBar';
import Search from '@material-ui/icons/Search';
import QueryBuilderBar from '../QueryBuilderBar'
import ReportTable from './AppReportTable';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';
import { isJSON } from '../../../utils/utilityfunctions';

export default function AppReports({ search, embedView }) {
  let storedQuery = isJSON(localStorage.getItem('appReportQuery'));
  if (storedQuery && storedQuery.startDate && storedQuery.endDate) {
    storedQuery.startDate = new Date(storedQuery.startDate)
    storedQuery.endDate = new Date(storedQuery.endDate)
  }
  const [reportQuery, setReportQuery] = React.useState(search || storedQuery || null);
  const appReportQuery = JSON.stringify(reportQuery);
  if (reportQuery) localStorage.setItem('appReportQuery', appReportQuery)
  // console.log('AppReports', { reportQuery });
  return <div>
    <TitleAppBar icon={<Search />}>App Submission Reports</TitleAppBar>
    <QueryBuilderBar setReportQuery={setReportQuery} embedView={embedView} reportQuery={reportQuery} contentDisplayType="appReports"
      usersRequired={true} />
    {reportQuery && reportQuery.appID ?
      <ReportTable {...reportQuery} key={appReportQuery} />
      : <EmptyImagePlaceholder
        message="Your apps submission data will appear here"
        imageType="office"
      />
    }
  </div>
}