import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { submitApp } from '../../store/actions/formActions'
import AppRenderDialog from '../appviewer/AppRenderDialog'
import TaskList from './TaskList'

const TaskCreatedContainer = ({ taskList, profile, submitApp }) => {
  const [selectedApp, setSelectedApp] = React.useState(null);

  if (profile && !profile.tasklist) return <Redirect to='/appviewer'></Redirect>

  console.log('TaskCreatedContainer', { taskList });

  const taskArray = taskList ? Object.entries(taskList)
    .map(([key, value]) => { return { ...value, taskID: key } })
    .filter(task => task.taskType) : []


  return <div>
    <TaskList taskList={taskArray} noResults={taskList === null} setSelectedApp={setSelectedApp} />
    {selectedApp &&
      <AppRenderDialog app={selectedApp} profile={profile} setSelectedApp={setSelectedApp}
        submitApp={(appSubmission) => { submitApp(appSubmission, { taskID: selectedApp.taskID, taskName: selectedApp.taskName }) }} open={true} />}
  </div>
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitApp: (appSubmission, task) => dispatch(submitApp(appSubmission, task)),
  }
}

const mapStateToProps = (state) => {
  // console.log(' mapStateToProps = (state)', state);
  return {
    taskList: state.firestore.data.assignedTasks,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'tasks' }],
        where: [
          ['taskByUID', '==', props.profile.uid]
        ],
        limit: props.rowLimit || 5,
        orderBy: [['editedAt', 'desc']], storeAs: 'assignedTasks'
      },
    ]
  }
  )
)(TaskCreatedContainer)
