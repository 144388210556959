import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
// import { useStyles } from '../../utils/styleclasses'

export default function Loader() {
  // const classes = useStyles();

  return (
    // <div className={classes.root}>
    <LinearProgress value={50} />
    // </div>
  );
}