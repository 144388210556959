import React from "react";
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import 'react-resizable/css/styles.css';
import { compose } from 'redux';
import { updateDashboardCard } from '../../../store/actions/formActions';
import RenderCard from '../../dashboardbuilder/card/RenderCard';

const AppRenderCardComponent = ({ field, card, appCard, cardID, updateDashboardCard, profile, handleFilterValueChange, dataPathPrefix }) => {

  const renderCard = { ...card, ...appCard, dashboardCardID: field.fieldID, }

  if (cardID && (appCard === null || (appCard && !appCard.requestID))) {
    updateDashboardCard(cardID, renderCard, `${dataPathPrefix}-` + field.fieldID, { skipDataRequest: false, updateOnlyIfEmpty: true })
  }

  return (
    <div >
      {card && cardID &&
        <RenderCard
          dataPath={`organizations/${profile.orgID}/userdata/${profile.uid}/${dataPathPrefix}-${field.fieldID}/${cardID}/card-data`}
          handleFilterValueChange={handleFilterValueChange}
          card={renderCard}
          hideHeader={field.fieldSettings.hideHeader}
          updateDashboardCard={updateDashboardCard}
        />
      }
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardCard: (dashboardCardID, card, dashboardID, options) => dispatch(updateDashboardCard(dashboardCardID, card, dashboardID, options)),
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('AppRenderCardComponent mapStateToProps = (state)', state);
  return {
    profile: state.firebase.profile,
    card: state.firestore.data['card' + ownProps.cardID],
    appCard: state.firestore.data['appCard' + ownProps.field.fieldID + ownProps.cardID]
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'cards', doc: props.cardID }], storeAs: 'card' + props.cardID },
      {
        collection: `organizations/${props.profile.orgID}/userdata/${props.profile.uid}/${props.dataPathPrefix}-` + props.field.fieldID, doc: props.cardID,
        storeAs: 'appCard' + props.field.fieldID + props.cardID
      },
    ]
  }
  )
)(AppRenderCardComponent)