import React from 'react'
import Share from '@material-ui/icons/Share';
import WhatsappIcon from '../img/Whatsapp-icon.png'
import { displayLeft, displayCenter } from '../../utils/styleclasses';
import { compose } from 'redux'
import { connect } from 'react-redux'

function ShareButton({ title, text, url, type, referenceID, profile }) {
  const whatsappLink = `https://api.whatsapp.com/send?text=Sharing activity from Opstack https://app.opstack.co/inbox/${type}/${profile.uid}/${referenceID}`

  return (
    <span style={displayLeft}>
      <Share color={navigator.share ? "primary" : "disabled"} style={{ margin: 0, marginLeft: 4 }} onClick={() => {
        if (navigator.share) {
          navigator.share({
            title: title,
            text: text,
            url: `https://app.opstack.co/inbox/${type}/${profile.uid}/${referenceID}`,
          })
            .then(() => console.log('Successful share'))
            .catch((err) => console.error('Error sharing', err));
        }
      }
      } />
      {<a href={whatsappLink} target="_blank" rel="noopener noreferrer" >
        <img src={WhatsappIcon} alt="Whatsapp" width={22}
          style={{ display: "inline", alignItems: "center", justifyContent: "center", margin: 0, marginLeft: 8, marginTop: 4 }} />
      </a>
      }
    </span>
  )
}

const mapStateToProps = (state) => ({ profile: state.firebase.profile })

export default compose(connect(mapStateToProps, null))(ShareButton)