import React from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { updateDashboardCard, updateDashboard, deleteDashboardCard, } from '../../store/actions/formActions'
import DashboardResponsiveGrid from "./DashboardResponsiveGrid";

class Dashboard extends React.PureComponent {
  render() {
    if (typeof this.props.dashboardData === 'undefined') return null

    const dashboardCardList = this.props.dashboardData ? Object.values(this.props.dashboardData)
      .filter(rowData => rowData && rowData.dashboardCardID && rowData.dashboardCardID !== 'cardBuilder')
      .map(rowData => { return { dashboardCardID: rowData.dashboardCardID, selectedDateRange: rowData.selectedDateRange } }) : []


    if (this.props.userDashboardList && this.props.userDashboardList[this.props.selectedDashboardID].layouts) {
      localStorage.setItem(`gridLayout-${this.props.selectedDashboardID}`, JSON.stringify({ layouts: this.props.userDashboardList[this.props.selectedDashboardID].layouts }))
    }

    return (
      <div>
        {dashboardCardList && dashboardCardList.length > 0 && this.props.selectedDashboardID &&
          <DashboardResponsiveGrid
            isSmallScreen={this.props.isSmallScreen}
            editOpen={this.props.editOpen}
            profile={this.props.profile}
            layouts={global.localStorage.getItem(`gridLayout-${this.props.selectedDashboardID}`) ?
              JSON.parse(global.localStorage.getItem(`gridLayout-${this.props.selectedDashboardID}`)).layouts : {}}
            key={this.props.selectedDashboardID}
            selectedDashboardID={this.props.selectedDashboardID}
            dashboardCardList={dashboardCardList}
            dashboardData={this.props.dashboardData}
            updateDashboardCard={
              (dashboardCardID, card, ignoreDashboardID, skipDataRequest) => this.props.updateDashboardCard(dashboardCardID, card, this.props.selectedDashboardID, { skipDataRequest })}
            deleteDashboardCard=
            {(dashboardCardID) => this.props.deleteDashboardCard(dashboardCardID, this.props.selectedDashboardID)} />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardCard: (dashboardCardID, card, dashboardID, options) => dispatch(updateDashboardCard(dashboardCardID, card, dashboardID, options)),
    updateDashboard: (dashboard, deleteDashboard) => dispatch(updateDashboard(dashboard, deleteDashboard)),
    deleteDashboardCard: (dashboardCardID, dashboardID) => dispatch(deleteDashboardCard(dashboardCardID, dashboardID)),
  }
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.firebase.profile,
    dashboardData: state.firestore.data[props.selectedDashboardID],
    userDashboardList: state.firestore.data.userDashboardList && state.firestore.data.userDashboardList.userDashboardList
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'userdata', doc: props.profile.uid, subcollections: [{ collection: props.selectedDashboardID, }] }], storeAs: props.selectedDashboardID
      },
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'userdata', doc: props.profile.uid }], storeAs: 'userDashboardList'
      }]
  },
  )
)(Dashboard)
