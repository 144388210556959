import React from "react"
import Group from '@material-ui/icons/GroupTwoTone';
// import GroupWork from '@material-ui/icons/GroupWorkTwoTone';
import PhoneIphone from '@material-ui/icons/PhoneIphoneTwoTone';
// import PowerSettingsNew from '@material-ui/icons/PowerSettingsNewTwoTone';
import Collections from '@material-ui/icons/CollectionsTwoTone';
import BorderColor from '@material-ui/icons/BorderColorTwoTone';
import CheckCircle from '@material-ui/icons/CheckCircleTwoTone';
import Assignment from '@material-ui/icons/AssignmentTwoTone';
import DynamicFeed from '@material-ui/icons/DynamicFeedTwoTone';
// import Dashboard from '@material-ui/icons/DashboardTwoTone';

import Folder from '@material-ui/icons/FolderTwoTone';
import Search from '@material-ui/icons/SearchTwoTone';
import Schedule from '@material-ui/icons/ScheduleTwoTone';
import InsertChart from '@material-ui/icons/InsertChartTwoTone';
import Settings from '@material-ui/icons/SettingsTwoTone';
import { DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Home from '@material-ui/icons/Home';
import Map from '@material-ui/icons/Map';
import ShoppingCart from '@material-ui/icons/ShoppingCartTwoTone';
import PageIcon from '@material-ui/icons/MenuBook';
import AssignmentChecked from '@material-ui/icons/AssignmentTurnedInTwoTone';
// const uuid = require('uuid/v4')


export const EmailRegEx = (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i).source;

export function fieldIDGenerator() { return new Date().getTime().toString(16) + Math.random().toString(36).substring(2, 15); }

export const getShortID = () => Math.random().toString(36).substring(2, 8);

export const SignedInLinkList = [
  { path: '/webhomepage', linkName: 'My Pages', iconName: (<PageIcon color="primary" />), permission: 'useforms', smallScreen: false },
  { path: '/newsfeed', linkName: 'My Channels', iconName: (<DynamicFeed color="primary" />), permission: 'useforms', smallScreen: false },
  { path: '/tasklist', linkName: 'My Tasks', iconName: (<CheckCircle color="primary" />), permission: 'useforms', smallScreen: false },
  { path: '/appviewer', linkName: 'My Apps', iconName: (<Assignment color="primary" />), permission: 'useforms', smallScreen: false },
  { path: '/gallery', linkName: 'Gallery View', iconName: (<Collections color="primary" />), permission: 'viewreports', smallScreen: false, dataTut: "tourReports3" },
  { path: '/querytasks', linkName: 'Task Search', iconName: (<AssignmentChecked color="primary" />), permission: 'scheduler', smallScreen: false, },
  { path: '/appreports', linkName: 'App Reports', iconName: (<Search color="primary" />), permission: 'viewreports', smallScreen: false, dataTut: "tourReports1" },
  { path: '/mapviewer', linkName: 'Map View', iconName: (<Map color="primary" />), permission: 'viewreports', smallScreen: false, dataTut: "tourReports2" },

  // { path: '/appbuilderapplist', linkName: 'App Editor', iconName: (<BorderColor color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourAppEditor" },
  // { path: '/appbuilder/create', linkName: 'New App', iconName: (<PhoneIphone color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourNewApp" },
  // { path: '/marketplace', linkName: 'App Marketplace', iconName: (<ShoppingCart color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourAppMarketplace" },
  // { path: '/resources', linkName: 'Resources', iconName: (<Folder color="primary" />), permission: 'editresources', smallScreen: false },
  // { path: '/scheduler', linkName: 'Task Scheduler', iconName: (<Schedule color="primary" />), permission: 'scheduler', smallScreen: false },
  // { path: '/dashboardbuilder', linkName: 'Card Builder', iconName: (<InsertChart color="primary" />), permission: 'dashboardbuilder', smallScreen: false },
  // { path: '/useradmin', linkName: 'Users & Groups', iconName: (<Group color="primary" />), permission: 'useradmin', smallScreen: true },
  // { path: '/orgsettings', linkName: 'Org Settings', iconName: (<Settings color="primary" />), permission: 'orgOwner', smallScreen: true },
]

export const advancedNavigationLinks = [
  { path: '/appbuilderapplist', linkName: 'App Editor', iconName: (<BorderColor color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourAppEditor" },
  { path: '/appbuilder/create', linkName: 'New App', iconName: (<PhoneIphone color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourNewApp" },
  { path: '/marketplace', linkName: 'App Marketplace', iconName: (<ShoppingCart color="primary" />), permission: 'editforms', smallScreen: false, dataTut: "tourAppMarketplace" },
  { path: '/resources', linkName: 'Resources', iconName: (<Folder color="primary" />), permission: 'editresources', smallScreen: false },
  { path: '/scheduler', linkName: 'Task Scheduler', iconName: (<Schedule color="primary" />), permission: 'scheduler', smallScreen: false },
  { path: '/dashboardbuilder', linkName: 'Card Builder', iconName: (<InsertChart color="primary" />), permission: 'dashboardbuilder', smallScreen: false },
  { path: '/useradmin', linkName: 'Users & Groups', iconName: (<Group color="primary" />), permission: 'useradmin', smallScreen: true },
  { path: '/orgsettings', linkName: 'Org Settings', iconName: (<Settings color="primary" />), permission: 'orgOwner', smallScreen: true },
]


export const BottomNavigationLinks = [
  { path: '/mobilehomepage', linkName: 'Home', iconName: (<Home />), permission: 'useforms', smallScreen: true },
  { path: '/tasklist', linkName: 'Tasks', iconName: (<CheckCircle />), permission: 'tasklist', smallScreen: true },
  { path: '/appviewer', linkName: 'Apps', iconName: (<Assignment />), permission: 'useforms', smallScreen: true },
  { path: '/newsfeed', linkName: 'Actions', iconName: (<DynamicFeed />), permission: 'newsfeed', smallScreen: true },
]

export const cDebug = false;

export const DynamicComponent = {
  switch: Switch,
  checkbox: Checkbox,
  time: TimePicker,
  datetime: DateTimePicker,
  date: DatePicker
}