import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Error from '@material-ui/icons/Error'

function AppUpdate(props) {
  const buildID = localStorage.getItem('buildID');
  // console.log("AppUpdate", props.appSettings && props.appSettings.minBuildID, props.orgLevelAppSettings && props.orgLevelAppSettings.minBuildID ? props.orgLevelAppSettings.minBuildID : 0, buildID);

  if (buildID && props.appSettings && props.appSettings.minBuildID && (
    Number(props.appSettings.minBuildID) > Number(buildID)
    || Number(props.orgLevelAppSettings && props.orgLevelAppSettings.minBuildID ? props.orgLevelAppSettings.minBuildID : 0) > Number(buildID))) {
    return (
      <div>
        <Card style={{ margin: 8, paddingTop: 16, paddingBottom: 16, justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
          <Typography variant="h6">Update Available</Typography>
          <Button onClick={() => {
            return navigator.serviceWorker.getRegistration()
              .then(registration => registration ? registration.unregister() : null)
              .then(() => window.location.reload(true))
          }} style={{ marginLeft: 8 }} variant="contained" color="primary">
            <Error style={{ marginRight: 8, }} />
            Update Now</Button>
        </Card>
      </div>
    )
  }
  else return null
}

const mapStateToProps = (state, ownProps) => {

  return {
    profile: state.firebase.profile,
    appSettings: state.firestore.data.appsettings,
    orgLevelAppSettings: state.firestore.data.orgLevelAppSettings,
  }
}

export default compose(connect(mapStateToProps, null),
  firestoreConnect(props => {
    // console.log('AppUpdate', props.profile.orgID);
    let queryArray = [
      { collection: 'async/appsettings/orgLevelSettings', doc: props.profile.orgID || 'NoOrg', storeAs: 'orgLevelAppSettings' },
      { collection: 'async', doc: "appsettings", storeAs: 'appsettings' }
    ]

    return queryArray
  }
  ))(AppUpdate)