import React, { useRef, useState } from "react";
import Divider from "@material-ui/core/Divider";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import RenderCardContent from './RenderCardContent'
import RenderCardHeader from './RenderCardHeader'
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import firebase from 'firebase/app'
import 'firebase/functions'
import { useSelector } from "react-redux";

const RenderCard =
  ({ card, hideDate, hideFilterSelect, hideHeader, updateDashboardCard, deleteDashboardCard, isSmallScreen, heightRef }) => {
    const [queryResults, setQueryResults] = useState({});//pendingFetch
    const profile = useSelector(state => state.firebase.profile)
    const pendingFetch = 'Pending Fetch'
    let { queryString, startDate, endDate, filters, } = card
    const { data, queryError, status } = queryResults

    React.useEffect(() => {
      function fetchData() {
        var queryCardData = firebase.app().functions("us-central1").httpsCallable('queryCardData');
        console.log('RenderCard fetching data');
        if (startDate && startDate.toDate) startDate = startDate.toDate()
        if (endDate && endDate.toDate) endDate = endDate.toDate()
        return queryCardData({ queryString, startDate, endDate, filters, orgID: profile.orgID })
          .then(({ data }) => {
            console.log('RenderCard', { ...data });
            setQueryResults(({ ...data, updatedAt: new Date() }))
          });
      };
      if (queryResults.status === pendingFetch) { fetchData() }
    }, [queryResults]);

    React.useEffect(() => {
      console.log('Rendercard useEffect Fetch Data');
      setQueryResults({ status: pendingFetch })
    }, [queryString, startDate, endDate, filters,])

    return (
      <div>
        {card && !hideHeader &&
          <RenderCardHeader
            dateSelectionType={card.dateSelectionType}
            isSmallScreen={isSmallScreen}
            updatedAt={queryResults.updatedAt || new Date()}
            cardName={card.cardName}
            queryString={card.queryString}
            cardID={card.cardID}
            handleDelete={deleteDashboardCard ?
              () => deleteDashboardCard(card.dashboardCardID) : null}
            hideDate={hideDate}
            handleColorChange={(hex) => {
              updateDashboardCard(card.dashboardCardID, { ...card, cardColor: hex }, null, { skipDataRequest: true });
            }}
            refreshCard={() => { setQueryResults({ status: pendingFetch }) }}
            startDate={card.startDate}
            endDate={card.endDate}
            selectedDateRange={card.selectedDateRange || "Yesterday"}
            setDateRange={
              (startDate, endDate, getDateRange, selectedDateRange) => {
                let modifiedCard = {
                  ...card,
                  startDate: getDateRange instanceof Function ? getDateRange().start.toDate() : startDate,
                  endDate: getDateRange instanceof Function ? getDateRange().end.toDate() : endDate,
                  pendingRefresh: true
                }
                if (selectedDateRange) modifiedCard.selectedDateRange = selectedDateRange
                // console.log("Updating dashboard", { modifiedCard });
                updateDashboardCard(card.dashboardCardID, { ...card, ...modifiedCard });
              }
            }
          />
        }
        <Divider />
        {
          card && !queryError && (data && !(status === pendingFetch) ?
            <RenderCardContent key={card.responseID}
              hideFilterSelect={hideFilterSelect}
              height={(heightRef && heightRef.current && heightRef.current.clientHeight && (heightRef.current.clientHeight - 56)) || null}
              isSmallScreen={isSmallScreen}
              responseID={card.responseID}
              style={{}}
              data={data}
              value1Label={card.value1Label}
              name1Label={card.name1Label}
              dataSeries={card.dataSeries}
              card={card}
              responsive={true}
              layout={card || ''}
              // width={window.innerWidth / 2.3}
              handleFilterValueChange={(filter, filterIndex) => {
                let updatedFilters = [...card.filters];
                updatedFilters[filterIndex] = filter;
                updateDashboardCard(card.dashboardCardID, { ...card, filters: updatedFilters });
              }} />
            : data === null ? <Typography variant='h6' color="primary" style={{ margin: 16 }}> No Data Found</Typography> :
              <div style={{ margin: 16 }}>Downloading Data <LinearProgress color="secondary" /></div>)
        }
        {card && queryError &&
          <div style={{ textAlign: 'left', width: '100%', padding: 16, margin: 16 }}>
            {queryError.includes("Not found: Dataset") ?
              <Typography variant='h5' color="primary"> No Data Found</Typography>
              : <div>
                <Typography variant='h5' color="primary"> Please check the query for the following error:</Typography>
                <Typography variant='h5'> {queryError}</Typography>
              </div>
            }
          </div>}
      </div >
    )
  }

export default function RenderCardHeightWrapper(props) {
  const ref = useRef(null);
  return <div ref={ref} style={{ minHeight: '100%', minWidth: 0 }}    >
    {props.card && <RenderCard {...props} heightRef={ref} />
    }
  </div>
}