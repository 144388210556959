import PersonalVideo from '@material-ui/icons/PersonalVideo';
import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import EmptyImagePlaceholder from '../standard/EmptyImagePlaceholder';
import Loader from '../standard/Loader';
import SingleActivityCard from '../tasks/SingleActivityCard';
import ActivityCard from './ActivityCard';

const ActivityFeed = (props) => {

  sessionStorage.setItem('hqImage', props.profile && props.profile.feedImageSettings === 'high' ? true : false)
  console.log("Rendering ActivityFeed", props);

  return (
    <div >
      {
        (props.activityFeed &&
          props.activityFeed.length > 0) ?
          props.activityFeed.map((activity, index) => {
            const channelSettings = activity.activityChannelSettings && props.selectedChannel && activity.activityChannelSettings.find(rowData => rowData.channelID === props.selectedChannel);

            return <div style={{ padding: 0, margin: 0, marginBottom: 8 }}
              key={activity.activityID + 'card' + index}>
              <ActivityCard key={activity.activityID + index} activity={activity} summary={((channelSettings && channelSettings.summary) || props.summary) ? true : false} hideFooter={!!props.hideFooter} activityID={activity.id} uid={props.profile.uid}
                cardMargin={12} />
              {activity.parentActivityID &&
                <SingleActivityCard style={{ margin: 0 }} hideFooter={true}
                  displayActivities={1} type="all" activityID={activity.parentActivityID} />}
            </div>
          })
          : (props.activityFeed && props.activityFeed.length === 0) ?
            <EmptyImagePlaceholder imageType="bench"
              icon={<PersonalVideo style={{ marginRight: 8, fontSize: 40 }} />} message={"No activity yet, let's get started!"} />
            : <Loader />
      }
    </div>
  )
}

// export default ActivityFeed

const mapStateToProps = (state, ownProps) => {
  let storeAs = 'activityfeed' + ownProps.type
  if (ownProps.selectedChannel) storeAs = storeAs + ownProps.selectedChannel

  return {
    activityFeed: state.firestore.ordered && state.firestore.ordered[storeAs] &&
      Object.entries(state.firestore.ordered[storeAs])
        .filter(([key, value]) => value !== null)
        .map(([key, value]) => { return { ...value, activityID: value.id } }),
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    let storeAs = 'activityfeed' + props.type
    if (props.selectedChannel) storeAs = storeAs + props.selectedChannel
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{ collection: 'activityfeed' }], limit: props.displayActivities, orderBy: ['activityReceivedTime', 'desc'],
        // where: [['activityTitle', '==', props.profile.orgID]],
        where: [[...props.where]], storeAs
      }
    ]
  }
  )
)(ActivityFeed)