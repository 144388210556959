import tinycolor from "tinycolor2";

export const primary = "#8555ce";//Purple
export const secondary = "#9ECE55"//"#58B737";
export const error = "#A64452";
const success = "#F78DA7";
const info = "#000000";
export const themeBlue = "#5155A5";
export const themeYellow = "#F8C548";




export const lightenRate = 35;
export const darkenRate = 15;

export const primaryLight = tinycolor(primary).lighten(lightenRate).toHexString()
export const secondaryLight = tinycolor(secondary).lighten(lightenRate).toHexString()

export default {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF"
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9"
    },
    background: {
      // default: "#F6F7FF",
      // light: "#F3F5FF"
      default: "#FFF",
      light: "#FFF"
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
  },

  overrides: {

    MuiInputLabel: {
      outlined: {
        '&$shrink': {
          transform: 'translate(4px, -14px) scale(0.75)',
        },
      },
    },
    MuiTabPanel: {
      root: {
        backgroundColor: 'red', borderRadius: '10px', borderStyle: 'solid', borderWidth: '0px', borderColor: 'red',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 50
      },
    },

    MuiBackdrop: {
      root: {
        // backgroundColor: "#9E9E9E"
        backgroundColor: 'rgba(0,0,0,0.2)'
      }
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
      }
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
        borderRadius: 50

      }
    },
    MuiListItem: {
      "root": {
        "&$selected": {
          "backgroundColor": "#F3F5FF !important",
          "&:focus": {
            "backgroundColor": "#F3F5FF"
          }
        }
      }
    },



    // {
    //   button: {
    //     '&:hover, &:focus': {
    //       backgroundColor: '#F3F5FF',
    //     },
    //   },
    //   selected: {
    //     backgroundColor: '#F3F5FF !important',
    //     '&:focus': {
    //       backgroundColor: '#F3F5FF',
    //     },
    //   }
    // }

    MuiTouchRipple: {
      child: {
        backgroundColor: "white"
      }
    },
    MuiTableRow: {
      root: {
        height: 56,
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      }
    },
    typography: {
      h1: {
        fontSize: '3rem',
      },
      h2: {
        fontSize: '2rem',
      },
      h3: {
        fontSize: '1.64rem',
      },
      h4: {
        fontSize: '1.5rem',
      },
      h5: {
        fontSize: '1.285rem',
      },
      h6: {
        fontSize: '1.142rem',
      }
      ,
      h6bold: {
        fontSize: '1.142rem',
        fontWeight: 'bold',
      }
    },
    Paper: {
      padding: 10,

    }
  }
};
