import React from 'react'
import CalendarToday from '@material-ui/icons/CalendarToday';
import Cancel from '@material-ui/icons/Cancel';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import DateRangeSelector, { getDisplayDateRange } from './DateRangeSelector';
import moment from 'moment'

export default function DateRangePopover(props) {
  const [popover, setPopover] = React.useState({
    open: false,

  });

  const startDate = props.startDate instanceof Date ? props.startDate : null
  const endDate = props.endDate instanceof Date ? props.endDate : null

  const DateRangeSelectorRef = <DateRangeSelector
    dateSelectionType={props.dateSelectionType}
    setDateRange={(startDate, endDate, getDateRange, selectedDateRange) => {
      props.setDateRange(startDate, endDate, getDateRange, selectedDateRange)
      setTimeout(() => setPopover({ ...popover, open: false, startDate, endDate }), 600)
    }}

    defaultDateRange={props.defaultDateRange || null}
    startDate={startDate} endDate={endDate}
  />
  // console.log('RenderingDateRangePopover');

  return (
    <div style={props.style || null}>
      <Chip size='small'
        name="dateRangePopover"
        color="primary"
        icon={<CalendarToday style={{ marginLeft: 8 }} />}
        onClick={(e) => {
          (setPopover({ ...popover, open: !popover.open, anchorEl: e.currentTarget, popoverID: props.id }))
        }}
        label={<div style={{ padding: props.isSmallScreen ? 8 : 16, paddingLeft: 0, paddingRight: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.isSmallScreen ? { fontSize: 11 } : null }}>
          {
            startDate && endDate ?
              moment(startDate instanceof Date ? startDate : startDate.toDate()).format('DD MMM YYYY') + ' - ' +
              moment(endDate instanceof Date ? endDate : endDate.toDate()).format('DD MMM YYYY')
              : props.defaultDateRange ? getDisplayDateRange(props.defaultDateRange).displayDateRange
                : "Select Date Range"
          }
        </div>} />
      {popover.open && <Popover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={() => { setPopover({ ...popover, open: false }) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ style: { maxWidth: 316, padding: 8, textAlign: 'center' } }}>
        <IconButton onClick={() => setPopover({ ...popover, open: false })}>
          <Cancel />
        </IconButton>
        {DateRangeSelectorRef}
      </Popover>}
    </div >
  )
}
