import React, { useContext } from 'react'
import { AppRenderContext } from './AppRenderContext';
import { RenderFieldSwitch } from './RenderFieldSwitch';

export default function AppRenderScreen() {

  const { app, handleFieldValueChange, allFields, getComponentProps, getCameraProps, profile } = useContext(AppRenderContext);

  return <div style={!(app.settings && app.settings.hideSubmitButton) ? { padding: 16 } : { padding: 2 }} >
    {app.screens[app.selectedScreenIndex].fieldList.map((field) =>
      <RenderFieldSwitch key={field.fieldID} {...{ getComponentProps, getCameraProps, allFields, field, profile }} handleChange={handleFieldValueChange} />
    )}
  </div>
}