import React, { useRef } from "react";
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import 'react-resizable/css/styles.css';
import { compose } from 'redux';
import { updateDashboardCard } from '../../store/actions/formActions';
import RenderCard from '../dashboardbuilder/card/RenderCard';

const GridCardItem = ({ gridID, gridItemID, card, userDataCard, cardID, updateDashboardCard, profile, handleFilterValueChange, dataPathPrefix, isSmallScreen }) => {
  const ref = useRef(null);

  let hasCardChanged = false
  if (card && userDataCard)
    ['cardName', 'cardType', 'name1Label', 'queryString', 'value1Label'].forEach(row => { if (card[row] !== userDataCard[row]) hasCardChanged = true })

  const renderCard = { ...card, ...userDataCard, ...(card && userDataCard && hasCardChanged) ? card : null, dashboardCardID: gridItemID, }

  const userDataEmpty = cardID && (userDataCard === null || (userDataCard && !userDataCard.requestID));

  if (userDataEmpty) updateDashboardCard(renderCard.dashboardCardID, renderCard, `${dataPathPrefix}-` + gridID, { skipDataRequest: false, updateOnlyIfEmpty: true })

  console.log('GridCardItem RenderCard', { renderCard });
  return (
    <RenderCard
      key={renderCard.responseID || 'firstRender'}
      // heightRef={ref}
      isSmallScreen={isSmallScreen}
      dataPath={`organizations/${profile.orgID}/userdata/${profile.uid}/${dataPathPrefix}-${gridID}/${gridItemID}/card-data`}
      card={renderCard}
      hideHeader={false}
      updateDashboardCard={(dashboardCardID, card) => updateDashboardCard(dashboardCardID, card, `${dataPathPrefix}-${gridID}`)}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardCard: (dashboardCardID, card, dashboardID, options) => dispatch(updateDashboardCard(dashboardCardID, card, dashboardID, options)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    card: state.firestore.data['card' + ownProps.cardID],
    userDataCard: state.firestore.data['userDataCard' + ownProps.gridID + ownProps.gridItemID]
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'cards', doc: props.cardID }], storeAs: 'card' + props.cardID },
      {
        collection: `organizations/${props.profile.orgID}/userdata/${props.profile.uid}/${props.dataPathPrefix}-` + props.gridID, doc: props.gridItemID,
        storeAs: 'userDataCard' + props.gridID + props.gridItemID
      },
    ]
  }
  )
)(GridCardItem)