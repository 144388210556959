import { calculateExpression, valueSubstitution } from "../../utils/calculateExpression";
import { getAllFields } from "./appRenderUtils";


export default function getSubmission(app, profile, allFields, all) {

  const uploadPromises = allFields.filter(field => field.fieldType.includes('camera')).map(field => field.imageUploadPromise)

  let fieldsForSubmission = allFields.map((field, index) => {
    if (field.fieldType.includes('camera')) {
      let newImageField = { ...field }
      delete newImageField.image
      delete newImageField.imageUploadPromise
      return { ...newImageField, fieldValue: field.fieldValue || "" }
    }
    else if (field.fieldType.includes('calculated'))
      return { ...field, fieldValue: calculateExpression(allFields, field.fieldSettings.expression.value, field) || '' }
    else if (field.fieldType.includes('select'))
      return { ...field, fieldSettings: null }
    else return { ...field, fieldValue: (field.fieldValue || null) }
  }).filter(field => field)
    .filter(field => !(field.fieldSettings && field.fieldSettings.dontPublish && field.fieldSettings.dontPublish.value === true))

  let fieldSets = getAllFields(app).filter(field => field.fieldType === 'fieldSet')
    .map(field => ({
      fieldID: field.fieldID,
      fieldSetsLength: field.fieldValue ? field.fieldValue.length : 0,
      fieldValue: field.fieldValue && JSON.stringify(field.fieldValue.map(fieldSet =>
        fieldSet.map(({ fieldID, fieldValue, fieldName, fieldLabel }) => ({ fieldID, fieldValue, fieldName, fieldLabel }))))
    }));

  const submissionLocation = sessionStorage.getItem('successLocation')
  sessionStorage.removeItem('successLocation');
  sessionStorage.removeItem('locationPermissionDenied')

  const allFieldsAndSubmitInfo = [...allFields, { fieldID: "submittedAt", fieldValue: new Date() },
  { fieldID: "submittedBy", fieldValue: profile.firstName + ' ' + profile.lastName }]

  const appForSubmission = {
    submissionLocation: submissionLocation ? submissionLocation : "Location not available",
    submissionID: app.submissionID,
    settings: app.settings || null,
    appID: app.appID,
    appName: app.appName || '',
    //For backward compatiblity   
    formID: app.appID,
    formName: app.appName || '',
    ...getAllSubmissionDestinations(),
    ...getCustomDestinationsAndSettings(),
    parentActivityID: app.parentActivityID || null,
    appData: fieldsForSubmission,
    fieldSets,
    // formData: fieldsForSubmission,
    uploadPromises: uploadPromises && uploadPromises.length > 0 ? uploadPromises : null,
    orgID: app.orgID
  };

  localStorage.setItem('submissionIDForTesting', app.submissionID)
  return { ...appForSubmission }

  function getAllSubmissionDestinations() {
    return {

      emailDestinations: evaluateDestinationWhenExpressions(app.emailDestinations, allFields),
      gsheetsDestinations: evaluateDestinationWhenExpressions(app.gsheetsDestinations, allFields),
      gDriveImageDestinations: evaluateDestinationWhenExpressions(app.gDriveImageDestinations, allFields),
      tableDestinations: evaluateDestinationWhenExpressions(app.tableDestinations, allFields)
        .map(row => row.newTableName ? { ...row, newTableName: valueSubstitution(allFieldsAndSubmitInfo, row.newTableName) } : row),

      channelDestinations: app.channelDestinations ? evaluateDestinationWhenExpressions(app.channelDestinations, allFields).map(channel => channel.channelID) : [],

      channelSettings: app.channelDestinations ? evaluateDestinationWhenExpressions(app.channelDestinations, allFields).map(channel => { return { channelID: channel.channelID, summary: channel.summary ? true : false } }) : [],

      taskDestinations: app.taskDestinations ? evaluateDestinationWhenExpressions(app.taskDestinations, allFields)
        .map(destination => {
          return destination.userTaskName ? {
            ...destination,
            userTaskName: valueSubstitution(allFieldsAndSubmitInfo, destination.userTaskName)
          } : destination
        })
        : [],
    }
  }

  function getCustomDestinationsAndSettings() {
    return {
      customEmailDestinations: app.customEmailSettings ? app.customEmailSettings.fieldDestinations ? app.customEmailSettings.fieldDestinations.map(destination => valueSubstitution(allFields, destination.value)).filter(destination => destination !== '') : [] : [],

      sendEmailToSubmitter: (app.customEmailSettings && app.customEmailSettings.sendEmailToSubmitter) ? app.customEmailSettings.sendEmailToSubmitter : false,

      customSender: (app.customEmailSettings && app.customEmailSettings.useCustomSenderEmail && app.customEmailSettings.senderID) ? app.customEmailSettings.senderID : null,

      customEmailSubject: (app.customEmailSettings && app.customEmailSettings.customEmailSubject && app.customEmailSettings.customEmailSubject !== '') ?
        valueSubstitution(allFieldsAndSubmitInfo, app.customEmailSettings.customEmailSubject) : null,

      fileNamePrefix: (app.fileNamePrefix && app.fileNamePrefix.length > 0) ?
        valueSubstitution(allFieldsAndSubmitInfo, app.fileNamePrefix) : '',
    }
  }

}

function evaluateDestinationWhenExpressions(destinations, allFields) {
  return (destinations ? Object.values(destinations)
    .filter(destination => {
      let returnValue = true
      if (destination.destinationWhenExpression && destination.destinationWhenExpression.length > 0)
        returnValue = calculateExpression(allFields, destination.destinationWhenExpression)
      return returnValue === 'false' ? false : returnValue;
    }) : [])
}
