import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from './Loader'
import StandardSearchSelector from './StandardSearchSelector'
import GroupIcon from '@material-ui/icons/GroupTwoTone';

const GroupSearchSelector = ({ groups, inputLabel, keepFirstValue, noIcon, style, quickEdit, selectedValues, handleSave, noEdit }) => {

  if (typeof groups === 'undefined' || !groups || groups.length === 0) return <Loader />

  const groupsArray = groups.filter(group => group && group.groupID)
    .map(group => ({
      label: group.groupName, value: group.groupID,
    }))

  return <StandardSearchSelector
    inputLabel={inputLabel || (noIcon ? null : <GroupIcon color='primary' style={{ marginRight: 4, }} />)}
    keepFirstValue={keepFirstValue}
    style={style}
    allValuesAndLabels={groupsArray}
    quickEdit={quickEdit}
    selectedValues={selectedValues}
    handleSave={handleSave}
    multiple={true}
    noEdit={noEdit}
  />
}

const mapStateToProps = (state) => {
  return {
    groups: state.firestore.ordered.groups,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'groups' }], storeAs: 'groups' },
    ]
  }
  )
)(GroupSearchSelector)