
export function getDistanceBetweenTwoLatLng(lat1, lng1, lat2, lng2) {
  var p = 0.017453292519943295;    // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p) / 2 +
    c(lat1 * p) * c(lat2 * p) *
    (1 - c((lng2 - lng1) * p)) / 2;

  return Number(12742 * Math.asin(Math.sqrt(a))).toFixed(2) // 2 * R; R = 6371 km
}


export function getDistanceFromLocalStorageLatLng(lat1, lng1) {
  let loclatlng = sessionStorage.getItem('loclatlng')
  if (!loclatlng) return null
  loclatlng = JSON.parse(loclatlng)

  const lat2 = loclatlng.lat;
  const lng2 = loclatlng.lng

  var p = 0.017453292519943295;    // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p) / 2 +
    c(lat1 * p) * c(lat2 * p) *
    (1 - c((lng2 - lng1) * p)) / 2;
  const distanceInKM = Number(12742 * Math.asin(Math.sqrt(a))).toFixed(2);
  console.log('locationutils getDistanceFromLocalStorageLatLng', { distanceInKM });

  return distanceInKM // 2 * R; R = 6371 km
}