import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Home from '@material-ui/icons/Home';
import { TabPanel } from '../standard/TabPanel';
import { Select, MenuItem, } from '@material-ui/core';
import { displayLeft } from '../../utils/styleclasses';
import Dashboard from '../dashboard/Dashboard';
import PageIcon from '@material-ui/icons/MenuBook';
import { EmbedIframe } from './EmbedIframe';
import SubPage from './SubPage'
import GridTabContainer from '../grid/GridTabContainer';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';
import { iconTextAlign } from '../../utils/iconutils';
import ViewCompact from '@material-ui/icons/ViewCompactTwoTone';
import Pages from '@material-ui/icons/PagesTwoTone';
import ViewColumn from '@material-ui/icons/ViewColumnTwoTone';


export function MobilePagesRender({ orgSettings, selectedPage, setSelectedPage, pages, updatePage, deletePage, refreshPageCards, isPageEditor, uid, tab }) {

  const [tabValue, setTabValue] = React.useState(tab || 1);
  const [tabViewed, setTabViewed] = React.useState({ 1: true });
  // React.useEffect(() => (tab !== tabValue) && setTabValue(tab || 1), [tab])

  const isMyPage = selectedPage.pageID === 'myPage';


  const tabs = selectedPage && selectedPage.tabs && Array.isArray(selectedPage.tabs) ? selectedPage.tabs.filter(tab => tab.showOnMobile) : [];
  const fixedTabsCount = selectedPage.pageID === 'myPage' ? 1 : 0;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (!tabViewed[newValue]) setTabViewed({ ...tabViewed, [newValue]: true })
  }
  const iconStyle = { fontSize: 30, marginLeft: 16, };
  const icons = { embed: <Pages />, grid: <ViewCompact />, page: <ViewColumn /> }

  return <div>
    <AppBar position="sticky" style={{ background: 'white', color: 'black', padding: 8 }}>
      <div style={displayLeft}>
        {!(orgSettings && orgSettings.orgLogo) ? (selectedPage.pageID === 'myPage' ? <Home color="primary" style={iconStyle} /> : <PageIcon color="primary" style={iconStyle} />)
          : <img src={orgSettings.orgLogo} style={{ height: 30, marginLeft: 16 }} alt={orgSettings.orgName} />
        }
        <Select value={selectedPage.pageID}
          onChange={(e) => { setTabValue(1); localStorage.setItem('selectedPageID', e.target.value); setSelectedPage(pages.find(row => row.pageID === e.target.value)); }}
          style={{
            color: 'black', marginLeft: 16, width: 250, marginRight: 16, borderBottom: '1px solid white',
          }}
        >
          {pages.map(page => <MenuItem value={page.pageID} key={page.pageID}>{page.pageLabel} </MenuItem>)}
        </Select>
      </div>
    </AppBar>

    <AppBar position="sticky" >
      <div style={displayLeft}>
        <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {isMyPage && <Tab label={iconTextAlign(<DashboardIcon style={{ color: 'white' }} />, 'My Dashboard')} value={0} key={0} style={{ minWidth: 150 }} />}
          {tabs.map((row, i) => <Tab key={row.tabID} value={i + fixedTabsCount + 1} style={{ minWidth: 100 }} label={iconTextAlign(icons[row.tabType], row.tabLabel)} />)}
        </Tabs>
      </div>
    </AppBar>
    {isMyPage && <TabPanel value={tabValue} index={1} key={1}>      {tabValue === 1 && <Dashboard />}    </TabPanel>}
    {tabs.map((row, i) => tabViewed[i + fixedTabsCount + 1] && <TabPanel key={row.tabID} value={tabValue} index={i + fixedTabsCount + 1}>
      {row.tabType === 'embed' && row.embedType === 'link' && row.embedLink && <EmbedIframe
        src={row.embedLink} />}
      {row.tabType === 'grid' && tabValue === i + fixedTabsCount + 1
        && <GridTabContainer
          refreshPageCards={refreshPageCards}
          tabID={row.tabID}
          isPageEditor={isPageEditor}
          gridLayout={row.gridLayout}
          saveGrid={(saveObject) => {
            const updatedPage = { ...selectedPage, tabs: selectedPage.tabs.map(tab => tab.tabID === row.tabID ? { ...tab, ...saveObject } : tab) };
            updatePage(updatedPage, selectedPage.pageID); setSelectedPage(updatedPage)
          }}
          gridItems={row.gridItems}
        />}
      {row.tabType === 'page' &&
        <SubPage
          page={row.subPage || { pageID: row.tabID, tabs: [] }}
          isPageEditor={isPageEditor}
          setPage={subPage => setSelectedPage({
            ...selectedPage,
            tabs: selectedPage.tabs.map(tab => tab.tabID === row.tabID ? { ...tab, subPage } : tab)
          })}
          updatePage={() => updatePage(selectedPage, selectedPage.pageID)}
          refreshPageCards={refreshPageCards}
        />}
    </TabPanel>)}
  </div>;
}