import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { displayCenter } from '../../utils/styleclasses'
import SplashScreen from '../img/splashimageloader.svg'

export default function LoadingSplashScreen({ message, noLoader }) {
  return (
    <div>
      <div style={{ height: "95vh", ...displayCenter }}>
        <img src={SplashScreen} alt=""
          style={{ height: "95vh" }} />
        <div style={{ ...displayCenter, position: "absolute", display: "block" }}>
          {!noLoader && <LinearProgress />}
          <Typography variant="h4">{message || "Syncing your data"} </Typography>
        </div>
      </div>
    </div>
  )
}
