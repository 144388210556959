import { CardContent, Chip, Divider, Grid, Typography } from '@material-ui/core';
import Chat from '@material-ui/icons/Chat';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Error from '@material-ui/icons/Error';
import TimerOff from '@material-ui/icons/TimerOff';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDisplayTimeZone } from '../../utils/datetimeutils';
import { displayLeft, divLightCard } from '../../utils/styleclasses';
import { error } from '../../utils/themes';
import { getAppIcon } from '../../utils/UIutils';
import GreenTickLogo from '../img/favicon.svg';
import NoteList from '../newsfeed/NoteList';
import ShareButton from '../standard/ShareButton';
import UserSearchSelector from '../standard/UserSearchSelector';
import SingleActivityCard from './SingleActivityCard';

export default function TaskCard(props) {
  // const [editUsers, setEditUsers] = React.useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showNotes, setShowNotes] = useState(false || (props.footerOptions && props.footerOptions.showNotesDefault))
  const [showSubmission, setShowSubmission] = React.useState(false);

  const timeZone = useSelector(({ firestore: { data: { orgSettings } } }) => orgSettings && orgSettings.advancedSettings && orgSettings.advancedSettings.timeZone)
  const displayTimeZone = getDisplayTimeZone(timeZone)

  const checkBox = <CheckBoxOutlineBlank style={{ fontSize: 36, cursor: 'pointer' }} onClick={() => props.setSelectedApp && openTask()} />
  const greenTick = <img src={GreenTickLogo} width={30} alt='Done' />
  const errorIcon = <Error style={{ fontSize: 36, color: error }} />
  const expiryTime = props.task.expiryTime ? props.task.expiryTime.toDate() : null
  const activationTime = props.task.activationTime ? props.task.activationTime.toDate() : null

  const openTask = () => (props.task.expiryTime ? moment().isBefore(expiryTime) : true)
    && moment().isAfter(activationTime)
    && (!props.task.submitted || props.task.taskType === 'periodTask')
    && props.setSelectedApp({ ...props.task.app, taskID: props.task.taskID, taskName: props.task.taskName, parentActivityID: props.task.parentActivityID ? props.task.parentActivityID : null, })

  let task = {
    taskStatusIcon: props.task.submitted ? greenTick : checkBox,
    id: (props.task.taskName || props.task.app.appName).replace(/ /g, ''),
    name: props.task.taskName || props.task.app.appName,
    appIcon: getAppIcon(props.task.app.appDescription + ' ' + props.task.app.appName),
    timeCaption: `Created At ${moment(props.task.editedAt.toDate()).format('Do MMM h:mm A')}`,
    submittedAt: props.task.submittedAt && moment(props.task.submittedAt.toDate()).format('Do MMM h:mm A')
  }

  if (props.task.taskType === 'periodTask') {
    task.taskStatusIcon = Number(props.task.submitted) >= Number(props.task.numberOfSubmissions) ?
      greenTick : moment().isBefore(expiryTime) ? checkBox : errorIcon
    task.appIcon = <Typography variant="h3" color="primary"> <span><span name="submittedCount">
      {props.task.submitted || 0}</span><span> / {props.task.numberOfSubmissions}</span></span></Typography>
    task.timeCaption = `For the period ${moment(activationTime).tz(displayTimeZone).format('Do MMM h:mm A')} to ${moment(expiryTime).format('Do MMM h:mm A')}`

  }
  if (props.task.taskType === 'timeTask') {
    task.taskStatusIcon = props.task.submitted ?
      greenTick : (
        moment().isBefore(expiryTime) && moment().isAfter(activationTime)
          ? checkBox
          : moment().isBefore(expiryTime) && moment().isBefore(activationTime) ? <TimerOff style={{ fontSize: 36, }} /> :
            errorIcon
      )
    task.timeCaption = moment().isAfter(expiryTime) &&
      !props.task.submitted ?
      `Expired at ${moment(expiryTime).format('Do MMM h:mm A')}`
      : `  Scheduled at ${moment(activationTime).format('Do MMM h:mm A')} ${expiryTime ? ' - ' + moment(expiryTime).format('Do MMM h:mm A') : ''}`
  }

  console.log('TaskCard', { props });

  return (
    <div key={props.task.id} style={{ ...props.cardStyle, ...divLightCard, }}>
      <Grid container style={{ ...displayLeft, padding: 8 }}>
        <Grid item xs={10}>
          <span style={displayLeft}> {task.taskStatusIcon}
            <Typography variant="subtitle1" style={{ marginLeft: 8 }} id={task.id} noWrap>
              {task.name}
            </Typography>
          </span>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          {task.appIcon}
        </Grid>
      </Grid>
      <Divider />
      <CardContent style={{ padding: 8 }} >
        {props.editUsers && props.task.assignedToUID && props.profile.scheduler &&
          <div style={{ marginBottom: 8 }}> <UserSearchSelector selectedValues={props.task.assignedToUID}
            style={{ width: "100%", marginBottom: 8 }}
            handleSave={props.handleUserChange} keepFirstValue={true} /></div>
        }
        <Typography variant="caption" style={{ ...displayLeft, marginBottom: 8 }}>
          {task.timeCaption}
        </Typography>
        {props.task.submittedAt &&
          <div style={{ ...displayLeft, marginBottom: 8 }}>
            <Typography variant="caption" > Submitted at {task.submittedAt}</Typography>
          </div>}

        <div style={{ display: "flex-inline", textAlign: "right", }}>

          {props.task.parentActivityID &&
            <Chip
              // id={`viewSubmission${task.id}-${props.task.taskType === 'userTask' ? props.task.parentActivityID : props.task.scheduleID}`}
              variant="outlined" color="primary" style={{ marginRight: 8 }}
              onClick={() => { setShowCard(!showCard); setShowNotes(!showCard) }}
              label={showCard ? "Hide Task History" : "Task Details"}>
            </Chip>}
          {
            props.task.submitted && props.task.taskSubmissionActivities ?
              <Chip variant="outlined" color="primary"
                onClick={() => setShowSubmission(!showSubmission)}
                id={`viewSubmission${task.id}-${props.task.taskType === 'userTask' ? props.task.parentActivityID : props.task.scheduleID}`}
                label={showSubmission ? "Hide Submissions" : "Submissions"} style={{ marginRight: 8 }}>
              </Chip> : null
          }
          {
            props.task.submitted && !(props.task.taskType === 'periodTask') ?
              null   // <Chip label="Submitted" color="secondary" />
              :
              (!props.task.expiryTime || moment().isBefore(expiryTime))
                && moment().isAfter(activationTime) ?
                (!props.setSelectedApp ? null :
                  <Chip id={`openTask${task.id}-${props.task.taskType === 'userTask' ? props.task.parentActivityID : props.task.scheduleID}`}
                    color="primary"
                    label="Open Task"
                    style={{ cursor: "pointer" }}
                    onClick={() => openTask()}
                  />)
                : moment().isBefore(expiryTime) && moment().isBefore(activationTime) ? <Chip color="primary" label="Due Later" variant="outlined" />
                  : <Chip label="Time Expired" />
          }
        </div>
      </CardContent>

      {
        props.task.submitted && (showSubmission || showCard) && props.task.taskSubmissionActivities ? Object.values(props.task.taskSubmissionActivities).map(activity =>
          <SingleActivityCard key={activity.activityID} style={{ marginBottom: 0 }} hideFooter={true}
            displayActivities={1} type="all" activityID={activity.activityID} hideParent={true}
          />) : null
      }
      <div style={{ ...displayLeft, marginTop: 8, marginBottom: 8 }} >
        <ShareButton title={task.name} text='Sharing a task from Opstack' referenceID={props.task.taskID} type="task" />

        {props.task.parentActivityID &&
          <Chip variant="outlined" color="primary" onClick={() => { setShowNotes(!showNotes) }} style={{ marginLeft: 8 }}
            label={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Chat style={{ fontSize: 20, marginRight: 4 }} /> {showNotes && 'Hide '}Notes </div>} />}
      </div>
      {showNotes && props.task.parentActivityID &&
        // <LoadMoreParent initialRows={3} increment={5}>
        <NoteList activityID={props.task.parentActivityID || ''} taskID={props.task.taskID} />
        // </LoadMoreParent>
      }
      {
        props.task.parentActivityID &&
        showCard &&
        <SingleActivityCard key={props.task.parentActivityID} style={{ margin: 0 }} hideFooter={true}
          // footerOptions={{ hideTasks: true, hideShare: true }}
          displayActivities={1} type="all" activityID={props.task.parentActivityID}
        />
      }
    </div>
  )
}