import Collections from '@material-ui/icons/Collections';
import React from 'react';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';
import LoadMoreParent from '../../standard/LoadMoreParent';
import TitleAppBar from '../../standard/TitleAppBar';
import QueryBuilderBar from '../QueryBuilderBar';
import ActivityQueryContainer from './ActivityQueryContainer';

export default function ActivityGalleryViewer({ search, embedView }) {
  const [reportQuery, setReportQuery] = React.useState(search || {});
  return <div>
    <TitleAppBar icon={<Collections />}>{(search && search.searchName) || 'Gallery Viewer'}</TitleAppBar>
    <QueryBuilderBar setReportQuery={setReportQuery} contentDisplayType="gallery" embedView={embedView} reportQuery={reportQuery} />
    {reportQuery.appID ?
      <LoadMoreParent initialRows={10} increment={10} >
        <ActivityQueryContainer  {...reportQuery} contentDisplayType="gallery" />
      </LoadMoreParent>
      : <EmptyImagePlaceholder />}
  </div >
}