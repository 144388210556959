const initState = {
  authError: null
}
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      console.log('LOGIN_ERROR');
      return {
        ...state,
        authError: 'LOGIN_ERROR'
      }
    case 'LOGIN_SUCCESS':
      console.log('LOGIN_SUCCESS');
      return {
        ...state,
        authError: 'LOGIN_SUCCESS'
      }
    case 'SIGNOUT_SUCCESS':
      console.log('SIGNOUT_SUCCESS');
      return {
        ...state,
        authError: 'SIGNED_OUT'
      }
    case 'PROFILE_UPDATE_SUCCESS':
      console.log('PROFILE_UPDATE_SUCCESS');
      return {
        ...state,
        authError: null
      }
    case 'PROFILE_UPDATE_ERROR':
      console.log('PROFILE_UPDATE_ERROR');
      return {
        ...state,
        authError: action.err.message
      }
    case 'USER_DELETE_SUCCESS':
      console.log('USER_DELETE_SUCCESS');
      return {
        ...state,
        authError: null
      }
    case 'USER_DELETE_ERROR':
      console.log('USER_DELETE_ERROR');
      return {
        ...state,
        authError: action.err.message
      }
    default: return state;
  }
}

export default authReducer