import React from 'react'
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { displayCenter } from '../../utils/styleclasses';

export default function LoadMoreParent(props) {
  const [displayLoadMore, setDisplayLoadMore] = React.useState("show")
  const [rowLimit, setRowLimit] = React.useState(props.initialRows)

  if (displayLoadMore === "loading")
    setTimeout(() => setDisplayLoadMore("show"), props.timeout || 3000)

  return (
    <div >
      {/* {props.render(rowLimit, props.propsForRender, setDisplayLoadMore)} */}
      {React.cloneElement(props.children, { rowLimit })}
      {displayLoadMore !== "hide" && displayLoadMore !== "loading" ?
        <Button color="primary" style={{ margin: 8, marginBottom: 20, width: "95%", }}
          onClick={() => {
            setRowLimit(rowLimit + (props.increment || props.initialRows))
            setDisplayLoadMore('loading')
          }}>
          Load More
            </Button>
        : displayLoadMore === "loading" ? <div style={displayCenter} ><CircularProgress /></div>
          : null
      }
    </div>
  )
}

export function handleLoadMoreDisplay({ activityQueryResults, rowLimit, setDisplayLoadMore }) {
  if (activityQueryResults === null || rowLimit > activityQueryResults.length)
    setDisplayLoadMore("hide")
  else
    setDisplayLoadMore("loading")
}

