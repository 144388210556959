import React from 'react'
import { WidthProvider, Responsive } from "react-grid-layout";
import Card from "@material-ui/core/Card";
import { divLightCard } from '../../utils/styleclasses';
import RenderCard from '../dashboardbuilder/card/RenderCard';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function DashboardResponsiveGrid(props) {

  const [layoutState, setLayoutState] = React.useState({ layouts: props.layouts, })

  const onLayoutChange = (currentScreenSizeLayout, allScreenSizeLayouts) => {
    delete allScreenSizeLayouts.layouts;
    const newLayouts = JSON.stringify({ layouts: allScreenSizeLayouts });

    if (newLayouts !== JSON.stringify(layoutState)) {
      console.log('onLayoutChange', newLayouts, JSON.stringify(layoutState));
      localStorage.setItem(`gridLayout-${props.selectedDashboardID}`, newLayouts)
      setLayoutState({ layouts: allScreenSizeLayouts });
    }
  }

  // console.log("props.dashboardData", props.dashboardData);
  // console.log("ResponsiveReactGridLayoutRendering props", props.selectedDashboardID, props.layouts);
  return (
    props.selectedDashboardID && <ResponsiveReactGridLayout
      isDraggable={props.editOpen}
      isResizable={props.editOpen}
      key={props.selectedDashboardID}
      margin={props.isSmallScreen ? (props.editOpen ? [16, 4] : [4, 4]) : [16, 16]}
      draggableCancel='.dragCancel'
      className="layout"
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={20}
      layouts={layoutState.layouts}
      compactType="vertical"
      onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}>
      {
        props.dashboardCardList
          .map((card, index) => {
            return <Card key={card.dashboardCardID}
              data-grid={
                {
                  i: card.dashboardCardID,
                  x: 0,
                  y: 0, // puts it at the bottom
                  w: 6,
                  h: 8,
                  minW: 3,
                  mihH: 4
                }
              }
              style={{ ...divLightCard, }}>
              <RenderCard
                isSmallScreen={props.isSmallScreen}
                dataPath={`organizations/${props.profile.orgID}/userdata/${props.profile.uid}/${props.selectedDashboardID}/${card.dashboardCardID}/card-data`}
                card={props.dashboardData[card.dashboardCardID]}
                updateDashboardCard={props.updateDashboardCard}
                deleteDashboardCard={props.deleteDashboardCard} />
            </Card>
          })
      }
    </ResponsiveReactGridLayout>
  )
}
