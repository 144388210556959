import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { createOrg } from '../../store/actions/authActions'
import SignIn from './SignIn'
import { fieldIDGenerator } from '../../utils/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { displayCenter } from '../../utils/styleclasses'
import { getQueryVariable } from '../../utils/utilityfunctions';

//TBD CHANGE THIS TO EXISTING <LoadingSplashScreen/>

const loadingMessage = (message) =>
  <div style={{ height: "95vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <img src="images/splashimageloader.svg" alt="" style={{ height: "95vh" }} />
    <div style={{ ...displayCenter, margin: 16, position: "absolute", top: "400", display: "block" }}>
      <CircularProgress style={{ marginRight: 8 }} />
      <Typography variant="h4">{message}</Typography>
    </div>
  </div>

function NewOrgCreator(props) {
  // const [createRequested, setCreateRequested] = React.useState(false);

  console.log('NewOrgCreator', props.newOrgDetails, props.createdOrg);

  if (props.newOrgDetails.orgName) {
    //Check both props and localstorage because google login redirects back to this page and props.createdOrg may be lost in the page refresh resulting in org being created again
    if (props.createdOrg || localStorage.getItem("newOrgID")) {
      console.log('2', props.createdOrg);
      if (props.createdOrg) {
        localStorage.setItem('newOrgID', props.createdOrg.orgID);
      }
      return <SignIn location={props.location}
        newOrgID={
          props.createdOrg && props.createdOrg.orgID ? props.createdOrg.orgID
            : localStorage.getItem("newOrgID")} disablePhoneLogin={true} />
    }

    if (!localStorage.getItem('createID') && (!props.createdOrg)) {
      const createID = fieldIDGenerator();
      console.log('1');
      localStorage.setItem('createID', createID);
      props.createOrg({
        createID: createID,
        ...props.newOrgDetails,
        // email:
      })
      return loadingMessage("Generating Organization ID")
    }
    else {
      console.log('3 - createdOrg', props.createdOrg);
      return loadingMessage("Setting Up Organization")
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createOrg: (newOrg) => { dispatch(createOrg(newOrg)) },
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log('NewOrgCreator mapStateToProps', state, ownProps.search);
  return {
    // profile: state.firebase.profile,
    createdOrg: state.firestore.ordered.createdOrg && state.firestore.ordered.createdOrg[0],
    newOrgDetails: {
      name: getQueryVariable('Contact-Name'),
      orgName: getQueryVariable('Organization-Name'),
      vertical: getQueryVariable('Vertical'),
      email: getQueryVariable('Contact-Email'),
    }
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', where: [['createID', '==', localStorage.getItem('createID')]], storeAs: 'createdOrg' }
    ]
  }))(NewOrgCreator)

