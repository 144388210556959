import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Loader from '../../standard/Loader'
import GalleryContent from './GalleryContent'
import MapContent from './MapContent'

export default function ActivityQueryContainer({ appID, uid, startDate, endDate, setDisplayLoadMore, rowLimit, contentDisplayType, fieldID }) {

  const profile = useSelector(state => state.firebase.profile)

  const whereClause = [
    ['activityFormID', '==', appID],
    uid && uid !== 'all' ? ['activityByUID', '==', uid] : null,
    startDate ? ['activityReceivedTime', '>', startDate.toDate ? startDate.toDate() : startDate] : null,
    endDate ? ['activityReceivedTime', '<', endDate.toDate ? endDate.toDate() : endDate] : null,
  ].filter(row => row)


  useFirestoreConnect([{
    collection: `organizations/${profile.orgID}/activityfeed`, where: whereClause, limit: rowLimit || 500, orderBy: ['activityReceivedTime', 'desc'], storeAs: 'activityQueryResults'
  },])

  const activityQueryResults = useSelector(({ firestore: { ordered: { activityQueryResults } } }) => activityQueryResults && Object.entries(activityQueryResults).filter(([key, value]) => value !== null).map(([key, value]) => { return { ...value, activityID: key } }))


  if (typeof activityQueryResults === 'undefined') return <Loader />

  return (
    (!activityQueryResults || activityQueryResults.length === 0) ?
      <Typography color="primary" variant="h5" style={{ margin: 16 }}> No Results Found For Selection</Typography>
      : contentDisplayType === 'map' ? <MapContent activityQueryResults={activityQueryResults} />
        : <GalleryContent fieldID={fieldID || 'all'} activityQueryResults={activityQueryResults} />
  )
}
