import React from 'react'
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { imageDisplayDiv, } from '../../utils/styleclasses'
import { calculateExpression } from "../../utils/calculateExpression";
import ImageOverlay from '../standard/ImageOverlay';
import { mergeArrays } from '../../utils/arrayutils';

export const fieldIsWithoutError = (field) => (!field.fieldValue || (field.regularExpression && new RegExp(field.regularExpression).test(field.fieldValue)) || field.fieldValue.length === 0 || (!field.regularExpression))

export function getWhenFieldSettingValue(field, allFields, fieldSetting) {
  return whenChecker(field, allFields,
    fieldSetting.value === 'when' ?
      fieldSetting.whenExpression
      : fieldSetting.value);

}


export const whenChecker = (field, fieldList, fieldSettingValue) => {
  if (fieldSettingValue === 'yes') { return true; }
  if (fieldSettingValue === 'no') return false;
  // console.log('whenChecker calling calculateexpression', fieldSettingValue);
  const returnValue = calculateExpression(fieldList, fieldSettingValue, field)
  if (returnValue === 'false') return false
  if (returnValue === 'true') return true
  // console.log('componentProps', returnValue);
  return returnValue

}

export const labelHelperText = (field) =>
  <div style={{ marginLeft: 0 }}>
    <Typography variant="caption">{field.fieldLabel}</Typography>
    {
      (field.errorMessage || field.fieldHelperText) &&
      <FormHelperText>
        {field.errorMessage ? field.errorMessage : field.fieldHelperText ? field.fieldHelperText : null}
      </FormHelperText>
    }
  </div>

export const helperImageRender = (imageURL, helperLabel) => {
  return imageURL &&
    <span>
      <ImageOverlay
        imageLink={imageURL}
        imageStyle={{
          width: "100%",
          maxWidth: 1000,
          maxHeight: "90vh",
          height: "auto",
          ...imageDisplayDiv
        }}
        zoomLink={imageURL}
        textOverlay={helperLabel}
        options={{ noRotate: true }}
      />

    </span>
}


export const getAllFields = (app, fieldValueKey) => mergeArrays(app.screens.map(screen => screen.fieldList));

export function spreadFieldSetsInFields(fields) {
  let allFields = fields
  while (allFields.find(field => field.fieldType && field.fieldType.includes('fieldSet'))) {
    const fieldSetIndex = allFields.findIndex(field => field.fieldType.includes('fieldSet'))
    const spreadFieldSetForInsertion = [].concat([], ...allFields[fieldSetIndex].fieldValue ? allFields[fieldSetIndex].fieldValue : [])
    if (spreadFieldSetForInsertion) allFields.splice(fieldSetIndex, 1, ...spreadFieldSetForInsertion)
  }
  return allFields
}


export function updateCalculatedFieldsToAppState(newAppState, profile) {
  let repeatLoop;
  do {
    let anyDataUpdated = false;
    let newAppStateAllFields = spreadFieldSetsInFields(getAllFields(newAppState));
    newAppState = {
      ...newAppState,
      screens: newAppState.screens.map((screen, index) => ({
        ...screen,
        fieldList: screen.fieldList.map(field => {
          if (field.fieldType === 'calculated') {
            const calculatedValue = calculateExpression(newAppStateAllFields, field.fieldSettings.expression.value, field, profile);

            if (calculatedValue !== field.fieldValue) { anyDataUpdated = true; }
            return { ...field, fieldValue: calculatedValue };
          }
          if (field.fieldType === 'fieldSet')
            return {
              ...field, fieldValue: field.fieldValue === null ? null :
                field.fieldValue.map(fieldSetRow => {
                  return fieldSetRow.map(fieldSetField => {
                    if (fieldSetField.fieldType === 'calculated') {
                      const fieldSetCalculatedValue = calculateExpression(newAppStateAllFields, fieldSetField.fieldSettings.expression.value, fieldSetField, profile);
                      if (fieldSetCalculatedValue !== fieldSetField.fieldValue) { anyDataUpdated = true; }
                      return { ...fieldSetField, fieldValue: fieldSetCalculatedValue };
                    } return fieldSetField;
                  }
                  );
                }
                )
            };
          return field;
        })
      }))
    };
    repeatLoop = anyDataUpdated;
  } while (repeatLoop);
  return newAppState;
}
