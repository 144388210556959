import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/messaging'
import 'firebase/functions'
// import 'firebase/analytics' ANALYTICS TURN ON

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId || 'G-ZK3G3FWCLR',
  persistence: true,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (process.env.REACT_APP_EMULATOR) {
  console.log('CONNECTING TO EMULATOR');
  firebase.firestore().useEmulator('localhost', 8080)
}
// firebase.analytics(); ANALYTICS TURN ON
// firebase.functions();
firebase.firestore().enablePersistence({ synchronizeTabs: true }) //FIRESTORE ASYNCQUEUE ERROR FIRES WHEN TRUE?
  .catch(function (err) {
    if (err.code === 'failed-precondition')
      console.log('enablePersistence failed-precondition');
    else if (err.code === 'unimplemented')
      console.log('enablePersistence unimplemented');
  });

export default firebase;