import React from 'react';
import AppRenderElement from './components/AppRenderElement';
import CalculatedField from './components/CalculatedField';
import AppRenderCameraComponent from './components/AppRenderCameraComponent';
import SectionHeader from './components/SectionHeader';
import TableLookupField, { doTableFieldsExist } from './components/TableLookupField';
import { FieldSetField } from './components/FieldSetField';
import ComponentWrapper from './components/ComponentWrapper';
import TableEditorField from './components/TableEditorField';

export function RenderFieldSwitch({ field, getComponentProps, getCameraProps, allFields, handleChange, profile }) {
  // console.log('RenderFieldSwitch', profile);
  const componentProps = getComponentProps(field)
  return <ComponentWrapper field={field} componentProps={componentProps}>
    {getField({ field, componentProps, allFields, handleChange, getCameraProps, getComponentProps, profile })}
  </ComponentWrapper >
}

function getField({ field, componentProps, allFields, handleChange, getCameraProps, getComponentProps, profile }) {
  switch (field.fieldType) {
    case 'sectionHeader': return <SectionHeader label={field.fieldLabel} />;
    case 'calculated': return <CalculatedField {...{ componentProps, handleChange, field, profile }}
      // calculatedValue={calculateExpression(allFields, field.fieldSettings.expression.value, field, profile)} 
      calculatedValue={field.fieldValue}

    />;
    case 'tableLookup':
    case 'tableLookupSelect':
    case 'tableEditor':
      if (!doTableFieldsExist(field)) return null
      const appLookupMatchField = allFields.find(row => row.fieldID === field.fieldSettings.tableSelector.tableStructure.appLookupMatchFieldID)
      return (field.fieldType === 'tableEditor') ?
        <TableEditorField {...{ field, appLookupMatchField }} />
        : <TableLookupField {...{ componentProps, field, handleChange, appLookupMatchField }} isSelect={field.fieldSettings.tableSelector && field.fieldSettings.tableSelector.isTableLookupSelect === true} />;
    case 'camera': return <AppRenderCameraComponent
      {...{ componentProps, field, allFields, handleChange }} {...{ ...getCameraProps(field) }} />;
    case 'fieldSet': return <FieldSetField {...{ field, getComponentProps, getCameraProps, allFields, handleChange }} />;
    default: return <AppRenderElement {...{ componentProps, field, handleChange, getComponentProps }} />;
  }
}