import React, { useState } from 'react';
import AlertCard from '../standard/AlertCard';

export default function InternetAlertCard() {
  const [time, setTime] = useState(null)

  if (!time) getInternetTime().then((t) => setTime(t)).catch((err) => console.error('time', err));

  return time ? null : <AlertCard message={"This app requires an active internet connection"} style={{ margin: 16, marginBottom: 30 }} />
}

export function getInternetTime() {
  console.log('getInternetTime');

  return fetch("http://worldtimeapi.org/api/ip")
    .then((response) => response.json())
    .then(responseJson => new Date(responseJson.datetime))
}
