import React from 'react'
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { displayLeft, } from '../../../utils/styleclasses'
import { Divider } from '@material-ui/core';
import { helperImageRender } from '../appRenderUtils';
import Chip from '@material-ui/core/Chip';
import { error } from '../../../utils/themes';

export default function ComponentWrapper({ field, children, componentProps }) {
  // console.log('ComponentWrapper', { field });
  return <div>
    <div style={{ ...displayLeft, ...componentProps.style, marginTop: 24 }}>
      <div style={displayLeft}>
        {field.fieldLabel && field.fieldType !== 'sectionHeader' && <Typography variant="h6" style={{ marginRight: 8, display: 'flex', width: "100%" }}>
          {field.fieldLabel}
        </Typography>
        }
        {(componentProps.required ? <span style={{ textAlign: 'right', display: 'flex', width: "20%" }}><Chip style={{ marginLeft: 4 }} color="secondary" size="small" title="Required Field"
          label={<Typography style={{ fontSize: 9 }}>Required</Typography>} /></span> : '')}</div>
      {children}
      {field.fieldType !== 'sectionHeader' && <Divider />}
      {(componentProps.error || field.fieldHelperText) && <FormHelperText
        style={{
          marginBottom: 8,
          ...componentProps.error ? { color: error } : null,
          ...field.fieldHelperTextFontSize ? { fontSize: Number(field.fieldHelperTextFontSize) } : null
        }}>
        {componentProps.error ? field.errorMessage : field.fieldHelperText ? field.fieldHelperText : ''}</FormHelperText>}
      {helperImageRender(field.fieldHelperImageURL, field.fieldHelperImageLabel)}
    </div>
  </div>;
}