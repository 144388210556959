import React from 'react'
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { displayLeft, displayCenter } from '../../utils/styleclasses';


export default function TitleAppBar(props) {
  return (
    <AppBar position="sticky" style={{
      zIndex: 100, width: props.width || '100%',
      background: "linear-gradient(90deg, #8555ce, #b87df9)", padding: 8, ...props.style
    }} >
      <div style={{ ...displayLeft, width: "100%" }}>
        {props.icon ? <span style={{ marginRight: 4, ...displayCenter }}>{props.icon} </span> : null}
        <Typography variant='h6' style={{
          width: "100%", padding: 0, marginLeft: 8, justifyContent: 'left', alignItems: 'center', display: 'inline-flex'
        }}>
          {props && props.children}
        </Typography>
      </div>
    </AppBar >
  )
}
