import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from './Loader'
import StandardSearchSelector from './StandardSearchSelector'
import PhoneIphone from '@material-ui/icons/PhoneIphone';

const AppSearchSelector = ({ apps, allApps, inputLabel, keepFirstValue, style, quickEdit, selectedValues, handleSave, id }) => {

  const appsArray = apps && Object.entries(apps).filter(([id, app]) => app).map(([id, app]) => ({ label: app.appName, value: id, }))

  if (typeof appsArray === 'undefined' || !appsArray || appsArray.length === 0) return <Loader />
  else
    return <StandardSearchSelector
      id={id || "appSearchSelector"}
      multiple={false} //No Usecase for selecting multiple apps
      inputLabel={inputLabel || <PhoneIphone style={{ marginRight: 4, }} />}
      allValuesAndLabels={[...allApps ? [{ label: 'All Apps', value: 'all' }] : [], ...appsArray,]}
      keepFirstValue={false}
      style={style}
      quickEdit={quickEdit}
      selectedValues={selectedValues}
      handleSave={handleSave}
    />
}

const mapStateToProps = (state) => {
  return {
    apps: state.firestore.data.apps,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'apps' }], orderBy: ['appName', 'asc'], storeAs: 'apps' },]
  }
  )
)(AppSearchSelector)