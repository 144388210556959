import React, { PureComponent } from 'react';
import { Typography } from "@material-ui/core";

import { LineChart, Line, XAxis, YAxis, Tooltip, } from 'recharts';

export default class LineChartComponent extends PureComponent {

  render() {
    const name1Label = this.props.name1Label;

    let maxLength = this.props.data && this.props.data.length > 0 ?
      this.props.data.reduce(function (a, b) { return a[name1Label] && b[name1Label] && (a[name1Label].length > b[name1Label].length) ? a : b; }) : 10
    maxLength = maxLength[name1Label] ? maxLength[name1Label].length : 10
    console.log('LineChartComponent', this.props);

    return (
      this.props.data && this.props.data.length === 0 ? <Typography style={{ textAlign: "center", flex: 1, }}>No Data</Typography> :
        // <ResponsiveContainer width="99%" key={'Container' + JSON.stringify(this.props.layout)} className="dragCancel">
        <LineChart
          key={'LineChart' + JSON.stringify(this.props.layout)}
          width={this.props.width} height={300 + maxLength * 8}
          data={this.props.data}

          margin={
            this.props.isSmallScreen ? { top: 0, right: 5, left: 0, bottom: 0 }
              : { top: 30, right: 40, left: 20, bottom: 5, }}>
          <XAxis
            dataKey={name1Label} angle={-75} tickLine={false} interval={0}
            height={maxLength * 9}
            tick={this.props.isSmallScreen ? { fontSize: 9 } : { fontSize: 12 }}
            textAnchor="end">
          </XAxis>
          <YAxis tickLine={false}> </YAxis>
          <Tooltip />
          {/* <Legend /> */}
          {/**Map for multiple bars */}
          <Line dataKey={this.props.value1Label} type="monotone" stroke={this.props.color || "#8884d8"} />
          {/* <Line dataKey="uv" fill="#82ca9d" /> */}

        </LineChart>
      // </ResponsiveContainer >
    );
  }
}
