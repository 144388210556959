import React from 'react';
import { BottomNavigationLinks } from '../../utils/constants';
import { Link } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { CssBaseline } from '@material-ui/core';

export default function MobileAppRender({ children, pathname, profile }) {


  return <main>
    <CssBaseline />
    <div style={{ marginBottom: 56, width: window.innerWidth, }}> {children} </div>
    <BottomNavigation value={pathname} showLabels data-tut="homeNavigationBar"
      style={{ width: '100%', position: 'fixed', bottom: 0, }}>
      {BottomNavigationLinks.map((link, index) => <BottomNavigationAction
        style={{ padding: 0 }}
        key={link.linkName} id={link.linkName} label={link.linkName} value={link.path} icon={link.iconName} to={link.path} component={Link} disabled={!profile[link.permission]} />)}
    </BottomNavigation>
  </main>;
}