import React from 'react'
import googlePlayBadge from '../img/google-play-badge.png'
import iphoneAddTohome from '../img/iphoneAddToHome.png'
import Typography from '@material-ui/core/Typography';
import { divLightCard } from '../../utils/styleclasses';

export default function AppInstallation() {

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  return (
    !(window.matchMedia('(display-mode: standalone)').matches) && ('ontouchstart' in document.documentElement) && <div>
      {
        (!isIos()) ?
          <div>
            <a href='https://play.google.com/store/apps/details?id=com.opstack.app'>
              <img src={googlePlayBadge} alt="Install Opstack App" style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                display: 'block',
                width: '60%'
              }} /></a>
          </div>
          : <div style={{ ...divLightCard, margin: 8, padding: 24, display: 'inline-flex' }}>
            <Typography>  To install the Opstack App on iPhone, tap on <img src={iphoneAddTohome} alt="Install Opstack App" style={{ height: '20px' }} /> & tap 'Add to Home Screen'.</Typography>
          </div>
      }
    </div>
  )
}
