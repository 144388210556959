import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

function MapLocationPickerMap({ lat, lng, zoom, height, setNewLocation, onMarkerDragEnd }) {

  const AsyncMap = withScriptjs(
    withGoogleMap(
      props => (
        <GoogleMap
          onClick={onMarkerDragEnd}
          defaultZoom={zoom} defaultCenter={{ lat: lat, lng: lng }}>
          <Autocomplete
            style={{ width: '100%', height: '40px', paddingLeft: '16px', marginTop: '2px', marginBottom: '100px' }}
            onPlaceSelected={place => {
              console.log({ place });
              setNewLocation(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address, place.address_components)
            }}
            types={['establishment']}
          />
          <Marker draggable={true} onDragEnd={onMarkerDragEnd} position={{ lat: lat, lng: lng }} />
        </GoogleMap >
      )
    )
  );

  return <AsyncMap
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTJRUhiJva6404aQH-wImKcks-znWzfWs&v=3.exp&libraries=places"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: height }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
}

export default React.memo(MapLocationPickerMap, (prevProps, nextProps) => {
  const arePropsUnchanged = prevProps.lat === nextProps.lat && prevProps.lng === nextProps.lng && prevProps.zoom === nextProps.zoom;
  return arePropsUnchanged
})