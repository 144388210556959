import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Loader from './Loader'
import StandardSearchSelector from './StandardSearchSelector'
import Person from '@material-ui/icons/Person';

const UserSearchSelector =
  ({ noEdit, multiple, hideLabel, inputLabel, keepFirstValue, style, quickEdit, selectedValues, handleSave, allUsers, hasDefault, id, returnWithLabels }) => {

    const profile = useSelector(state => state.firebase.profile)
    useFirestoreConnect([{ collection: 'users', where: [['orgID', '==', profile.orgID],], storeAs: 'users' },])
    const users = useSelector(({ firestore: { ordered: { users } } }) => users)

    if (typeof users === 'undefined' || !users || users.length === 0) return <Loader />

    const usersArray = users.map(user => ({ label: user.firstName + ' ' + user.lastName, value: user.uid, })).sort((a, b) => (a.label > b.label ? 1 : -1));

    const allValuesAndLabels = allUsers ? [{ label: 'All Users', value: 'all' }, ...usersArray] : usersArray

    return <StandardSearchSelector
      noEdit={noEdit}
      id={"userSearchSelector" + id || "userSearchSelector"}
      multiple={multiple}
      inputLabel={hideLabel ? "" : (inputLabel || <Person style={{ marginRight: 4, }} />)}
      allValuesAndLabels={allValuesAndLabels}
      keepFirstValue={keepFirstValue}
      style={style}
      quickEdit={quickEdit}
      selectedValues={selectedValues
        //  || (hasDefault && allValuesAndLabels && allValuesAndLabels[0]) ? [allValuesAndLabels[0].value] : []
      }
      returnWithLabels={returnWithLabels}
      handleSave={handleSave} />
  }

export default UserSearchSelector
