import React from 'react'
import MaterialTable from 'material-table'
import { LinearProgress, Button, } from '@material-ui/core';
import { tableIcons } from '../../utils/tableUtil'
import { getPendingUploadList, deleteAllPendingUploads } from '../../utils/utilityfunctions'
import DeleteOutline from '@material-ui/icons/DeleteOutline';


const PendingSubmissions = (props) => {
  const [pendingUploadList, setPendingUploadList] = React.useState({ pendingList: [], notLoaded: true, emptyMessage: <LinearProgress /> });
  const listRef = React.useRef(pendingUploadList);


  getPendingUploadList()
    .then(newList => {
      if (JSON.stringify(listRef.pendingList) !== JSON.stringify(newList)) {
        console.log('Updating pending upload');
        let tempTimer;
        if (listRef.notLoaded)
          setPendingUploadList({ pendingList: [...newList], notLoaded: false, emptyMessage: 'No Pending Uploads' })
        else
          tempTimer = setTimeout(() => setPendingUploadList({ pendingList: [...newList], notLoaded: false, emptyMessage: 'No Pending Uploads' }), 1000)
      }
    })
    .catch(err => console.error('Error in getPendingUploadList', err))

  return (
    <div>
      <MaterialTable
        key={'table' + pendingUploadList.pendingList.length}
        icons={tableIcons}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          padding: "dense",
        }}
        title=""
        localization={{ body: { emptyDataSourceMessage: pendingUploadList.emptyMessage } }}
        columns={[
          // { title: "Upload Status", field: "status" },
          { title: "Upload Details", field: "uploadDetails", },
          // { title: "Field Name", field: "fieldLabel", },
          { title: "Submitted At", field: "submittedAt", },
          { title: "Submission ID", field: "submissionID", hidden: true }
        ]}
        data={pendingUploadList.pendingList.map(dataRow => { return { ...dataRow, uploadDetails: <span>{dataRow.appName + ' ' + dataRow.fieldLabel} <LinearProgress size={10} /> </span> } })}
      />
      {pendingUploadList.pendingList.length > 10 &&
        <Button variant="outlined" color="primary" style={{ margin: 16 }}
          onClick={deleteAllPendingUploads}>Delete Pending HD Uploads
      <DeleteOutline />
        </Button>}
    </div>
  )
}

export default PendingSubmissions

