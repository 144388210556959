import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import AddCircle from '@material-ui/icons/AddCircle';
import { properCase } from '../../utils/stringutils';
import { iconTextAlign } from '../../utils/iconutils';

function SearchAddSelector({ handleAdd, profile, searches }) {
  const [search, setSearch] = React.useState({ openPopover: false });

  const searchTypes = ['map', 'gallery', 'report', 'task'];
  return (
    <span style={{ margin: 8 }} >
      {
        <span>
          <Chip color="primary" variant='outlined'
            label={iconTextAlign(<AddCircle />, 'Search')}
            onClick={(e) => setSearch({ ...search, anchorEl: e.currentTarget, openPopover: true })} />
          {search.openPopover && <Popover
            open={search.openPopover}
            anchorEl={search.anchorEl}
            onClose={() => { setSearch({ ...search, openPopover: false }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { padding: 16, textAlign: 'center' } }}>
            <div style={{ minWidth: 500, textAlign: 'left' }}>
              <Select id="selectSearches" variant="standard" style={{ width: '100%', padding: 8, }} value={search.searchID || ''}
                onChange={(e) =>
                  setSearch({ ...searches.find(row => row.searchID === e.target.value), openPopover: true })}>
                {searches.map(search => <MenuItem style={{ padding: 8 }} key={search.searchID} value={search.searchID}> {search.searchName}  </MenuItem>)}
              </Select>
              <Select id="searchType" variant="standard" style={{ width: '100%', padding: 8, }} value={search.searchType || ''}
                onChange={(e) => setSearch({ ...search, searchType: e.target.value, openPopover: true })}>
                {searchTypes.map(searchType =>
                  <MenuItem style={{ padding: 8 }} key={searchType} value={searchType}>{properCase(searchType)}</MenuItem>)}
              </Select>
              <Button color="primary" variant="outlined" onClick={() => { setSearch({ openPopover: false }) }} style={{ margin: 8 }}> Cancel </Button>
              {search.searchType && search.searchID && <Button variant="contained" color="primary" style={{ marginRight: 8 }}
                onClick={(e) => { handleAdd(search); setSearch({ openPopover: false }) }}>
                Add Search
              </Button>}
            </div>
          </Popover>}
        </span>
      }
    </span >
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    searches: state.firestore.ordered.searches,
  }
}

export default compose(connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'searches' }],
        orderBy: ['searchName', 'asc'], storeAs: 'searches'
      },
    ]
  }
  ))(SearchAddSelector)