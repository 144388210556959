import { Chip } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Refresh from '@material-ui/icons/Refresh';
import React, { useState } from 'react';

export function EmbedIframe({ src }) {
  const [key, resetKey] = useState(0)
  console.log('EmbedIframe', { src });
  return <div>
    <Chip color="primary" label={<Refresh />} onClick={() => resetKey(key + 1)} title="Reload Tab"
      style={{ position: 'absolute', top: 96, right: 0, margin: 8, zIndex: 1200 }}></Chip>
    <Chip color="primary" label={<OpenInNew />} onClick={() => window.open(src, '_blank')} title="Open Tab in New Browser"
      style={{ position: 'absolute', top: 132, right: 0, margin: 8, zIndex: 1200 }}></Chip>
    <iframe key={key} frameBorder={0} title=' '
      style={{ width: '100%', height: window.innerHeight - 97, display: 'block' }}
      src={src} /></div>
}
