import moment from 'moment'

export const getDateIfValidDate = (checkDate) => {
  if (checkDate instanceof Date) return checkDate
  const returnDate = new Date(checkDate)
  if (isNaN(returnDate)) return null
  else return returnDate
}

export const fieldToFormattedDate = (field, type) => {
  const momentDate = field && field.toDate ? moment(field.toDate()) : moment(field)
  let format = 'YYYY-MM-DD h:mm A'
  if (type === 'date') format = 'YYYY-MM-DD'
  if (type === 'time') format = 'h:mm A'
  if (type === 'monthYear') format = 'MMMM YYYY'
  if (type === 'year') format = 'YYYY'
  return momentDate.format(format)
}

export function getDisplayTimeZone(timeZone) {
  return (timeZone && timeZone[0] && timeZone[0].value) || "Asia/Calcutta";
}
