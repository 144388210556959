import React, { useState } from 'react';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { RenderTable } from './RenderTable';
import StandardComponentRenderer from '../../standard/StandardComponentRenderer';
import { Chip, Typography } from '@material-ui/core';
import { getLSBoolean } from '../../../utils/storageutils';
import { isJSON } from '../../../utils/utilityfunctions';
import { getJSONfromString } from '../../../utils/stringutils';
const uuid = require('uuid/v4')

export function RenderTableWithOptions({ rowData, columns, requiredFields, options, saveUpdatedRow, deleteRow, limit }) {

  const standardOptions =
    [
      { fieldLabel: 'Embed Map Links', fieldID: 'embedMaps', fieldValue: getLSBoolean('embedMaps', false), fieldType: 'switch' },
      { fieldLabel: 'Embed Images', fieldID: 'embedImages', fieldValue: getLSBoolean('embedImages', true), fieldType: 'switch' },
      { fieldLabel: 'Embed Tables', fieldID: 'embedTables', fieldValue: getLSBoolean('embedTables', true), fieldType: 'switch' },
    ]
  const [optionState, setOptionState] = useState({ ...Object.assign({}, ...standardOptions.map(row => ({ [row.fieldID]: row.fieldValue }))) })

  const [gridAPI, setGridAPI] = useState(null);
  const [gridColumnAPI, setGridColumnAPI] = useState(null);

  const convertersForCSVExport = {
    processCellCallback: ({ value }) => {
      if (value && (value.fieldURL || value.fieldCompressedURL)) return value.fieldURL || value.fieldCompressedURL
      if (value && value.columns) return JSON.stringify(value.columns.map(column => column.headerName)) + JSON.stringify(value.data.map(row => Object.values(row)))
      return value
    }
  }
  function handleChange(fieldID, fieldValue) {
    localStorage.setItem(fieldID, fieldValue);
    setOptionState({ ...optionState, [fieldID]: fieldValue })
  }

  function addRow() {
    let id = uuid();
    let emptyRow = { id, };
    if (gridAPI.getEditingCells().length > 0) { gridAPI.stopEditing(false) }


    setTimeout(() => {
      gridAPI.applyTransaction({ add: [emptyRow] });
      let node = gridAPI.getRowNode(id);
      gridAPI.ensureIndexVisible(node.rowIndex);
      gridAPI.startEditingCell({ rowIndex: node.rowIndex, colKey: gridColumnAPI.getAllColumns()[0].colId });
    }, 300);
  }

  let settings = options && options.settings ? isJSON(getJSONfromString(options.settings)) : null

  if (settings)
    settings = { ...settings.default, ...(window.innerWidth < 825) ? settings.smallScreen : {} }

  return <>
    {      (rowData.length >= limit) && <Typography variant='caption' style={{ marginLeft: 8 }}>Maximum display row limit of {limit} reached. Please use filters to limit rows to view all results.</Typography>}
    {
      options && !options.noToolbar &&
      <div style={{ display: "flex", maxWidth: window.innerWidth - 16, flexWrap: "wrap" }}>
        {standardOptions.map(field => <StandardComponentRenderer key={field.fieldID} field={{ ...field, fieldValue: optionState[field.fieldID] }}
          componentProps={{ style: { marginLeft: 8, fontSize: 10 } }} handleChange={handleChange} />)}
        {!options.hideExport &&
          <Chip label="Export"
            onClick={() => gridAPI.exportDataAsCsv(convertersForCSVExport)}
            variant='outlined' color='primary' style={{ margin: 8, marginTop: 0 }} />}
        {options.editable && gridAPI && saveUpdatedRow &&
          <>
            {options.addRow && <Chip name='addTableRow' label="Add Row" onClick={addRow} variant='outlined' color='primary' style={{ margin: 8, marginTop: 0 }} />}
            <Chip label="Save (Enter)" onClick={() => gridAPI.stopEditing(false)} variant='outlined' color='primary' style={{ margin: 8, marginTop: 0 }} />
            <Chip label="Discard (Esc)" onClick={() => gridAPI.stopEditing(true)} variant='outlined' color='primary' style={{ margin: 8, marginTop: 0 }} />
          </>
        }
      </div>
    }

    <div style={(options && options.style) || { height: window.innerHeight - ((settings && settings.heightModifier) || 135), width: '100%' }}>
      <RenderTable
        rowData={rowData}
        columns={columns}
        requiredFields={requiredFields}
        options={{ ...optionState, ...options }}
        setGridAPI={setGridAPI}
        setGridColumnAPI={setGridColumnAPI}
        saveUpdatedRow={saveUpdatedRow || null}
        deleteRow={deleteRow}
      />
    </div>
  </>
}