import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Tour from 'reactour';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Forward from '@material-ui/icons/Forward';
import Chip from '@material-ui/core/Chip'
import { displayLeft } from '../../utils/styleclasses';
import { primary } from '../../utils/themes';
import { updateProfileTourStatus } from '../../store/actions/authActions';

function TourContainer(props) {
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      {(!props.profile.tourStatus || props.profile.tourStatus[props.tourName] !== 'completed' || props.alwaysOn === true) &&
        <Tour
          nextButton={<Chip style={{ cursor: "pointer" }} variant='outlined' color='primary' name='viewNextTourStep' label={<div style={displayLeft}>View Next <Forward /> </div>} />}

          lastStepNextButton={<Chip style={{ cursor: "pointer" }} variant='outlined' color='primary' name='viewLastTourStep'
            label={<div style={displayLeft}>We're Done! <CheckCircle style={{ marginLeft: 8 }} /> </div>} />}

          onRequestClose={() => {
            setOpen(false); props.updateProfileTourStatus({
              ...props.profile,
              tourStatus: { ...props.profile.tourStatus, [props.tourName]: 'completed' }
            })
          }}
          steps={props.tourConfig}
          isOpen={open}
          maskClassName="mask"
          className="helper"
          rounded={20}
          accentColor={primary}
        />}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileTourStatus: (profile) => { dispatch(updateProfileTourStatus(profile)) },

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    channels: state.firestore.data.channels,
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TourContainer)