import React from 'react'
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import { Dialog, IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { commonIcons } from '../../utils/iconutils';

export default function PopoverButton(
  { text, icon, popoverContent, variant, color, showCloseIcon, verticalOrigin, horizontalOrigin, useChip, useIconButton, closePopoverOnClick, buttonProps, id, useDialog }) {

  const [popover, setPopover] = React.useState({ open: false, });
  const openPopover = (e) => (setPopover({ ...popover, open: !popover.open, anchorEl: e.currentTarget }));
  const Component = useDialog ? Dialog : Popover
  const componentProps = useDialog ?
    { fullWidth: true, maxWidth: "lg" } :
    {
      anchorEl: popover.anchorEl,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left', },
      transformOrigin: { vertical: verticalOrigin || 'top', horizontal: horizontalOrigin || 'left', }
    }

  return (
    <span>
      {useIconButton ? <span onClick={openPopover}>{commonIcons[icon]} </span>
        : !useChip ? <Button {...id ? { id } : {}} style={{ margin: 8 }} variant={variant || "contained"} color="primary"
          onClick={openPopover}>
          {commonIcons[icon] || null}<span style={{ marginLeft: icon ? 8 : 0 }} {...buttonProps}>{text || ""}</span>
        </Button>
          : <Chip {...id ? { id } : {}} clickable style={{ margin: 8 }} variant={variant || "default"} color={color || "primary"}
            onClick={openPopover}
            icon={commonIcons[icon] || null} label={text || ""} {...buttonProps}
          />
      }
      {popover.open && <Component open={popover.open} {...componentProps}
        onClose={() => { setPopover({ ...popover, open: false }) }}
        onClick={(e) => { return closePopoverOnClick ? setPopover({ ...popover, open: false }) : null }}

        PaperProps={{ style: { padding: 8, textAlign: 'center', } }}>
        {showCloseIcon &&
          <IconButton onClick={() => setPopover({ ...popover, open: false })}
            style={{ position: "absolute", top: 0, right: 0 }}>
            <Cancel />
          </IconButton>}
        {popoverContent}
      </Component>}
    </span >
  )
}