import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createTaskActivity } from '../../store/actions/formActions';
import { fieldIDGenerator } from '../../utils/constants';
import { getImageUploadPath } from '../../utils/firebaseUtil';
import { DialogTransition } from '../../utils/materialuiutils';
import { dialogSize } from '../../utils/styleclasses';
import ActivityCard from '../newsfeed/ActivityCard';
import ImageOverlay from '../standard/ImageOverlay';
import TitleAppBar from '../standard/TitleAppBar';
import UnifiedCameraComponent from '../standard/UnifiedCameraComponent';
import UserAppSearchSelector from '../standard/UserAppSearchSelector';
import UserSearchSelector from '../standard/UserSearchSelector';

const CreateTaskDialog = (props) => {
  const [task, setTask] = React.useState({
    userTaskName: props.taskTitle ? `${props.taskTitle} - ${(new Date()).toLocaleString()}` :
      (props.activity && props.activity.activityTitle) ?
        "Re: " + props.activity.activityTitle + " by " + props.activity.activityByName + " at " + (props.activity.activityReceivedTime.toDate()).toLocaleString()
        : "New Task - " + (new Date()).toLocaleString(),
    taskImage: (props.image && props.image.compressedURL) ? props.image : null,
    taskDescription: null,
    assignedToUID: props.activity && props.activity.activityByUID ? [props.activity.activityByUID] : [],
    appAssigned: null,
    appAssignedName: null,
    taskActivityID: fieldIDGenerator(),
    taskImagePaths: []
  });

  const imageFileName = `userTask-uid-${props.profile.uid}-tid-${task.taskActivityID}`
  console.log('CreateTaskDialog state', { task });

  return (
    <div >
      {<Dialog {...dialogSize}
        open={props.open} keepMounted TransitionComponent={DialogTransition}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <TitleAppBar>
          <IconButton edge="start" style={{ margin: 0, marginRight: 8, padding: 0, color: "white" }}
            onClick={() => props.setOpen(false)}
            aria-label="Close">
            <CloseIcon />
          </IconButton>
          Create New Task</TitleAppBar>

        <DialogContent>
          <Typography >Task Title</Typography>
          <TextField
            id="userTaskName"
            key="userTaskName"
            multiline={true}
            value={task.userTaskName}
            margin="normal"
            style={{ width: "100%", margin: 0, }}
            onChange={(e) => setTask({ ...task, [e.target.id]: e.target.value })}
          />

          <Typography style={{ marginTop: 16 }} >Assign Task To</Typography>
          <UserSearchSelector id='assignTaskTo' selectedValues={task.assignedToUID} style={{ width: "100%", marginBottom: 8 }}
            quickEdit={true} inputLabel={""} hideLabel={true} handleSave={(userList) => setTask({ ...task, assignedToUID: userList })}
          />
          {props.activity && props.activity.activityID && <ActivityCard key={props.activity.id} activity={props.activity} summary={false} hideFooter={true} activityID={props.activity.id}
            cardMargin={12} />}
          {!task.taskImage && <UnifiedCameraComponent style={{ marginBottom: 16 }}
            cameraToUse={props.profile.cameraToUse} fieldID={task.taskActivityID} allowUpload={true} frontCamera={false} name={'taskCamera'}
            orgID={props.profile.orgID}
            handleChange={(id, image, imageUploadPromise) => {
              setTask({
                ...task, taskImagePaths: [getImageUploadPath(props.profile.orgID, imageFileName)], imageUploadPromise: imageUploadPromise
              })
            }
            }
            uploadToStorage={() => { }}
            imageFileName={imageFileName}
            uploadDescription={task} />}

          {props.image && props.image.compressedURL &&
            <ImageOverlay
              imageLink={props.image.compressedURL}
              activityImage={props.image}
              imageStyle={{ height: 300 }}
              options={{ showImageTags: true, }}
              fieldID={props.image.fieldID}
              activityID={props.activityID} />
          }
          <Typography >Attach App to Task</Typography>
          <UserAppSearchSelector
            selectedValues={task.appAssigned ? [task.appAssigned] : []} style={{ width: "100%", }}
            quickEdit={true}
            inputLabel={""}
            handleSave={(appAssigned, appAssignedNames) => {
              console.log({ appAssigned });
              setTask({
                ...task, appAssigned: (appAssigned[0] && appAssigned[0].value) || null,
                appAssignedName: (appAssigned[0] && appAssigned[0].label) || null
              })
            }}
            disableClearable={true} />
          <TextField
            id="taskDescription"
            key="taskDescription"
            label="Task Note"
            name="taskNote"
            margin="normal"
            style={{ width: "100%", padding: 8, marginBottom: 0, }}
            onBlur={(e) => setTask({ ...task, [e.target.id]: e.target.value })}
          />
        </DialogContent>
        <div style={{ textAlign: 'right', width: "100%" }}>
          <Button variant="contained" color="secondary" style={{ margin: 16, marginBottom: 36, maxWidth: 300, }} id="createTask"
            disabled={((!task.userTaskName && !task.appAssigned) ||
              !(task.assignedToUID && task.assignedToUID[0]))}
            onClick={() => {
              console.log("task.taskImage", task.taskImage);
              props.createTaskActivity(
                {
                  taskName: task.userTaskName,
                  taskDescription: task.taskDescription,
                  assignedToUID: task.assignedToUID,
                  appAssigned: task.appAssigned,
                  taskActivityID: task.taskActivityID,
                  taskImagePaths: task.taskImagePaths,
                  imageUploadPromise: task.imageUploadPromise,
                  parentActivityID: (props.activityID || (props.activity && props.activity.id)) || null,
                  taskImage: task.taskImage,
                })
              props.setOpen(false)
              props.setAlertMessage('Task Created')
            }}>
            Create Task
          </Button>
        </div>
      </Dialog>}
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return { createTaskActivity: (taskObject) => dispatch(createTaskActivity(taskObject)) }
}

const mapStateToProps = (state) => {
  console.log(' mapStateToProps = (state)', state);
  return {
    // groupsData: state.firestore.data.groups,
    // users: state.firestore.ordered.users && Object.values(state.firestore.data.users),
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // firestoreConnect(props => {
  //   return [
  //     { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'groups' }], storeAs: 'groups' },
  //     { collection: 'users', where: [['orgID', '==', props.profile.orgID]], storeAs: 'users' }
  //   ]
  // }
  // )
)(CreateTaskDialog)