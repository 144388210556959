const initState = {
  forms:
    [
    ]
}
const formReducer = (state = initState, action) => {

  // console.log('State Inside formReducer', state);

  switch (action.type) {
    case 'CREATE_FORM':
      console.log('created form', action.form)
      return state;
    case 'CREATE_FORM_ERROR':
      console.log('create form ERROR', action.err)
      return state;
    case 'UPDATE_FORM':
      console.log('Updated form', action.form, action.id)
      return state;
    case 'UPDATE_FORM_ERROR':
      console.log('Update form ERROR', action.err)
      return state;
    case 'DELETE_FORM':
      console.log('Delete form')
      return state;
    case 'DELETE_FORM_ERROR':
      console.log('Delete form ERROR', action.err)
      return state;
    case 'SUBMIT_FORM':
      console.log('SUBMIT form')
      return state;
    case 'SUBMIT_FORM_ERROR':
      console.log('SUBMIT form ERROR', action.err)
      return state;
    case 'OPTION_LIST_CREATE_UPDATE':
      console.log('SUBMIT OPTION LIST')
      return state;
    case 'OPTION_LIST_CREATE_UPDATE_ERROR':
      console.log('SUBMIT OPTION LIST ERROR', action.err)
      return state;
    case 'OPTION_LIST_DELETE':
      console.log('DELETE OPTION LIST')
      return state;
    case 'OPTION_LIST_DELETE_ERROR':
      console.log('DELETE OPTION LIST ERROR', action.err)
      return state;
    default:
      return state;
  }
}

export default formReducer