import React from 'react'
import WorkOff from '@material-ui/icons/WorkOffTwoTone';
import Loader from '../standard/Loader'
import TaskCardContainer from './TaskCardContainer'
import EmptyImagePlaceholder from '../standard/EmptyImagePlaceholder';

const TaskList = ({ taskList, noResults, setSelectedApp }) => {
  if (noResults) return <EmptyImagePlaceholder message="No current tasks" icon={<WorkOff color="primary" style={{ marginRight: 16, fontSize: 40 }} />} />

  return (
    <div>
      {
        taskList && taskList.length > 0 ?
          <div >
            {
              taskList.filter(task => task.taskID).map((task, i) =>
                < div key={task.taskID} style={{ margin: 8 }}>
                  <TaskCardContainer task={task} setSelectedApp={setSelectedApp} />
                </div>)
            }
          </div>
          : <Loader />
      }
    </div>
  )
}
export default TaskList