import React from 'react'
import { Typography } from '@material-ui/core'

function EmptyImagePlaceholder({ message, icon, imageType }) {


  const imgSource = {
    office: 'https://storage.googleapis.com/opstak-2019.appspot.com/0SYSTEM/ASSETS/STANDARD/placeholders/OfficeEmpty.png',
    bench: "https://storage.googleapis.com/opstak-2019.appspot.com/0SYSTEM/ASSETS/STANDARD/NoActivity.png",
    default: "https://storage.cloud.google.com/opstak-2019.appspot.com/0SYSTEM/ASSETS/STANDARD/NoResults.png"
  }

  return <div style={{ marginTop: 16, display: 'block', textAlign: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {icon ? icon : null}
      {message ? <Typography>{message}</Typography> : null}
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 16 }}>

      <img src={(imageType && imgSource[imageType]) || imgSource.default} alt="No Results" style={{ objectFit: 'fill', maxWidth: '100%', opacity: 0.5 }} />

    </div>
  </div>
}

export default React.memo(EmptyImagePlaceholder)