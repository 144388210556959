import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser from 'react-html-parser';
import { allImageUpload } from '../../utils/firebaseUtil';
import { fieldIDGenerator } from '../../utils/constants';

function GridContentItem(props) {
  console.log('GridContentItem', props);

  class FBUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
    upload() {
      return this.loader.file.then(
        file => allImageUpload(file, `${props.orgID}/pages/${fieldIDGenerator()}`, 0.5)
          .then(downloadURL => ({ default: downloadURL }))
          .catch(err => console.error(err))
      );
    }
  }

  return <div className="GridContentItem">
    {
      props.editOpen && <CKEditor
        editor={ClassicEditor}
        data={props.data || "<p>Click on Edit Layout to modify content</p>"}
        onReady={editor => {
          if (editor)
            editor.plugins.get("FileRepository").createUploadAdapter = loader => { return new FBUploadAdapter(loader); };
        }}
        // onChange={(event, editor) => {
        //   const data = editor.getData();
        //   console.log({ event, editor, data });
        //   setData(data);
        // }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          console.log('GridContentItem', 'Blur.', { data, event, editor });
          props.saveItem(data)
        }}
        onFocus={(event, editor) => { console.log('Focus.', editor); }}
      />
    }
    {!props.editOpen && <div className="ck-content" style={{ margin: 8 }}>{ReactHtmlParser(props.data)}</div>}
  </div >
}

export default GridContentItem;

// CKEDITOR.editorConfig = function( config ) {
// 	config.toolbarGroups = [
// 		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
// 		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
// 		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
// 		{ name: 'forms', groups: [ 'forms' ] },
// 		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
// 		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
// 		{ name: 'links', groups: [ 'links' ] },
// 		{ name: 'insert', groups: [ 'insert' ] },
// 		{ name: 'styles', groups: [ 'styles' ] },
// 		{ name: 'colors', groups: [ 'colors' ] },
// 		{ name: 'tools', groups: [ 'tools' ] },
// 		{ name: 'others', groups: [ 'others' ] },
// 		{ name: 'about', groups: [ 'about' ] }
// 	];

// 	config.removeButtons = 'Scayt,SelectAll,Find,Replace,Cut,Copy,Paste,PasteText,PasteFromWord,Templates,Save,NewPage,ExportPdf,Preview,Print,ShowBlocks,Maximize,BidiLtr,BidiRtl,Language,Anchor,Flash,PageBreak,About,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv';
// };