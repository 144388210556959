import React from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { updatePage, deletePage, refreshAllDashboardCards } from '../../store/actions/formActions';
import LoadingSplashScreen from '../layout/LoadingSplashScreen';
import { MobilePagesRender } from './MobilePagesRender';

function MobilePagesRenderContainer({ orgSettings, pagesOrdered, myPageOrdered, updatePage, deletePage, refreshPageCards, profile, }) {
  const [selectedPage, setSelectedPage] = React.useState(null);

  if (typeof pagesOrdered === 'undefined' || typeof myPageOrdered === 'undefined')
    return <LoadingSplashScreen message="Syncing Data" />

  const myPage = { pageID: 'myPage', pageLabel: 'My Page', ...myPageOrdered };
  const pages = [...pagesOrdered.filter(page =>
    ((page.pageEditors && page.pageEditors.includes(profile.uid))
      || (page.userList && page.userList.includes(profile.uid))
      || (page.groupList && page.groupList.some(row => [...profile.groupList, "default", "all"].includes(row))))
    && page.tabs && page.tabs.find(tab => tab.showOnMobile)
  ), myPage]


  const uid = profile.uid
  if (!selectedPage) {
    let page
    if (localStorage.getItem('selectedPageID'))
      page = pages.find(page => page.pageID === localStorage.getItem('selectedPageID'))
    if (page) setSelectedPage(page)
    else setSelectedPage(pages[0])
    return <LoadingSplashScreen message="Syncing Data" />
  }

  const isPageEditor = selectedPage.pageEditors && selectedPage.pageEditors.includes(profile.uid)
  return <MobilePagesRender {...{ orgSettings, selectedPage, setSelectedPage, pages, updatePage, deletePage, refreshPageCards, isPageEditor, uid }} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePage: (page, id) => dispatch(updatePage(page, id)),
    deletePage: (id) => dispatch(deletePage(id)),
    refreshPageCards: (tabID) => dispatch(refreshAllDashboardCards('pageTabID-' + tabID))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orgSettings: state.firestore.data.orgSettings,
    pagesOrdered: state.firestore.ordered.pages,
    myPageOrdered: state.firestore.data.myPage,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, storeAs: 'orgSettings' },
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'pages' }], storeAs: 'pages' },
      { collection: `organizations/${props.profile.orgID}/userdata/${props.profile.uid}/myPage`, doc: 'myPage', storeAs: 'myPage' },
    ]
  })
)(MobilePagesRenderContainer)
