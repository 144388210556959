import React from 'react'
import { Typography } from '@material-ui/core';
import GreenTickLogo from '../img/favicon.svg'


const tourConfig = [
  {
    selector: '[data-tut="tourWelcomeMessage"]',
    content: <div style={{ textAlign: 'center', margin: 8 }}>
      <img src={GreenTickLogo} width={50} style={{ margin: 16 }} alt='Done' />
      <Typography variant="h4">Welcome to Opstack</Typography>
      <Typography variant="subtitle1">
        Let's give you a quick overview of the Opstack Mobile App
        </Typography>
      <img src={"https://uploads-ssl.webflow.com/5e513fd2670c44d0817277da/5e513fd2694fa2665acee2bb_ipad-02-min.png"} width={100} style={{ margin: 16 }} alt='Done' />
    </div>
  },
  {
    selector: '[data-tut="tourMyTasks"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Tasks assigned to you or your team are available in your task list.</Typography>
  },
  {
    selector: '[data-tut="tourRecentActivity"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Your Activity feed will display your completed tasks and a feed of your teams activities</Typography>
  },
  {
    selector: '[data-tut="tourAppsButton"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Processes, apps, requests & checklists in your organization are available as Apps. Use them to get things done!</Typography>
  },
  {
    selector: '[data-tut="homeNavigationBar"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Your Tasks, Apps and Activity can be accessed from this navigation bar.</Typography>
  },
];

export default tourConfig;
