import React, { useState } from 'react'
import { displayLeft } from '../../utils/styleclasses';
import MapPopover from '../standard/MapPopover'
import CreateTask from '../tasks/CreateTask';
import ShareButton from '../standard/ShareButton';
import Chat from '@material-ui/icons/Chat';
import CheckCircle from '@material-ui/icons/CheckCircle';
import NoteList from './NoteList';
import Chip from '@material-ui/core/Chip';
import InboxCard from './InboxCard';
import { Rating } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

export default function ActivityCardFooter({ activity, activityID, footerOptions, uid }) {
  const [showNotes, setShowNotes] = useState(false || (footerOptions && footerOptions.showNotesDefault))
  const [showTasks, setShowTasks] = useState(false)
  const tasksCount = (activity.tasksCreated && Object.values(activity.tasksCreated).length)
  const averageRating = activity.totalRatings && activity.ratingCount ? Math.round(activity.totalRatings / activity.ratingCount * 100) / 100 : null
  // console.log('ActivityCardFooter', { averageRating });
  return (
    <div>
      <div style={{ margin: 0, paddingTop: 8, paddingBottom: 8, ...displayLeft }}>
        {!(footerOptions && footerOptions.hideShare) && <ShareButton title={`${activity.activityTitle} by ${activity.activityByName} at  ${activity.activityReceivedTime && activity.activityReceivedTime.toDate().toLocaleString()}`} text='Sharing activity from Opstack' referenceID={activityID} type="activity"
        />}
        <MapPopover mapLink={activity.activityMapLink} style={{ marginLeft: 8 }} iconOnly={true} />
        {<Chip variant="outlined" color="primary"
          onClick={() => { setShowTasks(false); setShowNotes(!showNotes) }} style={{ marginLeft: 8 }}
          label={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Chat style={{ fontSize: 20, marginRight: 4 }} /> Notes {(activity.noteCount || "")}</div>} />}
        {averageRating && <Chip variant="outlined" color="primary" style={{ marginLeft: 8 }} onClick={() => { setShowTasks(false); setShowNotes(!showNotes) }}
          label={<span style={displayLeft}>  <Typography variant='h6' color='primary' style={{ marginLeft: 4, }}>{averageRating}</Typography>
            <Rating name={'rating' + activityID} readOnly value={averageRating} precision={0.1} max={1} /></span>} />
        }
        {!(footerOptions && footerOptions.hideTasks) &&
          (tasksCount && !showTasks ?
            <Chip variant="outlined" color="primary" onClick={() => setShowTasks(!showTasks)} style={{ marginLeft: 8 }}
              label={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CheckCircle style={{ fontSize: 20, marginRight: 4 }} />
                {tasksCount} Tasks</div>}
            />
            : <CreateTask variant="outlined" style={{ marginLeft: 8 }} activity={activity} activityID={activityID} />)
        }
      </div >
      {showTasks && <div style={{ textAlign: 'left', width: '100%', }}>
        {tasksCount &&
          <div >{Object.values(activity.tasksCreated)
            .map((task, index) => <InboxCard key={task.referenceID + index}
              from={task.taskByName || 'Opstack'}
              type={"task"}
              title={task.taskName || 'User Task'}
              date={(task.editedAt && task.editedAt.toDate()) || ''}
              referenceID={task.referenceID}
              reference={task.reference}
            />)}
          </div>
        }
      </div>
      }
      {showNotes && <div style={{ margin: 8 }}>
        <NoteList activityID={activityID} noteCount={activity.noteCount} />
      </div>}

    </div>
  )
}

// <Rating
// name="simple-controlled"
// value={(activity.rating && activity.rating[uid]) || 0}
// onChange={(event, newValue) => {
//   // setValue(newValue);
// }}
// />