import { ifDBisEmptyMovePendingSubmissionsToCompleted, ifOnlineAttemptPendingUploads } from '../../utils/firebaseUtil';
import { fieldIDGenerator } from "../../utils/constants";
import { getDisplayDateRange } from "../../components/standard/DateRangeSelector";
import { getInternetTime } from '../../components/appviewer/InternetAlertCard';

export const deleteApp = (appID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection(`organizations/${getState().firebase.profile.orgID}/apps`)
      .doc(appID).delete()
      .then(() => {
        dispatch({ type: 'DELETE_APP' })
      }).catch((err) => {
        console.error('DELETE_APP', err)
        dispatch({ type: 'DELETE_APP_ERROR', err: err })
      })
  }
}

export const updateApp = (app) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('updateApp', { app });
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/apps`).doc(app.appID).set({
      ...app,
      screens: app.screens.map(screen => ({
        ...screen, fieldList: screen.fieldList.map(field =>
          ({ ...field, fieldValue: null, fieldDesignerTempValue: null }))
      })),
      groupList: app.groupList ? app.groupList : [],
      editorFirstName: getState().firebase.profile.firstName,
      editorLastName: getState().firebase.profile.lastName,
      uid: getState().firebase.auth.uid,
      createdAt: new Date(),
    }).then(() => {
      dispatch({ type: 'UPDATE_APP', app: app, id: app.appID })
    }).catch((err) => {
      console.error('dispatch error', err)
      dispatch({ type: 'UPDATE_APP_ERROR', err: err })
    })

  }
}

export const setAppGroupList = (appID, newGroupList) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/apps`)
      .doc(appID)
      .set({ groupList: newGroupList }, { merge: true })
      .then(() => { dispatch({ type: 'UPDATE_APP', id: appID }) })
      .catch((err) => {
        console.error('appActions error', err); dispatch({ type: 'UPDATE_APP_ERROR', err: err })
      })
  }
}


export const submitApp = (appSubmission, task) => {
  console.log("submitApp", { appSubmission, task });

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const profile = getState().firebase.profile;
    const auth = getState().firebase.auth;
    const root = `organizations/${profile.orgID}`;
    const firestore = getFirestore();
    const uploadPromises = appSubmission.uploadPromises
    let collectionToSaveSubmission = (!uploadPromises) ? `${root}/submissions` : `${root}/pending-submissions`

    let submissionToSave = {
      ...appSubmission,
      submittedByFirstName: profile.firstName,
      submittedByLastName: profile.lastName,
      submittedByName: `${profile.firstName} ${profile.lastName}`,
      submittedByUID: auth.uid,
      submitterEmail: auth.email,
      submittedAt: new Date()
    };
    delete submissionToSave.uploadPromises

    if (task && task.taskID) { submissionToSave.taskID = task.taskID }
    if (task && task.taskName) { submissionToSave.taskName = task.taskName }

    const getSubmittedAt = new Promise((resolve, reject) => (appSubmission.settings && appSubmission.settings.requiredOnline)
      ? getInternetTime().then(time => resolve({ submittedAt: time })) : resolve({ submittedAt: new Date() }))
    // console.log('formActions 1 before updating taskID', task )

    return getSubmittedAt.then(submittedAt => {
      // console.log('formActions 1.5 before updating taskID', { task },
      //   { collectionToSaveSubmission },
      //   '  appSubmission.submissionID', appSubmission.submissionID,
      //   { submissionToSave }, { submittedAt }
      // )
      return firestore.collection(collectionToSaveSubmission).doc(appSubmission.submissionID).set({ ...submissionToSave, ...submittedAt }, { merge: true })
        .catch(err => console.error(err))
    }

    ).then(() => task && task.taskID && updateTaskWithSubmission())
      .then(() => uploadPromises && ifUploadDoneMovePendingToSubmissions())
      .then(() => ifOnlineAttemptPendingUploads())
      .then(() => { dispatch({ type: 'SUBMIT_APP', appSubmission: appSubmission }) })
      .catch((err) => { dispatch({ type: 'SUBMIT_APP_ERROR', err: err }) })
      // .then(() => getFirebase().analytics().logEvent("app_submission", getUserDetailsForAnalytics(profile))) ANALYTICS TURN ON
      .then(() => ifDBisEmptyMovePendingSubmissionsToCompleted(firestore, root, auth.uid))
      .catch((err) => console.error("Catch for submit image", err))

    function updateTaskWithSubmission() {
      return firestore.collection(`${root}/tasks`)
        .doc(task.taskID).get()
        .then((doc) => {
          let taskDoc = doc.data();
          let submitted = 1
          if (taskDoc.submitted) { submitted = taskDoc.submitted + 1; }
          const updatedTask = {
            submitted: submitted, submittedAt: new Date(), appName: appSubmission.appName,
            submittedBy: profile.firstName + ' ' + profile.lastName,
          }
          return firestore.collection(`${root}/tasks`).doc(task.taskID).set({ ...updatedTask }, { merge: true })
        }).then(() => console.log('formActions 4 before updating task.taskID', task.taskID))
    }

    function ifUploadDoneMovePendingToSubmissions() {
      return Promise.all(uploadPromises).then(() => {
        return firestore.collection(`${root}/submissions`).doc(appSubmission.submissionID).set({ ...submissionToSave })
      }).then(() => firestore.collection(`${root}/pending-submissions`).doc(appSubmission.submissionID).delete())
    }
  }
}

export const updateOptionList = (optionList, optionListID) => {
  console.log('updateOptionList = (optionList, optionListID)', optionList, optionListID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-optionlists`).doc(optionListID).set(
      {
        ...optionList,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        uid: getState().firebase.auth.uid,
        editedAt: new Date()
      }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_OPTION_LIST', optionList: optionList })
    }).catch((err) => {
      dispatch({ type: 'CREATE_OR_UPDATE_OPTION_LIST_ERROR', err: err })
    })
  }
}

export const deleteOptionList = (optionListID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteOptionList', optionListID);
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-optionlists`).doc(optionListID).delete()
      .then(() => {
        dispatch({ type: 'OPTION_LIST' })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_OPTION_LIST_ERROR', err: err })
      })
  }
}


export const updateTable = (table, tableID) => {
  console.log('updateTable = (table, tableID)', table, tableID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-tables`).doc(tableID).set(
      {
        ...table,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        editedByUID: getState().firebase.auth.uid,
        editedAt: new Date()
      }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_TABLE', table: table })
    }).catch((err) => {
      dispatch({ type: 'CREATE_OR_UPDATE_TABLE_ERROR', err: err })
    })
  }
}

export const deleteTable = (tableID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-tables`).doc(tableID).delete()
      .then(() => {
        dispatch({ type: 'TABLE' })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_TABLE_ERROR', err: err })
      })
  }
}


export const updateTableData = (tableRow, tableID, type) => {
  console.log('updateTable = (tableRow, tableID,type)', { tableRow, tableID, type });
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (type === 'delete')
      return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-tables/${tableID}/table-rows`)
        .doc(tableRow.tableRowID).delete()

    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-tables/${tableID}/table-rows`)
      .doc(tableRow.tableRowID).set(
        {
          ...tableRow,
          editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
          editedByUID: getState().firebase.auth.uid,
          editedAt: new Date()
        },
        { merge: true }
      ).then(() => {
        dispatch({ type: 'CREATE_OR_UPDATE_TABLE', data: tableRow })
      }).catch((err) => {
        dispatch({ type: 'CREATE_OR_UPDATE_TABLE_ERROR', err: err })
      })
  }
}



//Destinations 
//todo: DELETION OF DRIVE

export const updateDestination = (destination, destinationID, destinationType, isDelete) => {
  console.log('updateDestination = (destination, destinationID, destinationType, isDelete)', destination, destinationID, destinationType, isDelete);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const document = firestore.collection(`organizations/${getState().firebase.profile.orgID}/resources-destinations`).doc('destination-' + destinationType);

    if (isDelete) document.update({ [destinationID]: getFirestore().FieldValue.delete() })
    else document.set(
      {
        [destinationID]: {
          ...destination,
          destinationID: destinationID,
          editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
          uid: getState().firebase.auth.uid,
          editedAt: new Date()
        }
      }, { merge: true }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_DESTINATION', destination: destination })
    }).catch((err) => {
      dispatch({ type: 'CREATE_OR_UPDATE_DESTINATION_ERROR', err: err })
    })
  }
}

//Groups

export const updateGroup = (group, groupID) => {
  console.log('updateGroup = (group, groupID)', group, groupID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/groups`).doc(groupID).set(
      {
        ...group,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        uid: getState().firebase.auth.uid,
        editedAt: new Date()
      }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_GROUP', group: group })
    })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'CREATE_OR_UPDATE_GROUP_ERROR', err: err })
      })
  }
}

export const deleteGroup = (groupID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteGroup', groupID);
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/groups`).doc(groupID).delete()
      .then(() => {
        dispatch({ type: 'GROUP' })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_GROUP_ERROR', err: err })
      })
  }
}

export const updateAppWithGroupList = (appID, groupID, action) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/apps`).doc(appID).get()
      .then(formResponse => {
        const app = formResponse.data();
        let newGroupList = app.groupList ? Object.values(app.groupList) : []
        if (action === 'delete') newGroupList = newGroupList.filter(group => group !== groupID)
        else newGroupList.push(groupID)
        firestore.collection(`organizations/${getState().firebase.profile.orgID}/apps`).doc(appID).set({
          ...app,
          groupList: newGroupList
        }).then(() => {
          dispatch({ type: 'UPDATE_APP', app: app, id: appID })
        }).catch((err) => {
          console.error('dispatch error', err)
          dispatch({ type: 'UPDATE_APP_ERROR', err: err })
        })
      }
      )
  }
}



//Channels

export const updateChannel = (channel, channelID) => {
  console.log('updateChannel = (channel, channelID)', channel, channelID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/channels`).doc(channelID).set(
      {
        ...channel,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        uid: getState().firebase.auth.uid,
        editedAt: new Date()
      }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_CHANNEL', channel: channel })
    })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'CREATE_OR_UPDATE_CHANNEL_ERROR', err: err })
      })
  }
}

export const deleteChannel = (channelID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteChannel', channelID);
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/channels`).doc(channelID).delete()
      .then(() => {
        dispatch({ type: 'CHANNEL' })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_CHANNEL_ERROR', err: err })
      })
  }
}

export const updateChannelList = (userID, channelID, action) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`users`).doc(userID).get()
      .then(user => {
        const userSelected = user.data();
        let newChannelList = userSelected.channelList ? Object.values(userSelected.channelList) : []
        if (action === 'delete')
          newChannelList = newChannelList.filter(channel => channel !== channelID)
        else
          newChannelList.push(channelID)

        firestore.collection(`users`).doc(userID).set({
          ...userSelected,
          channelList: newChannelList
        }).then(() => {
          dispatch({ type: 'UPDATE_USER_CHANNEL', userSelected: userSelected })
        }).catch((err) => {
          console.error('dispatch error', err)
          dispatch({ type: 'UPDATE_USER_CHANNEL_ERROR', err: err })
        })
      }
      )

    firestore.collection(`organizations/${getState().firebase.profile.orgID}/channels`).doc(channelID).get()
      .then(channel => {
        const channelSelected = channel.data();
        let newUserList = channelSelected.userList ? Object.values(channelSelected.userList) : []
        if (action === 'delete')
          newUserList = newUserList.filter(user => user !== userID)
        else
          newUserList.push(userID)

        firestore.collection(`organizations/${getState().firebase.profile.orgID}/channels`).doc(channelID).set({
          ...channelSelected,
          userList: newUserList
        }).then(() => {
          dispatch({ type: 'UPDATE_CHANNEL', channelSelected: channelSelected })
        }).catch((err) => {
          console.error('dispatch error', err)
          dispatch({ type: 'UPDATE_CHANNEL_ERROR', err: err })
        })
      }
      )
  }
}

//Messages Received

//if message exists, just update the clickedAt field

export const saveMessage = (type, referenceID, updatedByUID) => {
  console.log('saveMessage', { type, referenceID, updatedByUID });
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let title, sentByName = null;

    const docCollection = type === 'activity' ? 'activityfeed' : type === 'task' ? 'tasks' : null
    const titleReference = type === 'activity' ? 'activityTitle' : type === 'task' ? 'taskName' : null

    if (!docCollection || !titleReference) { return null }

    const docReference =
      firestore.collection(`organizations/${getState().firebase.profile.orgID}/${docCollection}`).doc(referenceID);

    docReference.get().then((doc) => title = doc.data()[titleReference])
      .then(() => firestore.collection('users').doc(updatedByUID).get()
        .then(doc => sentByName = `${doc.data().firstName} ${doc.data().lastName}`))
      .then(() =>
        firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/inbox`).doc(referenceID)
          .set({
            title,
            updatedByUID,
            sentByName,
            type: type,
            referenceID,
            reference: docReference,
            clickedAt: new Date()
          }, { merge: true })
      ).then(() => {
        console.log('Inbox updated');

        dispatch({ type: 'UPDATE_MESSAGE' })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'UPDATE_MESSAGE_ERROR', err: err })
      })
  }
}


//Notes
export const addNote = ({ activityID, taskID, note }) => {
  console.log('addNote = (activityID, note)', activityID, note);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed/${activityID}/notes`)
      .add(
        {
          ...note,
          taskID: taskID || null,
          noteByName: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
          noteByUID: getState().firebase.auth.uid,
          submittedAt: new Date()
        }
      )
      .then(() =>
        firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed/`).doc(activityID).get())
      .then((doc) => {
        const activity = doc.data()
        return firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed/`)
          .doc(activityID).set({
            noteCount: 1 + (activity.noteCount || 0),
            totalRatings: (note.rating || 0) + (activity.totalRatings || 0),
            ratingCount: note.rating > 0 ? 1 + (activity.ratingCount || 0) : activity.ratingCount,
            ccList: note.ccList || [],
          }, { merge: true })
      })
      .then(() => {
        dispatch({ type: 'CREATE_NOTE', note })
      })
      .catch((err) => {
        console.error('dispatch CREATE_NOTE error', err)
        dispatch({ type: 'CREATE_NOTE_ERROR', err: err })
      })
  }
}



//Cards
//Save card in cardbuilder
export const updateCard = (card, cardID) => {
  console.log('updateCard = (card, cardID)', card, cardID);

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/cards`)
      .doc(cardID)
      .set(
        {
          ...card,
          data: card.data ? card.data : [],
          cardID: cardID,
          editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
          uid: getState().firebase.auth.uid,
          editedAt: new Date()
        }, { merge: true }
      )
      // .then(() =>
      //   firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/dashboardbuilder`).doc(cardID).delete())
      .then(() => {
        dispatch({ type: 'CREATE_OR_UPDATE_CARD', card: card })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'CREATE_OR_UPDATE_CARD_ERROR', err: err })
      })
  }
}

export const deleteCard = (cardID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteCard', cardID);
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/cards`).doc(cardID).delete()
      .then(() => {
        dispatch({ type: 'CARD' })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_CARD_ERROR', err: err })
      })
  }
}



//Dashboard

export const updateDashboardCard = (dashboardCardID, card, dashboardID, options) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('updateDashboardCard', dashboardCardID, card, dashboardID);
    const firestore = getFirestore();
    const profile = getState().firebase.profile
    const orgID = profile.orgID || options.profile.orgID
    const uid = getState().firebase.auth.uid || options.profile.uid
    const dataPath = `organizations/${orgID}/userdata/${uid}/${dashboardID}`
    const skipDataRequest = options && options.skipDataRequest;
    const updateOnlyIfEmpty = options && options.updateOnlyIfEmpty;

    if (typeof dashboardCardID === 'string') {

      let promisePrefix = Promise.resolve(true)

      if (updateOnlyIfEmpty)
        promisePrefix = firestore.collection(`${dataPath}/${dashboardCardID}/card-data`)
          .limit(1).get().then(qS => qS && qS.size > 0)

      const cardUpdatePromise = firestore.collection(dataPath).doc(dashboardCardID)
        .get()
        .then(doc => {
          const oldCard = doc.data();
          const newDashboardCard = {
            ...oldCard,
            ...card,
            updatedAt: new Date(),
            dashboardCardID: dashboardCardID,
            ...skipDataRequest ? {} : { data: null, queryError: '' },
            requestID: !skipDataRequest ? fieldIDGenerator() : oldCard.requestID,
            editedBy: profile.firstName + ' ' + profile.lastName,
            uid: uid,
            editedAt: new Date(),
            updateTest: true
          };

          console.log('updateDashboardCard', { newDashboardCard });

          return firestore.collection(dataPath)
            .doc(dashboardCardID)
            .set(newDashboardCard,
              { merge: true })
        })

      return promisePrefix.then(shouldUpdate => shouldUpdate && cardUpdatePromise)
        .then(() => {
          console.log('updateDashboardCard Firestore Dashboard updated');
          dispatch({ type: 'CREATE_OR_UPDATE_DASHBOARD', dashboard: getState().firebase.auth.uid })
        })
        .catch((err) => {
          console.error('Firestore Dashboard error', err);
          dispatch({ type: 'CREATE_OR_UPDATE_DASHBOARD_ERROR', err: err })
        })
    }
  }
}


export const deleteDashboardCard = (dashboardCardID, dashboardID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteDashboardCard', dashboardCardID);
    return firestore
      .collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/${dashboardID}`)
      .doc(dashboardCardID)
      .delete()
      .then((value) => {
        dispatch({ type: 'CARD' })
      })
      // .then(() => firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata`)
      //   .doc(getState().firebase.auth.uid)
      //   .set({ ['dashboardCardID' + dashboardCardID]: false }, { merge: true }))
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_CARD_ERROR', err: err })
      })
  }
}

export const refreshAllDashboardCards = (dashboardID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let promiseArray = []
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/${dashboardID}`).get().then(querySnapshot =>
      querySnapshot.forEach(doc => {
        const card = doc.data();
        console.log('formActions refreshAll', dashboardID, { card });
        let newDateRange = null;
        if (card.selectedDateRange) {
          const refreshedDateRange = getDisplayDateRange(card.selectedDateRange);
          newDateRange = {
            startDate: refreshedDateRange.startDate.toDate(),
            endDate: refreshedDateRange.endDate.toDate()
          }
        }
        if (card.cardID)
          promiseArray.push(
            firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/${dashboardID}`)
              .doc(doc.id)
              .set({
                updatedAt: new Date(),
                requestID: fieldIDGenerator(),
                ...(newDateRange || {})
              },
                { merge: true }))
      })).then(() => Promise.all(promiseArray))
  }
}

//Update or Create New Dashboard

export const updateDashboard = (dashboard, deleteDashboard) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().firebase.auth.uid;
    const orgID = getState().firebase.profile.orgID;

    let newDashboard = dashboard
    if (!newDashboard.dashboardID) { newDashboard.dashboardID = fieldIDGenerator() }

    const firestore = getFirestore();
    return firestore.collection(`organizations/${orgID}/userdata`)
      .doc(uid)
      .get()
      .then(doc => {
        const oldDashboard = doc.data();
        console.log('formActions', { oldDashboard, newDashboard });
        let userDashboardList = { ...oldDashboard && oldDashboard.userDashboardList }
        let deletePromiseArray = []
        if (dashboard.dashboardID !== 'dashdata' && deleteDashboard) {
          delete userDashboardList[dashboard.dashboardID]
          const ref = `organizations/${orgID}/userdata/${uid}/${dashboard.dashboardID}`
          firestore.collection(ref).onSnapshot(snapshot => {
            snapshot.docs.forEach(doc => deletePromiseArray.push(firestore.collection(ref).doc(doc.id).delete()))
          })

        } else {
          userDashboardList[newDashboard.dashboardID] = newDashboard;
        }

        return Promise.all(
          [firestore.collection(`organizations/${orgID}/userdata`)
            .doc(uid)
            .set({ userDashboardList: userDashboardList }, { merge: false })
            .then(() => console.log('formActions dashboard updated')), ...deletePromiseArray])


      }).then(() => {
        console.log('Firestore Dashboard updated');
        dispatch({ type: 'CREATE_OR_UPDATE_DASHBOARD', dashboard: uid })
      })
      .catch((err) => {
        console.error('Firestore Dashboard error', err);
        dispatch({ type: 'CREATE_OR_UPDATE_DASHBOARD_ERROR', err: err })
      })
  }
}


//Schedules

export const updateSchedule = (schedule, scheduleID) => {
  console.log('Called Update Schedule', schedule, scheduleID);

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('Updating Schedule..', schedule, scheduleID);

    const firestore = getFirestore();
    firestore.collection(`schedules`).doc(scheduleID).set(
      {
        ...schedule,
        orgID: getState().firebase.profile.orgID,
        scheduleID: scheduleID,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        uid: getState().firebase.auth.uid,
        editedAt: new Date()
      }
    ).then(() => {
      console.log('CREATE_OR_UPDATE_SCHEDULE', schedule)
      dispatch({ type: 'CREATE_OR_UPDATE_SCHEDULE', schedule: schedule })
    })
      .catch((err) => {
        console.log('CREATE_OR_UPDATE_SCHEDULE_ERROR', err)
        dispatch({ type: 'CREATE_OR_UPDATE_SCHEDULE_ERROR', err: err })
      })
  }
}

export const deleteSchedule = (scheduleID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('deleteSchedule', scheduleID);
    firestore.collection(`schedules`).doc(scheduleID).delete()
      .then(() => {
        dispatch({ type: 'SCHEDULE' })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_SCHEDULE_ERROR', err: err })
      })
  }
}

//Tasks
export const updateTaskUsers = (taskID, userList) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection(`organizations/${getState().firebase.profile.orgID}/tasks`).doc(taskID).get()
      .then(task => {
        const taskSelected = task.data();
        return firestore.collection(`organizations/${getState().firebase.profile.orgID}/tasks`).doc(taskID).set({
          ...taskSelected,
          assignedToUID: userList,
          editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
          uid: getState().firebase.auth.uid,
          editedAt: new Date()
        }).then(() => {
          dispatch({ type: 'UPDATE_TASK_USERS', taskSelected: taskSelected })
        }).catch((err) => {
          console.error('dispatch error', err)
          dispatch({ type: 'UPDATE_TASK_USERS_ERROR', err: err })
        })
      }
      )
  }
}

//Task creation - Create "task created" activity -> Create task with this as parentActivity
//Activity that creates a task will be the parent of the "task created" activity
export const createTaskActivity = ({ taskName, taskDescription, assignedToUID, appAssigned, taskActivityID, taskImagePaths, imageUploadPromise, parentActivityID, taskImage }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log({ taskImage });

    let activity = {
      orgID: getState().firebase.profile.orgID,
      activityByName: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
      activityVerb: "createdTask",
      activityTitle: taskName,
      activityContent: taskDescription || " ",
      activityImages: taskImage ? [taskImage] //A task image array copied from activity
        : (taskImagePaths && taskImagePaths.length > 0) ?//An attached task image needing an array 
          taskImagePaths.map(imagePath => ({ imageLocation: imagePath, fieldID: fieldIDGenerator(), fieldLabel: 'Task Image' }))
          : [],
      activityChannelList: [],
      activityChannelSettings: {},
      activityMapLink: null,
      activityByUID: getState().firebase.auth.uid,
      activityAppID: null,
      activityReceivedTime: new Date(),
      parentActivityID: parentActivityID || null,
      parentActivityReference: parentActivityID ? firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed`).doc(parentActivityID) : null
    }
    console.log('createTaskActivity', { activity, taskName, taskDescription, assignedToUID, appAssigned });

    const activityID = taskActivityID || fieldIDGenerator();

    sessionStorage.setItem('testingTaskParentActivityID', activityID)

    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed`).doc(activityID)
      .set({
        ...activity,
        activityID,
        task: { taskName, taskDescription, assignedToUID, appAssigned },
        // editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        // uid: getState().firebase.auth.uid,
        // editedAt: new Date()
      }, { merge: false })
      .then(() => imageUploadPromise && imageUploadPromise.then(() => console.log('imageUploadPromise resolved')))
      .then(() => {
        dispatch({ type: 'CREATED_TASK_ACTIVITY', taskCreated: { taskName, taskDescription, assignedToUID, } })
      })
      .catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'CREATED_TASK_ACTIVITY_ERROR', err: err })
      })
  }
}

//Activity update tags
export const updateActivityTags = (activityID, fieldID, tagList) => {
  if (!activityID || !fieldID) { return null }
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed`)
      .doc(activityID).get()
      .then(doc => {
        const activity = doc.data();
        let activityImages = activity.activityImages.map(activityImage => (fieldID === activityImage.fieldID) ? { ...activityImage, tagList } : activityImage)

        console.log('updateActivityTags', { activityID, fieldID, tagList, activityImages })

        return firestore.collection(`organizations/${getState().firebase.profile.orgID}/activityfeed`).doc(activityID).set({ activityImages }, { merge: true }).then(() => console.log('updateActivityTags saved'))
      }).then(() => {
        dispatch({ type: 'UPDATE_ACTIVITY_TAGS', activityID, tagList })
      }).catch((err) => {
        console.error(err)
        dispatch({ type: 'UPDATE_ACTIVITY_TAGS', err: err })
      })
  }
}


//Rotate Images on GCS
export const createRotationRequest = (imageLocation, rotateAngle,) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log('createRotationRequest', { imageLocation, rotateAngle, })
    return firestore.collection(`async/uploads/imagerotation`).add({
      imageLocation, rotateAngle, orgID: getState().firebase.profile.orgID,
    })
      .then(() => {
        dispatch({ type: 'UPDATE_ROTATION_REQUEST', })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'UPDATE_ROTATION_REQUEST', err: err })
      })
  }
}


export const updatePageSendSchedule = (schedule) => {
  console.log('formActions', schedule.scheduleTime);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebaseState = getState().firebase;
    const scheduleID = schedule.scheduleID || fieldIDGenerator();
    return firestore.collection(`async/schedules/page-send`).doc(scheduleID).set({
      ...schedule,
      scheduleUTCHour: schedule.scheduleTime.toDate ? schedule.scheduleTime.toDate().getUTCHours() : schedule.scheduleTime.getUTCHours(),
      scheduleID,
      addedBy: firebaseState.profile.firstName + ' ' + firebaseState.profile.lastName,
      addedByUID: firebaseState.auth.uid,
      addedAt: new Date(),
      orgID: firebaseState.profile.orgID,
    }, { merge: true })
      .then(() => {
        dispatch({ type: 'UPDATE_PAGE_SEND_SCHEDULE_REQUEST', })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'UPDATE_PAGE_SEND_SCHEDULE_REQUEST', err: err })
      })
  }
}

export const deletePageSendSchedule = (scheduleID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`async/schedules/page-send`).doc(scheduleID).delete()
      .then(() => {
        dispatch({ type: 'DELETE_PAGE_SEND_SCHEDULE_REQUEST', })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_PAGE_SEND_SCHEDULE_REQUEST', err: err })
      })
  }
}


export const updatePage = (page, pageID) => {
  console.log('updatePage = (page, pageID)', page, pageID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const fSCollection = (pageID === 'myPage') ?
      firestore.collection(`organizations/${getState().firebase.profile.orgID}/userdata/${getState().firebase.auth.uid}/myPage`)
      : firestore.collection(`organizations/${getState().firebase.profile.orgID}/pages`)

    return fSCollection.doc(pageID).set(
      {
        ...page,
        editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
        editedByUID: getState().firebase.auth.uid,
        editedAt: new Date(),
        pageEditors: [...new Set([...(page.pageEditors || []), getState().firebase.auth.uid])]
      },
      { merge: true }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_PAGE', page: page })
    }).catch((err) => {
      dispatch({ type: 'CREATE_OR_UPDATE_PAGE_ERROR', err: err })
    })
  }
}

export const deletePage = (pageID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (pageID === 'myPage') return null
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/pages`).doc(pageID).delete()
      .then(() => {
        dispatch({ type: 'PAGE' })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_PAGE_ERROR', err: err })
      })
  }
}

export const updateSearch = (search, searchID) => {
  console.log('updateSearch = (search, searchID)', search, searchID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebaseState = getState().firebase
    return firestore.collection(`organizations/${firebaseState.profile.orgID}/searches`).doc(searchID).set(
      {
        ...search,
        editedBy: firebaseState.profile.firstName + ' ' + firebaseState.profile.lastName,
        editedByUID: firebaseState.auth.uid,
        editedAt: new Date(),
      },
      { merge: true }
    ).then(() => {
      dispatch({ type: 'CREATE_OR_UPDATE_SEARCH', search: search })
    }).catch((err) => {
      dispatch({ type: 'CREATE_OR_UPDATE_SEARCH_ERROR', err: err })
    })
  }
}

export const deleteSearch = (searchID) => {
  console.log('deleteSearch', searchID);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection(`organizations/${getState().firebase.profile.orgID}/searches`).doc(searchID).delete()
      .then(() => {
        dispatch({ type: 'SEARCH' })
      }).catch((err) => {
        console.error('dispatch error', err)
        dispatch({ type: 'DELETE_SEARCH_ERROR', err: err })
      })
  }
}

// ANALYTICS TURN ON
// function getUserDetailsForAnalytics(profile) {
//   return {
//     submittedByName: `${profile.firstName} ${profile.lastName}`,
//     submittedByUID: profile.uid,
//     orgID: profile.orgID
//   };
// }
