import React from 'react';
import AppRenderSelectComponent from '../../appviewer/components/AppRenderSelectComponent';
import AppSearchSelector from '../../standard/AppSearchSelector';
import UserSearchSelector from '../../standard/UserSearchSelector';

const FilterSelect = (props) => {

  const divStyle = { paddingLeft: 8, paddingRight: 8, paddingTop: 8 };
  if (props.filter && props.filter.filterID === 'users')
    return <div style={divStyle}>
      <UserSearchSelector
        id={'FilterSelect' + props.responseID} allUsers={true} hasDefault={false} selectedValues={[props.filter.filterSelectedValue]}
        multiple={false} quickEdit={true} inputLabel={""}
        handleSave={(userList) =>
          props.handleFilterValueChange({
            ...props.filter,
            ...userList && userList[0] && userList[0].value ? { filterSelectedValue: userList[0].value, filterSelectedLabel: userList[0].label, }
              : { filterSelectedLabel: 'Select All Values', filterSelectedValue: '%' }
          })} />
    </div>

  if (props.filter && props.filter.filterID === 'apps')
    return <div style={divStyle}><AppSearchSelector
      allApps={false}
      selectedValues={[props.filter.filterSelectedValue || null]}
      quickEdit={true}
      inputLabel={""}
      handleSave={(appAssigned) => {
        props.handleFilterValueChange({
          ...props.filter,
          ...appAssigned && appAssigned[0] && appAssigned[0].value ? { filterSelectedValue: appAssigned[0].value, filterSelectedLabel: appAssigned[0].label, }
            : { filterSelectedLabel: 'Select All Values', filterSelectedValue: '%' }
        })
      }}
    />
    </div>
  if (props.filter && props.filter.filterID)
    return <div style={divStyle}>
      <AppRenderSelectComponent
        name={props.filter.filterSelectedValue}
        optionListID={props.filter.filterID}
        field={{ fieldValue: props.filter.filterSelectedValue, fieldLabel: '' }}
        onChange={(value) => {
          props.handleFilterValueChange({
            ...props.filter,
            ...value ? { filterSelectedValue: value } : { filterSelectedValue: '%' }
          })
        }}
      /></div>
  return null
}

export default FilterSelect