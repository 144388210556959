import React from 'react'
import ImageOverlay from './ImageOverlay';
import { GridList, GridListTile, makeStyles } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export default function ImageScrollerGridList(props) {
  const classes = useStyles();

  const hqImage = sessionStorage.getItem('hqImage') === 'true';

  let images = props.images.map && props.images.filter(row => (row.compressedURL || row.fullURL)).map((image, i) => < div key={'image' + i} style={{ margin: 0, padding: 0, overflow: "hidden", position: "relative", }}>
    <ImageOverlay
      imageLink={(image.compressedURL || props.fullImages) && !hqImage ? image.compressedURL : image.fullURL}
      imageLocation={image.imageLocation}
      imageStyle={{ maxHeight: props.height, marginRight: 1, maxWidth: 1000, }}
      zoomLink={image.fullURL}
      textOverlay={image.fieldLabel}
      options={{ showImageTags: true, }}
      fieldID={image.fieldID}
      activityImage={image}
      activityID={props.activityID}
      tagOptionListID={image.fieldTagOptionListID}
    />
  </div >)
  if (!images || images.length === 0) return null

  return <GridList className={clsx([classes.gridList, "slimscroll"])} cellHeight={props.height}>
    {images.map((image, i) => <GridListTile key={'GridListTile' + i} style={{ width: null }}   >{image}</GridListTile>)}</GridList>
}