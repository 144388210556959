import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Loader from '../../standard/Loader'
import { mergeArrays } from '../../../utils/arrayutils'
import { RenderTableWithOptions } from './RenderTableWithOptions'
import { getTableRowFromFieldsArray, getTableColumnsFromFieldsArray } from './renderTableDataPrepHelpers'
import { useFirestoreQuery } from '../../standard/firestoreQueryHook'

export default function AppReportTable({ appID, uid, startDate, endDate, }) {
  const selectedApp = useSelector(({ firestore: { data: { apps } } }) => appID && apps && apps[appID])

  const query = {
    collection: `organizations/{{orgID}}/submissions`,
    whereClause: [
      ['appID', '==', appID],
      uid && uid !== 'all' ? ['submittedByUID', '==', uid] : null,
      startDate ? ['submittedAt', '>', startDate] : null,
      endDate ? ['submittedAt', '<', endDate] : null,
    ].filter(row => row),
    orderBy: ['submittedAt', 'desc'],
  }

  const submissions = useFirestoreQuery(query);

  if (submissions.includes('Requested') || !selectedApp) return <Loader />
  return <ReportTable {...{ submissions, selectedApp }} />
}

function ReportTable({ submissions, selectedApp }) {
  const appFields = mergeArrays(selectedApp.screens.map(screen => screen.fieldList))

  const data = (submissions && submissions.length > 0) ?
    submissions.map(submission => ({
      submittedAt: moment(submission.submittedAt.toDate()).format('YYYY-MM-DD hh:mm'),
      submittedBy: submission.submittedByFirstName + ' ' + submission.submittedByLastName,
      submissionLocation: submission.submissionLocation,
      ...getTableRowFromFieldsArray(appFields, submission.appData || submission.formData || [], submission.fieldSets)
    })
    ) : []

  const columns = appFields ? [{ headerName: "Submitted At", field: "submittedAt", },
  { headerName: "Location", field: "submissionLocation", type: 'location', },
  { headerName: "Submitted By", field: "submittedBy", },
  ...getTableColumnsFromFieldsArray(appFields)] : []

  return <RenderTableWithOptions rowData={data} columns={columns} options={{ noToolbar: false, hideExport: false, editable: false }} />
}

