import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { dialogSize } from '../../utils/styleclasses';
import { DialogTransition } from '../../utils/materialuiutils';
import { AppRender } from './AppRender';
import { primary } from '../../utils/themes';
import AlertSnackbar from '../standard/AlertSnackbar';

export default function AppRenderDialog({ app, profile, submitApp, setSelectedApp }) {
  const [open, setOpen] = useState(false);
  const [alertState, setAlertState] = useState({ open: false, message: '', backgroundColor: primary, alertTimeout: 1200, });

  useEffect(() => { if (!open && app) { setOpen(true); } }, [app])

  const goToOnAppClose = (appSubmitted) => {
    setSelectedApp(null); setOpen(false)
    if (appSubmitted && appSubmitted.appName)
      setAlertState({ ...alertState, open: true, icon: 'CheckCircleOutline', message: `${appSubmitted.appName} submission saved`, backgroundColor: 'secondary' })
    else
      setAlertState({ ...alertState, open: true, icon: 'DeleteOutline', message: `App discarded`, backgroundColor: 'primary' })
  }

  return (
    <div >
      {open && app &&
        <Dialog {...dialogSize} open={open} keepMounted TransitionComponent={DialogTransition}
          onClose={goToOnAppClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
          <div className="slimscroll" style={{ overflowY: 'scroll', height: '100%' }}>
            <AppRender  {...{ goToOnAppClose, app, profile, submitApp }} />
          </div>
        </Dialog>}
      <AlertSnackbar {...{ alertState, setAlertState }} />
    </div >
  )
}
