import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { displayLeft } from '../../../utils/styleclasses'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from '../../standard/Loader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const AppRenderSelectComponent = (props) => {

  if (typeof props.valuesAndLabels === 'undefined') return <Loader />

  // console.log('AppRenderSelectComponent', props);
  const valuesAndLabels = props.valuesAndLabels || []
  const selectedValue = props.valuesAndLabels ? props.valuesAndLabels.find(item => item.value === props.field.fieldValue) : null

  if (props.disabled)
    return <Typography>{selectedValue && selectedValue.label}</Typography>

  return (
    <span style={{ ...displayLeft, width: '100%' }}>
      {valuesAndLabels.length < 11 ?
        <Select
          disabled={props.disabled}
          name={props.name}
          style={{ display: 'flex', width: '100%' }}
          value={props.field.fieldValue || ''}
          onChange={(e) => { props.onChange(e.target.value) }}>
          {valuesAndLabels && Array.isArray(valuesAndLabels) && valuesAndLabels.map((item, index) =>
            <MenuItem
              key={item.value + index}
              value={item.value}>
              {item.label}
            </MenuItem>)}
        </Select>
        : <Autocomplete onChange={(event, newValue) => {
          // console.log({ newValue });
          if (newValue) { props.onChange(newValue.value) }
          else { props.onChange(null) }
        }}
          disabled={props.disabled}
          value={selectedValue}
          options={valuesAndLabels} getOptionLabel={(option) => option.label} style={{ display: 'flex', width: '100%' }}
          renderInput={(params) => <TextField {...params} style={{ display: 'flex', width: '100%' }}
            name={props.name} />}
        />}
    </span>
  )
}

const mapStateToProps = (state, ownProps) => {
  const optionListID = ownProps.optionListID
  return {
    valuesAndLabels: state.firestore.data['optionList' + optionListID]
      && state.firestore.data['optionList' + optionListID][optionListID].options.map(option => ({ label: option.optionName, value: option.optionValue })),
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    // console.log('AppRenderSelectComponent', { props });

    const optionListID = props.optionListID
    return [{
      collection: `organizations/${props.profile.orgID}/resources-optionlists`,
      where: [['optionListID', '==', optionListID]], storeAs: 'optionList' + optionListID
    }]
  }
  )
)(AppRenderSelectComponent)