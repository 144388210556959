import React, { useContext } from 'react'
import { AppRenderContext } from './AppRenderContext';
import Check from '@material-ui/icons/Check';
import { Fab } from '@material-ui/core';
import { getWhenFieldSettingValue, fieldIsWithoutError } from './appRenderUtils';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import getSubmission from './appRenderSubmit';
import LocationPermissionAlert from './LocationPermissionAlert';
import InternetAlertCard from './InternetAlertCard';
import { getDistanceFromLocalStorageLatLng } from '../../utils/locationutils';
import AlertCard from '../standard/AlertCard';
import { isJSON } from '../../utils/utilityfunctions';

export default function AppRenderFooter({ goToOnAppClose, profile, submitApp }) {
  const { app, setSelectedScreen, allFields } = useContext(AppRenderContext);
  return AppRenderFooterComponent({ app, allFields, setSelectedScreen, submitApp, profile, goToOnAppClose });
}

export function AppRenderFooterComponent({ app, allFields, setSelectedScreen, submitApp, profile, goToOnAppClose, buttonPosition }) {
  console.log('AppRenderFooter', app);

  const showSubmit = true && !(app.settings && app.settings.hideSubmitButton);
  const fabStyle = { position: buttonPosition || 'absolute', bottom: 24, margin: 0, zIndex: 1200 }
  const isOutsideGeoFenceMessage = isOutsideGeoFence();

  const isSubmitDisabled = () => {
    if (app.settings && app.settings.geoLocation === 'required' && !sessionStorage.getItem('successLocation')) return true
    if (isOutsideGeoFenceMessage) return true
    return !!allFields.find(field => {
      const isAnyFieldVisibleRequiredAndNotFilled = (!getWhenFieldSettingValue(field, allFields, field.fieldSettings.hidden) && getWhenFieldSettingValue(field, allFields, field.fieldSettings.required) && (!field.fieldValue));
      return (isAnyFieldVisibleRequiredAndNotFilled || (field.fieldValue && !fieldIsWithoutError(field)));
    }
    );
  }

  return <div>
    {(app.settings && (['required', 'prompt'].includes(app.settings.geoLocation) || app.settings.geoFence)
      && !sessionStorage.getItem('successLocation'))
      && <LocationPermissionAlert />}
    {app.settings && app.settings.requiredOnline && <InternetAlertCard />}
    {isOutsideGeoFenceMessage && <AlertCard message={isOutsideGeoFenceMessage} />}

    <div style={(app.screens.length > 1 || showSubmit) ? { padding: 16, marginTop: 30 } : null}>
      {app.screens.length > 1 &&
        <Fab color="primary" id="backButton" variant="extended" disabled={!(app.selectedScreenIndex > 0)}
          style={{ right: 220, ...fabStyle }}
          onClick={() => { setSelectedScreen(Math.max(app.selectedScreenIndex - 1, 0)); }}>
          <ArrowBackIos style={{ fontSize: '30px' }} />Back
      </Fab>}
      {app.screens.length > 1 &&
        <Fab color="primary" id="nextButton" variant="extended" disabled={!(app.selectedScreenIndex < app.screens.length - 1)}
          style={{ right: 100, ...fabStyle }}
          onClick={() => { setSelectedScreen(Math.min(app.selectedScreenIndex + 1, app.screens.length - 1)); }}>
          Next <ArrowForwardIos style={{ fontSize: '30px' }} />
        </Fab>}
      {showSubmit &&
        <Fab color="secondary" style={{ right: 20, ...fabStyle }} id="submitButton" disabled={isSubmitDisabled()}
          onClick={() => {
            if (!isSubmitDisabled() && submitApp) { submitApp(getSubmission(app, profile, allFields)); goToOnAppClose(app); }
          }}
        ><Check style={{ fontSize: '30px' }} />
        </Fab>}
    </div>
  </div >

  function isOutsideGeoFence() {
    if (app.settings && app.settings.geoFence) {
      const getDistance = ({ lat, lng }) => Number(getDistanceFromLocalStorageLatLng(lat, lng)) > Number(app.settings.geoFenceRadiusKM) ? "App may be submitted only at specfied locations." : false

      if (app.settings.geoFenceLocationType === 'fixed' && app.settings.geoFenceFixedLocation && app.settings.geoFenceFixedLocation.lat)
        return getDistance(app.settings.geoFenceFixedLocation)

      if (app.settings.geoFenceLocationType === 'dynamic' && app.settings.geoFenceDynamicLocationFieldID) {
        const geoFenceReferenceField = allFields.find(field => field.fieldID === app.settings.geoFenceDynamicLocationFieldID)
        if (geoFenceReferenceField && isJSON(geoFenceReferenceField.fieldValue))
          return getDistance(JSON.parse(geoFenceReferenceField.fieldValue))
        else return `App may be submitted only at specfied locations. Geofence location lookup field returns ${geoFenceReferenceField.fieldValue}`
      }
    }
    return false
  }
}