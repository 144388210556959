import React from 'react'
import { Typography } from '@material-ui/core';

const tourConfig = [
  {
    selector: '[data-tut="tourDashboard"]',
    content: <div style={{ textAlign: 'center', margin: 8 }}>
      <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5"> Data from app submissions can be viewed via a customized dashboard </Typography>
      <img src={"https://uploads-ssl.webflow.com/5e513fd2670c44d0817277da/5e513fd2694fa2215ecee2b1_bg15-1.svg"} width={250} style={{ margin: 16 }} alt='Done' />
    </div>
  },
  {
    selector: '[data-tut="tourAddCards"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Once dashboard cards are created in the dashboard builder, they can be added here by users.</Typography>
  },
  {
    selector: '[data-tut="tourDashboardEnd"]',
    content: <Typography style={{ textAlign: 'center', margin: 8 }} variant="h5">Added cards can be used, resized and queried based on individual user inputs.</Typography>
  },
];

export default tourConfig;
