import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import TaskList from './TaskList'

const TaskCompletedContainer = ({ taskListData, profile }) => {

  const taskList = taskListData && Object.entries(taskListData)
    .map(([key, value]) => { return { ...value, taskID: key } })
    .filter(task => task.taskType)

  if (profile && !profile.tasklist) return <Redirect to='/appviewer'></Redirect>

  return <div>
    <TaskList taskList={taskList} noResults={taskList === null} />

  </div>
}


const mapStateToProps = (state) => {
  // console.log(' mapStateToProps = (state)', state);
  return {
    taskListData: state.firestore.data.submittedTasks,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    // const dateToday = moment();
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'tasks' }],
        where: [
          // ['activationTime', '<', dateToday.endOf('day').toDate()],
          ['submitted', '==', 1],
          ['assignedToUID', 'array-contains', props.profile.uid]
        ],
        limit: props.rowLimit || 5,
        orderBy: [['submittedAt', 'desc']], storeAs: 'submittedTasks'
      },
    ]
  }
  )
)(TaskCompletedContainer)