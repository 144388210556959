import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from '../standard/TabPanel'
import ActivityFeed from './ActivityFeed';
import Loader from '../standard/Loader'
import DynamicFeed from '@material-ui/icons/DynamicFeedTwoTone';
import PresentToAll from '@material-ui/icons/PresentToAllTwoTone';
import MoveToInbox from '@material-ui/icons/MoveToInboxTwoTone';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoadMore from '../standard/LoadMore'
import InboxFeed from './InboxFeed'
import { displayLeft } from '../../utils/styleclasses'
import { Typography } from '@material-ui/core'

export default function NewsFeed() {
  const profile = useSelector(state => state.firebase.profile)
  useFirestoreConnect([{ collection: 'organizations', doc: profile.orgID, subcollections: [{ collection: 'channels' }], storeAs: 'channels' },])
  const channels = useSelector(({ firestore: { data: { channels } } }) => channels)

  const [newsFeedState, setNewsFeedState] = React.useState({
    newsFeedTabValue: localStorage.getItem("newsFeedTabValue") ? Number(localStorage.getItem("newsFeedTabValue")) : !profile.channelList ? 1 : 0,
    displayActivities: 3,
    searchArray: [[]],
    displayChannel: null,
    selectedChannel: localStorage.getItem('selectedChannel') ? JSON.parse(localStorage.getItem('selectedChannel')) : "all",
    loadInbox: localStorage.getItem("newsFeedTabValue") == 2,
    loadSent: localStorage.getItem("newsFeedTabValue") == 1,
    loadActivityFeed: localStorage.getItem("newsFeedTabValue") != 1 && localStorage.getItem("newsFeedTabValue") != 2
  })


  console.log('$$$ NewsFeed Props $$$', newsFeedState);
  if (!profile.newsfeed) return <Redirect to='/appviewer'></Redirect>

  const isChannelEnabled = !(profile && (channels === null || profile.channelList === null));
  return (
    <div style={{ maxWidth: '100vw' }} >
      <div >
        <AppBar position="sticky">
          <Tabs value={newsFeedState.newsFeedTabValue} aria-label="edit tabs"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(event, newValue) => {
              localStorage.setItem("newsFeedTabValue", newValue);
              setNewsFeedState({
                ...newsFeedState,
                loadInbox: newsFeedState.loadInbox || newValue === 2,
                loadSent: newsFeedState.loadSent || newValue === 1,
                loadActivityFeed: newsFeedState.loadActivityFeed || newValue === 0,
                newsFeedTabValue: newValue
              });
            }} >
            {isChannelEnabled ?
              <Tab label={<span style={{ display: 'inline-flex' }} id="Channels">
                <DynamicFeed style={{ marginRight: 4 }} />Channels</span>} {...a11yProps(0)}
                disabled={(!profile.channelList || profile.channelList.length === 0)} />
              : null}
            <Tab data-tut="tourMyActivity" name="MyActions" label={
              <span style={{ display: 'inline-flex' }}>
                <PresentToAll style={{ marginRight: 4 }} />
                Actions</span>} {...a11yProps(1)} />
            <Tab
              label={<span style={{ display: 'inline-flex' }}>
                <MoveToInbox style={{ marginRight: 4 }} />Inbox</span>}
              {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        {isChannelEnabled && <TabPanel value={newsFeedState.newsFeedTabValue} index={0}>
          <div style={{ margin: 16, ...displayLeft }}>
            <DynamicFeed color='primary' style={{ fontSize: 30, marginRight: 16 }} />
            <Typography variant="h5" color="primary">My Channels</Typography>
          </div>
          {
            profile && channels && profile.channelList && profile.channelList.length > 0 ?
              <div style={{ maxWidth: '100vw' }}>
                <Select id="selectChannels" variant="standard" style={{ width: '100%', padding: 8, }}
                  value={newsFeedState.selectedChannel}
                  onChange={(e) => {
                    localStorage.setItem('selectedChannel', JSON.stringify(e.target.value));
                    setNewsFeedState({ ...newsFeedState, selectedChannel: e.target.value })
                  }}>
                  {profile.channelList.filter(channelID => channels[channelID])
                    .map(channelID => { return { value: channelID, label: channels[channelID].channelName } })
                    .sort((channelA, channelB) => channelA.label > channelB.label ? 1 : -1)
                    .map(channel =>
                      <MenuItem style={{ padding: 8 }} key={channel.value} value={channel.value}>
                        {channel.label}
                      </MenuItem>)}
                </Select>
                <LoadMore key={newsFeedState.selectedChannel || localStorage.getItem('selectedChannel')} initialRows={3} increment={3}
                  render={rowLimit =>
                    <ActivityFeed
                      profile={profile}
                      displayActivities={rowLimit} type="all" selectedChannel={newsFeedState.selectedChannel}
                      where={newsFeedState.selectedChannel ? ['activityChannelList', 'array-contains', newsFeedState.selectedChannel] : ['activityChannelList', 'array-contains', 'noChannels']
                      } />} />
              </div>
              : <Loader />
          }
        </TabPanel>}

        <TabPanel value={newsFeedState.newsFeedTabValue} index={1}>
          <div style={{ margin: 16, ...displayLeft }}>
            <PresentToAll color='primary' style={{ fontSize: 30, marginRight: 16 }} />
            <Typography variant="h5" color="primary">My Actions</Typography>
          </div>
          {
            newsFeedState.loadSent && <LoadMore initialRows={3} increment={3}
              render={rowLimit => <ActivityFeed profile={profile} type="sent" displayActivities={rowLimit} hideFooter={true} where={profile.uid && ['activityByUID', '==', profile.uid]} />} />
          }
        </TabPanel>

        <TabPanel value={newsFeedState.newsFeedTabValue} index={2}>
          {
            newsFeedState.loadInbox && <LoadMore initialRows={3} increment={3}
              render={rowLimit => <InboxFeed profile={profile} displayActivities={rowLimit} hideFooter={true} where={profile.uid && ['activityByUID', '==', profile.uid]} />} />
          }
        </TabPanel>


      </div >
    </div >
  )

}
