import React, { Suspense } from 'react';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';
import LogoAppBar from '../standard/LogoAppBar';
import AppInstallation from '../standard/AppInstallation'
import { displayCenter, landingPageDivStyle, divLightCard } from '../../utils/styleclasses'
import CopyToClipChip from '../standard/CopyToClipChip'
import BrowserCheck from '../standard/BrowserCheck';
import { getQueryVariable } from '../../utils/utilityfunctions';

const StyledFirebaseAuth = React.lazy(() => import('react-firebaseui/StyledFirebaseAuth'))


let uiConfig = {
  credentialHelper: 'none',
  signInSuccessUrl: '/', //Successful sign in redirects here
  signInOptions: [
    { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID, },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },

  ],
}

export default class SignIn extends React.Component {
  state = {
    countryCode: localStorage.getItem('countryCode') || "IN"
  }

  componentDidMount() {

    if (!localStorage.getItem('countryCode')) {
      var url = 'https://get.geojs.io/v1/ip/country.json';
      fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          localStorage.setItem('countryCode', responseJson.country);
          this.setState({ countryCode: responseJson.country })

        }).catch((error) => { console.error(error); });
    }
  }


  render() {
    if (this.props.location.search) { localStorage.setItem("orgID", getQueryVariable("orgID")) }

    // console.log('SignIn state', this.state);
    if (this.props.disablePhoneLogin !== true) {
      uiConfig.signInOptions[2] = {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: this.state.countryCode
      }
    }
    const cardStyle = localStorage.getItem('isSmallScreen') === 'true' ?
      {
        minWidth: 250, maxWidth: 500, borderRadius: 25, margin: 8, marginTop: 8, padding: 8,
        paddingBottom: 36, opacity: 0.9,
      } :
      { minWidth: 250, maxWidth: 500, borderRadius: 25, margin: 36, padding: 36, opacity: 0.8, marginTop: 16 };

    return (
      <div>
        {!this.props.signUpComponentMode && <LogoAppBar />}
        {!this.props.signUpComponentMode && <BrowserCheck />}
        {localStorage.getItem("newOrgID") &&
          <div style={{
            ...displayCenter,
            // margin: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
            <div style={{ ...cardStyle, margin: 0 }}>
              <Typography variant={"h3"} color="primary">Welcome to Opstack!</Typography>
              <div style={{ ...divLightCard, padding: 16, marginTop: 24, }}>
                <Typography >
                  Your organization has been created and your Organization ID<strong>{<CopyToClipChip id={localStorage.getItem("newOrgID")} />}</strong>has been emailed to you. Please sign in.
            </Typography>
              </div>
            </div>
          </div>
        }
        <div style={landingPageDivStyle()}>
          <CssBaseline />
          <Card style={cardStyle}>
            <Typography variant="h5" style={{ margin: 10, textAlign: 'center' }} color="primary">
              Sign In</Typography>
            <Suspense fallback={<div>Loading...</div>}>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
                uiCallback={ui => this.props.signUpComponentMode ? ui.disableAutoSignIn() : null}
              />
            </Suspense>
            {!this.props.signUpComponentMode && <AppInstallation />}
          </Card>
        </div>
      </div>

    );
  }
}