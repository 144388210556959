import React, { useState } from 'react'
import isEqual from 'lodash.isequal';
import { getFirestore } from 'redux-firestore';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

function TableLookupField({ field, appLookupMatchField, handleChange, isSelect }) {
  const [query, setQuery] = useState(null);
  const [options, setOptions] = useState([]);
  const fsLimit = Math.min(Number((field.fieldSettings.maxRows && field.fieldSettings.maxRows.value) || 500), 500);
  isSelect && console.log('TableLookupField', { field, options })

  React.useEffect(() => {
    const fetchData = async () => {
      if (query.tableID) {
        const firestore = getFirestore();
        let results = []
        let firestoreQuery = firestore.collection(`organizations/${localStorage.getItem('orgID')}/resources-tables/${query.tableID}/table-rows`);
        if (query.whereClause) { firestoreQuery = firestoreQuery.where(...query.whereClause) }

        let qS = await firestoreQuery.limit(fsLimit).get()
        qS.forEach(doc => results.push(doc.data()))

        if (!isSelect && query.tableValueFieldID && query.tableValueFieldID !== 'allColumns')
          results = results.map(row => ({ [row[query.tableLookupMatchFieldID]]: row[query.tableValueFieldID] }))

        if (isSelect && query.tableValueFieldID) {
          results = results.map(row => {
            let optionValue = row[query.tableValueFieldID];
            console.log('TableLookupField optionValue', optionValue, optionValue.toDate && optionValue.toDate());
            if (typeof optionValue !== 'string') {
              if (optionValue.toDate) optionValue = optionValue.toDate().toString()
              else if (optionValue.toString) optionValue = optionValue.toString()
              else optionValue = String(optionValue)
            }
            return { label: optionValue, value: optionValue }
          })
          setOptions(results)
        }
        else handleChange(field.fieldID, JSON.stringify(results), { fieldType: 'tableLookup' })
      }
    };
    fetchData();
  }, [query, fsLimit]);


  if (!doTableFieldsExist(field)) return null

  const tableID = field.fieldSettings.tableSelector.value
  const tableLookupMatchFieldID = field.fieldSettings.tableSelector.tableStructure.tableLookupMatchFieldID
  const tableValueFieldID = field.fieldSettings.tableSelector.tableStructure.tableValueFieldID || null

  let whereClause, limit;
  if (tableLookupMatchFieldID && appLookupMatchField && appLookupMatchField.fieldValue)
    whereClause = [tableLookupMatchFieldID, '==', appLookupMatchField.fieldValue]

  if (tableLookupMatchFieldID && field.fieldSettings.fullTableLookup && !field.fieldSettings.fullTableLookup.value && !whereClause)
    whereClause = [tableLookupMatchFieldID, '==', 'fullTableLookupIsDisabledAndMatchFieldValueIsUndefined']

  if (field.fieldSettings.firstRow && field.fieldSettings.firstRow.value) limit = 1

  const newQuery = { tableID, whereClause, limit, tableValueFieldID, tableLookupMatchFieldID };
  if (!isEqual(query, newQuery)) setQuery(newQuery)

  return isSelect ? <Autocomplete onChange={(event, newValue) => {
    console.log({ newValue });
    if (newValue) { handleChange(field.fieldID, newValue.value,) } //{ fieldType: 'tableLookup' }
    else { handleChange(field.fieldID, null,) }
  }}
    disabled={field.fieldSettings.readonly && field.fieldSettings.readonly.value === 'yes'}
    value={options.find(option => option.value === field.fieldValue) || null}
    options={options}
    getOptionLabel={(option) => { console.log('TableLookupField option', option); return option.label }} style={{ display: 'flex', width: '100%' }}
    renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
  />
    :
    <div className='slimscroll' style={{ marginTop: 8, overflowY: 'scroll' }}><Typography variant='caption'> {field.fieldValue}</Typography> </div>

}

export default React.memo(TableLookupField, (prevProps, nextProps) => isEqual(prevProps.field, nextProps.field) && isEqual(prevProps.appLookupMatchField, nextProps.appLookupMatchField))

export function doTableFieldsExist(field) {
  return field && field.fieldSettings && field.fieldSettings.tableSelector && field.fieldSettings.tableSelector.tableStructure && field.fieldSettings.tableSelector.value
}