import React from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import RecurringSchedule from '../standard/RecurringSchedule'
import PopoverButton from "../standard/PopoverButton";
import MaterialTable from "material-table";
import { tableIcons } from "../../utils/tableUtil";
import moment from "moment";
import { properCase } from "../../utils/stringutils";
import GroupSearchSelector from "../standard/GroupSearchSelector";
import { EmailTwoTone, PhoneIphoneTwoTone } from "@material-ui/icons";
import { TextField } from "@material-ui/core";

//Not implementing edit, just add or delete
function SchedulePageSend(props) {
  if (!props.scheduledPageTabSendList) return null
  const scheduleArray = props.scheduledPageTabSendList.map(row => ({ ...row, }))

  console.log('SchedulePageSend', { scheduleArray });

  return (
    <span>
      <div>
        {
          <MaterialTable
            icons={tableIcons}
            options={{ actionsColumnIndex: -1, pageSize: props.pageSize || 5, toolbar: false, padding: "dense", pageSizeOptions: [] }}
            localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this schedule?' } } }}
            columns={[
              {
                title: "Name", field: "scheduleName",
                render: rowData => props.updateSchedule ? <TextField defaultValue={rowData.scheduleName}
                  name='scheduleName'
                  onBlur={e => saveFieldOnBlur(e, rowData)} />
                  : rowData.scheduleName
              },
              {
                title: "Description", field: "scheduleDescription",
                render: rowData => props.updateSchedule ? <TextField defaultValue={rowData.scheduleDescription}
                  name='scheduleDescription'
                  onBlur={e => saveFieldOnBlur(e, rowData)} />
                  : rowData.scheduleDescription
              },
              {
                title: "Send Via", field: "sendType",
                render: rowData => rowData.sendType === 'notification' ? <PhoneIphoneTwoTone color='primary' /> : <EmailTwoTone color='primary' />
              },
              {
                title: "Sent", field: "scheduleRepeatPeriodType", render: row => `Every ${row.scheduleRepeatPeriodCount > 1 ? row.scheduleRepeatPeriodCount : ''} ${properCase(row.scheduleRepeatPeriodType)}${row.scheduleRepeatPeriodCount > 1 ? 's' : ''} ${row.scheduleDay ? 'on day ' + properCase(String(row.scheduleDay)) + ' of ' + row.scheduleRepeatPeriodType : ''}`,
              },
              { title: "At", field: "scheduleTime", render: rowData => rowData.scheduleTime && rowData.scheduleTime.toDate && moment(rowData.scheduleTime.toDate()).format('h:mm A') },
              { title: "On", field: "scheduleDay", hidden: true },
              { title: "scheduleID", field: "scheduleID", hidden: true },
              {
                title: 'Published to Groups', field: 'groupList', editable: "never",
                render: rowData => {
                  return <GroupSearchSelector
                    noEdit={props.updateSchedule ? false : true}
                    quickEdit={true}
                    selectedValues={rowData.groupList}
                    inputLabel=" "
                    handleSave={(groupList) => {
                      let updatedSchedule = { ...rowData, groupList };
                      delete updatedSchedule.tableData
                      props.updateSchedule(updatedSchedule);
                    }} />
                }
              },
            ]}
            data={scheduleArray}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  const data = [...scheduleArray];
                  data.splice(data.findIndex(row => row.scheduleID === oldData.scheduleID), 1);
                  props.deleteSchedule(oldData.scheduleID);
                  return setTimeout(() => resolve(), 500)
                }),
            }}
          />
        }
        {props.updateSchedule && !props.noAddSchedule && <div>
          <PopoverButton
            popoverContent={
              <RecurringSchedule
                handleSave={schedule => props.updateSchedule({ ...schedule, sendType: 'email' })}
                title="New Email Schedule" />}
            text="Email Schedule"
            icon="AddCircle"
            horizontalOrigin="center"
            showCloseIcon={true}
            verticalOrigin="bottom"
          />
          <PopoverButton
            popoverContent={
              <RecurringSchedule
                handleSave={schedule => props.updateSchedule({ ...schedule, sendType: 'notification' })}
                title="New Notification Schedule" />}
            text="Notification Schedule"
            icon="AddCircle"
            horizontalOrigin="center"
            showCloseIcon={true}
            verticalOrigin="bottom"
          />
        </div>}
      </div>
    </span>
  )

  function saveFieldOnBlur(e, rowData) {
    let updatedSchedule = { ...rowData, [e.target.name]: e.target.value };
    delete updatedSchedule.tableData;
    props.updateSchedule(updatedSchedule);
  };

}

const mapStateToProps = (state, props) => {
  return {
    profile: state.firebase.profile,
    scheduledPageTabSendList: state.firestore.ordered.scheduledPageTabSendList
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => {
    let whereClause = [['orgID', '==', props.profile.orgID]];

    if (props.pageIDTabID)
      whereClause.push(['pageIDTabID', '==', props.pageIDTabID])

    return [{ collection: 'async/schedules/page-send', where: whereClause, storeAs: 'scheduledPageTabSendList' },]
  }
  )
)(SchedulePageSend)