import Typography from '@material-ui/core/Typography';
import ZoomIn from '@material-ui/icons/ZoomIn';
import React, { lazy, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createRotationRequest, updateActivityTags } from '../../store/actions/formActions';
const ImageAnnotationDialog = lazy(() => import('./ImageAnnotationDialog'))


const ImageOverlay = (props) => {
  let { imageLink, imageStyle, zoomLink, textOverlay, options, tagOptionListID, fieldID, submissionID, imageLocation, activityImage, activityID } = props;

  imageStyle = imageStyle || { maxWidth: 500, height: 400 }
  const [rotateAngle, setRotateAngle] = useState(0)
  const [loading, setLoading] = useState(true);

  return <div style={{
    position: "relative", display: loading ? "none" : "block"
  }} >
    {imageLink &&
      <img src={imageLink} id={fieldID} alt={'Loading'}
        onError={(e) => { console.log(e.target.src); e.target.style.display = "none" }}
        onLoad={() => setLoading(false)}
        style={{
          ...imageStyle, minHeight: 300,
          ...rotateAngle !== 0 ? { transform: `rotate(${rotateAngle}deg)`, width: '100%' } : null,
        }}
      />
    }

    <div style={{
      position: "absolute", top: 0, left: 0, display: 'inline-flex', padding: 4, width: "100%",
      background: 'rgba(255, 255, 255, .3)'
    }}>
      {textOverlay &&
        <Typography variant="caption" noWrap={true}
          style={{ padding: 4, color: '#000', width: "100%", }} >
          {textOverlay}
        </Typography>
      }
      {
        zoomLink &&
        <ZoomIn onClick={() => window.open(zoomLink, '_blank')} style={{ cursor: "pointer", fill: '#000' }} />
      }
      {/* {
        !(options && options.noRotate) && imageLocation &&
        <Loop onClick={() => {
          setRotateAngle(rotateAngle - 90);
          if (imageLocation) { props.createRotationRequest(imageLocation, -90, submissionID) }
        }}
          style={{ cursor: "pointer", fill: '#000' }} />
      } */}
      {
        options && options.showImageTags && activityID && (window.innerWidth >= 825) &&
        <Suspense>
          <ImageAnnotationDialog
            activityImage={activityImage}
            handleSave={(tagList) => props.updateActivityTags(activityID, activityImage.fieldID, tagList)}
            tagOptionListID={tagOptionListID}
            activityID={activityID}
          />
        </Suspense>
      }
    </div>

    {
      options && options.showImageTags && activityImage && activityImage.tagList && activityImage.tagList.map((tag, i) => {
        const annotationImageHeight = tag.annotationImageHeight ? tag.annotationImageHeight : 1506
        const imageHeight = imageStyle.height || imageStyle.maxHeight || 500
        return <div
          key={i}
          style={{
            position: "absolute",
            left: tag.mark.x * (imageHeight) / annotationImageHeight,
            top: tag.mark.y * (imageHeight) / annotationImageHeight,
            height: tag.mark.height * (imageHeight) / annotationImageHeight,
            width: tag.mark.width * (imageHeight) / annotationImageHeight,
            margin: 0, padding: 0, borderStyle: 'solid', borderWidth: '3px', opacity: 0.8,
            borderColor: 'white'
          }}>
          {tag.comment && <div style={{ background: 'white' }}>
            <Typography variant="caption">
              {tag.comment
                .replace(/"value":"[\w\s]*"*/gi, "")
                .replace(/"text":*|"label":*|"select":*|"value":*/gi, "")
                .replace(/]*"*\[*\{*\}*:*/gi, "")
                .replace(/,/gi, " ")
              }
            </Typography>
          </div>}
        </div>
      }
      )
    }
  </div >
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivityTags: (activityID, fieldID, tagList) => { dispatch(updateActivityTags(activityID, fieldID, tagList)) },
    createRotationRequest: (imageLocation, rotateAngle, submissionID,) =>
      dispatch(createRotationRequest(imageLocation, rotateAngle, submissionID)),
  }
}

export default compose(connect(null, mapDispatchToProps))(ImageOverlay)