import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import AddCircle from '@material-ui/icons/AddCircle';
import { iconTextAlign } from '../../utils/iconutils';

function ChannelAddSelector({ handleAdd, profile, channelsData }) {
  const [channel, setChannel] = React.useState({ openPopover: false });

  if (!channelsData) return null
  const channels = profile.channelList.filter(channelID => channelsData[channelID])
    .map(channelID => { return { value: channelID, label: channelsData[channelID].channelName }; })
    .sort((channelA, channelB) => channelA.label > channelB.label ? 1 : -1);
  return (
    <span style={{ margin: 8 }} >
      {
        <span>

          <Chip color="primary" variant='outlined'
            label={iconTextAlign(<AddCircle />, 'Channel')}
            onClick={(e) => setChannel({ ...channel, anchorEl: e.currentTarget, openPopover: true })} />


          {channel.openPopover && <Popover
            open={channel.openPopover}
            anchorEl={channel.anchorEl}
            onClose={() => { setChannel({ ...channel, openPopover: false }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { padding: 16, textAlign: 'center' } }}>
            <div style={{ minWidth: 500 }}>
              <Select id="selectChannels" variant="standard" style={{ width: '100%', padding: 8, }}
                value={channel.channelID || ''}
                onChange={(e) => setChannel({ ...channel, channelID: e.target.value, channelName: channels.find(row => row.value === e.target.value).label, openPopover: true })}>
                {channels.map(channel =>
                  <MenuItem style={{ padding: 8 }} key={channel.value} value={channel.value}>
                    {channel.label}
                  </MenuItem>)}
              </Select>
              <Button color="primary" variant="outlined" onClick={() => { setChannel({ openPopover: false }) }} style={{ margin: 8 }}> Cancel </Button>
              <Button variant="contained" color="primary" style={{ marginRight: 8 }}
                onClick={(e) => {
                  handleAdd({ channelID: channel.channelID, channelName: channel.channelName });
                  setChannel({ openPopover: false })
                }
                }>
                Add Channel
              </Button>
            </div>
          </Popover>}
        </span>
      }
    </span >
  )
}

const mapStateToProps = (newsFeedState, ownProps) => {
  return {
    profile: newsFeedState.firebase.profile,
    channelsData: newsFeedState.firestore.data.channels,
  }
}

export default compose(connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{ collection: 'channels' }], storeAs: 'channels'
      }
    ]
  }
  ))(ChannelAddSelector)