import React from 'react'
import MaterialTable from 'material-table'
import { tableIcons } from '../../../utils/tableUtil'

export default function TableComponent(props) {
  // console.log('TableComponent', props.data);

  return (
    <div>
      {props.data && <MaterialTable
        icons={tableIcons}
        options={{ actionsColumnIndex: -1, pageSize: 5, toolbar: false, pageSizeOptions: [] }}
        columns={[{
          title: props.name1Label ? props.name1Label.replace(/[_-]/g, " ") : 'Label',
          field: props.name1Label ? props.name1Label : 'Label'
        },
        { title: props.value1Label.replace(/[_-]/g, " "), field: props.value1Label, defaultSort: 'desc', type: 'numeric' }]}
        data={props.data.map(row => { return { ...row } })} />}
    </div>
  )
}
