import React, { useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import MaterialTable from 'material-table'
import Loader from '../standard/Loader';
import { tableIcons } from '../../utils/tableUtil'
import Typography from '@material-ui/core/Typography';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import { getAppIcon } from '../../utils/UIutils'
import { zeroPaddingStyleForTable, displayCenter } from '../../utils/styleclasses'
import AppRenderDialog from './AppRenderDialog'
import { submitApp } from '../../store/actions/formActions'
import { makeUserAppsFirestoreQuery } from '../../utils/firebaseUtil'

const AppViewerList = ({ profile, userApps, submitApp }) => {
  const isSmallScreen = localStorage.getItem('isSmallScreen') === 'true';
  const [selectedApp, setSelectedApp] = useState(null)

  const apps = userApps && Object.values(userApps).filter(app => app && app.appID && !app.deleted)

  if (typeof apps === 'undefined'/*|| !profile.useapps*/) return <Loader />

  const columns = [
    {
      title: "App Name", field: "appName", defaultSort: 'asc', ...(isSmallScreen ? zeroPaddingStyleForTable : {}),
      render: rowData => {
        return <div style={{ margin: 8, padding: 8, }}>
          <div style={{ display: 'flex' }}>
            {<div style={{ marginRight: 16 }}>{getAppIcon(rowData.appDescription)}</div>}
            <div>
              <Typography variant="h6" id={rowData.appID}>{rowData.appName}</Typography>
              <Typography variant="caption">  {rowData.appDescription}</Typography>
            </div>
          </div>
        </div>
      }
    },
    { title: "App Description", field: "appDescription", hidden: true },
    { title: "appID", field: "appID", hidden: true },
  ]
  const data =
    (!profile.groupList || !apps) ? [] :
      apps.map(app => ({ appID: app.appID, appName: app.appName, appDescription: app.appDescription, }))

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        onRowClick={(event, rowData) => { // history.push(`/apprender/${rowData.appID}`, "_self")
          console.log('selectedApp', apps.find(app => app.appID === rowData.appID))
          setSelectedApp(apps.find(app => app.appID === rowData.appID))
        }
        }
        options={{
          header: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          padding: "dense",
          searchFieldAlignment: 'left',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <Typography style={displayCenter}>
              <PhoneIphone style={{ marginRight: 16, fontSize: 40 }} /> No apps enabled yet</Typography>
          }
        }}
        title=" "
        columns={columns}
        data={data}
      />
      <AppRenderDialog app={selectedApp} profile={profile} submitApp={submitApp} setSelectedApp={setSelectedApp}
      />


    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitApp: (appSubmission) => {
      if (!appSubmission.submissionLocation) console.error('SUBMITTING EMPTY app', appSubmission);
      return dispatch(submitApp(appSubmission))
    }
  }
}

const mapStateToProps = (state) => {
  let userApps = state.firestore.data.userApps
  if (state.firestore.data.userApps1)
    userApps = { ...state.firestore.data.userApps, ...state.firestore.data.userApps1 }
  return {
    userApps: userApps,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}


export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect(makeUserAppsFirestoreQuery))(AppViewerList)