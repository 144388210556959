import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from '../standard/Loader'
import { updateTaskUsers } from '../../store/actions/formActions'
import TaskCard from './TaskCard'

const TaskCardContainer = (props) => {

  if (!(props.task && props.apps && props.apps["taskDefaultApp"])) return <Loader />

  const cardStyle = { padding: 8, };
  const handleUserChange = (userList) => (userList.length === 0) ?
    alert('Tasks must be assigned to at least one user')
    : props.updateTaskUsers(props.task.taskID, userList)

  const appID = (props.task.appAssigned) || "taskDefaultApp"
  const app = (props.apps[props.task.appAssigned]) || props.apps["taskDefaultApp"]

  const taskProps = {
    task: {
      ...props.task,
      app: { ...app, taskDestinations: [...app.taskDestinations || [], ...props.task.childTaskDestinations || []] }, appID
    },
    editUsers: props.editUsers || false,
    cardStyle: cardStyle,
    profile: props.profile,
    setSelectedApp: props.setSelectedApp,
    handleUserChange: handleUserChange,
    footerOptions: props.footerOptions || null,
  }

  console.log("TaskCardContainer", props.task);
  return (
    <div>
      {app && props.task && <TaskCard {...taskProps} />}

    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTaskUsers: (taskID, userList) => dispatch(updateTaskUsers(taskID, userList)),
  }
}

const mapStateToProps = (state) => {
  return {
    apps: state.firestore.data.apps,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    users: state.firestore.data.users
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{ collection: 'apps' }], orderBy: ['appName', 'asc'], storeAs: 'apps'
      },
      { collection: 'users', where: [['orgID', '==', props.profile.orgID]], storeAs: 'users' }
    ]
  }
  )
)(TaskCardContainer)