import React, { PureComponent } from 'react';
import { Typography } from "@material-ui/core";
import { PieChart, Pie, Tooltip, } from 'recharts';

export default class PieChartComponent extends PureComponent {

  render() {
    const name1Label = this.props.name1Label;

    let maxLength = this.props.data.length > 0 ?
      this.props.data.reduce(function (a, b) { return a[name1Label] && b[name1Label] && (a[name1Label].length > b[name1Label].length) ? a : b; }) : 10
    maxLength = maxLength[name1Label] ? maxLength[name1Label].length : 10
    console.log('PieChartComponent', this.props);

    return (
      this.props.data.length === 0 ? <Typography style={{ textAlign: "center", flex: 1, }}>No Data</Typography> :
        // <ResponsiveContainer width="99%" key={'Container' + JSON.stringify(this.props.layout)} className="dragCancel">
        <PieChart
          key={'PieChart' + JSON.stringify(this.props.layout)}
          width={this.props.width} height={300 + maxLength * 8}
          data={this.props.data}
          margin={this.props.isSmallScreen ? { top: 0, right: 5, left: 0, bottom: 0 }
            : { top: 30, right: 30, left: 20, bottom: 5, }}>
          <Tooltip />
          {/* <Legend /> */}
          <Pie data={this.props.data} nameKey={name1Label} dataKey={this.props.value1Label}
            fill={this.props.color || "#8884d8"} label tick={this.props.isSmallScreen ? { fontSize: 9 } : { fontSize: 12 }} />
          {/* {
          	this.props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
          }
          </Pie> */}

        </PieChart>
      // </ResponsiveContainer >
    );
  }
}
