import React, { Component } from 'react'
import DateRangePicker from 'react-daterange-picker'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './react-calendar.css' // For some basic styling. (OPTIONAL)
import { dateRangeOptions, singleDayDateRangeOptions } from './dateRangeOptions';
export const moment = extendMoment(Moment);

export const getDisplayDateRange = (range) => {
  // console.log({ range });

  if (dateRangeOptions[range]) {
    const dateRange = dateRangeOptions[range].rangeFunction()
    return { startDate: dateRange.start, endDate: dateRange.end, displayDateRange: dateRange.start.format('DD MMM YYYY') + ' - ' + dateRange.end.format('DD MMM YYYY') }
  }

}

export default class DateRangeSelector extends Component {

  constructor(props) {
    super(props)
    let dates = null;
    let selectedButton = null;
    if (this.props.startDate && this.props.endDate) {
      dates = moment().range(moment(this.props.startDate), moment(this.props.endDate));
      selectedButton = null
    } else if (this.props.defaultDateRange && dateRangeOptions[this.props.defaultDateRange]) {
      dates = dateRangeOptions[this.props.defaultDateRange].rangeFunction()
      selectedButton = dateRangeOptions[this.props.defaultDateRange].label
    } else {
      dates = dateRangeOptions["Today"].rangeFunction()
      selectedButton = dateRangeOptions["Today"].label
    }
    this.state = { dates, selectedButton };
  }


  componentDidMount() { if (this.props.defaultDate) { this.onSetDateRange(() => this.state.dates) } }

  //Users can either explicitly choose set of dates or choose a named range (last 7 days, last month etc)
  onSetDateRange = (getDateRange, selectedButton) => {
    const dateRange = getDateRange()
    this.setState({ dates: dateRange });
    this.props.setDateRange(dateRange.start.startOf('day').toDate(), dateRange.end.endOf('day').toDate(), getDateRange, selectedButton ? selectedButton : null)
  }

  onSelect = dates => {
    console.log('RenderingDateRangeSelector - onSelect', { dates });
    let dateRange;
    if (!moment.isRange(dates))
      dateRange = moment.range(dates.startOf('day'), dates.endOf('day'))
    else dateRange = dates
    this.setState({ dateRange });
    this.props.setDateRange(dateRange.start.startOf('day').toDate(), dateRange.end.endOf('day').toDate(), null, null)
  }

  render() {
    console.log('RenderingDateRangeSelector', this.state.dates.start.toDate(), this.state.dates.end.toDate(), moment());

    return (
      <Grid container justify="center">
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <DateRangePicker
            numberOfCalendars={this.props.numberOfCalendars || 1}
            selectionType={this.props.dateSelectionType && this.props.dateSelectionType === 'single' ? 'single' : 'range'}
            // selectionType='range'
            singleDateRange={true}
            onSelect={this.onSelect}
            // value={this.state.dates}
            value={this.props.dateSelectionType && this.props.dateSelectionType === 'single' ? this.state.dates.start : this.state.dates}
          />
        </Grid>
        <Grid item sm={12} style={{ textAlign: "center", maxWidth: 500 }} >
          {Object.values(this.props.dateSelectionType && this.props.dateSelectionType === 'single' ? singleDayDateRangeOptions : dateRangeOptions).map(item =>
            <Button key={item.label} style={{ margin: 4, paddingLeft: 16, paddingRight: 16 }}
              variant={item.label === this.state.selectedButton ? "contained" : "outlined"}
              color="primary" onClick={() => {
                this.onSetDateRange(() => item.rangeFunction(), item.label)
                this.setState({ selectedButton: item.label })
              }}>{item.label}
            </Button>)}
        </Grid>
      </Grid >
    )
  }
}
