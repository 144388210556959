import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { submitApp } from '../../store/actions/formActions'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import TaskList from './TaskList'
import AppRenderDialog from '../appviewer/AppRenderDialog'

const TaskListContainer = ({ tasksData, userTasksData, profile, submitApp }) => {
  const [selectedApp, setSelectedApp] = React.useState(null);

  if (profile && !profile.tasklist) return <Redirect to='/appviewer'></Redirect>

  const tasks = tasksData && Object.entries(tasksData)
    .map(([key, value]) => { return { ...value, taskID: key } })
    .filter(task => task.taskType && task.taskType !== 'userTask');

  const userTasks = userTasksData && Object.entries(userTasksData)
    .map(([key, value]) => { return { ...value, taskID: key } });

  const userTaskList = userTasks && userTasks.length > 0 ? userTasks : [];
  const scheduledTasks = tasks && tasks.length > 0 ? tasks : [];
  const taskList = [
    ...userTaskList.filter(task => task.taskPriority < 2)
      .sort((a, b) => a.activationTime.toDate() < moment().toDate() || a.taskPriority < b.taskPriority ? -1 : 0),
    ...scheduledTasks.sort((a, b) => a.taskType === 'timeTask' && b.taskType === 'periodTask'
      && a.activationTime.toDate() < moment().toDate() ? -1 : 0),
    ...userTaskList.filter(task => task.taskPriority >= 2)
      .sort((a, b) => a.activationTime.toDate() < moment().toDate() || a.taskPriority < b.taskPriority ? -1 : 0)
  ]

  return <div>
    <TaskList taskList={taskList} noResults={tasks === null && userTasks === null} setSelectedApp={setSelectedApp} />
    {selectedApp &&
      <AppRenderDialog app={selectedApp} profile={profile} setSelectedApp={setSelectedApp}
        submitApp={(appSubmission) => submitApp(appSubmission, { taskID: selectedApp.taskID, taskName: selectedApp.taskName })} open={true} />}
  </div>
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitApp: (appSubmission, task) => dispatch(submitApp(appSubmission, task)),
  }
}

const mapStateToProps = (state) => {
  // console.log(' mapStateToProps = (state)', state);
  return {
    tasksData: state.firestore.data.tasks,
    userTasksData: state.firestore.data.userTasks,
    tasksThatExpiredToday: state.firestore.ordered.tasksThatExpiredToday,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    const dateToday = moment();
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{ collection: 'apps' }], orderBy: ['appName', 'asc'], storeAs: 'apps'
      },
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'tasks' }],
        where: [
          ['activationTime', '<', dateToday.endOf('day').toDate()],
          ['expired', '==', false],
          ['assignedToUID', 'array-contains', props.profile.uid],
        ],
        // limit: 20,
        orderBy: [['activationTime', 'asc']], storeAs: 'tasks'
      },
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'tasks' }],
        where: [
          ['activationTime', '<', dateToday.endOf('day').toDate()],
          ['taskType', '==', 'userTask'],
          ['submitted', '==', 0],
          ['assignedToUID', 'array-contains', props.profile.uid]
        ],
        // limit: props.limit,
        orderBy: [['activationTime', 'asc']], storeAs: 'userTasks'
      },
    ]
  }
  )
)(TaskListContainer)