import firebase from 'firebase/app'
import 'firebase/messaging'

export const initializeFCM = ({ profile, sendFCMToken, setNotificationStatus }) => {

  if (!("Notification" in window)) {
    return null
  }

  if (Notification) {
    const messaging = firebase.messaging();
    return Notification.requestPermission()
      .then((permission) => {
        // console.log('Notification permission status', { permission });
        setNotificationStatus(permission || 'unknown')
        if (permission === 'granted') {
          return messaging.getToken()
            .then((token) => {
              console.log('Notification permission granted, got Token', { token });
              localStorage.setItem("FCMToken", token);
              if (profile.FCMToken !== token)
                sendFCMToken(profile.uid, token);
            })
            .catch((err) => console.error('Notification Error', err))
        }
      })
  }
}