export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //Async function that returns a promise
    firebase.auth().signInWithEmailAndPassword(
      credentials.email, credentials.password
    ).then(() => {
      dispatch({
        type: 'LOGIN_SUCCESS'
      })
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err })
    })
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signOut()
      .then(() => { sessionStorage.clear(); localStorage.removeItem("orgId"); dispatch({ type: 'SIGNOUT_SUCCESS' }) });
  }
}

export const updateProfile = (profile) => {
  console.log('updateProfile = (profile)', profile);

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection('users').doc(profile.uid).get()
      .then(doc => {
        const oldUserData = doc.data();
        return firestore.collection('users').doc(profile.uid).set({
          ...oldUserData,
          orgID: profile.orgID,
          firstName: profile.firstName,
          lastName: profile.lastName,
          initials: profile.firstName[0] + profile.lastName[0],
          email: profile.email,
          phoneNumber: profile.phoneNumber,
          uid: profile.uid,
          active: (oldUserData && oldUserData.active) ? oldUserData.active : true,
          orgOwner: (oldUserData && oldUserData.orgOwner) ? oldUserData.orgOwner : profile.orgOwner || 0,
          groupList: (oldUserData && oldUserData.groupList) ? oldUserData.groupList : ['all'],
          channelList: (oldUserData && oldUserData.channelList) ? oldUserData.channelList : ['all'],

          //Set 3 basic rights for new but not invited users - active will be undefined
          //Overwrite with set rights from new profile for invited users
          //Overwrite with old profile for existing users (old profile is null for invited/new)
          ...getUserSettings({
            useforms: true, newsfeed: true, tasklist: true, ...profile, ...oldUserData,
          }, !!profile.orgOwner),

          cameraToUse: profile.cameraToUse ? profile.cameraToUse : 'inapp',
          feedImageSettings: profile.feedImageSettings ? profile.feedImageSettings : 'low',

        })
          .then(() => dispatch({ type: 'PROFILE_UPDATE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'PROFILE_UPDATE_ERROR', err: error })
            console.error(error);
          });
      })
  }
}

export const updateProfileTourStatus = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection('users').doc(user.uid).get()
      .then(doc => {
        const oldUserData = doc.data();
        firestore.collection('users').doc(user.uid)
          .set({
            ...oldUserData,
            tourStatus: user.tourStatus
          }, { merge: true })
          .then(() => dispatch({ type: 'TOUR_STATUS_UPDATE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'TOUR_STATUS_UPDATE_ERROR', err: error })
            console.error(error);
          });
      })
  }
}


export const updateUser = (user) => {
  console.log('updateUser', user);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore.collection('users').doc(user.uid).get()
      .then(doc => {
        const oldUserData = doc.data();
        firestore.collection('users').doc(user.uid).set({
          // orgID: user.orgID,
          ...oldUserData,
          firstName: user.firstName,
          lastName: user.lastName,
          photo: user.photo ? user.photo : null,
          descriptors: user.descriptors ? user.descriptors : null,
          initials: user.firstName[0] + user.lastName[0],
          ...getUserSettings(user)
        })
          .then(() => dispatch({ type: 'PROFILE_UPDATE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'PROFILE_UPDATE_ERROR', err: error })
            console.error(error);
          });
      })
  }
}

const getUserSettings = (user, orgOwner) => {
  return {
    //When Setting up auto-approved user signup, if "Approval Required" change true to false
    active: orgOwner || (typeof user.active === 'undefined') ? true : !!user.active,
    useforms: orgOwner || !!user.useforms,
    editforms: orgOwner || !!user.editforms,
    editresources: orgOwner || !!user.editresources,
    newsfeed: orgOwner || !!user.newsfeed,
    useradmin: orgOwner || !!user.useradmin,
    groupsadmin: orgOwner || !!user.groupsadmin,
    addusers: orgOwner || !!user.addusers,
    viewreports: orgOwner || !!user.viewreports,
    dashboard: orgOwner || !!user.dashboard,
    tasklist: orgOwner || !!user.tasklist,
    scheduler: orgOwner || !!user.scheduler,
    dashboardbuilder: orgOwner || !!user.dashboardbuilder,
  }
}

export const deleteUser = (uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').doc(uid).get()
      .then(doc => {
        if (!doc.data().accountOwner) {
          firestore.collection('users').doc(uid).delete()
            .then(() => dispatch({ type: 'USER_DELETE_SUCCESS' }))
            .catch((error) => {
              dispatch({ type: 'USER_DELETE_ERROR', err: error })
              console.error(error);
            });
        }
      }
      )
  }
}


//Update User Groups

export const updateUserGroupList = (uid, groupID, action) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').doc(uid).get()
      .then(doc => {
        const user = doc.data();
        let newGroupList = user.groupList ? Object.values(user.groupList) : []

        if (action === 'delete') newGroupList = newGroupList.filter(group => group !== groupID)
        else newGroupList.push(groupID)

        firestore.collection('users').doc(uid).set({
          // orgID: user.orgID,
          ...user,
          groupList: newGroupList

        })
          .then(() => dispatch({ type: 'PROFILE_UPDATE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'PROFILE_UPDATE_ERROR', err: error })
            console.error(error);
          });
      })
  }
}
//Create tab and table in users & roles - done
//Field Generate the user id - done
//On create in usersinvited send an email - done
//Check if email sending works correctly?
//On login if orgID empty, in ProfileTabs check if useremail exists in /usersinvited, then populate fields on update profile from invited users
export const addInvitedUser = (user) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('addInvitedUser', { user });

    const firestore = getFirestore();
    firestore.collection('usersinvited').doc(user.inviteID).set({
      inviteID: user.inviteID,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email || '',
      phoneNumber: user.phoneNumber || '',
      photo: user.photo ? user.photo : null,
      descriptors: user.descriptors ? user.descriptors : null,
      // initials: user.firstName[0]  + user.lastName[0],
      ...getUserSettings(user)
      ,
      orgID: getState().firebase.profile.orgID,
      editedBy: getState().firebase.profile.firstName + ' ' + getState().firebase.profile.lastName,
      editedByUID: getState().firebase.auth.uid,
      editedAt: new Date()
    }, { merge: true })
      .then(() => dispatch({ type: 'INVITE_UPDATE_SUCCESS' }))
      .catch((error) => {
        dispatch({ type: 'INVITE_UPDATE_ERROR', err: error })
        console.error(error);
      });
  }
}


export const deleteInvitedUser = (inviteID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log(deleteInvitedUser, { inviteID });

    firestore.collection('usersinvited').doc(inviteID).get()
      .then(doc => {
        firestore.collection('usersinvited').doc(inviteID).delete()
          .then(() => dispatch({ type: 'INVITED_USERS_DELETE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'INVITED_USERS_DELETE_ERROR', err: error })
            console.error(error);
          });
      }
      )
  }
}

export const sendFCMToken = (uid, FCMToken) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').doc(uid).get()
      .then(doc => {
        const oldUserData = doc.data();
        firestore.collection('users').doc(uid).set({
          // orgID: user.orgID,
          ...oldUserData,
          FCMToken: FCMToken,
          FCMTokenUpdateTime: new Date()
        }, { merge: true })
          .then(() => dispatch({ type: 'FCM_Token_UPDATE_SUCCESS' }))
          .catch((error) => {
            dispatch({ type: 'FCM_Token_UPDATE_ERROR', err: error })
            console.error(error);
          });
      })
  }
}


export const createOrg = (newOrg) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('createOrg', { newOrg });
    const firestore = getFirestore();
    firestore.collection('async/newOrg/pending').doc(newOrg.createID).set({
      ...newOrg,
      createdAt: new Date()
    }, { merge: true })
      .then(() => dispatch({ type: 'ASYNC_ORG_CREATED_SUCCESS' }))
      .catch((error) => {
        dispatch({ type: 'ASYNC_ORG_CREATED_ERROR', err: error })
        console.error(error);
      });
  }
}


export const updateOrgAdvancedSettings = (advancedSettings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('updateOrgSettings', { advancedSettings });
    if (!advancedSettings) return null
    const firestore = getFirestore();
    const orgID = getState().firebase.profile.orgID
    return firestore.collection(`organizations`).doc(orgID).set({ advancedSettings, }, { merge: true })
      .then(() => dispatch({ type: 'ORG_ADVANCED_SETTINGS_UPDATED_SUCCESS' }))
      .catch((error) => {
        dispatch({ type: 'ORG_ADVANCED_SETTINGS_UPDATED_ERROR', err: error })
      })
  }
}