import React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GoogleMapEmbed, GoogleMapEmbedFromLocation } from '../../standard/MapPopover';
import { noPaddingDenseStyleForTable } from '../../../utils/styleclasses';
import MaterialTable from 'material-table';
import { fieldToFormattedDate } from '../../../utils/datetimeutils';
import { tableIcons } from '../../../utils/tableUtil';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ListItemsDisplay } from '../../standard/ListItemsSearchSelector';
import { checkBoxEditor, dateTimeEditor, locationEditor, numberEditor, selectEditor } from './renderTableCellEditors';
import { Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import PopoverIcon from '../../standard/PopoverIcon';

export function RenderTable({ rowData, columns, requiredFields, options, setGridAPI, setGridColumnAPI, saveUpdatedRow, deleteRow }) {

  const columnTypes = {
    'text': {
      filter: true,
      valueSetter: ({ newValue, colDef, data }) => { data[colDef.field] = newValue || null; return true; }
    },
    'number': {
      filter: 'agNumberColumnFilter',
      cellEditorFramework: numberEditor
    },
    'date': {
      filter: true,
      valueGetter: ({ data, column }) => data && data[column.colId] ? fieldToFormattedDate(data[column.colId], 'date') : '',
      cellEditorFramework: dateTimeEditor
    },
    'time': {
      filter: true,
      valueGetter: ({ data, column }) => data && data[column.colId] ? fieldToFormattedDate(data[column.colId], 'time') : '',
      cellEditorFramework: dateTimeEditor
    },
    'datetime': {
      filter: true,
      valueGetter: ({ data, column }) => data && data[column.colId] ? fieldToFormattedDate(data[column.colId], 'datetime') : '',
      cellEditorFramework: dateTimeEditor
    },
    'monthYear': {
      filter: true,
      valueGetter: ({ data, column }) => data && data[column.colId] ? fieldToFormattedDate(data[column.colId], 'monthYear') : '',
      cellEditorFramework: dateTimeEditor
    },
    'year': {
      filter: true,
      valueGetter: ({ data, column }) => data && data[column.colId] ? fieldToFormattedDate(data[column.colId], 'year') : '',
      cellEditorFramework: dateTimeEditor
    },
    'boolean': {
      filter: true,
      cellRendererFramework: ({ value }) => <Checkbox checked={value} color='secondary' />,
      cellEditorFramework: checkBoxEditor
    },
    'select': {
      filter: true,
      suppressKeyboardEvent: (props) => {
        console.log('RenderTable suppressKeyboardEvent', props);
        const { event, editing } = props
        return (editing && event && event.key === 'Enter') ? false : false //Can evaluate and drop this function 
      },
      cellEditorFramework: selectEditor,
      cellRendererFramework: ({ value, colDef }) => {
        if (colDef && colDef.cellEditorParams && colDef.cellEditorParams.optionListID)
          return <ListItemsDisplay optionListID={colDef.cellEditorParams.optionListID} value={[value || null]} />
        else return value ? value : ''
      }
    },
    'location': {
      filter: false,
      cellEditorFramework: locationEditor,
      cellRendererFramework: ({ value }) => {
        return (options && !options.embedMaps) ?
          <a href={value} target="_blank" rel="noopener noreferrer">Location</a> :
          (value && value.includes && value.includes('http://maps.google.com/')) ?
            <GoogleMapEmbed mapLink={value} style={{ width: '350px', display: 'block', height: '300px' }} />
            : <GoogleMapEmbedFromLocation location={value} style={{ width: '350px', display: 'block', height: '300px' }} options={{ hide: ['city', 'state', 'country'] }} />;
        // <GoogleMapEmbed mapLink={value} style={{ width: '350px', display: 'block', height: '300px' }} />
      }
    },
    'camera': {
      filter: false,
      cellRendererFramework: ({ value }) => {
        if (!(value && ((value.fieldURL && value.fieldURL.includes('http')) || (value.fieldCompressedURL && value.fieldCompressedURL.includes('http'))))) return null
        return <a href={value.fieldURL || value.fieldCompressedURL} target="_blank" rel="noopener noreferrer">
          {(options && !options.embedImages) ? "Image" : <img style={{ height: 300, }}
            src={value.fieldCompressedURL || value.fieldURL} alt='Link Not Loaded'
          />}</a>

      }
    },
    'fieldSet': {
      filter: true,
      editable: false,
      cellRendererFramework: ({ value }) =>
        (options && !options.embedTables) ?
          (!value ? 'No Data' : JSON.stringify(value.columns.map(column => column.headerName)) + JSON.stringify(value.data.map(row => Object.values(row))))
          : <MaterialTable
            icons={tableIcons}
            options={{ search: false, toolbar: false, pageSizeOptions: [5], ...noPaddingDenseStyleForTable, emptyRowsWhenPaging: false }}
            data={(value && value.data) || []}
            columns={(value && value.columns) || []}
          />

    },
    'userSelector': { filter: true },
    'deleteRow': {
      headerComponentFramework: () => <Delete />,
      cellRendererFramework: ({ data, api }) => {
        return <PopoverIcon showCloseIcon={true} closePopoverOnClick={true} icon="DeleteOutline" style={{ marginTop: 20 }}
          iconButton={true} id='deleteTableRow'
          popoverContent={
            <div style={{ padding: 4, paddingBottom: 8 }}>
              <Typography variant="h6" color="error" style={{ textAlign: 'left', margin: 8, marginTop: 4 }}>Confirm Row Deletion</Typography>
              <Typography style={{ margin: 8 }}>Do you want to delete this row?</Typography>
              <Chip id='confirmTableRowDeletion'
                variant="outlined" clickable
                style={{ padding: 15, }}
                onClick={() => {
                  api.updateRowData({ remove: [data] });
                  return deleteRow(data)
                }}
                label='Delete Row'
              />
            </div>
          } />
      }
    }
  }

  function onGridReady(params) {
    if (rowData.length > 0)
      params.api.sizeColumnsToFit();
    if (setGridAPI) { setGridAPI(params.api); }
    if (setGridColumnAPI) { setGridColumnAPI(params.columnApi) }
  }

  function autoSizeColumns(params) {
    const colIds = params.columnApi.getAllDisplayedColumns().map(col => col.getColId());
    setTimeout(() => params.columnApi.autoSizeColumns(colIds), 300)
  };

  const rowHeight =
    (options.embedMaps && columns.find(column => column.type === 'location')) ||
      (options.embedImages && columns.find(column => column.type === 'camera')) ||
      (options.embedTables && columns.find(column => column.type === 'fieldSet'))
      ? 300 : null

  const onRowValueChanged = ({ data, node, columnApi, api }) => {

    let status;
    if (saveUpdatedRow)
      status = saveUpdatedRow(data);
    if (status.status === 'error') {
      if (status.action === 'continueEdit') {
        api.startEditingCell({ rowIndex: node.rowIndex, colKey: status.columnID || columnApi.getAllColumns()[0].colId });
      }
    } else if (status.updateRowData) {
      api.applyTransaction({ update: [status.updateRowData] });
      api.refreshCells({ force: true });
    }
  };

  let optionalSettings = options.editable ? {
    // immutableData: true,
    getRowNodeId: (data) => data && (data.id || data[options.columnWithRowID]),
    editType: 'fullRow',
    onRowValueChanged: onRowValueChanged
  } : {}

  if (options && options.noHeader) optionalSettings.headerHeight = 0

  const requiredFieldFormatter = ({ value }) => value ? { 'background-color': 'white' } : { 'background-color': 'red' };

  return <>
    <AgGridReact
      style={{ width: '100%', height: '100%' }}
      // domLayout='autoHeight'
      key={JSON.stringify(options)}
      className="ag-theme-alpine"
      onGridReady={onGridReady}
      rowData={rowData}
      columnTypes={columnTypes}
      rowHeight={rowHeight}
      defaultColDef={{ resizable: true }}
      onFirstDataRendered={autoSizeColumns}
      {...optionalSettings}
    >
      {columns.map(column => {
        const requiredField = requiredFields && requiredFields.find(field => field.fieldID === column.field);
        return <AgGridColumn
          singleClickEdit={!!(options.editable && options.singleClickEdit)}
          key={column.field}
          field={column.field}
          headerName={column.headerName}
          sortable={true}
          type={column.type}
          editable={!!(options.editable && column.editable && !['fieldSet', 'camera'].includes(column.type))}
          cellEditorParams={column.cellEditorParams}
          cellStyle={requiredField ? (requiredFieldFormatter) : {}}
        />
      })}
      <AgGridColumn hide={!deleteRow} key='deleteRow' sortable={false} filter={false} headerName='' field='deleteRow' type='deleteRow' suppressColumnsToolPanel={!deleteRow}
      />
    </AgGridReact>
  </>
}