import handlebars from 'handlebars'

export const calculateExpression = (fieldList, expression, field, profile) => {


  const fields = field ? fieldList.filter(row => row.fieldID !== field.fieldID) : fieldList;
  const fieldSetIndex = field && field.fieldID.includes('__') ? field.fieldID.substr(field.fieldID.lastIndexOf("__")) : null;
  const fieldIDsValues = fields.filter(field => field.fieldType !== 'tableLookup').map(({ fieldID, fieldValue, fieldIdentifier, fieldLabel, fieldName }) => ({ fieldID, fieldValue, fieldIdentifier, fieldLabel, fieldName }));

  //Changed profile to be used as {{profile.uid}} etc
  const profileAndStandardMappings = {
    profile,
    app:
    {
      thisFieldID: field && field.fieldID,
      thisFieldSetIndex: fieldSetIndex,
      fieldList: JSON.stringify(fieldIDsValues),
    }
  }

  let modifiedExpression = expression;

  //ONCE ALL CODE BLOCKS ARE CHANGED TO {{{ }}} NOTATION THIS CAN BE DELETED
  if (profile) {
    modifiedExpression = modifiedExpression.replace(new RegExp(`\\{groupList}`, 'g'), `${JSON.stringify(profile.groupList)}`);
    modifiedExpression = modifiedExpression.replace(new RegExp(`\\{uid}`, 'g'), `${JSON.stringify(profile.uid)}`);
  }
  if (field)
    modifiedExpression = modifiedExpression.replace(new RegExp(`\\{thisFieldID}`, 'g'), `${field.fieldID}`);
  if (fieldSetIndex)
    modifiedExpression = modifiedExpression.replace(new RegExp(`\\{thisFieldSetIndex}`, 'g'), `${fieldSetIndex}`);

  modifiedExpression = modifiedExpression.replace(new RegExp(`\\{fieldList}`, 'g'), JSON.stringify(fieldIDsValues));
  //LEGACY CODE ENDS HERE


  try {
    modifiedExpression = valueSubstitution(fields, modifiedExpression, profileAndStandardMappings);

    const evaluatedExpression = String(eval(modifiedExpression));
    let result = evaluatedExpression.includes('NaN') || evaluatedExpression === undefined ? '' : evaluatedExpression;

    const checker = value => !fields.some(field => value.includes(field));
    if (checker(result))
      result = valueSubstitution(fields, result).replace(/'/g, '');

    return result;
  }
  catch (err) { console.log('Not a valid calculation expression', field && field.fieldLabel, modifiedExpression); }
};


export const valueSubstitution = (fieldList, expression, additionalMappings) => {
  let modifiedExpression = expression;

  const fieldValueMappings = Object.assign({},
    ...fieldList.map(field => ({
      [field.fieldID]: field.fieldValue ? (typeof field.fieldValue !== 'string' ? JSON.stringify(field.fieldValue) : field.fieldValue) : ''
    })))

  fieldList.forEach(field => {
    if (expression.includes(field.fieldID)) {
      let regExForAnyWordPlusID = new RegExp(`{{\\w*${field.fieldID}}}`, 'g');
      modifiedExpression = modifiedExpression.replace(regExForAnyWordPlusID, `{{${field.fieldID}}}`);
    }
  });

  modifiedExpression = handlebars.compile(modifiedExpression)({ ...additionalMappings, ...fieldValueMappings });

  //ONCE ALL CODE BLOCKS ARE CHANGED TO {{{ }}} NOTATION THIS CAN BE DELETED
  fieldList.forEach(field => {
    if (expression.includes(field.fieldID)) {
      let re = new RegExp(`{\\w*${field.fieldID}}`, 'g');
      modifiedExpression = modifiedExpression.replace(re, field.fieldValue ?
        typeof field.fieldValue !== 'string' ?
          `'${JSON.stringify(field.fieldValue)}'` : `'${field.fieldValue}'`
        : "''");
    }
  }
  );
  //LEGACY CODE ENDS HERE

  console.log('calculateExpression valueSubstitution after substitution', modifiedExpression);

  try { return (modifiedExpression); }
  catch (err) { alert('Error, not a valid expression. Please inform administrator' + modifiedExpression); }
};