import React from 'react'
import Chip from '@material-ui/core/Chip';
import AddCircle from '@material-ui/icons/AddCircle';
import NoteAdd from '@material-ui/icons/NoteAdd';
import CreateTaskDialog from './CreateTaskDialog';
import { displayLeft } from '../../utils/styleclasses';
import { secondary } from '../../utils/themes';
import AlertSnackbar from '../standard/AlertSnackbar';

const CreateTask = (props) => {
  console.log('CreateTask props', props);
  const [open, setOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState({ open: false, message: '', backgroundColor: secondary, alertTimeout: 2000 });

  const handleClick = () => {
    if (props.handleClick) {
      console.log('CreateTask handlingClick',);
      props.handleClick()
    }
    setOpen(true)
  }
  // if (typeof props.apps === 'undefined') return <Loader />

  return (
    <div >
      {
        props.showIcon ?
          <NoteAdd onClick={handleClick} />
          : <Chip variant={props.variant || "default"}
            color="primary"
            id="newTask"
            style={props.style || { marginRight: 16 }}
            onClick={handleClick}
            label={<div style={displayLeft}><AddCircle style={{ marginRight: 4 }} />Task</div>} >
          </Chip>
      }
      {open && <CreateTaskDialog open={open} setOpen={setOpen} activity={props.activity} activityID={props.activityID}
        setAlertMessage={(message) => setAlertState({ ...alertState, open: true, message })}
        image={props.image} taskTitle={props.taskTitle || null} />}
      <AlertSnackbar alertState={alertState} setAlertState={setAlertState} />
    </div >
  )
}

export default CreateTask