import React from 'react'
import { Typography, Card } from '@material-ui/core';

function CalculatedField({ calculatedValue, handleChange, field }) {
  if (field.fieldValue !== calculatedValue)
    handleChange(field.fieldID, typeof calculatedValue === 'undefined' ? 'Not Defined' : calculatedValue)
  return <Card style={{ padding: 8 }}>
    <Typography name={field.fieldIdentifier}>{calculatedValue}</Typography>
  </Card>
}

export default React.memo(CalculatedField, (prevProps, nextProps) => prevProps.calculatedValue === nextProps.calculatedValue)