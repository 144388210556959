import React from 'react'
import FreshChat from 'react-freshchat'
import { connect } from 'react-redux'
import './freshchat.css'
import HelpOutline from '@material-ui/icons/HelpOutline';
import { displayLeft } from '../../utils/styleclasses'
import AlertSnackbar from '../standard/AlertSnackbar'
import { Chip } from '@material-ui/core'
import { primary } from '../../utils/themes'

function HelpWidget(props) {
  const [open, setOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState({ open: false, message: '', backgroundColor: primary, alertTimeout: 1200, });
  return (
    <div>
      <Chip color="primary" style={{ margin: 16, }}
        onClick={() => {
          if (window.fcWidget) { window.fcWidget.open(); }
          else setAlertState({ ...alertState, open: true, message: "Loading Support Chat", progressBar: true })
          setOpen(true);
        }}
        label={<div style={displayLeft}> <HelpOutline style={{ marginRight: 4 }} />Help</div>}
      />

      {
        props.profile && open &&
        <FreshChat
          token={"5c42e365-31a8-4ea5-90b0-4756f0a47e5e"}
          config={{
            content:
            {
              headers: { chat: 'Opstack Support Chat' },
            },
            cssNames: localStorage.getItem('isSmallScreen') === 'true' ? { widget: "custom_fc_frame" } : null

          }}
          onInit={widget => {
            /* Use `widget` instead of `window.fcWidget`*/
            widget.init({
              content:
              {
                headers:
                  { chat: 'Opstack Support Chat' }
              }
            })
            widget.user.setEmail("props.profile.email");
            widget.user.setFirstName(props.profile.firstName || 'Guest');
            widget.user.setLastName((props.profile.lastName || '') + ' ' + (props.profile.orgID + ""));
            widget.user.setPhone(props.profile.phoneNumber || '');
            widget.setExternalId(props.profile.uid + ' ' + props.profile.orgID);
            widget.open();
          }}
          onLoad={() => setAlertState({ open: false })}
          onClose={widget => widget.close()}
        />
      }
      <AlertSnackbar alertState={alertState} setAlertState={setAlertState} />

    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}


export default connect(mapStateToProps, null)(HelpWidget)