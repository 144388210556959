import React from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import CardAddSelector from "./CardAddSelector";
import { updateDashboardCard, updateDashboard, refreshAllDashboardCards, } from '../../store/actions/formActions'
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';
import Email from '@material-ui/icons/Email';
import { fieldIDGenerator } from "../../utils/constants";
import TourContainer from "../standard/TourContainer";
import tourConfig from "./dashboardtourconfig";
import DashboardResponsiveGridContainer from "./DashboardResponsiveGridContainer";
import SchedulePageSend from "./SchedulePageSend";
import PopoverButton from "../standard/PopoverButton";
import Chip from "@material-ui/core/Chip";
import { iconTextAlign } from "../../utils/iconutils";
import { isJSON } from "../../utils/utilityfunctions";

const defaultDashboard = {
  dashboardID: 'dashdata',
  dashboardName: 'My Dashboard'
}
class Dashboard extends React.PureComponent {

  state = {
    ...isJSON(window.localStorage.getItem('lastSelectedDashboard')) || defaultDashboard,
    editOpen: false
  };

  handleAddCard(card) {
    console.log('updateDashboardCard handleAddCard', { card });
    const dashboardCardID = fieldIDGenerator();
    this.props.updateDashboardCard(dashboardCardID, card, this.state.dashboardID);
  }

  updateSelectedDashboard(dashboardSelected) {
    if (dashboardSelected[0] && dashboardSelected[0].value) {
      const dashboard = { dashboardID: dashboardSelected[0].value, dashboardName: dashboardSelected[0].label };
      window.localStorage.setItem('lastSelectedDashboard', JSON.stringify(dashboard))
      this.setState(dashboard)
    }
    else this.setState(defaultDashboard)
  }

  render() {
    const isSmallScreen = (window.innerWidth < 825);

    return (
      <div >
        {/* <Grid container style={{ padding: 0, paddingTop: 4, paddingLeft: 8, ...displayLeft }}>
          <Grid item sm={12} md={6} style={displayLeft}> */}
        {/* <DashboardSearchSelector
              selectedValues={[this.state.dashboardID]} style={{ width: "100%", }}
              quickEdit={true} inputLabel={<DashboardIcon style={{ marginRight: 8 }} color="primary" fontSize="large" />}
              handleSave={(dashboardSelected) => this.updateSelectedDashboard(dashboardSelected)} disableClearable={true} />
            {!isSmallScreen && <span style={displayLeft} >
              <NewDashboardButton updateDashboard={this.props.updateDashboard} />
              {
                this.state.dashboardID !== 'dashdata' &&
                <EditDashboardButton updateDashboard={
                  (dashboard, deleteDashboard) => {
                    if (deleteDashboardCard) { this.setState({ ...defaultDashboard }) }
                    this.props.updateDashboard(dashboard, deleteDashboard)
                  }
                } dashboard={this.state} />}
            </span>} */}
        {/* </Grid> */}
        {/* <Grid item sm={12} md={6}> */}
        <span style={{ textAlign: 'right', width: '100%', display: 'inline-flex' }} >
          {/* <PopoverButton
            popoverContent={<SchedulePageSend
              selectedDashboardID={this.state.dashboardID}
              handleSave={(scheduleList) => {
                const newDashboard = { ...this.state, scheduleList: scheduleList }
                console.log({ newDashboard });
                this.props.updateDashboard(newDashboard);
                this.setState(newDashboard)
              }} />}
            text={isSmallScreen ? <Email /> : iconTextAlign(<Email />, 'Schedule Email')}
            horizontalOrigin="right"
            showCloseIcon={true}
            useChip={true}
            variant="outlined"
          /> */}

          <Chip style={{ margin: 8 }} variant="outlined" color="primary" title="Refresh All Cards"
            onClick={() => this.props.refreshAllDashboardCards(this.state.dashboardID)} label={<Refresh />} />

          {this.state.editOpen ?
            <Chip style={{ margin: 8 }} variant="outlined" color="primary"
              onClick={() => {
                this.setState({
                  editOpen: false,
                  layouts: global.localStorage.getItem(`gridLayout-${this.state.dashboardID}`) ?
                    JSON.parse(global.localStorage.getItem(`gridLayout-${this.state.dashboardID}`)).layouts : {}
                }, () => this.props.updateDashboard(this.state))
              }
              } label={isSmallScreen ? <Save /> : 'Save Layout'} title="Save Dashboard Layout" />
            : <Chip style={{ margin: 8 }} variant="outlined" color="primary"
              onClick={() => this.setState({ editOpen: true })} label={isSmallScreen ? <Edit /> : 'Edit Layout'} title="Edit Dashboard Layout" />
          }

          <span data-tut="tourAddCards" style={{ margin: 8 }}>
            <CardAddSelector isChip={true} handleAdd={(card) => { this.handleAddCard(card) }} />
          </span>
        </span>
        {/* </Grid>
        </Grid> */}
        {this.state.dashboardID &&
          <DashboardResponsiveGridContainer key={this.state.dashboardID} selectedDashboardID={this.state.dashboardID} isSmallScreen={isSmallScreen} editOpen={this.state.editOpen} />}
        {this.props.profile && <TourContainer tourName="MobileHomePageTour" tourConfig={tourConfig} />}
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboard: (dashboard, deleteDashboard) => dispatch(updateDashboard(dashboard, deleteDashboard)),
    updateDashboardCard: (dashboardCardID, card, dashboardID) => dispatch(updateDashboardCard(dashboardCardID, card, dashboardID)),
    refreshAllDashboardCards: (dashboardID) => dispatch(refreshAllDashboardCards(dashboardID)),
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard)