import React from 'react'
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';

export default class InputCamera extends React.Component {

  state = { imageURI: this.props.imageURI || null }

  imageUpload = (e) => {
    let uploadImage = e.target.files[0] // upload the first file only

    if (uploadImage) {
      let classThis = this;
      let reader = new FileReader();
      reader.onload = (readerEvent) => (classThis.setState({ imageURI: readerEvent.target.result }))
      reader.readAsDataURL(uploadImage);
      this.props.uploadToStorage(uploadImage, uploadImage /** URI*/);
      this.props.handleChange(this.props.fieldID, uploadImage);
    }
  }

  componentWillUnmount() {
    // if (this.state.imageURI) {
    // URL.revokeObjectURL(this.state.imageURI);
    // console.log('Revoking Object URL');
    // }
  }

  render() {
    return <div>
      <Button variant="outlined" component="label"
        style={{ marginTop: 10, marginBottom: 10 }} id={this.props.id} name={this.props.name}>
        {!this.props.noButtonText && <div style={{ marginRight: 8 }}>{!this.state.imageURI ? "Launch Camera" : "Retake Photo"}</div>}
        <PhotoCamera />
        {this.props.allowUpload ?
          <input type="file" accept="image/gif,image/png,image/jpeg,image/jpg,image/bmp"
            onChange={this.imageUpload} style={{ display: "none" }} />
          : <input type="file" accept="image/*"
            capture={this.props.facingModes === 'user' ? "user" : "environment"}
            onChange={this.imageUpload} style={{ display: "none" }} />}
      </Button>
      {
        (this.state.imageURI) &&
        <img src={this.state.imageURI}
          alt="Captured Data"
          style={{
            width: "100%",
            maxWidth: 1000,
            maxHeight: "70vh",
            height: "auto",
            justifyContent: "center",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: "8px"
          }} />
      }
    </div>
  }
}
