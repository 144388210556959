import React from 'react'
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Cancel from '@material-ui/icons/Cancel';
import { commonIcons } from '../../utils/iconutils';

export default function PopoverIcon({ icon, popoverContent, style, showCloseIcon, horizontalOrigin, useIconButton, closePopoverOnClick, id }) {
  const [popover, setPopover] = React.useState({ open: false, });
  const openPopover = (e) => (setPopover({ ...popover, open: !popover.open, anchorEl: e.currentTarget }));
  let iconStyle = { cursor: 'pointer', ...style }
  let iconComponent = useIconButton ? <IconButton onClick={openPopover} {...id ? { id } : {}}> {commonIcons[icon]}</IconButton> :
    <span onClick={openPopover} style={iconStyle} {...id ? { id } : {}}>{commonIcons[icon]}</span>

  return (
    <span>
      {iconComponent}
      {popover.open && <Popover open={popover.open} anchorEl={popover.anchorEl}
        onClose={() => { setPopover({ ...popover, open: false }) }}
        onClick={(e) => closePopoverOnClick ? setPopover({ ...popover, open: false }) : null}
        anchorOrigin={{ vertical: 'bottom', horizontal: horizontalOrigin || 'left', }} transformOrigin={{ vertical: 'top', horizontal: horizontalOrigin || 'left', }}
        PaperProps={{ style: { padding: 8, textAlign: 'center', } }}>
        {showCloseIcon &&
          <IconButton onClick={() => setPopover({ ...popover, open: false })} style={{ position: "absolute", top: 0, right: 0 }}>
            <Cancel />
          </IconButton>}
        {popoverContent}
      </Popover>}
    </span>
  )
}