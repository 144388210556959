// import Resizer from 'react-image-file-resizer';
import imageCompression from 'browser-image-compression';

export const imageResizer =
  (file, maxSize) => {

    var options = {
      maxSizeMB: maxSize,
      // maxWidthOrHeight: 1920,
      useWebWorker: false
    }
    return imageCompression(file, options)
      .then(function (compressedFile) {
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        return compressedFile; // write your own logic
      })
      .catch(function (error) {
        console.error(error.message);
      });
  }