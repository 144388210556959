import React from 'react';
import Queue from '@material-ui/icons/Queue';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DynamicComponent, fieldIDGenerator } from './constants';
import MapLocationPicker from '../components/standard/MapLocationPicker';
import UserSearchSelector from '../components/standard/UserSearchSelector';
import { GoogleMapEmbed, GoogleMapEmbedFromLocation } from '../components/standard/MapPopover';
import AppRenderSelectComponent from '../components/appviewer/components/AppRenderSelectComponent';
import ImageOverlay from '../components/standard/ImageOverlay';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isJSON } from './utilityfunctions';

export const tableIcons = {
  Add: Queue,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: Search,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};


export const getTableColumnStyle = (style) =>
  ({
    cellStyle: { textAlign: 'center', padding: 0, margin: 0, paddingLeft: 0, ...style },
    rowStyle: { textAlign: 'center', padding: 0, margin: 0, paddingLeft: 0, },
    headerStyle: { textAlign: 'center', padding: 0, margin: 0, paddingLeft: 0, ...style },
  })


export function getEditableFunctions(tableData, handleSave, options) {

  let returnValue = {}
  const delay = options.delay || 500

  if (!options.disableRowAdd)
    returnValue.onRowAdd = newData => new Promise(resolve => {
      const newFieldID = options.uniqueIDField ? { [options.uniqueIDField]: fieldIDGenerator() } : null
      const data = tableData.data
      delete newData.tableData
      data.push({ ...newData, ...newFieldID })
      if (options.saveOnlyRow) handleSave({ ...newData, ...newFieldID }, 'add')
      else handleSave({ ...tableData, data })
      return setTimeout(() => resolve(), delay)
    })

  if (!options.disableRowUpdate)
    returnValue.onRowUpdate = (newData, oldData) => new Promise(resolve => {
      const data = tableData.data
      const index = data.indexOf(oldData)
      delete newData.tableData
      data[index] = newData
      if (options.saveOnlyRow) handleSave({ ...newData }, 'update')
      else handleSave({ ...tableData, data })
      return setTimeout(() => resolve(), delay)
    })

  if (!options.disableRowDelete)
    returnValue.onRowDelete = oldData => new Promise(resolve => {
      let data = tableData.data
      const index = data.indexOf(oldData)
      data.splice(index, 1)
      if (options.saveOnlyRow) handleSave({ ...oldData }, 'delete')
      else handleSave({ ...tableData, data }, 'delete')
      return setTimeout(() => resolve(), delay)
    })

  return returnValue
}


export function renderDateTimeColumn(field) {
  return { render: rowData => getDateStringFromTimestamp(rowData[field.fieldID], field.fieldType) }
}

export function getDateStringFromTimestamp(timestampField, fieldType) {
  return timestampField && timestampField.toDate ?
    (fieldType === 'time' ? timestampField.toDate().toLocaleTimeString() :
      fieldType === 'date' ? timestampField.toDate().toLocaleDateString() :
        timestampField.toDate().toString())
    : '';
}

export function getLocationFieldForTable(field) {
  console.log('tableUtil getLocationFieldForTable', { field });
  return {
    render: rowData => {
      console.log('tableUtil getLocationFieldForTable', rowData, rowData[field.fieldID]);
      const saveData = { ...rowData };
      delete saveData.tableData;
      const location = rowData[field.fieldID];
      if (location && location.includes && location.includes('http://maps.google.com/'))
        return <GoogleMapEmbed mapLink={location} style={{ width: '350px', display: 'block', height: '150px' }} />
      else
        return <GoogleMapEmbedFromLocation location={location} style={{}} options={{ hide: ['city', 'state', 'country'] }} />;
    },
    editComponent: (editData) => {
      console.log({ editData });
      const rowData = editData.rowData;
      delete rowData.tableData;
      return <MapLocationPicker height='300px' zoom={15} location={rowData[field.fieldID]}
        handleSave={(location) => { editData.onChange(location); }}
        onChange={(location) => { editData.onChange(location); }} />;
    }
  };
}

export function getImageFieldForTable(field) {
  const renderFunction = rowData => {
    if (!rowData[field.fieldID]) return null
    const imageObject = isJSON(rowData[field.fieldID])
    if (!imageObject && typeof rowData[field.fieldID] === 'string')
      return rowData[field.fieldID]
    let fieldURL = imageObject.fieldURL.includes('http') ? imageObject.fieldURL : null
    let fieldCompressedURL = imageObject.fieldCompressedURL.includes('http') ? imageObject.fieldCompressedURL : fieldURL
    return fieldCompressedURL ?
      <ImageOverlay
        imageLink={fieldCompressedURL}
        imageStyle={{ maxWidth: 500, height: 150 }}
        zoomLink={fieldURL}
        textOverlay={null} /> : null
  }
  return {
    render: renderFunction,
    // editComponent: (editData) => renderFunction(editData.rowData)
  }
}


export function getListFieldForTable(field) {
  return {
    render: rowData => {
      const saveData = { ...rowData };
      delete saveData.tableData;
      return <AppRenderSelectComponent
        disabled={true}
        name={field.fieldName}
        optionListID={(field.fieldSettings && field.fieldSettings.selectOptions && field.fieldSettings.selectOptions.selectedOptionListID) || null}
        field={{ ...field, fieldValue: rowData[field.fieldID], fieldLabel: field.fieldName }}
        onChange={() => { }}
      />
    },
    editComponent: (editData) => {
      const rowData = editData.rowData;
      delete rowData.tableData;
      return <AppRenderSelectComponent
        name={field.fieldName}
        optionListID={(field.fieldSettings && field.fieldSettings.selectOptions && field.fieldSettings.selectOptions.selectedOptionListID) || null}
        field={{ ...field, fieldValue: rowData[field.fieldID], fieldLabel: field.fieldName }}
        onChange={(value) => editData.onChange(value)}
      />
    }
  };
}

export function getUserFieldForTable(field) {
  return {
    render: rowData => {
      const saveData = { ...rowData };
      delete saveData.tableData;
      return <UserSearchSelector
        noEdit={true}
        allUsers={false}
        hasDefault={false}
        selectedValues={[rowData[field.fieldID]] || []}
        multiple={false}
        style={{ width: "100%", }}
        inputLabel={""} />;
    },
    editComponent: (editData) => {
      console.log({ editData });
      const rowData = editData.rowData;
      delete rowData.tableData;
      return <UserSearchSelector
        allUsers={false}
        hasDefault={false}
        selectedValues={[rowData[field.fieldID]] || []}
        multiple={false}
        style={{ width: "100%", }}
        quickEdit={true}
        inputLabel={""}
        handleSave={(userList) => {
          const newValue = userList && userList[0] && userList[0].value;
          editData.onChange(newValue);
        }} />;
    }
  };
}


export function getDateTimeEditComponent(field) {
  return {
    render: rowData => {
      const saveData = { ...rowData };
      delete saveData.tableData;
      return JSON.stringify(rowData[field.fieldID])
    },
    editComponent: (editData) => {
      console.log({ editData });
      const rowData = editData.rowData;
      delete rowData.tableData;
      const DateTimeComponent = DynamicComponent[field.fieldType];
      // if (!field.fieldValue) handleChange(field.fieldID, new Date());
      return <MuiPickersUtilsProvider utils={DateFnsUtils}
        style={{ width: "100%", padding: 8, marginTop: 8 }}>
        <DateTimeComponent
          // id={field.fieldID}
          // name={field.fieldLabel}
          style={{ width: "100%" }}
          onChange={(date) => { editData.onChange(date) }}
          value={field.fieldValue ? ((field.fieldValue.toDate && field.fieldValue.toDate()) || field.fieldValue) : new Date()} />
      </MuiPickersUtilsProvider>
    }
  }
};