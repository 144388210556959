import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import fbConfig from './config/fbConfig'
import "firebase/storage";
import "firebase/messaging";
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import preval from 'preval.macro'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import LoadingSplashScreen from './components/layout/LoadingSplashScreen';

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <LoadingSplashScreen message="Connecting" />;
  return children
}

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig),
    // reactReduxFirebase(fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true })
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ),
);

const rrfProps = {
  firebase: fbConfig,
  config: { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true },
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}


ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <App />
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>, document.getElementById('root'))

//Set buildID to local storage (calculated at build time)
localStorage.setItem('buildDate', preval`module.exports = new Date();`);
localStorage.setItem('buildLocalDate', preval`module.exports = new Date().toLocaleString();`);
localStorage.setItem('buildID', preval`module.exports = Date.now();`);

if (window.location.hostname.includes('opstak-2019.web.app') ||
  window.location.hostname.includes('opstak-2019.firebaseapp.com') ||
  window.location.hostname.includes('app.opstack.co')) {
  if (!window.console) { window.console = {}; }
  let methods = ["log", "debug", "warn", "info"];
  for (let i = 0; i < methods.length; i++) { console[methods[i]] = function () { }; }
}

serviceWorker.register();
if (!window.location.hostname.includes('localhost')) {
  Sentry.init({
    dsn: "https://751b2d3f20a44f0e89f4c54ec049de3b@sentry.io/1869232",
    integrations: [
      new CaptureConsole({
        levels: ['error']
      })
    ],
    release: localStorage.getItem('buildID')
  });

  // initializeFCM();
}
