import React from 'react'
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import AddCircle from '@material-ui/icons/AddCircle';
import UserAppSearchSelector from '../standard/UserAppSearchSelector';
import { iconTextAlign } from '../../utils/iconutils';

export default function UserAppAddSelector({ handleAdd }) {
  const [app, setApp] = React.useState({ addCard: false });

  return (
    <span style={{ margin: 8 }} >
      {
        <span>
          <Chip color="primary" variant='outlined' id='addUserApp'
            label={iconTextAlign(<AddCircle />, 'App')}
            onClick={(e) => setApp({ ...app, addCard: true, anchorEl: e.currentTarget })} />

          {app.addCard && <Popover
            open={app.addCard}
            anchorEl={app.anchorEl}
            onClose={() => { setApp({ ...app, addCard: false }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { padding: 16, textAlign: 'center' } }}>
            <div style={{ minWidth: 500 }}>
              <UserAppSearchSelector
                selectedValues={app.appAssigned ? [app.appAssigned] : []} style={{ width: "100%", }}
                quickEdit={true}
                inputLabel={""}
                handleSave={(appAssigned, appAssignedNames) => {
                  console.log({ appAssigned });
                  setApp({
                    ...app, appID: (appAssigned[0] && appAssigned[0].value) || null,
                    appName: (appAssigned[0] && appAssigned[0].label) || null
                  })
                }}
                disableClearable={true} />
              <Button color="primary" variant="outlined" onClick={() => { setApp({ addCard: false }) }} style={{ margin: 8 }}>
                Cancel </Button>
              <Button variant="contained" color="primary" id="addApp"
                onClick={(e) => {
                  handleAdd({ appID: app.appID });
                  setApp({ ...app, addCard: false })
                }
                }>
                Add App
              </Button>
            </div>
          </Popover>}
        </span>
      }

    </span >
  )
}
