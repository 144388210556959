import { fieldIDGenerator } from "./constants";

export const EmailRegEx = (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i).source;


export const fieldSettings = {
  readonly: { name: 'readonly', value: 'no', settingLabel: 'Read Only', type: 'when', whenExpression: '', sortIndex: 10 },
  hidden: { name: 'hidden', value: 'no', settingLabel: 'Hide', type: 'when', whenExpression: '', sortIndex: 20 },
  required: { name: 'required', value: 'no', settingLabel: 'Required', type: 'when', whenExpression: '', sortIndex: 30 }
}

export const fieldTypes = {
  text: {
    fieldType: 'text',
    name: 'Text',
    fieldLabel: 'Text',
    identifier: 'Text',
    placeholder: 'Enter',
    fieldSettings: {
      ...fieldSettings,
      numberOfLines: { sortIndex: 9, name: 'numberOfLines', value: 1, settingLabel: 'Number of Lines', type: 'number', }
    },
  },
  checkbox: {
    fieldType: 'checkbox',
    name: 'Checkbox',
    fieldLabel: 'Checkbox',
    identifier: 'Checkbox',
    placeholder: '',
    fieldSettings,
  },
  switch: {
    fieldType: 'switch',
    name: 'Switch',
    fieldLabel: 'Switch',
    identifier: 'Switch',
    placeholder: '',
    regularExpression: '',
    fieldSettings,
  },
  select: {
    fieldType: 'select',
    name: 'Select',
    fieldLabel: 'Select',
    identifier: 'Select',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      selectOptions: { sortIndex: 40, name: 'selectOptions', value: null, settingLabel: 'Select Options', type: 'select', selectedOptionListID: null, options: [] }
    }
  },
  time: {
    fieldType: 'time',
    name: 'Time',
    fieldLabel: 'Time',
    identifier: 'Time',
    placeholder: '',
    fieldSettings,
  },
  date: {
    fieldType: 'date',
    name: 'Date',
    fieldLabel: 'Date',
    identifier: 'Date',
    placeholder: '',
    fieldSettings,
  },
  email: {
    fieldType: 'email',
    name: 'Email',
    fieldLabel: 'Email',
    identifier: 'Email',
    placeholder: '',
    regularExpression: (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i).source,
    errorMessage: 'Please enter a valid email address',
    fieldSettings,
  },
  phone: {
    fieldType: 'phone',
    name: 'Phone',
    fieldLabel: 'Phone',
    identifier: 'Phone',
    placeholder: '',
    regularExpression: (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).source,
    errorMessage: 'Please enter a valid phone number',
    fieldSettings,
  },
  number: {
    fieldType: 'number',
    name: 'Number',
    fieldLabel: 'Number',
    identifier: 'Number',
    placeholder: 'Enter',
    fieldSettings: {
      ...fieldSettings,
      minValue: { sortIndex: 9, name: 'minValue', value: 0, settingLabel: 'Minimum Value', type: 'number', },
      maxValue: { sortIndex: 9.1, name: 'maxValue', value: '', settingLabel: 'Maximum Value', type: 'number', },
    },
  },
  integer: {
    fieldType: 'integer',
    name: 'Integer',
    fieldLabel: 'Integer',
    identifier: 'Integer',
    placeholder: '',
    regularExpression: (/^\d+$/).source,
    errorMessage: 'Please enter a valid integer',
    fieldSettings,
  },
  camera: {
    fieldType: 'camera',
    name: 'Camera',
    fieldLabel: 'Camera',
    identifier: 'Camera',
    placeholder: '',
    regularExpression: '',
    fieldSettings: {
      ...fieldSettings,
      frontCamera: { sortIndex: 7, name: 'frontCamera', value: false, settingLabel: 'Use Front Facing Camera', type: 'checkbox', },
      allowUpload: { sortIndex: 8, name: 'allowUpload', value: false, settingLabel: 'Permit Image File Upload', type: 'checkbox', },
      focusBorder: { sortIndex: 8, name: 'focusBorder', value: false, settingLabel: 'Border To Improve Image Focus', type: 'checkbox', },
      submissionTagOptions: { sortIndex: 40, name: 'submissionTagOptions', value: [], settingLabel: 'Submission Tag Options', type: 'select', selectedOptionListID: null, options: [] }
      //Todo: TEST submissionTagOptions
    }
  },
  location: {
    fieldType: 'location',
    name: 'Location',
    fieldLabel: 'Device Location',
    identifier: 'Location',
    placeholder: '',
    regularExpression: '',
    fieldSettings,
  },
  locationPicker: {
    fieldType: 'locationPicker',
    name: 'LocationPicker',
    fieldLabel: 'Location Picker',
    identifier: 'LocationPicker',
    placeholder: '',
    regularExpression: '',
    fieldSettings,
  },
  datetime: {
    fieldType: 'datetime',
    name: 'Date + Time',
    fieldLabel: 'Date + Time',
    identifier: 'DateTime',
    placeholder: '',
    fieldSettings,
  },
  monthYear: {
    fieldType: 'monthYear',
    name: 'Month & Year',
    fieldLabel: 'Month & Year',
    identifier: 'MonthYear',
    placeholder: '',
    fieldSettings,
  },
  year: {
    fieldType: 'year',
    name: 'Year',
    fieldLabel: 'Year',
    identifier: 'Year',
    placeholder: '',
    fieldSettings,
  },
  // month: {
  //   fieldType: 'month',
  //   name: 'Month',
  //   fieldLabel: 'Month',
  //   identifier: 'Month',
  //   placeholder: '',
  //   fieldSettings,
  // },
  calculated: {
    fieldType: 'calculated',
    name: 'Calculated',
    fieldLabel: 'Calculated',
    identifier: 'Calculated',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      expression: { sortIndex: 5, name: 'expression', value: '', settingLabel: 'Calculated Expression Code Editor', type: 'expression', }
    },

  },
  customSubmit: {
    fieldType: 'customSubmit',
    name: 'Custom Submit Button',
    fieldLabel: 'Custom Submit Button',
    identifier: 'CustomSubmitButton',
    placeholder: '',
    regularExpression: '',
    fieldSettings: {
      ...fieldSettings,
      reOpenAppOnSubmit: { name: 'reOpenAppOnSubmit', value: false, settingLabel: 'Reopen App on Submit', type: 'checkbox', }
    },
  },
  sectionHeader: {
    fieldType: 'sectionHeader',
    name: 'Section Header',
    fieldLabel: 'Section Header',
    identifier: 'SectionHeader',
    placeholder: '',
    fieldSettings,
  },
  card: {
    fieldType: 'card',
    name: 'Card',
    fieldLabel: 'Card',
    identifier: 'Card',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      cardSelector:
        { sortIndex: 9, name: 'cardSelector', value: '', settingLabel: 'Select Card', type: 'cardSelector', },
      hideHeader: { sortIndex: 9, name: 'hideHeader', value: false, settingLabel: 'Hide Card Header', type: 'checkbox', },
      dontPublish: { name: 'dontPublish', value: false, settingLabel: 'Exclude Field From Publishing', type: 'when', whenExpression: '', sortIndex: 9.1 }
    },
  },
  tableLookup: {
    fieldType: 'tableLookup',
    name: 'Table Lookup',
    fieldLabel: 'Table Lookup',
    identifier: 'TableLookup',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      tableSelector: { sortIndex: 5, name: 'tableSelector', value: '', settingLabel: 'Select Table', type: 'tableSelector', },
      fullTableLookup: { sortIndex: 9.5, name: 'fullTableLookup', value: false, settingLabel: 'Show all rows on empty lookup value', type: 'checkbox', settingCaption: 'All rows in table will be displayed' },
      firstRow: { name: 'firstRow', value: false, settingLabel: 'Limit Results to First Row', type: 'checkbox', sortIndex: 9.3 },
      dontPublish: { name: 'dontPublish', value: true, settingLabel: 'Exclude Field From Publishing', type: 'checkbox', sortIndex: 9.2 },
      maxRows: { sortIndex: 9.6, name: 'maxRows', value: 500, settingLabel: 'Maximum rows returned by table query', type: 'number', minValue: 0, maxValue: 500 },
    },
  },
  tableLookupSelect: {
    fieldType: 'tableLookupSelect',
    name: 'Table Lookup Select',
    fieldLabel: 'Table Lookup Select',
    identifier: 'TableLookupSelect',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      tableSelector: { sortIndex: 5, name: 'tableSelector', value: '', settingLabel: 'Select Table', type: 'tableSelector', isTableLookupSelect: true },
      fullTableLookup: { sortIndex: 9.5, name: 'fullTableLookup', value: false, settingLabel: 'Show all rows on empty lookup value', type: 'checkbox', settingCaption: 'All rows in table will be displayed' },
      firstRow: { name: 'firstRow', value: false, settingLabel: 'Limit Results to First Row', type: 'checkbox', sortIndex: 9.3 },
      dontPublish: { name: 'dontPublish', value: true, settingLabel: 'Exclude Field From Publishing', type: 'checkbox', sortIndex: 9.2 },
      maxRows: { sortIndex: 9.6, name: 'maxRows', value: 500, settingLabel: 'Maximum rows returned by table query', type: 'number', minValue: 0, maxValue: 500 },
    },
  },
  tableEditor: {
    fieldType: 'tableEditor',
    name: 'Table Editor',
    fieldLabel: 'Table Editor',
    identifier: 'TableEditor',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      readonly: { name: 'readonly', value: 'yes', settingLabel: 'Read Only', type: 'when', whenExpression: '', sortIndex: 10 },
      tableSelector: { sortIndex: 5, name: 'tableSelector', value: '', settingLabel: 'Select Table', type: 'tableSelector', },
      selectiveEdit: { sortIndex: 5.1, name: 'selectiveEdit', value: false, settingLabel: 'Selectively Edit Table', type: 'checkbox', },
      // noPaging: { sortIndex: 7, name: 'noPaging', value: false, settingLabel: 'Single Page View', type: 'hide', },
      noHeader: { sortIndex: 7, name: 'noHeader', value: false, settingLabel: 'Hide table column header', type: 'checkbox', },
      noFilter: { sortIndex: 7, name: 'noFilter', value: false, settingLabel: 'Hide data query filter', type: 'checkbox', },
      addRow: { sortIndex: 7, name: 'addRow', value: false, settingLabel: 'Enable adding new rows', type: 'checkbox', },
      deleteRow: { sortIndex: 7, name: 'deleteRow', value: false, settingLabel: 'Enable row deletion', type: 'checkbox', },
      fullTableLookup: { sortIndex: 9.5, name: 'fullTableLookup', value: false, settingLabel: 'Show all rows on empty lookup value', type: 'checkbox', settingCaption: 'All rows in table will be displayed' },
      enableToolbar: { sortIndex: 9, name: 'enableToolbar', value: false, settingLabel: 'Enable table toolbar', type: 'checkbox', },
      firstRow: { name: 'firstRow', value: false, settingLabel: 'Limit Results to First Row', type: 'checkbox', sortIndex: 9.3 },
      exportButton: { name: 'exportButton', value: true, settingLabel: 'Allow Data Export', type: 'checkbox', sortIndex: 9.2 },
      dontPublish: { name: 'dontPublish', value: true, settingLabel: 'Exclude Field From Publishing', type: 'hide', sortIndex: 9.2 },
      maxRows: { sortIndex: 9, name: 'maxRows', value: 500, settingLabel: 'Maximum rows returned by table query', type: 'number', minValue: 0, maxValue: 500 },
      settings: { sortIndex: 9, name: 'settings', value: '{default:{},smallScreen:{}}', settingLabel: 'Settings', type: 'text', },
    },
  },
  fieldSet: {
    fieldType: 'fieldSet',
    name: 'Field Set',
    fieldLabel: 'Repeatable Field Set',
    identifier: 'fieldSet',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      fieldSet: { sortIndex: 9, name: 'fieldSet', value: false, settingLabel: 'Fields Setup', type: 'fieldSet', },
      labelAddButton: { sortIndex: 9, name: 'labelAddButton', value: '', settingLabel: 'Customize Add Button Label', type: 'text', },
      repeatingLabel: { sortIndex: 9, name: 'repeatingLabel', value: '{counter} of {totalcount}', settingLabel: 'Repeating Item Label', type: 'text', },
      maxRepeats: { sortIndex: 9, name: 'maxRepeats', value: '', settingLabel: 'Maximum times fields can repeat', type: 'number', minValue: 1 },
      allowFieldDelete: { sortIndex: 9, name: 'allowFieldDelete', value: true, settingLabel: 'Allow Field Sets to be deleted', type: 'checkbox', },

    },
  },
  userSelector: {
    fieldType: 'userSelector',
    name: 'userSelector',
    fieldLabel: 'User Selector',
    identifier: 'userSelector',
    placeholder: '',
    fieldSettings: {
      ...fieldSettings,
      multiple: { sortIndex: 9, name: 'multiple', value: true, settingLabel: 'Enable multiple user selection', type: 'checkbox', },
      allUsers: { sortIndex: 9, name: 'allUsers', value: false, settingLabel: 'Enable All Users select option', type: 'checkbox', },
    },
  },
};


export const getScreenStructure = () => ({ ...{ fieldList: [], screenID: fieldIDGenerator() } })

export const appStructure = {
  appName: '',
  appDescription: '',
  editorFirstName: '',
  editorLastName: '',
  uid: '',
  screens: [getScreenStructure()],
  groupList: ["all"]
};


export function getNewField(fieldType) {
  let newField = fieldTypes[fieldType];
  newField.fieldID = fieldIDGenerator();
  newField.fieldIdentifier = newField.identifier + '_' + newField.fieldID;
  return { ...newField };
}