import debounce from 'lodash.debounce';
import React, { createContext, useCallback, useState } from 'react';
import { fieldIDGenerator } from '../../utils/constants';
import { fieldIsWithoutError, getAllFields, getWhenFieldSettingValue, spreadFieldSetsInFields, updateCalculatedFieldsToAppState } from './appRenderUtils';

export const AppRenderContext = createContext();

const AppRenderContextProvider = ({ data, children, profile }) => {
  const [app, setApp] = useState({
    ...data, selectedScreenIndex: 0, orgID: profile.orgID,
    submissionID: `${fieldIDGenerator()}-${data.appID}-${(profile && profile.orgID) || 'NoOrgID'}-${(profile && profile.uid) || 'NoUID'}`,
    cameraToUse: profile.cameraToUse, submittedByUID: profile.uid
  })
  // const [update, setUpdate] = useDebounce({}, 10, true);

  const [update, setUpdate] = useState({});

  const debouncedUpdate = useCallback(debounce((update) => {
    setUpdate(update)
  }, 300, { leading: true }), []);



  const allFields = spreadFieldSetsInFields(getAllFields(app))

  React.useEffect(() => {
    let updateValue = { fieldValue: update.value, }
    if (update.data && update.data.image) updateValue = { ...updateValue, ...update.data }
    if (update.data && typeof update.data.fieldSetIndex !== 'undefined') {
      const fieldSetsToUpdate = app.screens[app.selectedScreenIndex].fieldList.find(field => field.fieldID === update.fieldID)
      console.log('AppRenderContext', { fieldSetsToUpdate });
      if (fieldSetsToUpdate) {
        let newValue = [...fieldSetsToUpdate.fieldValue]
        newValue[update.data.fieldSetIndex] = newValue[update.data.fieldSetIndex].map(innerField => innerField.fieldID === update.data.innerFieldID ? { ...innerField, fieldValue: update.value } : innerField)
        updateValue = { fieldValue: newValue }
      }
    }

    let newAppState = {
      ...app,
      screens: app.screens.map((screen, index) => index !== app.selectedScreenIndex ? screen
        : {
          ...screen,
          fieldList: screen.fieldList.map(field =>
            field.fieldID !== update.fieldID ? field
              : { ...field, ...updateValue })
        })
    }
    newAppState = updateCalculatedFieldsToAppState(newAppState, profile);

    console.log('AppRenderContext', { newAppState });

    setApp(newAppState)
  }, [update])

  const handleFieldValueChange = (fieldID, value, data) => {
    console.log('2. AppRenderContext tableData handleFieldValueChange', fieldID, value, data);

    if (data && data.fieldType === 'tableLookup')
      setUpdate({ fieldID, value, data })
    else
      debouncedUpdate({ fieldID, value, data })
  }
  const setSelectedScreen = (selectedScreenIndex) => { setApp({ ...app, selectedScreenIndex: selectedScreenIndex }) }


  return (
    <AppRenderContext.Provider
      value={{ app, handleFieldValueChange, setApp, setSelectedScreen, getComponentProps, getCameraProps, allFields, profile }}>
      {children}
    </AppRenderContext.Provider>
  )

  function getComponentProps(field) {

    return {
      key: field.fieldID,
      style: {
        width: "100%", margin: 0,
        marginTop: 16,
        marginBottom: 0,
        display: getWhenFieldSettingValue(field, allFields, field.fieldSettings.hidden) ? 'none' : 'block'
      },
      required: getWhenFieldSettingValue(field, allFields, field.fieldSettings.required),
      disabled: getWhenFieldSettingValue(field, allFields, field.fieldSettings.readonly),
      // error: field.errorMessage ? true : undefined,
      error: fieldIsWithoutError(field) ? '' : field.errorMessage
    };
  }

  function getCameraProps(field) {
    let cameraProps = null;
    if (field.fieldType.includes('camera')) {
      const { orgID, submittedByUID, appID, submissionID, appName, cameraToUse } = app;
      cameraProps = { orgID, uid: submittedByUID, appID, submissionID, appName, cameraToUse };
    }
    return cameraProps;
  }
}
export default AppRenderContextProvider
