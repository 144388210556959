import { isJSON } from '../../../utils/utilityfunctions';

//used by AppReports, TableDataViewer

export function getTableColumnsFromFieldsArray(fields, options) {
  if (!fields)
    return [];
  return fields.map(field => {
    return {
      headerName: field.fieldName || field.fieldLabel,
      title: field.fieldName || field.fieldLabel,
      field: field.fieldID,
      type: field.fieldType,
      suppressSizeToFit: false,
      ...(field.fieldType === 'select' && field.fieldSettings && field.fieldSettings.selectOptions) ?
        { cellEditorParams: { optionListID: field.fieldSettings.selectOptions.selectedOptionListID, } } : null,
      ...options && options.editableFields && options.editableFields.length > 0 ?
        (options.editableFields.includes(field.fieldID) ? { editable: true } : { editable: false }) : {},
      ...(options && options.allColumnsEditable === true) ? { editable: true } : {}
    };
  });
}
function parseFieldSetString(fieldSetString) {
  return (isJSON(fieldSetString)) ?
    JSON.parse(fieldSetString).map(row => Object.assign({}, ...row.map(field => ({
      [field.fieldID.substring(0, field.fieldID.lastIndexOf(field.fieldID.includes('-') ? '-' : '__'))]: (field.fieldValue || null)
    }))))
    : [];
}

export function getTableRowFromFieldsArray(fieldsColumnDef, fieldsData, fieldSetsData) {

  const fieldLookupObject = Object.assign({},
    ...fieldsData.map(field => {
      if (field.fieldType.includes('camera'))
        return { [field.fieldID]: { fieldCompressedURL: field.fieldCompressedURL, fieldURL: field.fieldURL } };
      // if (field.fieldType.includes('date') || field.fieldType.includes('time'))
      //   return (field.fieldValue && field.fieldValue.toDate ?
      //     moment(field.fieldValue.toDate()).format('YYYY-mm-DD h:mm A') :
      //     moment(field.fieldValue).format('YYYY-mm-DD h:mm A'));
      // else if (field.fieldType === 'select')
      //   console.log('renderTableDataPrepHelpers', field);
      return { [field.fieldID]: field.fieldValue };
    }));


  return Object.assign({}, ...fieldsColumnDef.map(colDefField => ({
    [colDefField.fieldID]: colDefField.fieldType.includes('fieldSet') ? {
      data: parseFieldSetString(getFieldSetFieldValue(colDefField.fieldID)),
      columns: getTableColumnsFromFieldsArray(colDefField.fieldSettings.fieldSet.value.fields)
    }
      : fieldLookupObject[colDefField.fieldID],
  })));

  function getFieldSetFieldValue(fieldID) {
    if (!fieldSetsData)
      return '';
    const fieldSet = fieldSetsData.find(fS => fS.fieldID === fieldID);
    return (fieldSet && fieldSet.fieldValue) || '';
  }
}
