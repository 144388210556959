import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import Loader from '../../standard/Loader'
import { updateTableData } from '../../../store/actions/formActions'
import { fieldIDGenerator } from '../../../utils/constants'
import { convertToTextReplace } from '../../../utils/firebaseUtil'
import { RenderTableWithOptions } from '../../reports/submissions/RenderTableWithOptions'
import { getTableColumnsFromFieldsArray } from '../../reports/submissions/renderTableDataPrepHelpers'
import { useTableDataFromBQ } from '../../standard/tableDataFromBQ'
import { useFirestoreQuery } from '../../standard/firestoreQueryHook'


export default function TableDataViewerTable({ tableID, tableStructure, whereClause, limit, options }) {
  const dispatch = useDispatch();

  let query = {
    collection: `organizations/{{orgID}}/resources-tables/${tableID}/table-rows`,
    whereClause: whereClause || [],
    orderBy: null,
    tableName: `table_${tableID}`
  }

  //Convert bigquery return value with parse

  if (!isNaN(Number(limit))) query.limit = limit
  else query.limit = 1000

  // const tableData = useTableDataFromBQ(query);
  const tableData = useFirestoreQuery(query);


  if (typeof tableStructure === "undefined" || tableData.includes('Requested')) return <Loader />
  if (tableStructure === null) return null

  let columns = getTableColumnsFromFieldsArray(tableStructure.fields, options)
  if (options.showRowID) columns.push({ headerName: 'Row ID', title: 'Row ID', field: 'tableRowID', editable: false })

  const handleSave = (tableRow, type) => dispatch(updateTableData(tableRow, tableStructure.tableID, type))

  const keyFields = tableStructure.fields.filter(field => field.fieldSettings.keyField.value).sort((a, b) => a.fieldID < b.fieldID ? -1 : 1)

  const requiredFields = tableStructure.fields.filter(field => field.fieldSettings.required.value || field.fieldSettings.keyField.value).map(field => ({ fieldID: field.fieldID }))

  const getEmptyRequiredFieldsIfAny = (newData) => {
    let returnValue = false
    if (requiredFields && requiredFields.length > 0)
      requiredFields.forEach(field => { if (!newData[field.fieldID]) { returnValue = field; return returnValue } })
    return returnValue
  }

  const getTableRowID = (newData) => keyFields && keyFields.length > 0 ?
    {
      tableRowID: convertToTextReplace(keyFields.map(keyField =>
        keyField.fieldType === 'date' ? moment(newData[keyField.fieldID]).format('YYYY-MM-DD').toString() : newData[keyField.fieldID]
      ).join(','), '-')
    }
    : { tableRowID: fieldIDGenerator() }

  const saveUpdatedRow = (updatedRowData) => {
    console.log('TableDataViewerTable updatedRowData', updatedRowData);
    const emptyRequiredField = getEmptyRequiredFieldsIfAny(updatedRowData)
    if (emptyRequiredField)
      return {
        status: 'error', columnID: emptyRequiredField.fieldID,
        message: `${emptyRequiredField.fieldName || emptyRequiredField.fieldLabel || 'Any required'} field must contain data`, action: 'continueEdit'
      }
    if (updatedRowData.tableRowID)
      handleSave(updatedRowData, 'update')
    else {
      const updateRowWithTableRowID = { ...updatedRowData, ...getTableRowID(updatedRowData) }
      handleSave(updateRowWithTableRowID, 'update')
      return { status: 'success', updateRowData: updateRowWithTableRowID }
    }
    return { status: 'success' }
  }
  const rowData = Object.values(tableData).filter(row => row)

  return <RenderTableWithOptions columns={columns} rowData={rowData} limit={limit}
    options={{
      editable: !(options && options.editable === 'false'),
      columnWithRowID: 'tableRowID',
      singleClickEdit: true,
      settings: options.settings,
      hideExport: options.hideExport,
      noToolbar: options.noToolbar,
      noHeader: options.noHeader,
      addRow: options.addRow
    }}
    requiredFields={requiredFields}
    saveUpdatedRow={saveUpdatedRow}
    deleteRow={options.deleteRow ? rowToDelete => handleSave(rowToDelete, 'delete') : null}
  />

}
