import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import AlertSnackbar from './AlertSnackbar';

function ForceAppUpdate(props) {
  const [alertState, setAlertState] = React.useState({
    open: false, vertical: 'bottom', horizontal: 'center', message: '',
  });

  const buildID = localStorage.getItem('buildID') || null;

  console.log("ForceAppUpdate (0)", props.appSettings && props.appSettings.forceUpdateBuildID, Number(buildID), props.appSettings && props.appSettings.forceUpdateBuildID > Number(buildID));

  if (buildID && props.appSettings && props.appSettings.forceUpdateBuildID
    && Number(props.appSettings.forceUpdateBuildID) > Number(buildID) //True if current build older than force update build
    && !(window.location.hostname === 'localhost')
  ) {

    //If forceUpate was tried but did not work, wait for 15 minutes before trying again
    const forceUpdate = localStorage.getItem('forceUpdate') ? JSON.parse(localStorage.getItem('forceUpdate')) : null;

    console.log("ForceAppUpdate (1)")

    if (!forceUpdate || (forceUpdate && Math.abs(Date.now() - Number(forceUpdate.updateAttemptedAt)) > (10 * 60 * 1000))) {

      if (forceUpdate) { console.log("ForceAppUpdate (2)- reloading as sufficient time since last attempt") }

      localStorage.setItem('forceUpdate',
        JSON.stringify({ forceUpdateBuildID: props.appSettings.forceUpdateBuildID, updateAttemptedAt: Date.now() }));

      console.log("ForceAppUpdate (3) - reloading now ")
      setAlertState({ ...alertState, message: `Updating to the latest version`, open: true, alertTimeout: 1000 });

      const reloadPromise =
        navigator.serviceWorker.getRegistration()
          .then(registration => registration ? registration.unregister() : null)
          .then(() => window.location.reload(true))
          .catch(err => { console.error(err); window.location.reload(true) })

      return <AlertSnackbar alertState={alertState} setAlertState={setAlertState} />

    }
  }

  return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    appSettings: state.firestore.data.appsettings,
  }
}

export default compose(connect(mapStateToProps, null),
  firestoreConnect(props => {
    return [
      {
        collection: 'async', doc: "appsettings", storeAs: 'appsettings'
      }
    ]
  }
  ))(ForceAppUpdate)

