import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { fieldTypes } from '../../utils/appstructureconstants'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import HelpWidget from '../support/HelpWidget'

export default class UpdateProfile extends Component {

  state = {
    submitDisabled: true,
    alertState: {
      open: false, vertical: 'center', horizontal: 'center', message: ''
    },
    usersinvitedmatch: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const isUsersinvitedmatchFound = (nextProps.usersinvitedmatch && nextProps.usersinvitedmatch.length > prevState.usersinvitedmatch.length);

    //On first load or when usersinvitedmatch is found, update the state
    if (typeof prevState.orgID === 'undefined' || isUsersinvitedmatchFound) {
      console.log('Updating state in getDerivedStateFromProps', { prevState, nextProps });

      const newState = {
        ...prevState,
        orgID: (nextProps.profile.orgID && nextProps.profile.orgID.length > 0 && nextProps.profile.orgID) || (nextProps.usersinvitedmatch[0] && nextProps.usersinvitedmatch[0].orgID) || localStorage.getItem('newOrgID') || '',
        firstName: nextProps.profile.firstName || (nextProps.usersinvitedmatch[0] && nextProps.usersinvitedmatch[0].firstName) || '',
        lastName: nextProps.profile.lastName || (nextProps.usersinvitedmatch[0] && nextProps.usersinvitedmatch[0].lastName) || '',
        email: nextProps.auth.email || nextProps.profile.email || (nextProps.usersinvitedmatch[0] && nextProps.usersinvitedmatch[0].email) || '',
        phoneNumber: nextProps.auth.phoneNumber || nextProps.profile.phoneNumber || (nextProps.usersinvitedmatch[0] && nextProps.usersinvitedmatch[0].phoneNumber) || '',
        usersinvitedmatch: nextProps.usersinvitedmatch,
        submitDisabled: !isUsersinvitedmatchFound
      }
      return newState
    } else return prevState
  }


  handleSubmit = (e) => {
    let profile = {
      orgID: this.state.orgID,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.props.auth.email || this.state.email,
      phoneNumber: this.props.auth.phoneNumber || this.state.phoneNumber,
      uid: this.props.auth.uid,

    }

    //If new org ID has been created in this session, then set this user as the orgOwner and the user admin
    if (localStorage.getItem('newOrgID') === profile.orgID) {
      profile.orgOwner = 1;
      profile.useradmin = true;
    }

    //Clean up new org ID since profile has been updated
    if (localStorage.getItem('newOrgID') || localStorage.getItem('createID')) {
      localStorage.removeItem('newOrgID');
      localStorage.removeItem('createID');
    }

    //If user has been invited, pick up his settings from the invite
    if (this.props.usersinvitedmatch && this.props.usersinvitedmatch.length > 0) {
      profile = { ...this.props.usersinvitedmatch[0], ...profile }

      //Delete all invitations that match this email id
      this.props.usersinvitedmatch.forEach(invite => this.props.deleteInvitedUser(invite.inviteID))
    }

    console.log('handleSubmit', { profile });
    this.props.updateProfile(profile);
    e.preventDefault();
    this.setState({
      alertState: {
        ...this.state.alertState, open: true,
        variant: 'secondary', message: 'Profile updated successfully.'
      }
    })

  }
  handleChange = (e) => {
    console.log('handleChange', e.target.id, e.target.value);

    this.setState({ [e.target.id]: e.target.value }, () => {
      //If email is not the sign in method, the entered email should be either blank or valid
      let validEmail = this.props.auth.email || this.state.email ? new RegExp(fieldTypes.email.regularExpression).test(this.state.email) : true

      //Enable submit if all fields valid
      if (this.state.orgID.length > 2 && this.state.firstName.length > 1 && this.state.lastName.length > 0 && validEmail && this.state.submitDisabled === true) this.setState({ submitDisabled: false })

      //Disable submit if any field invalid
      if ((this.state.orgID.length < 3 || this.state.firstName.length < 2 || this.state.lastName.length < 1 || !validEmail) && this.state.submitDisabled === false) this.setState({ submitDisabled: true })
    })

  }


  render() {
    console.log('Update Profile state', this.state);
    return (
      // <div>
      <Card style={{ width: '100%' }}>
        <CssBaseline />
        {this.props.profile && this.props.auth &&
          <Grid container justify="center" >

            <Grid item xs={12} style={{ margin: 10, marginTop: 20 }}>
              <TextField type="text" label="Org ID" id="orgID" name="orgID"
                style={{ width: "95%", }} variant="outlined" onChange={this.handleChange}
                value={this.state.orgID} />

              {((!this.state.orgID)) && this.props.orgIDMessage}
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" label="First Name" id="firstName" name="firstName" style={{ width: "95%", margin: 10 }} variant="outlined" onChange={this.handleChange} value={this.state.firstName} />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" label="Last Name" id="lastName" name="lastName" style={{ width: "95%", margin: 10 }} variant="outlined" onChange={this.handleChange} value={this.state.lastName} />
            </Grid>
            <Grid item xs={12}>
              <TextField type="email" label="Email" id="email" name="email" style={{ width: "95%", margin: 10 }} variant="outlined" onChange={(e) => { if (!this.props.auth.email) this.handleChange(e) }} value={this.props.auth.email || this.props.profile.email} disabled={!!this.props.auth.email} />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" label="Phone Number" id="phoneNumber" name="phoneNumber" style={{ width: "95%", margin: 10 }} variant="outlined" onChange={(e) => { if (!this.props.auth.phoneNumber) this.handleChange(e) }} value={this.props.auth.phoneNumber || this.props.profile.phoneNumber} disabled={!!this.props.auth.phoneNumber} />
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center", }}>
              <Button color="primary" style={{ width: "85%", margin: 16, }} variant="outlined" onClick={this.props.signOut} >Sign Out
              <PowerSettingsNew
                  style={{ marginLeft: 10, justifyContent: 'center', alignItems: 'center', display: 'inline-flex', }} />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center", }} >
              <Button id='submitProfile' name='submitProfile' color="secondary" style={{ width: "85%", margin: 16, }} variant="contained" onClick={this.handleSubmit} disabled={this.state.submitDisabled}>Submit
                <CheckCircleOutline
                  style={{ marginLeft: 10, justifyContent: 'center', alignItems: 'center', display: 'inline-flex', }} />
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", marginBottom: 16 }}>
              <HelpWidget />
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              key={`${this.state.alertState.vertical},${this.state.alertState.horizontal}`}
              open={this.state.alertState.open}
              onClose={() => { this.setState({ alertState: { ...this.state.alertState, open: false } }) }}
              autoHideDuration={1200}
              message={this.state.alertState.message} />

            {/* {(this.props.authError && this.props.authError !== 'SIGNED_OUT') ? <p>{this.props.authError}</p> : null} */}

          </Grid>
        }
      </Card>
      // </Grid>
    )
  }
}
