import React from 'react'
import { displayLeft } from './styleclasses'
import { primary } from './themes'
import AddCircle from '@material-ui/icons/AddCircle';
import Send from '@material-ui/icons/Send';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Settings from '@material-ui/icons/Settings';

export const gmailIcon = (maxHeight) => <img src='https://firebasestorage.googleapis.com/v0/b/opstak-2019.appspot.com/o/0SYSTEM%2FASSETS%2FSTANDARD%2FICONS%2FGmail-icon.png?alt=media&token=a9ce752b-96e7-4875-8df6-0afe80d6908b' style={{ maxHeight }} alt='' />

export const driveIcon = (maxHeight) => <img src='https://firebasestorage.googleapis.com/v0/b/opstak-2019.appspot.com/o/0SYSTEM%2FASSETS%2FSTANDARD%2FICONS%2FDrive-icon.png?alt=media&token=1e430177-9806-412e-86af-ccff9c9e9151' style={{ maxHeight }} alt='' />

export const sheetsIcon = (maxHeight) => <img src='https://firebasestorage.googleapis.com/v0/b/opstak-2019.appspot.com/o/0SYSTEM%2FASSETS%2FSTANDARD%2FICONS%2FSheets-icon.png?alt=media&token=5de2c455-bfad-4080-8695-152297b71d19' style={{ maxHeight }} alt='' />

export const iconTextAlign = (icon, text, options) => <span style={{ display: 'inline-flex', ...displayLeft }}>
  <span style={{ marginRight: (options && options.gap) || 4, ...displayLeft, color: (options && options.color) || primary }}>{icon}</span>{text}</span>



export const textIconAlign = (text, icon, options) => <span style={{ display: 'inline-flex', ...displayLeft, marginRight: (options && options.gap) || 4, }}>
  {text}<span style={{ ...displayLeft, color: (options && options.color) || primary }}>{icon}</span></span>


export const commonIcons = {
  AddCircle: <AddCircle />,
  Send: <Send />,
  DeleteOutline: <DeleteOutline />,
  CheckCircleOutline: <CheckCircleOutline />,
  Settings: <Settings />
}