import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FindInPageTwoTone';
import { useDebounce } from '@react-hook/debounce';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { calculateExpression } from '../../../utils/calculateExpression';
import { getDateIfValidDate } from '../../../utils/datetimeutils';
import { getCamelCase } from '../../../utils/stringutils';
import { displayCenter, displayLeft } from '../../../utils/styleclasses';
import { isJSON } from '../../../utils/utilityfunctions';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';
import Loader from '../../standard/Loader';
import StandardComponentRenderer from '../../standard/StandardComponentRenderer';
import TableDataViewerTable from './TableDataViewerTable';
import moment from 'moment'

export default function TableFilterWrapper({ tableID, options, whereClause, limit }) {
  const lSFilter = isJSON(localStorage.getItem('tableFilters' + tableID))
  const [filters, setFilters] = useDebounce(lSFilter || {}, 300)

  const profile = useSelector(state => state.firebase.profile)
  useFirestoreConnect([{
    collection: 'organizations', doc: profile.orgID, subcollections: [{ collection: 'resources-tables', doc: tableID, }],
    storeAs: 'table' + tableID
  },])
  const tableStructure = useSelector(({ firestore: { data } }) => data['table' + tableID])
  const maxRowsInTableRender = useSelector(({ firestore: { data: { orgSettings } } }) => orgSettings && orgSettings.advancedSettings && orgSettings.advancedSettings.maxRowsInTableRender)

  if (typeof tableStructure === 'undefined') return <Loader />
  if (tableStructure === null) return <EmptyImagePlaceholder />

  if (whereClause || (options && options.noFilter))
    return <TableDataViewerTable {...{ whereClause, tableID, tableStructure, options, }} limit={maxRowsInTableRender} />

  let optionsWithCalculatedExpressions = { ...options }
  if (options && options.editableFieldExpressions && tableStructure) {
    const editableFieldsFromExpressions = isJSON(calculateExpression(tableStructure.fields, options.editableFieldExpressions, null, profile));
    optionsWithCalculatedExpressions.editableFields =
      [...options.editableFields ? options.editableFields : [], ...Array.isArray(editableFieldsFromExpressions) ? editableFieldsFromExpressions : []]
  }

  const handleFilterChange = (updatedFilter, isDelete) => {
    let filterToSave
    const filterArray = Object.values(filters);
    const inequalityFilter = filterArray.find(filter => filter.filterType && filter.filterType !== 'equals')

    if (isDelete === 'delete')
      filterToSave = Object.assign({}, ...filterArray.filter(f => !f.filterColumnID.includes(updatedFilter.filterColumnID)).map(f => ({ [f.filterColumnID]: f })))
    else {
      if (inequalityFilter)
        filterToSave = {//Retaining all Filter__ filters, and removing the rest
          ...updatedFilter.filterColumnID.includes('Filter__') ?
            Object.assign({}, ...filterArray.filter(f => f.filterColumnID.includes('Filter__')).map(row => ({ [row.filterColumnID]: row }))) : {},
        }
      else filterToSave = { ...filters }
      filterToSave[updatedFilter.filterColumnID] = { ...filters[updatedFilter.filterColumnID], ...updatedFilter }
    }
    localStorage.setItem('tableFilters' + tableID, JSON.stringify(filterToSave));
    setFilters(filterToSave)
  }

  return <div style={{ maxWidth: window.innerWidth - 16 }}>
    <div style={{ marginBottom: 8 }}> {FilterHeader()}</div>
    {tableID && tableStructure &&
      <TableDataViewerTable tableID={tableID} options={optionsWithCalculatedExpressions || { showRowID: true }} tableStructure={tableStructure}
        whereClause={getWhereClause(Object.values(filters))} limit={maxRowsInTableRender} />}
  </div>;

  function FilterHeader() {
    const filterArray = Object.values(filters)
    const inequalityFilter = filterArray.find(filter => filter.filterType && filter.filterType !== 'equals')

    if (filterArray.length === 0) return <FilterComponent displayFields={tableStructure.fields} handleFilterChange={handleFilterChange} filter={{}} showInequality={true} />

    return filterArray.map(filter =>
      <FilterComponent key={filter.filterColumnID} displayFields={tableStructure.fields} handleFilterChange={handleFilterChange} filter={filter} showInequality={!inequalityFilter || inequalityFilter.filterColumnID === filter.filterColumnID} />)

  }
}

function getWhereClause(filters) {
  let whereClause = []
  if (filters && filters.length > 0)
    filters.forEach(filter => {
      let filterValue1 = filter.filterValue1
      let filterValue2 = filter.filterValue2
      if (filter.filterColumnType && (filter.filterColumnType.includes('date') || filter.filterColumnType.includes('time'))) {
        filterValue1 = getDateIfValidDate(filterValue1)
        filterValue2 = getDateIfValidDate(filterValue2)
        if (filter.filterColumnType.includes('date')) {
          filterValue1 = filterValue1 && moment(filterValue1).startOf('day').toDate();
          filterValue2 = filterValue2 && moment(filterValue2).endOf('day').toDate();
        }
      }
      if (Array.isArray(filterValue1)) filterValue1 = filterValue1[0]
      if (Array.isArray(filterValue2)) filterValue2 = filterValue2[0]
      if (filterValue1) {
        switch (filter.filterType) {
          case 'equals': whereClause.push([filter.filterColumnID, '==', filterValue1]); break;
          case '>=': whereClause.push([filter.filterColumnID, '>=', filterValue1]); break;
          case '<=': whereClause.push([filter.filterColumnID, '<=', filterValue1]); break;
          case 'between': if (filterValue2) {
            whereClause.push([filter.filterColumnID, '>=', filterValue1]);
            whereClause.push([filter.filterColumnID, '<=', filterValue2]);
          } break;
          default: console.log('TableFilterWrapper FilterType does not match');
        }
      }
    })
  whereClause = whereClause.sort((a, b) => {
    if (a[0].includes('Filter__') && !b[0].includes('Filter__')) return -1
    if (a[0].includes('Filter__') && b[0].includes('Filter__') && a[0] < b[0]) return -1
    return 0
  })
  return whereClause.length > 0 ? whereClause : null
}


function FilterComponent({ displayFields, handleFilterChange, filter, showInequality }) {
  //To update saved filter.field if tablestructure changes
  return <Grid container style={{ ...displayLeft, paddingLeft: 8 }}>
    <Grid item sm={12} md={5} style={displayLeft}>
      <FilterIcon color='primary' style={{ marginRight: 16 }} />
      <Select
        style={{ width: '100%', marginRight: 4 }}
        displayEmpty value={filter.filterColumnID || ''}
        onChange={e => {
          if (e.target.value === 'NoFilter')
            handleFilterChange({ filterColumnID: filter.filterColumnID, }, 'delete')
          else {
            let field = displayFields.find(field => field.fieldID === e.target.value);
            const filterColumnType = field && field.fieldType;
            handleFilterChange({ filterColumnID: e.target.value, filterColumnType, field });
          }
        }}>
        {filter.filterColumnID && <MenuItem value='NoFilter'>Clear Filter</MenuItem>}
        {displayFields.filter(field => !["camera", "fieldSet", "tableLookup",].includes(field.fieldType))
          .map(field => <MenuItem key={field.fieldID} value={field.fieldID}>
            <span style={displayLeft}>
              {field.fieldName}
              {field.fieldID.includes('Filter__') &&
                <Typography style={{ marginLeft: 8, }} variant='caption' color='primary'>
                  Group Filter
                </Typography>
              }
            </span>
          </MenuItem>)}
      </Select>
    </Grid>
    <Grid item sm={12} md={7} style={{ ...displayLeft }}>
      {filter.filterColumnID &&
        <Select style={{ ...displayCenter, width: 150 }} displayEmpty
          value={filter.filterType ? filter.filterType : ''}
          onChange={(e) => { handleFilterChange({ ...filter, filterType: e.target.value }); }}>
          {['equals', ...showInequality ? ['between', '>=', '<='] : []]
            .map(filterType => <MenuItem style={displayCenter} key={filterType} value={getCamelCase(filterType)}>{filterType}</MenuItem>)}
        </Select>}
      <div style={{ ...displayLeft, width: '100%' }}>
        {filter.field &&
          <StandardComponentRenderer field={{ ...filter.field, fieldValue: filter.filterValue1 || '' }}
            handleChange={(fieldID, value) => handleFilterChange({ ...filter, filterValue1: value })} />}
        {filter.field && filter.filterType === 'between' && <Typography style={{ marginRight: 16, marginLeft: 16 }}>and</Typography>}
        {filter.field && filter.filterType === 'between' &&
          <StandardComponentRenderer field={{ ...filter.field, fieldValue: filter.filterValue2 || '' }} handleChange={(fieldID, value) => handleFilterChange({ ...filter, filterValue2: value })} />}
      </div>
    </Grid>
  </Grid>;
}