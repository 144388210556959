import React from 'react'
import InputCamera from './InputCamera'
import CameraComponent from './CameraComponentClass'
import { saveToDBAndUploadImages } from '../../utils/firebaseUtil'

export default function UnifiedCameraComponent(
  { cameraToUse, fieldID, allowUpload, frontCamera, focusBorder, orgID, handleChange, imageFileName, uploadDescription, style, uploadToStorage, imageURI, name }) {

  //Save image uri as state here
  //display image here
  const cameraProps = {
    fieldID: fieldID,
    key: fieldID + 'camera',
    imageURI: imageURI || null,
    allowUpload: allowUpload ? true : false,
    facingModes: frontCamera ? "user" : "environment", orgID: orgID,
    focusBorder: focusBorder || false,
    handleChange: (id, image) => handleChange(id, image,
      (uploadDescription && imageFileName) ? saveToDBAndUploadImages(image, imageFileName, orgID, uploadDescription) : null),
    uploadToStorage: uploadToStorage,
    name
  }



  return (
    <div style={style} >
      {
        /* Device Camera Component - Default for device memory >=4GB */
        (((!cameraToUse || cameraToUse === 'device') && (navigator.deviceMemory && navigator.deviceMemory >= 4) && !focusBorder)
          && !(navigator.platform.toUpperCase().indexOf('MAC') >= 0
            || navigator.platform.toUpperCase().indexOf('WIN') >= 0)) ?
          <InputCamera {...cameraProps} />
          :
          <CameraComponent {...cameraProps} />
      }
    </div>
  )
}
