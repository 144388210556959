import React, { useState } from 'react'
import Loader from '../../standard/Loader'
import Card from '@material-ui/core/Card';
import moment from 'moment-timezone'
import parse from 'html-react-parser';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Typography from '@material-ui/core/Typography'
import { displayLeft, divLightCard, imageDisplayDiv } from '../../../utils/styleclasses'
import ImageOverlay from '../../standard/ImageOverlay'
import Slider from '@material-ui/core/Slider';
import { Switch, TextField } from '@material-ui/core'
import { GoogleMapEmbed } from '../../standard/MapPopover';
import Fuse from 'fuse.js'
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { getDisplayTimeZone } from '../../../utils/datetimeutils';

const GalleryContent = (props) => {
  const lSGalleryContentSettings = localStorage.getItem('galleryContentSettings')
  const [galleryContentSettings, setGalleryContentSettings] =
    useState({
      contentHeight: 50,
      showHighResolution: false,
      showActivityDetails: false,
      showLocation: false,
      ...(lSGalleryContentSettings ? JSON.parse(lSGalleryContentSettings) : null)
    })
  const { contentHeight, showHighResolution, showActivityDetails, showLocation } = galleryContentSettings;
  const timeZone = useSelector(({ firestore: { data: { orgSettings } } }) => orgSettings && orgSettings.advancedSettings && orgSettings.advancedSettings.timeZone)
  const displayTimeZone = getDisplayTimeZone(timeZone)

  const handleChange = (setting) => {
    const newSetting = { ...galleryContentSettings, ...setting };
    setGalleryContentSettings(newSetting)
    localStorage.setItem('galleryContentSettings', JSON.stringify(newSetting))
  }

  if (typeof props.activityQueryResults === 'undefined') return <Loader />

  const fuseSearch = new Fuse(props.activityQueryResults, { keys: ['activityContent', 'activityByName'] });
  let results
  if (galleryContentSettings.searchTerm)
    results = fuseSearch.search(galleryContentSettings.searchTerm || '').map(result => result.item)
  else
    results = props.activityQueryResults


  return (
    <div style={{ textAlign: "center", justifyContent: 'center', alignItems: 'center' }}>
      <GallerySettings {...galleryContentSettings} handleChange={handleChange} />
      {results.map((activity, index) =>
        <span key={'activity' + index}>
          {activity.activityImages && activity.activityImages.length > 0 && <span>
            {showLocation && <LocationCard{...{ activity, showActivityDetails, contentHeight }} />}
            {
              activity.activityImages
                .filter(field => (props.fieldID === 'all' || props.fieldID === field.fieldID))
                .map(image =>
                  <div key={image.fieldID} style={{ display: "inline-block", margin: 8, }}>
                    {showActivityDetails && <ImageHeader {...activity} displayTimeZone={displayTimeZone} />}
                    <ImageOverlay
                      imageLink={showHighResolution ? image.fullURL : image.compressedURL}
                      imageLocation={image.imageLocation}
                      activityImage={image}
                      imageStyle={{ margin: 0, height: (window.innerHeight * contentHeight / 100), ...imageDisplayDiv }}
                      zoomLink={image.fullURL}
                      textOverlay={null}
                      options={{ showImageTags: true, }}
                      fieldID={image.fieldID}
                      tagOptionListID={image.fieldTagOptionListID}
                      activityID={activity.activityID} />
                  </div>
                )}
          </span>}
        </span>
      )
      }
      {
        !props.activityQueryResults &&
        <Typography color="primary" variant="h5" style={{ margin: 16 }}> No Results Found For Selection</Typography>
      }
    </div >
  )
}

export default GalleryContent

function LocationCard({ activity, showActivityDetails, contentHeight }) {
  return <Card style={{ display: "inline-block", borderRadius: "8px", margin: 8, }}>
    {showActivityDetails && <ImageHeader {...activity} />}
    <GoogleMapEmbed mapLink={activity.activityMapLink} style={{ width: '300px', display: 'block', height: `${contentHeight}vh` }} />
  </Card>
}

function GallerySettings({ searchTerm, contentHeight, showActivityDetails, showHighResolution, showLocation, handleChange }) {
  return <span style={{ textAlign: "center", justifyContent: 'center', alignItems: 'center', display: "flex", ...divLightCard, margin: 16 }}>
    <Typography style={{ marginLeft: 16, marginRight: 16 }}>Search</Typography>
    <TextField type="text" style={{ maxWidth: 600 }}
      onChange={e => handleChange({ searchTerm: e.target.value })} value={searchTerm} />
    <CloseIcon onClick={e => handleChange({ searchTerm: null })} />
    <Typography style={{ marginLeft: 16 }}>Image Zoom</Typography>
    <ZoomIn style={{ marginLeft: 16, marginRight: 16 }} />
    <Slider value={contentHeight} onChange={(e, newValue) => handleChange({ contentHeight: newValue })} aria-labelledby="continuous-slider" color="primary" style={{ width: 300, }} min={20} />
    <ZoomOut style={{ marginLeft: 16, marginRight: 16 }} />
    <Typography style={{ marginLeft: 16, }}>High Resolution Images</Typography>
    <Switch checked={showHighResolution} value={showHighResolution}
      onChange={(e) => handleChange({ showHighResolution: e.target.checked })} color="secondary" />
    <Typography style={{ marginLeft: 16 }}>Show Locations</Typography>
    <Switch checked={showLocation} value={showLocation}
      onChange={(e) => handleChange({ showLocation: e.target.checked })} color="secondary" />
    <Typography style={{ marginLeft: 16 }}>Show Activity Details</Typography>
    <Switch checked={showActivityDetails} value={showActivityDetails}
      onChange={(e) => handleChange({ showActivityDetails: e.target.checked })} color="secondary" />
  </span>
}

function ImageHeader({ activityByName, activityReceivedTime, activityContent, activityMapLink, displayTimeZone }) {
  return <div style={displayLeft}>
    <strong style={{ fontSize: 12, paddingLeft: 8 }}>
      {'By ' + activityByName + ' ' + (activityReceivedTime ? moment(activityReceivedTime.toDate()).tz(displayTimeZone).format('h:mm A on DD-MMM') : '')}
    </strong>
    <Tooltip leaveTouchDelay={1500} title={<div style={{ fontSize: 14 }}>{parse(activityContent)}</div>}>
      <Info style={{ verticalAlign: 'middle', fontSize: '20px', margin: 5 }} />
    </Tooltip>
  </div>
}
