import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import parse from 'html-react-parser';
import ActivityCardFooter from './ActivityCardFooter';
import { Typography } from '@material-ui/core';
import ImageScrollerGridList from '../standard/ImageScrollerGridList';


export default function ActivityCard(props) {
  // const [currentImage, setCurrentImage] = React.useState(0);

  const activity = props.activity;
  const activityTitle = <span><Typography style={{ fontWeight: "bold" }}>{`${activity.activityTitle}`}</Typography>
    <Typography variant="caption">
      {activity.activityVerb === 'tagged'
        ? 'was tagged'
        : activity.activityVerb === 'createdTask'
          ? `Task created by ${activity.activityByName}`
          : `App submitted by ${activity.activityByName}`}&nbsp;
    {`${activity.activityReceivedTime && moment(activity.activityReceivedTime.toDate()).calendar(null, {
            sameDay: '[today at]  HH:mm A',
            nextDay: '[tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[yesterday at] HH:mm A',
            lastWeek: '[Last] dddd [at] HH:mm A',
            sameElse: 'DD MMM YYYY [at] HH:mm A'
          })}`}</Typography></span>

  // console.log(`ActivityCardProps${props.activityID}`, props.activity)


  return (
    <div key={props.activityID} className="slimscroll"
      style=
      {{ margin: props.cardMargin, marginLeft: 0, marginRight: 0, maxWidth: '100vw', }}>
      <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: '#D3D3D3', margin: 4, borderRadius: 4 }}>
        <Accordion style={{ margin: 0, marginBottom: props.summary ? 8 : 0, boxShadow: 'none' }} >
          <AccordionSummary
            style={{ paddingLeft: 16, margin: 0, boxShadow: 'none' }}
            IconButtonProps={{ style: { margin: 0, padding: 0 } }}
            expandIcon={<ExpandMoreIcon />} >
            {activityTitle}
          </AccordionSummary>
          <AccordionDetails style={{ margin: 0, padding: 0, boxShadow: 'none' }}>
            <div style={{ width: "100%", margin: 0, display: "block" }}>
              {(activity.activityImages && props.summary && activity.activityImages.length > 0) &&
                <ImageScrollerGridList images={activity.activityImages}
                  activityID={activity.activityID}
                  location={activity.activityMapLink} fullImages={props.fullImages ? true : false}
                  height={100} />}
              <div style={{ paddingLeft: 8, paddingBottom: 8, width: "100%", margin: 0, display: "block" }}>
                {
                  (typeof activity.activityContent === 'string') ?

                    getParsedText(activity.activityContent)
                    : " "
                }
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {(activity.activityImages && !props.summary && activity.activityImages.length > 0) &&
          <ImageScrollerGridList activityID={activity.activityID}
            hqImage={props.hqImage} tagList={activity.tagList || null} submissionID={activity.submissionID || null}
            images={activity.activityImages} location={activity.activityMapLink} height={300}
          />}
        {!props.summary && !props.hideFooter &&
          <ActivityCardFooter activity={activity} activityID={props.activityID} footerOptions={props.footerOptions} uid={props.uid} />
        }
      </div>
    </div >
  )
}

function getParsedText(text) {
  try {
    return parse(text);
  } catch (error) {
    console.error(error)
    return text.replace('<strong>', '').replace('</strong>', '').replace('<', '').replace('>', '')
  }
}

