import React from 'react'
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Logo from '../img/Logo.png'


export default function LogoAppBar(props) {
  const styleProps = props.style ? props.style : null;
  return (
    <AppBar position="sticky" style={{ background: "linear-gradient(90deg, #683fb5, #b87df9)", ...styleProps }}>
      <Toolbar><img src={Logo} alt="Opstack" style={{ height: 40, color: "white" }} /></Toolbar>
    </AppBar>
  )
}
