import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Save from '@material-ui/icons/Save'
import Share from '@material-ui/icons/Share'
import { Rating } from '@material-ui/lab'
import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { addNote } from '../../store/actions/formActions'
import { displayLeft, divLightCard } from '../../utils/styleclasses'
import UserSearchSelector from '../standard/UserSearchSelector'

const NoteList = ({ addNote, activityID, taskID, noteList, activity }) => {
  const [note, setNote] = useState({ noteText: "" })
  // const profile = useSelector(state => state.firebase.profile)
  // useFirestoreConnect([{ collection: 'users', where: [['orgID', '==', profile.orgID || 'NoOrg'],], storeAs: 'orgSettings' },])
  const orgSettings = useSelector(({ firestore: { ordered: { orgSettings } } }) => orgSettings && orgSettings[0])
  const ratingsEnabled = orgSettings && orgSettings.advancedSettings && orgSettings.advancedSettings.ratings
  const saveNote = () => {
    if ((note.noteText && note.noteText.length > 0 && (new RegExp(/^(?=.*\S).*/).test(note.noteText))) || note.rating) { addNote({ activityID, taskID, note }); setNote({ ...note, noteText: "", rating: null }); }
  }
  console.log('NoteList', noteList);
  return (
    <div style={{ margin: 0, }}>
      { activity && <UserSearchSelector
        id={'ccList' + activityID}
        selectedValues={note.ccList || activity.ccList} style={{ width: '100%' }}
        quickEdit={true} inputLabel={<Typography style={{ marginRight: 16 }} color="primary">CC</Typography>}
        handleSave={(userList) => setNote({ ...note, ccList: userList })} keepFirstValue={false} />}
      <TextField type="noteText" label="Add Note" id="note" style={{ marginTop: 8, marginBottom: 16, marginLeft: 8, width: "100%", }}
        onChange={e => setNote({ ...note, noteText: e.target.value })} value={note.noteText} multiline
        onKeyUp={(event) => (event.ctrlKey && event.key == 'Enter') && saveNote()}
      />
      {ratingsEnabled && <Rating value={note.rating} size='large' onChange={(event, newValue) => setNote({ ...note, rating: newValue })} />}
      {(note.noteText || note.rating) && <div style={{ width: "100%", textAlign: "right" }}
        onClick={() => saveNote()}>
        <Chip style={{ margin: 8, marginTop: 0 }} color="secondary" clickable
          label={<span style={displayLeft}>  <Save style={{ marginRight: 10 }} />Save </span>}>
        </Chip>
        <Chip variant={"outlined"} style={{ margin: 8, marginTop: 0 }} color="secondary"
          label={<span style={displayLeft}>  <Share style={{ marginRight: 10 }} />Share</span>}>
        </Chip>
      </div>}
      {
        (noteList &&
          noteList.length > 0) ?
          noteList.map((note, index) => {
            return <div key={note.id} style={{ padding: 16, marginBottom: 16, ...divLightCard, }}>
              <Typography style={{ marginRight: 16 }}>{`${note.noteByName} ${note.submittedAt ? note.submittedAt.toDate().toLocaleString() : null}`}</Typography>
              {note.noteText && <Typography >{note.noteText}</Typography>}
              {ratingsEnabled && note.rating && <Rating value={note.rating} size='large' style={{ marginTop: 8 }} readOnly />}
            </div>
          })
          : (noteList && noteList.length === 0) ?
            null
            : null
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNote: ({ activityID, taskID, note }) => { dispatch(addNote({ activityID, taskID, note })) },
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log('NoteList mapStateToProps', state);

  return {
    activity: state.firestore.data && state.firestore.data['activity' + ownProps.activityID],
    noteList: state.firestore.ordered && state.firestore.ordered['notes' + ownProps.activityID],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{
          collection: 'activityfeed', doc: props.activityID,
          subcollections: [{ collection: 'notes' }]
        }],
        ...props.rowLimit ? { limit: props.rowLimit } : {},
        orderBy: ['submittedAt', 'desc'],
        storeAs: 'notes' + props.activityID
      },
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{
          collection: 'activityfeed', doc: props.activityID,
        }],
        storeAs: 'activity' + props.activityID
      }
    ]
  }
  )
)(NoteList)