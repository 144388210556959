import { getFirestore } from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'
import localForage from "localforage";
import { imageResizer } from './imageResizer'
import { dataURItoBlob } from './utilityfunctions';

export function getFirestoreDocumentFromReference(documentReference) {
  const firestore = getFirestore();
  return firestore.doc(documentReference).get().then(doc => doc.data())
    .catch(err => console.error(err))
}

export function ifOnlineAttemptPendingUploads() {
  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  const orgID = localStorage.getItem("orgID");

  if (navigator.onLine && orgID && orgID !== 'undefined') {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const root = `organizations/${orgID}`;

    return Promise.all([

      store.length().then((countOfEntriesInStore) => countOfEntriesInStore > 0 && uploadPendingSubmissionsAndMoveToSubmissionsCollection(store, firebase, firestore, root)),

      storeHD.length().then((countOfEntriesInStore) => {
        //Deletes HD Store if number of image entries is greater than 50
        if (countOfEntriesInStore > 50) { storeHD.clear() }
        else if (countOfEntriesInStore > 0) {
          uploadPendingSubmissionsAndMoveToSubmissionsCollection(storeHD, firebase, firestore, root)
        }
      })
    ])
  }
  else { console.log('Internet is offline'); }
}

function uploadPendingSubmissionsAndMoveToSubmissionsCollection(store, firebase, firestore, root) {
  return store.length().then((numberOfEntriesInIndexedDB) => {

    let imagePromiseArray = [];
    if (numberOfEntriesInIndexedDB > 0) {
      store.iterate((value, key, iterationNumber) => {

        let ID = value.uploadDescription.submissionID;
        let storageRef = firebase.storage().ref().child(key);

        console.log('uploadPendingSubmissionsAndMoveToSubmissionsCollection typeof value.image', typeof value.image, 'submissionID', ID);
        // To debug Non-Error exception captured with keys: code_, message_, name_, serverResponse_

        let uploadFunction = (typeof value.image !== 'string') ?
          storageRef.put(value.image, {
            contentType: 'image/jpeg',
          }) : storageRef.putString(value.image, 'data_url', { contentType: 'image/jpeg' })

        imagePromiseArray.push(
          uploadFunction
            .then(uploadTaskSnapshot => {
              return store.removeItem(key)
                .then(() => {
                  // console.log('Uploaded file and Deleted Indexed DB', key);
                  let submissionIDsAfterUpload = [];
                  store.iterate((value) => { submissionIDsAfterUpload.push(value.uploadDescription.submissionID) })
                    .then(() => {
                      //After every upload, iterate through store to check if the ID just uploaded exists. If same ID does not exist in store, transfer from pending to submissions
                      if (!submissionIDsAfterUpload.find(element => element === ID)) {
                        return firestore.collection(`${root}/pending-submissions`).doc(ID).get()
                          .then(doc => {
                            console.log('Submission uploaded', doc);
                            const submission = doc.data();
                            if (submission && submission.submissionID) { return firestore.collection(`${root}/submissions`).doc(submission.submissionID).set(submission, { merge: true }).then(firestore.collection(`${root}/pending-submissions`).doc(ID).delete()) }
                          })
                      }
                    })
                })
            })
            .catch(err => { if (err.code && err.code.includes && err.code.includes("invalid-argument")) { store.removeItem(key) } else console.error(err) })
        )
      })
        .catch((err) => { console.error(err); });
      return Promise.all(imagePromiseArray)
    }
  })
}


export function ifDBisEmptyMovePendingSubmissionsToCompleted(firestore, root, uid) {
  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });

  return store.length().then((numberOfEntriesInIndexedDB) => {
    return firestore.collection(`organizations/${localStorage.getItem("orgID")}/pending-submissions`)
      .where('submittedByUID', '==', uid).get()
      .then((querySnapshot) => {
        // console.log(('submittedByUID', '==', uid, querySnapshot))
        if (numberOfEntriesInIndexedDB === 0 && querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            const submission = doc.data();
            return firestore.collection(`${root}/submissions`)
              .doc(submission.submissionID).set({ ...submission })
              .then(() =>
                firestore.collection(`${root}/pending-submissions`).doc(submission.submissionID).delete())

            //Move pending submissions to submissions  
          })
        }
        if (numberOfEntriesInIndexedDB > 0 && querySnapshot.size === 0) {
          return store.clear()
        }
      })
  })
}

export const getImageUploadPath = (orgID, imageFileName) => {
  let todaysDate = new Date();
  return `${orgID}/${todaysDate.getFullYear()}/${todaysDate.getMonth() + 1}/${todaysDate.getDate()}/${imageFileName}.jpg`
}

//This is used by UnifiedCameraComponent - 09/07
export function saveToDBAndUploadImages(image, imageFileName, orgID, uploadDescription) {
  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  const firebase = getFirebase();
  let imagesPath = getImageUploadPath(orgID, imageFileName);
  const uploadImage = typeof image === 'string' ? dataURItoBlob(image) : image
  console.log('saveToDBAndUploadImages', typeof image, { imagesPath });

  return Promise.all(
    [uploadImageIfUploadFailsSaveToIndexedDB(uploadImage, store, imagesPath, 0.02, uploadDescription, firebase)
      , uploadImageIfUploadFailsSaveToIndexedDB(uploadImage, storeHD, imagesPath, 0.2, uploadDescription, firebase)])

}

const uploadImageIfUploadFailsSaveToIndexedDB = (image, store, imagesPath, maxSize, uploadDescription, firebase) => {
  return imageResizer(image, maxSize)
    .then(image => new Response(image).arrayBuffer())
    .then(image => {
      console.log(typeof image, 'TYPEOF IMAGE');
      store.setItem(imagesPath, { uploadDescription, image: image, });
      let storageRef = firebase.storage().ref().child(imagesPath);

      return storageRef.put(image, { contentType: 'image/jpeg', })
        .then(uploadTaskSnapshot => {
          console.log(uploadTaskSnapshot, { maxSize }, 'uploadImageIfUploadFailsSaveToIndexedDB Image Uploaded');
          return store.removeItem(imagesPath)
            .catch(err => { if (err.code && err.code.includes && err.code.includes("invalid-argument")) { store.removeItem(imagesPath) } else console.error(err) })
        })
    })
    .catch(err => {
      console.error({ err })
    })
}

//This is used by UserCamera Component and SelectedApp for UserCamera Only
export function saveForUpload(upload, orgID, uid) {
  let todaysDate = new Date();
  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  let imagesPath = `${orgID}/${todaysDate.getFullYear()}/${todaysDate.getMonth() + 1}/formSubmissions-uid-` + uid + '-fid-' + upload.appID + '-sid-' + upload.submissionID + '-ffid-' + upload.fieldID + '.jpg'

  return imageResizer(upload.image, 0.02)
    .then(image => new Response(image).arrayBuffer())

    .then(image => {
      store.removeItem(imagesPath)
      store.setItem(imagesPath, { ...upload, image: image, });
      return imageResizer(upload.image, 0.2)
        .then(image => new Response(image).arrayBuffer())
        .then(imageHD => {
          storeHD.removeItem(imagesPath);
          storeHD.setItem(imagesPath, { ...upload, image: imageHD });
        })
    })
}

//This is used by FaceUpload.js FirebaseImage Uploader
export function allImageUpload(image, path, maxSize) {
  const firebase = getFirebase();
  let storageRef = firebase.storage().ref().child(path);

  return imageResizer(image, maxSize)
    .then(imageHD => {
      let uploadFunctionStore = (typeof imageHD !== 'string') ?
        storageRef.put(imageHD, { contentType: 'image/jpeg', }) : storageRef.putString(imageHD, 'data_url', { contentType: 'image/jpeg' })
      return uploadFunctionStore
        .then(uploadTaskSnapshot => { console.log(uploadTaskSnapshot, 'Regular Image Uploaded'); return storageRef.getDownloadURL() })
        .catch(err => { console.error(err) })
    })
}

export function convertToTextReplace(text, replaceWith) {
  return String(text).replace(/[^A-Za-z0-9]/gi, replaceWith)
}

export const makeUserAppsFirestoreQuery = props => {

  let groupList = props.profile.groupList
  let groupList1
  if (groupList.length >= 10) {
    groupList1 = groupList.slice(9, groupList.length)
    groupList = groupList.slice(0, 9)
  }
  let queryArray = [
    {
      collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'apps' }],
      where: [
        ['groupList', 'array-contains-any', [...groupList, "all"]]
      ], orderBy: ['appName', 'asc'], storeAs: 'userApps'
    }
  ]
  if (groupList1)
    queryArray.push({
      collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'apps' }],
      where: [
        ['groupList', 'array-contains-any', [...groupList1]]
      ], orderBy: ['appName', 'asc'], storeAs: 'userApps1'
    })
  return queryArray
}