import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import { divLightCard } from '../../utils/styleclasses';
import { initializeFCM } from '../../pushFCM';
import { Chip } from '@material-ui/core';

export default function NotificationPermission({ profile, sendFCMToken }) {
  const [notificationStatus, setNotificationStatus] = useState();
  console.log('NotificationPermission', { notificationStatus });
  const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  const tokenWasUpdatedInLastHour = (profile.FCMTokenUpdateTime && profile.FCMTokenUpdateTime.toDate && new Date() - profile.FCMTokenUpdateTime.toDate() < 3600000);

  React.useEffect(() => {
    if ((
      (!notificationStatus && profile.FCMToken) || (notificationStatus === 'checkForNotifications' && !profile.FCMToken))
      && !isIos && !tokenWasUpdatedInLastHour)
      initializeFCM({ profile, sendFCMToken, setNotificationStatus })
  }, [notificationStatus])


  if (!profile.FCMToken && notificationStatus !== 'checkForNotifications')
    return <div style={{ ...divLightCard, margin: 8, padding: 16, display: 'inline-flex' }}>
      <Typography>Enable notifications to receive updates on notes & tasks.</Typography>
      <Chip label='Enable Now' color='primary' onClick={() => setNotificationStatus('checkForNotifications')} />
    </div>

  return !isIos && notificationStatus === 'denied' ?
    <div style={{ ...divLightCard, margin: 8, padding: 16, display: 'inline-flex' }}>
      <Typography>Enable notifications in your browser settings to receive updates on notes & tasks.
      <a href={'https://storage.googleapis.com/opstak-2019.appspot.com/0SYSTEM/ASSETS/STANDARD/EnableNotifications.mp4'} target="_blank" rel="noopener noreferrer">
          Click here to learn how.
      </a>
      </Typography>
    </div>
    : null
}
