import React from 'react';
import { divLightCard, displayLeft } from '../../utils/styleclasses';
import Error from '@material-ui/icons/Error';
import { error } from '../../utils/themes';

export default function AlertCard({ message, fontSize, style, noIcon }) {
  return <div style={{
    ...divLightCard, margin: 8, padding: 16, display: 'inline-flex', borderColor: error,
    ...displayLeft, alignItems: 'start', verticalAlign: 'top', ...style
  }}>
    {!noIcon && <Error style={{ fontSize: fontSize || 36, marginRight: 16, color: error }} />}
    <div style={{ fontSize: '20' }}>{message}</div >
  </div>;
}