import React, { PureComponent } from 'react';
import { Typography } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, Tooltip, } from 'recharts';

export default class BarChartComponent extends PureComponent {

  render() {
    const name1Label = this.props.name1Label;

    let maxLength = this.props.data && this.props.data.length > 0 ?
      this.props.data.reduce(function (a, b) { return a[name1Label] && b[name1Label] && (a[name1Label].length > b[name1Label].length) ? a : b; }) : 10
    maxLength = maxLength[name1Label] ? maxLength[name1Label].length : 10

    return (
      this.props.data && this.props.data.length === 0 ? <Typography variant="h6" style={{ textAlign: "center", flex: 1, margin: 16 }}>No Data</Typography> :
        <BarChart
          key={'BarChart' + JSON.stringify(this.props.layout)}
          width={this.props.width}
          height={300 + maxLength * 8}
          data={this.props.data}
          minWidth={200}
          minHeight={200}
          margin={
            this.props.isSmallScreen ? { top: 0, right: 5, left: 0, bottom: 0 }
              : { top: 30, right: 40, left: 20, bottom: 5, }
          }>
          <XAxis
            dataKey={name1Label} angle={-75} tickLine={false} interval={0}
            height={maxLength * 9} tick={this.props.isSmallScreen ? { fontSize: 9 } : { fontSize: 12 }}
            textAnchor="end">
          </XAxis>
          <YAxis tickLine={false}> </YAxis>
          <Tooltip />
          {/* <Legend /> */}
          {/**Map for multiple bars */}
          <Bar dataKey={this.props.value1Label} fill={this.props.color || "#8884d8"} />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}

        </BarChart>
    );
  }
}
