import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from './Loader'
import StandardSearchSelector from './StandardSearchSelector'
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import { Typography } from '@material-ui/core'
import { makeUserAppsFirestoreQuery } from '../../utils/firebaseUtil'

const UserAppsSearchSelector = ({ userApps, inputLabel, keepFirstValue, style, quickEdit, selectedValues, handleSave }) => {
  console.log('UserAppsSearchSelector rendered', { userApps, inputLabel, keepFirstValue, style, quickEdit, selectedValues, handleSave });

  if (typeof userApps === 'undefined') return <Loader />
  else if (userApps === null || userApps.length === 0) return <Typography>No apps enabled yet </Typography>
  else
    return <StandardSearchSelector
      id="userAppSearchSelector"
      multiple={false} //No Usecase for selecting multiple apps
      inputLabel={inputLabel || <PhoneIphone style={{ marginRight: 4, }} />}
      allValuesAndLabels={userApps.map(value => value)}
      keepFirstValue={false}
      style={style}
      quickEdit={quickEdit}
      selectedValues={selectedValues}
      handleSave={handleSave}
    />
}

const mapStateToProps = (state) => {
  let userApps = state.firestore.ordered.userApps
  if (state.firestore.ordered.userApps1)
    userApps = [...state.firestore.ordered.userApps, ...state.firestore.ordered.userApps1]
  return { userApps: userApps ? userApps.map(app => ({ label: app.appName, value: app.appID })) : [], profile: state.firebase.profile }
}

export default compose(connect(mapStateToProps, null), firestoreConnect(makeUserAppsFirestoreQuery))(UserAppsSearchSelector)