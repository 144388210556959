import { moment } from './DateRangeSelector';

export const singleDayDateRangeOptions = {
  'Today': {
    rangeFunction: () => moment().range(
      moment().startOf('day'),
      moment().endOf('day')
    ),
    label: 'Today'
  },
  'Yesterday': {
    rangeFunction: () => moment().range(
      moment().subtract(1, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day')
    ),
    label: 'Yesterday'
  },
}

export const dateRangeOptions = {
  ...singleDayDateRangeOptions,
  'Last 7 Days': {
    rangeFunction: () => moment().range(
      moment().subtract(7, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day')),
    label: 'Last 7 Days'
  },
  'Last 30 Days': {
    rangeFunction: () => moment().range(
      moment().subtract(30, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day')),
    label: 'Last 30 Days'
  },
  'Last Week': {
    rangeFunction: () => moment().range(
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week')
    ),
    label: 'Last Week'
  },
  'Last Month': {
    rangeFunction: () => moment().range(
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ),
    label: 'Last Month'
  },
  'This Week': {
    rangeFunction: () => moment().range(
      moment().startOf('week'),
      moment().endOf('week')
    ),
    label: 'This Week'
  },
  'This Month': {
    rangeFunction: () => moment().range(
      moment().startOf('month'),
      moment().endOf('month')
    ),
    label: 'This Month'
  }
};
