import React, { useState, useEffect } from 'react'
import Geocode from "react-geocode";
import { TextField, Chip, } from '@material-ui/core';
import MapLocationPickerMap from './MapLocationPickerMap';

Geocode.setApiKey("AIzaSyC0lZAlHh2nrn-IxGEB2-rD6Kx9d9DqDMU");
Geocode.enableDebug();

function MapLocationPicker(props) {
  const [location, setLocation] = useState({
    locationName: '',
    address: '',
    city: '',
    country: '',
    state: '',
    lat: null,
    lng: null,
    ...props.location
  })
  const zoom = props.zoom || 15;

  useEffect(() => { if (props.onChange && JSON.stringify(props.location) !== JSON.stringify(location)) props.onChange(location) }, [location, props])


  function setNewLocation(lat, lng, address, addressArray) {
    const newLocation = { ...location, checkedGPS: true, address: address || '', ...getAddress(addressArray), lat, lng, }
    setLocation(newLocation)
  }

  if ((!location.lng || !location.lat) && !location.checkedGPS) { const wpid = setGPSLocationAsLocation(setLocation, location) }

  const onChange = (event) => {
    const newLocation = { ...location, [event.target.id]: event.target.value }
    setLocation(newLocation)
  }

  const onMarkerDragEnd = (e) => getAddressFromLatLng(e.latLng.lat(), e.latLng.lng())

  return location.lat ?
    <div>
      <div >
        <MapLocationPickerMap height={props.height}
          {...{ lat: location.lat, lng: location.lng, zoom, setNewLocation, onMarkerDragEnd }} />
        <div style={{ marginTop: 50, }}>
          <TextField style={{ width: !props.onChange ? '75%' : '100%' }} id="locationName" placeholder="Location Name"
            onBlur={onChange} defaultValue={location.locationName || ''} />
          {!props.onChange && <Chip variant="outlined" style={{ width: '20%', marginLeft: 8 }} color="secondary" label='Save'
            onClick={() => props.handleSave(location)} />}
          <TextField style={{ display: 'flex' }} id="address" placeholder="Address"
            onChange={onChange} value={location.address || ''} />
          <TextField style={{ width: '33%' }} id="city" placeholder="City" onChange={onChange} value={location.city || ''} />
          <TextField style={{ width: '33%' }} id="state" placeholder="State" onChange={onChange} value={location.state || ''} />
          <TextField style={{ width: '33%' }} id="country" placeholder="Country" onChange={onChange} value={location.country || ''} />
        </div>
      </div>

    </div>
    : <div style={{ height: props.height }} />


  function getAddressFromLatLng
    (lat, lng) {
    Geocode.fromLatLng(lat, lng)
      .then(
        response => setNewLocation(lat, lng, response.results[0].formatted_address, response.results[0].address_components),
        error => console.error(error)
      );
  }
}

export default React.memo(MapLocationPicker,
  (prevProps, nextProps) => {
    const arePropsUnchanged = JSON.stringify(prevProps.location) === JSON.stringify(nextProps.location) && prevProps.zoom === nextProps.zoom;
    return arePropsUnchanged
  })


function setGPSLocationAsLocation(setLocation, location) {
  return navigator.geolocation.getCurrentPosition(
    position => {
      let lat = position.coords.latitude, lng = position.coords.longitude;
      const newLocation = { lat, lng, checkedGPS: true }
      setLocation(newLocation);
    },
    error => { setLocation({ ...location, checkedGPS: true }); console.error(error) },
    { enableHighAccuracy: false, maximumAge: 60000, timeout: 5000 });
}

function getAddress(addressArray) {
  let city = '', country = '', state = '';

  if (addressArray && addressArray.length) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0])
        city = addressArray[i - 1].long_name;
      if (addressArray[i].types[0] && 'country' === addressArray[i].types[0])
        country = addressArray[i].long_name;
      if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0])
        state = addressArray[i].long_name;
    }
  }

  return { city, state, country, }
}