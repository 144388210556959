import React from 'react';
import { RenderFieldSwitch } from '../RenderFieldSwitch';
import { Chip, Typography, Grid, Divider } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { divLightCard, } from '../../../utils/styleclasses';

export function FieldSetField({ field, getComponentProps, getCameraProps, allFields, handleChange }) {

  if (!(field.fieldSettings.fieldSet.value && field.fieldSettings.fieldSet.value.fields)) return null

  let fieldSets = (field.fieldValue && field.fieldValue.length > 0) ? field.fieldValue : [field.fieldSettings.fieldSet.value.fields]
  const repeatingLabel = field.fieldSettings.repeatingLabel && field.fieldSettings.repeatingLabel.value
  const maxRepeats = field.fieldSettings.maxRepeats && field.fieldSettings.maxRepeats.value
  const allowFieldDelete = field.fieldSettings.allowFieldDelete && field.fieldSettings.allowFieldDelete.value

  return <div >
    {
      fieldSets.map((fieldSet, fieldSetIndex) => <div key={'fieldSet' + fieldSetIndex} style={{ ...divLightCard, padding: 8, margin: 8 }} >
        {
          (allowFieldDelete || repeatingLabel) &&
          <Grid container >
            <Grid item lg={11}>
              {repeatingLabel &&
                <div>
                  <Typography variant="h6" style={{ marginTop: 8 }}>
                    {repeatingLabel.replace(/{counter}/g, fieldSetIndex + 1).replace(/{totalcount}/g, fieldSets.length)}
                  </Typography>
                  <Divider />
                </div>
              }
            </Grid>
            <Grid item lg={1}>{allowFieldDelete && fieldSets.length > 1 && fieldSetIndex + 1 === fieldSets.length &&
              <Close onClick={() => { let fS = [...fieldSets]; fS.pop(); handleChange(field.fieldID, fS) }} />
            }
            </Grid>
          </Grid>
        }
        {
          fieldSet.map(innerField => <RenderFieldSwitch key={innerField.fieldID} field={{ ...innerField, disableMemo: true }}
            {...{ getComponentProps, getCameraProps, allFields }}
            handleChange={(id, value) => {
              if (!field.fieldValue) {
                const initializedFirstRowInFieldSet = [field.fieldSettings.fieldSet.value.fields.map(fieldSetField => ({
                  ...fieldSetField,
                  ...fieldSetField.fieldID === innerField.fieldID ? { fieldValue: value } : {},
                  fieldID: fieldSetField.fieldID + '__1', fieldIdentifier: fieldSetField.fieldIdentifier + '__1'
                }))];
                handleChange(field.fieldID, initializedFirstRowInFieldSet)
              } else
                handleChange(field.fieldID, value, { fieldSetIndex, innerFieldID: innerField.fieldID })
            }} />
          )
        }
      </div >
      )
    }

    <div style={{
      width: '100%', textAlign: 'right', marginTop: 16, marginBottom: 24,
      display: !maxRepeats || fieldSets.length < maxRepeats ? 'block' : 'none'
    }} >
      <Chip variant="outlined" color="primary" name='addFieldSetItem'
        icon={field.fieldSettings.labelAddButton.value ? <Add /> : null}
        label={field.fieldSettings.labelAddButton.value || <Add />}
        onClick={() => handleChange(field.fieldID, [...fieldSets, field.fieldSettings.fieldSet.value.fields
          .map(innerField => ({
            ...innerField,
            fieldID: innerField.fieldID + '__' + (fieldSets.length + 1),
            fieldIdentifier: innerField.fieldIdentifier + '__' + (fieldSets.length + 1),
          }))])}
      />
    </div>
  </div >;
}