import React from 'react'
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import { KeyboardTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { displayLeft, } from '../../utils/styleclasses';
import { Typography, Radio, FormControlLabel, Button } from '@material-ui/core';
import { fieldIDGenerator } from '../../utils/constants';

export default function RecurringSchedule(props) {
  const [schedule, setSchedule] = React.useState(
    {
      scheduleID: props.scheduleID || fieldIDGenerator(),
      scheduleTime: props.scheduleTime || new Date(new Date().setMinutes(0, 0, 0)),
      scheduleRepeatPeriodCount: props.scheduleRepeatPeriodCount || 1,
      scheduleRepeatPeriodType: props.scheduleRepeatPeriodType || 'day',
      scheduleDay: props.scheduleDayOrDate || '',
    }
  );

  return (
    <div style={{ padding: 8 }} >
      {props.title && <Typography style={{ textAlign: "left" }} variant="h6" color="primary">{props.title}</Typography>}
      <div style={displayLeft}>
        <Typography style={{ display: 'block', textAlign: 'left', marginRight: 8, marginTop: 10 }}> Repeats At</Typography>

        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginLeft: 8 }}>

          <KeyboardTimePicker
            views={["hours"]}
            margin="normal"
            id="time-picker"
            // label="Time picker"
            style={{ maxWidth: 150 }}

            value={schedule.scheduleTime}
            onChange={(time) => setSchedule({ ...schedule, scheduleTime: new Date(time) })}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div style={displayLeft}>
        <Typography style={{ display: 'block', textAlign: 'left', marginRight: 8, marginTop: 10 }}> Repeats Every</Typography>
        <TextField
          defaultValue={schedule.scheduleRepeatPeriodCount}
          inputProps={{
            type: 'number',
            min: 1,
            style: { textAlign: 'center' }
          }}
          margin="normal"
          style={{ maxWidth: 56 }}
          // variant="outlined"
          onBlur={(e) => setSchedule({ ...schedule, scheduleRepeatPeriodCount: e.target.value })}
        />
        <FormControl style={{ maxWidth: 200, paddingTop: 8 }}>
          <Select
            // variant="outlined"
            value={schedule.scheduleRepeatPeriodType}
            onChange={(e) => {
              setSchedule({
                ...schedule, scheduleRepeatPeriodType: e.target.value,
                scheduleDay: ''
              })
            }}>
            <MenuItem key={'day'} value="day">day(s)</MenuItem>
            <MenuItem key={'week'} value="week">week(s)</MenuItem>
            <MenuItem key={'month'} value="month">months(s)</MenuItem>
          </Select>
        </FormControl>

      </div>
      <div style={displayLeft}>
        {schedule.scheduleRepeatPeriodType !== 'day' && <Typography style={{ display: 'block', textAlign: 'left', marginTop: 8 }}> Repeats On</Typography>}

        {
          schedule.scheduleRepeatPeriodType === 'week' &&
          <div style={{ marginTop: 16 }}>
            <RadioGroup row value={schedule.scheduleDay || ""} onChange={(e) => setSchedule({ ...schedule, scheduleDay: e.target.value })}>
              <FormControlLabel style={{ margin: 0 }} value={"1"} control={<Radio />} label="M" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"2"} control={<Radio />} label="T" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"3"} control={<Radio />} label="W" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"4"} control={<Radio />} label="T" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"5"} control={<Radio />} label="F" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"6"} control={<Radio />} label="S" labelPlacement="bottom" />
              <FormControlLabel style={{ margin: 0 }} value={"7"} control={<Radio />} label="S" labelPlacement="bottom" />
            </RadioGroup>
          </div>
        }
        {
          schedule.scheduleRepeatPeriodType === 'month' && <FormControl style={{ maxWidth: 400, margin: 8, }}>
            <Select
              //  variant="outlined"
              style={{ minWidth: 200, marginTop: 4 }}
              value={schedule.scheduleDay}
              onChange={(e) => { setSchedule({ ...schedule, scheduleDay: e.target.value }) }} >
              {[...Array(30).keys()].map(day => <MenuItem key={day + 1} value={day + 1}>{`Day ${day + 1} of Month`}</MenuItem>)}
            </Select>
          </FormControl>}

      </div>
      <div>
        <Button variant="outlined" color="primary" style={{ margin: 16 }}
          onClick={() => {
            console.log({ schedule });
            props.handleSave(schedule)
          }}>
          Save Schedule
          </Button>
      </div>
    </div>
  )
}
