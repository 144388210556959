
import React from 'react'
import { getImageUploadPath, } from '../../../utils/firebaseUtil'
import { Divider } from '@material-ui/core';
import UnifiedCameraComponent from '../../standard/UnifiedCameraComponent';

function AppRenderCameraComponent({ field, handleChange, orgID, uid, appID, submissionID, appName, cameraToUse }) {

  const imageFileName = `appSubmissions-uid-${uid}-aid-${appID}-sid-${submissionID}-ffid-${field.fieldID}.jpg`;

  console.log('AppRenderCameraComponent', orgID, uid, appID, submissionID, appName, cameraToUse, field, imageFileName);
  return <div><UnifiedCameraComponent
    name={field.fieldLabel}
    style={{ marginBottom: 16 }}
    imageURI={field.image || null}
    cameraToUse={cameraToUse}
    fieldID={field.fieldID}
    allowUpload={field.fieldSettings.allowUpload && field.fieldSettings.allowUpload.value ? true : false}
    facingModes={field.fieldSettings.frontCamera && field.fieldSettings.frontCamera.value ? "user" : "environment"}
    focusBorder={field.fieldSettings.focusBorder && field.fieldSettings.focusBorder.value}
    orgID={orgID}
    handleChange={
      (id, image, imageUploadPromise) => handleChange(id, getImageUploadPath(orgID, imageFileName), { image, imageUploadPromise })}
    uploadToStorage={(image) => { }}
    imageFileName={imageFileName}
    uploadDescription={{ appID: appID, submissionID: submissionID, fieldID: field.fieldID, fieldLabel: field.fieldLabel, appTitle: appName, submittedAt: new Date() }} />
    <Divider />
  </div>
}

export default React.memo(AppRenderCameraComponent,
  (prevProps, nextProps) => {
    const arePropsUnchanged = prevProps.cameraToUse === nextProps.cameraToUse && prevProps.submissionID === nextProps.submissionID
    const areFieldValuesUnchanged =
      prevProps.field.fieldValue === nextProps.field.fieldValue && prevProps.field.fieldLabel === nextProps.field.fieldLabel;
    let areFieldSettingsUnchanged = true;
    return arePropsUnchanged && areFieldValuesUnchanged && areFieldSettingsUnchanged
  })