import React from "react";
import Refresh from '@material-ui/icons/Refresh';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Typography from '@material-ui/core/Typography';
import DateRangePopover from '../../standard/DateRangePopover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from 'moment'
import { Chip } from "@material-ui/core";

const RenderCardHeader = (props) => {
  const [settings, setSettings] = React.useState(false);
  // console.log('RenderingDashboardCardHeader ', { props });

  return (
    <ClickAwayListener onClickAway={() => setSettings(false)}>
      <div style={{ textAlign: 'center' }}>
        {props.isSmallScreen && <Typography style={{ fontWeight: "bold", marginLeft: 6, }} variant="subtitle1" color="primary">
          {props.cardName && props.cardName}
        </Typography>}
        <div
          style={{ display: 'inline-flex', textAlign: 'center', justifyContent: 'start', alignItems: 'center', width: '100%', padding: 4 }}>
          {props.updatedAt &&
            <Chip onClick={props.refreshCard} size="small"
              icon={<Refresh />} label=
              {<Typography style={{ color: 'gray', }} variant="caption" >
                {moment(props.updatedAt).format('MMM DD h:mm A')}
              </Typography>}
            />}
          {/* {props.updatedAt &&
          <div style={{ display: 'flex' }} >
            <IconButton onClick={props.refreshCard} > <Refresh /> </IconButton>
            <Typography style={{ color: 'gray', }} variant="caption" >
              {moment(props.updatedAt.toDate()).format('MMM-DD h:mm A')}
            </Typography>
          </div>} */}
          {/* <IconButton
          onClick={(e) => { (setSettings(!settings)) }}>
          {settings ? <MoreHoriz /> : <MoreVert />}
        </IconButton>
        {settings ?
          <span style={{ display: 'inline-flex', justifyContent: 'start', alignItems: 'center', width: '100%' }} >
            <ColorPickerPopover handleColorChange={props.handleColorChange} />
            {props.handleDelete && <IconButton onClick={props.handleDelete}> <DeleteOutline /></IconButton>}
            <IconButton onClick={props.refreshCard} > <Refresh /> </IconButton>
            {props.updatedAt &&
              <div >
                <Typography style={{ color: 'gray', }} variant="caption" >
                  Refreshed at {moment(props.updatedAt.toDate()).format('MMM-DD h:mm A')}
                </Typography>
              </div>}
          </span>
          : */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            {!props.isSmallScreen &&
              <Typography style={{ fontWeight: "bold", marginLeft: 6, width: '80%' }} variant={props.isSmallScreen ? 'caption' : "body1"} color="primary">
                {props.cardName && props.cardName}
              </Typography>}

            {(props.startDate || props.endDate)
              && (props.queryString.includes('#StartDate')
                || props.queryString.includes('#EndDate'))
              && !props.hideDate &&
              <div style={{ marginLeft: 16, fontWeight: "normal", }}>
                <DateRangePopover
                  dateSelectionType={props.dateSelectionType}
                  isSmallScreen={props.isSmallScreen}
                  setDateRange={props.setDateRange}
                  startDate={props.startDate.toDate()}
                  endDate={props.endDate.toDate()}
                  defaultDateRange={props.selectedDateRange} />
              </div>}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default RenderCardHeader
