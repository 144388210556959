import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from '../standard/TabPanel'
import Timelapse from '@material-ui/icons/Timelapse';
import Loader from '../standard/Loader'
import AppViewerList from './AppViewerList'
import PendingSubmissions from './PendingSubmissions'
import Assignment from '@material-ui/icons/Assignment';

export default function AppViewerTab(props) {
  const profile = useSelector(state => state.firebase.profile)

  const [state, setState] = useState({ tabValue: 0, displayActivities: 5, });

  console.log('AppViewerTab', props, state);
  if (!profile.useforms) return <Loader />
  return (
    <div key={JSON.stringify(profile)} >
      <AppBar position="sticky" >
        <Tabs value={state.tabValue} aria-label="App edit tabs"
          onChange={(event, newValue) => { setState({ tabValue: newValue }); }} >
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <Assignment style={{ marginRight: 4 }} />Apps</span>} {...a11yProps(0)} />
          {/* <Tab label={<PresentToAll color="inherit" />} {...a11yProps(1)} /> */}
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <Timelapse style={{ marginRight: 4 }} color="inherit" /></span>
          } {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={state.tabValue} index={0}>
        <AppViewerList history={props.history} />
      </TabPanel>
      <TabPanel key={state.tabValue + 'tab'} value={state.tabValue} index={1}>
        {state.tabValue === 1 && <PendingSubmissions key={state.tabValue + 'submission'} />}
      </TabPanel>
    </div >
  )
}