
import React from 'react'
import Assignment from '@material-ui/icons/AssignmentTwoTone';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedInTwoTone';
import AssignmentInd from '@material-ui/icons/AssignmentIndTwoTone';

export const getAppIcon = (appDescription, fontSize, color) => {
  if (['selfie', 'user', 'customer'].some(word => appDescription && appDescription.toLowerCase().includes(word)))
    return <AssignmentInd color={color ? color : "primary"} style={{ fontSize: fontSize ? fontSize : 36, }} />
  if (['checklist', 'check'].some(word => appDescription && appDescription.toLowerCase().includes(word)))
    return <AssignmentTurnedIn color={color ? color : "primary"} style={{ fontSize: fontSize ? fontSize : 36, }} />
  return <Assignment color={color ? color : "primary"} style={{ fontSize: fontSize ? fontSize : 36, }} />
}

export const touchHandler = (event) => {
  console.log('touchHandler called', event.type);

  var touches = event.changedTouches,
    first = touches[0],
    type = "";
  switch (event.type) {
    case "touchstart": type = "mousedown"; break;
    case "touchmove": type = "mousemove"; break;
    case "touchend": type = "mouseup"; break;
    default: return;
  }

  // initMouseEvent(type, canBubble, cancelable, view, clickCount, 
  //                screenX, screenY, clientX, clientY, ctrlKey, 
  //                altKey, shiftKey, metaKey, button, relatedTarget);

  var simulatedEvent = document.createEvent("MouseEvent");
  simulatedEvent.initMouseEvent(type, true, true, window, 1,
    first.screenX, first.screenY,
    first.clientX, first.clientY, false,
    false, false, false, 0/*left*/, null);

  first.target.dispatchEvent(simulatedEvent);
  // event.preventDefault();
}

export const touchHandlerEffect = () => {
  document.addEventListener("touchstart", touchHandler, { passive: false, });
  document.addEventListener("touchmove", touchHandler, { passive: false, });
  document.addEventListener("touchend", touchHandler, { passive: false, });
  document.addEventListener("touchcancel", touchHandler, { passive: false, });
  return () => {
    document.removeEventListener("touchstart", touchHandler);
    document.removeEventListener("touchmove", touchHandler);
    document.removeEventListener("touchend", touchHandler);
    document.removeEventListener("touchcancel", touchHandler);
  }
}

export const blockScrollEffect = () => {
  // console.log('Adding event listener');

  document.body.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  document.body.addEventListener(wheelEvent, preventDefault, { passive: false }); // modern desktop
  document.body.addEventListener('touchmove', preventDefault, { passive: false }); // mobile
  document.body.addEventListener('keydown', preventDefaultForScrollKeys, false);
  return () => {
    // console.log('Removing event listener');

    document.body.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.body.removeEventListener(wheelEvent, preventDefault, { passive: false });
    document.body.removeEventListener('touchmove', preventDefault, { passive: false });
    document.body.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  }
}

function preventDefaultForScrollKeys(e) {
  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

function preventDefault(e) {
  // console.log('Preventing scroll default');
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
}

var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
