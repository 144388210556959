import React from 'react'
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import CardSearchSelector from '../standard/CardSearchSelector';
import InsertChart from '@material-ui/icons/InsertChart';
import AddCircle from '@material-ui/icons/AddCircle';
import { iconTextAlign } from '../../utils/iconutils';
import { Chip } from '@material-ui/core';

export default function CardAddSelector({ handleAdd, isChip }) {
  const [card, setCard] = React.useState({ addCard: false });

  return (
    <span style={{ margin: 8 }} >
      {
        <span>
          <Chip color="primary" variant='outlined' id='cardAddSelector'
            label={iconTextAlign(<AddCircle />, 'Card')}
            onClick={(e) => setCard({ ...card, addCard: true, anchorEl: e.currentTarget })} />
          {card.addCard && <Popover
            open={card.addCard}
            anchorEl={card.anchorEl}
            onClose={() => { setCard({ ...card, addCard: false }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { padding: 16, textAlign: 'center' } }}>
            <div style={{ minWidth: 300 }}>
              <CardSearchSelector
                // key={card.cardID}
                selectedValues={card.cardID === 'cardBuilder' ? [] : [card.cardID]} style={{ width: "100%", }}
                quickEdit={true}
                inputLabel={<InsertChart style={{ marginRight: 8 }} />}
                handleSave={(cardSelected) => {
                  console.log(cardSelected[0].label, cardSelected[0].value);
                  setCard({ ...card, selectedCardID: cardSelected[0].value, selectedCardTitle: cardSelected[0].label })
                }
                } disableClearable={true} />
              <Button color="primary" variant="outlined" onClick={() => { setCard({ addCard: false }) }} style={{ margin: 8 }}> Cancel </Button>
              <Button variant="contained" color="primary" id='addCard'
                onClick={(e) => {
                  // const findSelectedCard = props.cards.find(row => row.cardID === card.selectedCardID);
                  handleAdd({ cardID: card.selectedCardID });
                  setCard({ ...card, addCard: false })
                }
                }>
                Add Card
              </Button>
            </div>
          </Popover>}
        </span>
      }

    </span >
  )
}
