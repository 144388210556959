import React from 'react'
import Cancel from '@material-ui/icons/Cancel';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LocationOff from '@material-ui/icons/LocationOff';
import LocationOn from '@material-ui/icons/LocationOn';
import { displayLeft } from '../../utils/styleclasses';
import GpsOff from '@material-ui/icons/GpsOff';
import { Typography } from '@material-ui/core';

export default function MapPopover(props) {
  const [popover, setPopover] = React.useState({ open: false, });
  const handleClick = (e) => { (setPopover({ ...popover, open: !popover.open, anchorEl: e.currentTarget })) }
  const MapPopoverRef = <GoogleMapEmbed mapLink={props.mapLink} />
  if (!props.mapLink) return null
  return (
    <div style={props.style || null}>
      {!props.mapLink.includes('http') ? <span style={displayLeft}><LocationOff color="disabled" style={{ fontSize: 26, marginBottom: 4 }} /></span>
        :
        <div>
          <div style={{ display: "none" }}> {MapPopoverRef}</div>
          {props.iconOnly ? <span style={displayLeft}> <LocationOn onClick={handleClick} color="secondary" style={{ marginRight: 4, fontSize: 26, marginBottom: 4 }} /></span>
            : <Chip
              id={"ViewMapLinkIframe"} key={"ViewMapLinkIframe"} variant="outlined" color="primary"
              onClick={handleClick}
              label={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LocationOn style={{ fontSize: 20, marginRight: 4 }} />Map</div>} />}

          {popover.open && <Popover
            key={'mapPopover'}
            id={'mapPopover'}
            open={popover.open}
            anchorEl={popover.anchorEl}
            onClose={() => { setPopover({ ...popover, open: false }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ style: { maxWidth: 316, padding: 8, textAlign: 'center', } }}>
            <IconButton onClick={() => setPopover({ ...popover, open: false })} style={{ position: "absolute", top: 0, right: 0 }}>
              <Cancel />
            </IconButton>
            {MapPopoverRef}
          </Popover>}
        </div>
      }
    </div >
  )
}

export function GoogleMapEmbed({ mapLink, style }) {
  return mapLink && mapLink.includes('http') ?
    <iframe frameBorder={0} title=' ' style={style || { width: '100%', display: 'block' }}
      src={`https://www.google.com/maps/embed/v1/place?zoom=17&&key=AIzaSyBTJRUhiJva6404aQH-wImKcks-znWzfWs&q${mapLink.substring(mapLink.lastIndexOf("q=") + 1, mapLink.length)}`} />
    : <div style={displayLeft}>
      <GpsOff color="disabled" style={{ fontSize: 24, marginLeft: 8 }} />
      Device GPS NA
    </div>
}

export function GoogleMapEmbedFromLocation({ location, style, options }) {
  return location && location.lat && location.lng ?
    <div>
      <Typography>{location.locationName}</Typography>
      <Typography variant="caption" style={{ width: '100%', display: 'block' }}>
        {location.address}</Typography>
      <Typography variant="caption" style={{ width: '100%', display: 'block' }}>
        {!options.hide.includes('city') && location.city}
        {!options.hide.includes('state') && location.state}
        {!options.hide.includes('country') && location.country}
      </Typography>
      <iframe frameBorder={0} title=' ' style={style || { width: '100%', display: 'block' }}
        src={`https://www.google.com/maps/embed/v1/place?zoom=17&&key=AIzaSyBTJRUhiJva6404aQH-wImKcks-znWzfWs&q=${location.lat},${location.lng}`} />
    </div> :
    <div style={displayLeft}>
      <GpsOff color="disabled" style={{ fontSize: 24, marginLeft: 8 }} />No Location
      </div>
}