import React, { useState } from 'react'
import { AppRender } from '../appviewer/AppRender';
import { primary } from '../../utils/themes';
import AlertSnackbar from '../standard/AlertSnackbar';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { submitApp } from '../../store/actions/formActions'
import EmptyImagePlaceholder from '../standard/EmptyImagePlaceholder';
import { makeUserAppsFirestoreQuery } from '../../utils/firebaseUtil';

function GridAppRender({ app, profile, submitApp, isSingleItemPage }) {
  const [alertState, setAlertState] = useState({ open: false, message: '', backgroundColor: primary, alertTimeout: 1200, });

  const goToOnAppClose = (appSubmitted) => {
    if (appSubmitted && appSubmitted.appName)
      setAlertState({ ...alertState, open: true, icon: 'CheckCircleOutline', message: `${appSubmitted.appName} submission saved`, backgroundColor: 'secondary' })
    else
      setAlertState({ ...alertState, open: true, icon: 'DeleteOutline', message: `App discarded`, backgroundColor: 'primary', alertTimeout: 1500, })
  }

  return (
    <div >
      {app && !alertState.open && <div
        style={isSingleItemPage ? null : { overflowY: 'scroll', maxHeight: window.innerHeight - 130 }}>
        <AppRender  {...{ goToOnAppClose, app, profile, submitApp }} hideAppBar={isSingleItemPage} />   </div>}
      {alertState.open && alertState.message.includes('discarded') && <EmptyImagePlaceholder imageType="bench" />}
      <AlertSnackbar {...{ alertState, setAlertState }} />
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitApp: (appSubmission) => {
      if (!appSubmission.submissionLocation) console.error('SUBMITTING EMPTY app', appSubmission);
      return dispatch(submitApp(appSubmission))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('AppViewerList state userApps', state.firestore.data.userApps);
  let userApps = state.firestore.data.userApps
  if (state.firestore.data.userApps1)
    userApps = { ...state.firestore.data.userApps, ...state.firestore.data.userApps1 }

  return {
    app: userApps && userApps[ownProps.appID],
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect(makeUserAppsFirestoreQuery))(GridAppRender)