import React from 'react';
import Bowser from "bowser";
import AlertCard from './AlertCard';

export default function BrowserCheck() {
  const { os, browserName } = getBrowserNameAndOSName();

  let message = null;

  if (os.name.includes("Android")
    && parseInt(os.version) < 6) { message = "Opstack works best with Android 6 & above. Please upgrade your Android version" }
  else if (os.name.includes("iOS")
    && parseInt(os.version) < 11) { message = "Opstack works best with iOS 11 & above. Please upgrade your iOS version" }
  else if (os.name.includes("iOS")
    && !browserName.includes("Safari")) { message = "Opstack works best with the Safari browser on iOS devices" }
  else if (os.name.includes("Android")
    && !browserName.includes("Chrome")) { message = "Opstack works best with Chrome on Android. Please install Chrome on your device." }

  return message ? <AlertCard message={message} /> : null
}

export function getBrowserNameAndOSName() {
  const parser = Bowser.getParser(window.navigator.userAgent)
  return {
    os: (parser.parsedResult && parser.parsedResult.os) || {},
    browserName: (parser.parsedResult && parser.parsedResult.browser.name) || ''
  }
}