import React from 'react'
import BarChartComponent from '../cardcomponents/BarChartComponent'
import VerticalBarChartComponent from '../cardcomponents/VerticalBarChartComponent'
import TableComponent from '../cardcomponents/TableComponent'
import LineChartComponent from '../cardcomponents/LineChartComponent'
import PieChartComponent from '../cardcomponents/PieChartComponent'
import HeatMapComponent from '../cardcomponents/HeatMapComponent'
import ClusterMap from '../../standard/ClusterMap'
import { ResponsiveContainer, } from 'recharts';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardReport from '../cardcomponents/CardReport'

import FilterSelect from './FilterSelect'
import MultiLineChartComponent from '../cardcomponents/MultiLineChartComponent'

export default function DashboardCardContent(props) {


  const cardRender = (width) => {

    if (!props.data) return <div />
    // if (!props.card.requestID || !props.card.responseID ||
    //   JSON.stringify(props.card.requestID) !== JSON.stringify(props.card.responseID))
    //   return <div style={{ margin: 16 }}>Requesting Data <LinearProgress /> </div>

    if (props.data && props.data.length === 0 && !props.card.queryError) {
      return <Typography variant="h6" color="primary" className="CardRendered"
        style={{ textAlign: "center", flex: 1, margin: 16, }}>No Data found for this query</Typography>
    }

    switch (props.card.cardType) {

      case 'report': console.log('RenderCardContent height', props.height);
        return <div className="CardRendered" style={{ height: props.height, width: '100%' }}>
          <CardReport data={props.data} cardSettings={props.card.cardSettings} reportColumns={props.card.columns} isSmallScreen={props.isSmallScreen} />
        </div>

      case 'verticalbar': return <VerticalBarChartComponent className="CardRendered" data={props.data} value1Label={props.card.value1Label} isSmallScreen={props.isSmallScreen} cardSettings={props.card.cardSettings}
        name1Label={props.card.name1Label} color={props.layout.cardColor}
        layout={props.layout || ''} width={width || window.innerWidth / 2.3} height={props.height || window.innerHeight / 5} />

      case 'linechart': return <LineChartComponent isSmallScreen={props.isSmallScreen} className="CardRendered" data={props.data} value1Label={props.card.value1Label} layout={props.layout || ''} color={props.layout.cardColor} cardSettings={props.card.cardSettings}
        name1Label={props.card.name1Label}
        width={window.innerWidth / 2.3} />

      case 'multilinechart': console.log('RenderCardContent', props.height, props.width);
        return <div className="CardRendered" style={{ height: props.height || 400, minWidth: 0 }}        >
          <MultiLineChartComponent isSmallScreen={props.isSmallScreen} className="CardRendered" data={props.data} value1Label={props.card.value1Label} layout={props.layout || ''} dataSeries={props.dataSeries} color={props.layout.cardColor}
            name1Label={props.card.name1Label} cardSettings={props.card.cardSettings}
          /></div>

      case 'piechart': return <PieChartComponent isSmallScreen={props.isSmallScreen} className="CardRendered" data={props.data} value1Label={props.card.value1Label} layout={props.layout || ''} color={props.layout.cardColor}
        name1Label={props.card.name1Label} cardSettings={props.card.cardSettings}
        width={window.innerWidth / 2.3} />

      case 'table': return <TableComponent isSmallScreen={props.isSmallScreen} className="CardRendered" data={props.data}
        name1Label={props.card.name1Label} color={props.layout.cardColor}
        value1Label={props.card.value1Label} layout={props.layout || ''} width={window.innerWidth / 2.3} cardSettings={props.card.cardSettings} />

      case 'heatmap': return <div className="CardRendered" style={{ height: props.height || 400 }}>
        <HeatMapComponent data={props.data} isSmallScreen={props.isSmallScreen}
          name1Label={props.card.name1Label} color={props.layout.cardColor}
          value1Label={props.card.value1Label} layout={props.layout || ''} width={window.innerWidth / 2.3} cardSettings={props.card.cardSettings} />
      </div>

      case 'clustermap': return <ClusterMap className="CardRendered" data={props.data} value1Label={props.card.value1Label} layout={props.layout || ''} width={window.innerWidth / 2.3} color={props.layout.cardColor}
        name1Label={props.card.name1Label} isSmallScreen={props.isSmallScreen}
        containerElement={<div style={{ height: 400 }} cardSettings={props.card.cardSettings} />}

        markers={props.data && props.data.map(row => {
          if (row[props.card.value1Label]) return {
            lat: Number(row[props.card.value1Label].substring(row[props.card.value1Label].lastIndexOf("=") + 1, row[props.card.value1Label].lastIndexOf("+"))),
            lng: Number(row[props.card.value1Label].substring(row[props.card.value1Label].lastIndexOf("+") + 1, row[props.card.value1Label].length))
          }
          else return null
        })} />

      case 'valuecard':
        return props.card.value1Label && props.card.name1Label &&
          props.data[0][props.card.name1Label] && props.data[0][props.card.value1Label] &&
          <div className="CardRendered" style={{
            backgroundColor: props.layout.cardColor,
            width: "100%", height: "100%",//Needed for full color + responsive container seems to increase div size by
            position: "relative",
          }}>
            <Grid container spacing={2} style={{
              // backgroundColor: props.card.cardColor,
              width: "100%", height: "80%",//Needed for full color + responsive container seems to increase div size by
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // display: 'block',
              textAlign: 'center',
              padding: 16
            }} >
              <Grid item lg={12} >
                <div style={{ width: "100%", height: "100%", textAlign: 'center', verticalAlign: 'center', }}>
                  <Typography style={{ fontWeight: "bold" }} variant='h4'>
                    {props.data[0][props.card.name1Label].replace(/[_-]/g, " ")}
                  </Typography>
                  <Divider style={{ width: "100%" }} />
                  <Typography variant="caption">
                    {props.card.value1Label.replace(/[_-]/g, " ")}
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={12}>
                <Typography style={{ fontWeight: "bold" }} variant='h1'>
                  {props.data[0][props.card.value1Label]}
                </Typography>
              </Grid>
            </Grid>
          </div>
      default: return <BarChartComponent isSmallScreen={props.isSmallScreen} className="CardRendered" data={props.data} value1Label={props.card.value1Label} layout={props.layout || ''} width={window.innerWidth / 2.3} color={props.layout.cardColor}
        name1Label={props.card.name1Label} />
    }
  }

  // console.log('DashboardCardContent(props)', props);
  return (
    props.card ? <div id={"CardRendered" + props.responseID} style={{ height: "100%", width: '100%', }} name="cardRendered" >
      {
        props.card.filters && props.card.filters.length > 0 &&
        props.card.filters.map((filter, index) => filter ?
          (props.hideFilterSelect ?
            <Typography style={{ margin: 8 }} key={'filter' + index} >
              {filter.filterLabel}: {filter.filterSelectedLabel || filter.filterSelectedValue}
            </Typography> : <FilterSelect responseID={props.responseID + index} key={'filter' + props.responseID + index} filter={filter} handleFilterValueChange={(updatedFilter) => props.handleFilterValueChange && props.handleFilterValueChange(updatedFilter, index)} />) : null)
      }
      {
        props.responsive && props.card.cardType !== 'multilinechart' ?
          <ResponsiveContainer className="dragCancel">
            {cardRender()}
          </ResponsiveContainer >
          : cardRender()
      }
    </div >
      : <div />
  )

}

