import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React from 'react';
import { getJSONfromString } from '../../../utils/stringutils';
import { isJSON } from '../../../utils/utilityfunctions';


const App = (props) => {
  const cardSettings = props.cardSettings ? isJSON(getJSONfromString(props.cardSettings)) : null
  // console.log('CardReport columns', props.reportColumns, cardSettings);
  // const [gridAPI, setGridAPI] = useState(null);
  // const [gridColumnAPI, setGridColumnAPI] = useState(null);

  // function onGridReady(params) { setGridAPI(params.api); setGridColumnAPI(params.columnAPI); }

  let columns
  if (!props.reportColumns)
    columns = Object.entries(props.data[0]).filter(([key, value]) => key !== 'index' && key !== 'id').map(([key, value]) => ({ field: key, headerName: key.replace(/_/g, " "), width: 150 }));
  else
    columns = props.reportColumns.replace(/\s/g, "").split(',').map(columnName => ({
      field: columnName, headerName: columnName.replace(/_/g, " "),
      width: (cardSettings && cardSettings.columnWidth && Number(cardSettings.columnWidth[columnName])) || 150
    }))

  let rows = props.data.map((row, i) => { return { id: i + 1, ...row } });
  console.log('CardReport', { cardSettings, rows, columns });

  function onGridReady(params) {
    if (rows.length > 0)
      params.api.sizeColumnsToFit();
  }

  function autoSizeColumns(params) {
    const colIds = params.columnApi.getAllDisplayedColumns().map(col => col.getColId());
    setTimeout(() => params.columnApi.autoSizeColumns(colIds), 300)
  };


  return (
    <>
      <AgGridReact
        onGridReady={onGridReady}
        onFirstDataRendered={autoSizeColumns}
        defaultColDef={{ resizable: true }}
        className="ag-theme-alpine" rowData={rows}>
        {columns.map((column, i) => <AgGridColumn
          key={i}
          field={column.field} headerName={column.headerName} sortable={true} filter={true}
          pinned={cardSettings && cardSettings.pinned && cardSettings.pinned.length > 0 && cardSettings.pinned.includes(i + 1)}
        />)}
      </AgGridReact>
    </>
  );
};

export default App;