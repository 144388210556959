import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Assignment from '@material-ui/icons/AssignmentTwoTone'
import CheckCircle from '@material-ui/icons/CheckCircle'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Dashboard from '@material-ui/icons/DashboardTwoTone'
import DynamicFeed from '@material-ui/icons/DynamicFeedTwoTone'
import Person from '@material-ui/icons/PersonTwoTone'
import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { sendFCMToken } from '../../store/actions/authActions'
import { displayLeft, displayRight } from '../../utils/styleclasses'
import { primary, secondary } from '../../utils/themes'
import AfternoonSun from '../img/AfternoonSun.png'
import BoyPlaceholder from '../img/BoyPlaceholder.png'
import EveningSun from '../img/EveningSun.png'
import MorningSun from '../img/MorningSun.png'
import AppInstallation from '../standard/AppInstallation'
import BrowserCheck from '../standard/BrowserCheck'
import FloatingSpeedDial from '../standard/FloatingSpeedDial'
import NotificationPermission from '../standard/NotificationPermission'
import TourContainer from '../standard/TourContainer'
import tourConfig from './mobilehomepagetourconfig'

function HomePage({ profile, orgSettings, sendFCMToken }) {

  const time = new Date().getHours();
  const greeting = time < 12 ? { img: MorningSun, text: `Good Morning` } : time < 16 ? { img: AfternoonSun, text: `Good Afternoon` } : { img: EveningSun, text: `Good Evening` }

  const mobileHomeLinks = [
    { to: 'mobilepages', linkName: 'My Pages', IconName: Dashboard, permission: 'dashboard', iconColor: primary },
    { to: 'tasklist', linkName: 'My Tasks', IconName: CheckCircle, permission: 'tasklist', iconColor: secondary },
    { to: 'appviewer', linkName: 'My Apps', IconName: Assignment, permission: 'useforms', iconColor: primary },
    { to: 'newsfeed', linkName: 'Actions & Channels', IconName: DynamicFeed, permission: 'newsfeed', iconColor: primary },
    { to: 'updateprofile', linkName: 'My Profile', IconName: Person, permission: 'useforms', iconColor: primary },
  ]

  return (
    <div >
      <div style={{ width: "100vw", textAlign: "center", height: window.innerHeight - 56 }}>
        <div variant="outlined" style={{ margin: 8, marginTop: 16, marginBottom: 0, padding: 8, fontSize: 20, }}>
          <strong>
            <span style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
              <img src={(orgSettings && orgSettings.orgLogo) || greeting.img} alt="Morning" style={{ height: 48, margin: 8 }} />
              {`${greeting.text} ${profile.firstName}!`}
            </span>
          </strong>
        </div>
        <BrowserCheck />

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {/* <div data-tut="tourRecentActivity" variant="outlined"> </div> */}
          {mobileHomeLinks.map(link => profile[link.permission] && <Link key={link.to} to={link.to} style={{ textDecoration: 'none', width: '100%', }}>
            <Card style={{ margin: 8, padding: 8, }} >
              <Grid container>
                <Grid item xs={11} style={displayLeft}>
                  <link.IconName style={{ fontSize: 36, marginRight: 16, color: link.iconColor }} />
                  <Typography variant="h5" color="primary" >{link.linkName}</Typography>
                </Grid>
                <Grid item xs={1} style={displayRight}>
                  <ChevronRight style={{ fontSize: 36, }} color='primary' />
                </Grid>
              </Grid>
            </Card>
          </Link>)
          }
        </div >
        <AppInstallation />
        <NotificationPermission profile={profile} sendFCMToken={sendFCMToken} />
        <img src={BoyPlaceholder} alt="Morning" style={{ maxHeight: '50vh', maxWidth: '70vw', marginBottom: 50 }} />
        {profile && <TourContainer tourName="MobileHomePageTour" tourConfig={tourConfig} />}
      </div>
      <FloatingSpeedDial style={{ bottom: 65, marginRight: 0, right: -20, position: 'fixed' }} profile={profile} canAddUsers={(profile.orgOwner || profile.addusers)} />
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    // updateProfileTourStatus: (profile) => { dispatch(updateProfileTourStatus(profile)) },
    sendFCMToken: (uid, FCMToken) => dispatch(sendFCMToken(uid, FCMToken)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orgSettings: state.firestore.data.orgSettings,
    profile: state.firebase.profile,
    // channels: state.firestore.data.channels,
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID || 'NoOrg', storeAs: 'orgSettings' },
      // { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'channels' }], storeAs: 'channels' }
    ]
  }
  ))(HomePage)
