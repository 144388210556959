import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { submitApp } from '../../store/actions/formActions'
import Loader from '../standard/Loader'
import { AppRender } from './AppRender'

const AppRenderContainer = ({ appID, auth, app, profile, history, submitApp }) => {

  if (!app) return <Loader />
  const goToOnAppClose = () => { history.push('/appviewer'); };


  return <AppRender  {...{ goToOnAppClose, app, profile, submitApp }} />
}

const mapStateToProps = (state, ownProps) => {
  console.log('State.mapStateToProps.AppBuilder', state);
  let appID;
  if ((ownProps.match && ownProps.match.params && ownProps.match.params.appID)) { appID = String(ownProps.match.params.appID); }

  return {
    app: appID ? state.firestore.data[`app-${appID}`] : null,
    auth: state.firebase.auth,
    profile: state.firebase.profile

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitApp: (appSubmission) => dispatch(submitApp(appSubmission))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    if (!(props.match && props.match.params && props.match.params.appID)) return []
    const appID = String(props.match.params.appID);
    console.log("firestoreConnect", { appID, props });
    return [
      {
        collection: `organizations/${props.profile.orgID}/apps`, doc: appID, storeAs: `app-${appID}`
      }
    ]
  })
)(AppRenderContainer)


