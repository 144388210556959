import React from 'react'
import { useSelector } from 'react-redux'
import { WidthProvider, Responsive } from "react-grid-layout";
import Card from "@material-ui/core/Card";
import { divLightCard } from '../../utils/styleclasses';
import GridCardItem from './GridCardItem';
import GridAppRender from './GridAppRender';
import ActivityFeed from '../newsfeed/ActivityFeed';
import LoadMore from '../standard/LoadMore';
import { Typography, Chip, AppBar, Box } from '@material-ui/core';
import PopoverButton from '../standard/PopoverButton';
import ActivityMapViewer from '../reports/activityfeed/ActivityMapViewer';
import AppReports from '../reports/submissions/AppReports';
import TaskSearch from '../reports/tasks/TaskSearch';
import ActivityGalleryViewer from '../reports/activityfeed/ActivityGalleryViewer';
import Copy from '@material-ui/icons/FileCopyTwoTone';
import Delete from '@material-ui/icons/DeleteTwoTone';
import GridContentItem from './GridContentItem';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function ResponsiveGrid(props) {

  const [layoutState, setLayoutState] = React.useState({ layouts: props.layouts, })
  const profile = useSelector(state => state.firebase.profile)

  const isSmallScreen = (window.innerWidth < 825);

  const onLayoutChange = (currentScreenSizeLayout, allScreenSizeLayouts) => {
    delete allScreenSizeLayouts.layouts;
    const newLayouts = JSON.stringify({ layouts: allScreenSizeLayouts });
    if (newLayouts !== JSON.stringify(layoutState)) {
      console.log('ResponsiveGrid layout change', { newLayouts, layoutState });
      localStorage.setItem(`gridLayout-${props.gridID}`, newLayouts)
      setLayoutState({ layouts: allScreenSizeLayouts });
    }
    else
      console.log('ResponsiveGrid no change');

  }
  const isSingleItemPage = props.gridItems.length === 1;

  console.log('ResponsiveGrid layoutState.layouts', layoutState.layouts);
  return (
    props.gridID && <ResponsiveReactGridLayout
      key={props.gridID}
      margin={props.isSmallScreen ? (props.editOpen ? [16, 4] : [2, 2]) : (isSingleItemPage ? [0, 0] : [16, 16])}
      isDraggable={props.editOpen}
      isResizable={props.editOpen}
      draggableCancel='.dragCancel'
      className="layout"
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={20}
      layouts={layoutState.layouts}
      compactType="vertical"
      onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}>
      {
        props.gridItems.map((item, index) => {
          return <Card
            className='slimscroll'
            //  component={props.gridItems.length === 1 ? 'div' : 'Card'} 
            key={item.gridItemID} data-grid={{ i: item.gridItemID, x: 0, y: 0, w: 14, h: 12, minW: 3, mihH: 4, }}
            style={{
              ...divLightCard,
              overflowY: 'auto'
            }}>
            {props.editOpen && <div style={{ position: "absolute", top: 0, right: 0, zIndex: 1400, marginRight: 16 }}>
              <PopoverButton showCloseIcon={true} closePopoverOnClick={true} text={<Delete />} useChip={true}
                popoverContent={
                  <div style={{ padding: 4 }}>
                    <Typography variant="h5" color="error" style={{ textAlign: 'left', margin: 8, marginRight: 30 }}>
                      Confirm Grid Item Deletion </Typography>
                    <Chip
                      variant="outlined" clickable
                      style={{ padding: 15, margin: 5 }}
                      onClick={() => props.deleteItem(item.gridItemID)}
                      label='Confirm'
                    />
                  </div>
                } />
              <Chip clickable color="primary" onClick={() => props.copyItem(item.gridItemID)} label={<Copy />} />
            </div>}
            {item.gridItemType === 'card' && <GridCardItem isSmallScreen={isSmallScreen} gridID={props.gridID} gridItemID={item.gridItemID} name={item.cardLabel} cardID={item.cardID} dataPathPrefix="pageTabID" />}
            {item.gridItemType === 'app' && <GridAppRender id={item.gridItemID} appID={item.appID} isSingleItemPage={isSingleItemPage} />}
            {item.gridItemType === 'channel' && GridChannelItem(item, profile)}
            {item.gridItemType === 'search' && <div style={{ overflowY: 'scroll', maxHeight: window.innerHeight - 150 }}>{GridSearchItem(item)}</div>}
            {item.gridItemType === 'content' && <GridContentItem id={item.gridItemID} editOpen={props.editOpen} data={item.data}
              saveItem={(data) => props.saveItem(item, { data })} orgID={profile && profile.orgID} />}
          </Card>
        })
      }
    </ResponsiveReactGridLayout>
  )
}

function GridChannelItem(item, profile) {
  return <div style={{ overflowY: 'scroll', maxHeight: window.innerHeight - 150 }}>
    <AppBar position="sticky" style={{ padding: 16 }}>Channel Feed{item.channelName && ` # ${item.channelName}`}</AppBar>
    {/* <Typography variant="h6" color="primary" style={{ margin: 16, }}></Typography> */}
    <LoadMore key={item.channelID} initialRows={3} increment={3}
      render={rowLimit => <ActivityFeed profile={profile} displayActivities={rowLimit} type="all" selectedChannel={item.channelID}
        where={item.channelID ? ['activityChannelList', 'array-contains', item.channelID]
          : ['activityChannelList', 'array-contains', 'noChannels']} />} />
  </div>
}

function GridSearchItem(item) {
  switch (item.searchType) {
    case 'map': return <ActivityMapViewer search={item} embedView={true} />
    case 'gallery': return <ActivityGalleryViewer search={item} embedView={true} />
    case 'report': return <AppReports search={item} embedView={true} />
    case 'task': return <TaskSearch search={item} embedView={true} />
    default: return <ActivityGalleryViewer search={item} embedView={true} />
  }
}
