import React, { useState } from 'react';
import AlertCard from '../standard/AlertCard';
import { Chip, Card, div } from '@material-ui/core';
import OpstackEnableLocation from '../img/OpstackEnableLocation.png'
import { getBrowserNameAndOSName } from '../standard/BrowserCheck';

export default function LocationPermissionAlert() {
  const [show, setShow] = useState(false)
  const permissionDenied = sessionStorage.getItem('locationPermissionDenied') && !sessionStorage.getItem('successLocation')

  return <AlertCard noIcon={show} message={permissionDenied ?
    <div>
      <div>Location permissions need to be enabled in {getBrowserNameAndOSName().browserName} browser for <a href="https://app.opstack.co/" target="_blank" rel="noopener noreferrer">app.opstack.co</a>. Please update location settings to allowed.</div>
      <Chip
        onClick={() => setShow(!show)}
        variant="outlined"
        label={!show ? 'Show Steps' : 'Hide Steps'}
        style={{ marginTop: 8, marginBottom: 12 }} />
      {show &&
        <Card style={{ padding: 0, margin: 0 }}>
          <img src={OpstackEnableLocation} width={window.innerWidth - 64} style={{ maxWidth: 500 }} alt='Done' />
        </Card>}
    </div>
    : "Please enable location (GPS) in your device settings"} style={{ margin: 16, marginBottom: 30 }} />;
}