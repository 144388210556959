import React, { useState } from 'react'
import { useFirestore } from "react-redux-firebase";
import 'ag-grid-community/dist/styles/ag-grid.css';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector } from 'react-redux';
import handlebars from 'handlebars'
//Using this as a work around for querying to redux state - multiple queries to redux state result in slow updates and jank
//Downside of this is that data changes are not pushed/refreshed 
export function useFirestoreQuery({ collection, whereClause = [], orderBy, limit }) {
  const [results, setResults] = useState({ counter: 0, results: 'NotRequested' });
  const [response, setResponse] = useState({ counter: 0, results: 'NotRequested' });
  const [request, setRequest] = useState({ counter: 0 });
  const profile = useSelector(state => state.firebase.profile)
  const compiledCollection = handlebars.compile(collection)(profile);

  const firestore = useFirestore();

  useDeepCompareEffect(() => {
    const fetchData = (counter) => {
      let firestoreQuery = firestore.collection(compiledCollection);
      for (let i = 0; i < whereClause.length; i++)
        if (whereClause[i]) { firestoreQuery = firestoreQuery.where(...whereClause[i]) }

      if (orderBy) firestoreQuery = firestoreQuery.orderBy(...orderBy)
      if (limit) firestoreQuery = firestoreQuery.limit(limit)

      return firestoreQuery.onSnapshot(qS => {
        let results = []
        if (qS.size !== 0) qS.forEach(doc => results.push(doc.data()))
        setResponse({ results, counter: counter })
      })
    };

    const newRequestCounter = request.counter + 1;
    setRequest({ counter: newRequestCounter })
    fetchData(newRequestCounter);

  }, [compiledCollection, whereClause, orderBy]);

  // console.log('firestoreQueryHook', response.counter, request.counter);
  if (response.counter === request.counter && results.counter < response.counter) setResults(response)

  if (request.counter === results.counter) return results.results
  else return 'Requested'
}