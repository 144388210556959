import React, { memo } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { displayLeft } from '../../utils/styleclasses'
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

export default function ListItemsSearchSelector({ optionListID, value, disabled, multiple, name, onChange, setElementRef }) {
  const profile = useSelector(state => state.firebase.profile)

  useFirestoreConnect([{
    collection: `organizations/${profile.orgID}/resources-optionlists`, where: [['optionListID', '==', optionListID]], storeAs: 'optionList' + optionListID
  }])
  const allValuesAndLabels = useSelector(({ firestore: { data } }) => data['optionList' + optionListID]
    && data['optionList' + optionListID][optionListID].options.map(option => ({ label: option.optionName, value: option.optionValue })))

  let selectedValues = allValuesAndLabels ? allValuesAndLabels.filter(item => value.includes(item.value)) : null

  if (disabled) return (selectedValues && selectedValues.map(row => row.label).join()) || ''

  if (selectedValues && selectedValues.length === 0) selectedValues = null

  return <span style={{ ...displayLeft, width: '100%' }} >
    <Autocomplete onChange={(event, newValue) => onChange(newValue || null)}
      getOptionSelected={(option, valueArray) => valueArray.map(item => item.value).includes(option.value)}
      multiple={multiple}
      disabled={disabled}
      value={selectedValues}
      options={allValuesAndLabels}
      getOptionLabel={(options) => options && options.length > 0 ? options.map(option => option.label).join() : (options.value ? options.label : '')}
      style={{ display: 'flex', width: '100%' }}
      renderInput={(params) =>
        <TextField  {...params} InputProps={{ ...params.InputProps, disableUnderline: true }}
          inputRef={input => input && setElementRef && setElementRef(input)}
          style={{ display: 'flex', width: '100%', paddingLeft: 4 }} name={name} />}
    />
  </span>
}



export const ListItemsDisplay = memo(({ optionListID, value, }) => {
  const profile = useSelector(state => state.firebase.profile)

  useFirestoreConnect([{
    collection: `organizations/${profile.orgID}/resources-optionlists`, where: [['optionListID', '==', optionListID]], storeAs: 'optionList' + optionListID
  }])
  const allValuesAndLabels = useSelector(({ firestore: { data } }) => data['optionList' + optionListID]
    && data['optionList' + optionListID][optionListID].options.map(option => ({ label: option.optionName, value: option.optionValue })))

  let selectedValues = allValuesAndLabels ? allValuesAndLabels.filter(item => value.includes(item.value)) : null

  let isValuePresentButNotFound = value && value.length > 0 && (!selectedValues || selectedValues.length === 0)
  if (isValuePresentButNotFound) return value.join()

  return (selectedValues && selectedValues.map(row => row.label).join()) || ''
}) 
