import React from 'react'
import TitleAppBar from '../../standard/TitleAppBar';
import Map from '@material-ui/icons/Map';
import QueryBuilderBar from '../QueryBuilderBar'
import ActivityQueryContainer from './ActivityQueryContainer';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';

export default function ActivityMapViewer({ search, embedView }) {
  const [reportQuery, setReportQuery] = React.useState(search || {});
  console.log('ActivityMapViewer', { reportQuery });
  return <div>
    <TitleAppBar icon={<Map />}>Map Viewer</TitleAppBar>
    <QueryBuilderBar setReportQuery={setReportQuery} embedView={embedView} reportQuery={reportQuery} />
    {reportQuery.appID ? <ActivityQueryContainer {...reportQuery} contentDisplayType="map" /> : <EmptyImagePlaceholder />}
  </div >
}