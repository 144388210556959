import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UpdateProfile from './UpdateProfile'
import ProfileSettings from './ProfileSettings'
import { updateProfile, signOut } from '../../store/actions/authActions'
import Person from '@material-ui/icons/Person';
import Settings from '@material-ui/icons/Settings';
import { TabPanel, a11yProps } from '../standard/TabPanel'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { deleteInvitedUser } from '../../store/actions/authActions'
import { landingPageDivStyle, displayCenter } from '../../utils/styleclasses';
import LogoAppBar from '../standard/LogoAppBar';
import { Typography } from '@material-ui/core';


function ProfileTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log('ProfileTabs(props) ', { props });
  const noOrg = props.profile && (!props.profile.orgID || props.orgSettingsNotFound)

  const orgIDMessage = <div>
    <Typography variant="caption">Check with your Organization Admin for a valid Org ID. Admins will receive the Org ID on email.
    </Typography></div>

  return (
    <div>
      {noOrg && <LogoAppBar />}
      {noOrg && <div style={{ ...displayCenter, margin: 24, display: "block" }}>
        <Typography variant={"h4"} color="primary">Get started with Opstack</Typography>
        <Typography variant={"h6"} style={{ marginTop: 16 }}>
          {props.orgSettingsNotFound ? orgIDMessage : "Please save your profile details"}
        </Typography>
      </div>}
      <div style={noOrg ? landingPageDivStyle(200) : null}>


        <div style={{ width: noOrg ? "30%" : "100%", minWidth: 300, }}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label={<span style={{ display: 'inline-flex' }}>
                <Person style={{ marginRight: 8 }} />
            Update Profile</span>}
                {...a11yProps(0)} />
              {props.profile.uid && <Tab label={<span style={{ display: 'inline-flex' }}> <Settings style={{ marginRight: 8 }} />
            Profile Settings</span>}
                {...a11yProps(0)} />}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} style={{ padding: 0 }}>
            <UpdateProfile auth={props.auth} profile={props.profile} usersinvitedmatch={props.usersinvitedmatch} updateProfile={props.updateProfile} signOut={props.signOut} deleteInvitedUser={props.deleteInvitedUser} orgIDMessage={orgIDMessage} />  </TabPanel>
          {props.profile.uid && <TabPanel value={value} index={1}>
            <ProfileSettings {...props} />
          </TabPanel>}
        </div>
      </div>
    </div>

  );
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    authError: state.auth.authError,
    usersinvitedmatch:
      state.firestore.ordered.usersinvitedmatch ? state.firestore.ordered.usersinvitedmatch : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (profile) => { dispatch(updateProfile(profile)) },
    signOut: () => dispatch(signOut()),
    deleteInvitedUser: (inviteID) => dispatch(deleteInvitedUser(inviteID)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'usersinvited',
        where: [
          ['email', '==', props.auth.email ? props.auth.email : 'Phone Login, No Match'],
        ], storeAs: 'usersinvitedmatch'
      },
    ]
  }))(ProfileTabs)
