import React from 'react';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import ProfileTabs from '../auth/ProfileTabs';
import LoadingSplashScreen from './LoadingSplashScreen';

export function setOrgIDToLocalStorageAndCleanup(profile) {
  if (profile && profile.isLoaded && (typeof profile.orgID !== 'undefined') && (localStorage.getItem("orgID") !== profile.orgID))
    localStorage.setItem("orgID", profile.orgID)

  if (localStorage.getItem('newOrgID') || localStorage.getItem('createID')) { localStorage.removeItem('newOrgID'); localStorage.removeItem('createID'); }
}

export function checkProfileAndRedirect(auth, profile, orgDocRequested, orgSettings) {
  /**
 * Paths
 * 1. Logged in with rights*
 * 2. Offline with profile === undefined --> Reload
 * 3. Online with profile.orgID === undefined/null -> Update ProfileTabs
 * 4. Online with profile === undefined --> Loading
 */

  console.log('appSignedInRoutesUtils', { auth, profile, orgDocRequested, orgSettings }, navigator.onLine);
  if (!auth.uid)
    return <Redirect to='/signin'></Redirect>;

  //If profile is still loading
  if (!profile.isLoaded || !orgDocRequested)
    return <LoadingSplashScreen />;


  if (profile && profile && profile.isLoaded && profile.active === false)
    return <LoadingSplashScreen message="Profile Deactivated. Please contact your organization's administrator or support@opstack.co" noLoader={true} />;

  if (profile.isLoaded && (!profile.orgID || (profile.orgID && typeof orgSettings === 'undefined'))) {
    if (navigator.onLine) {
      console.log('appSignedInRoutesUtils 1', { auth, profile, orgDocRequested, orgSettings }, navigator.onLine);
      return <ProfileTabs profile={profile} auth={auth} orgSettingsNotFound={(profile.orgID && typeof orgSettings === 'undefined')} />;
    }
    //If profile is not loaded and offline, try reloading
    else
      return <Button variant="outlined" style={{ width: "90vw", margin: 24, }} onClick={() => window.location.reload()}>      Use Offline      </Button>;
  }

  //If orgSettings not loaded
  if (profile && profile.active && orgSettings === null)
    return <LoadingSplashScreen message="Loading Settings" />;

  return null;
}
