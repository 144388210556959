import React, { useState } from "react";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ResponsiveGrid from "./ResponsiveGrid";
import CardAddSelector from "../dashboard/CardAddSelector";
import { displayLeft, displayRight } from "../../utils/styleclasses";
import { fieldIDGenerator } from "../../utils/constants";
import UserAppAddSelector from "./UserAppAddSelector";
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ChannelAddSelector from "./ChannelAddSelector";
import { Chip } from "@material-ui/core";
import EmptyImagePlaceholder from "../standard/EmptyImagePlaceholder";
import SearchAddSelector from "./SearchAddSelector";
import { iconTextAlign } from "../../utils/iconutils";
import { AddCircle } from "@material-ui/icons";

export default function GridTabContainer(props) {
  const [open, setOpen] = useState(false);//change back
  const gridItems = props.gridItems || []
  const areAllGridItemsCards = !gridItems.find(item => item.gridItemType !== 'card')

  if (props.gridLayout) {
    if (!localStorage.getItem(`gridLayout-${props.tabID}`) || !props.isPageEditor)
      localStorage.setItem(`gridLayout-${props.tabID}`, JSON.stringify({ layouts: props.gridLayout }))
  }
  console.log('GridTabContainer layout props', props.gridLayout);
  return (
    <div>
      {gridItems && gridItems.length > 0 ?
        <ResponsiveGrid gridItems={gridItems} gridID={props.tabID}
          layouts={global.localStorage.getItem(`gridLayout-${props.tabID}`) ? JSON.parse(global.localStorage.getItem(`gridLayout-${props.tabID}`)).layouts : {}}
          editOpen={open}
          deleteItem={(gridItemID) => props.saveGrid({ gridItems: gridItems.filter(item => item.gridItemID !== gridItemID) })}
          copyItem={(gridItemID) =>
            props.saveGrid({ gridItems: [...gridItems, { ...gridItems.find(item => item.gridItemID === gridItemID), gridItemID: fieldIDGenerator() }] })}
          saveItem={(item, data) => {
            console.log('GridTabContainer - Save Item Content', { item, data });
            props.saveGrid(
              // { gridItems: [...(gridItems || []), { ...gridItems.find(i => i.gridItemID === item.gridItemID), ...data }] }
              { gridItems: gridItems.map(i => i.gridItemID === item.gridItemID ? { ...item, ...data } : i) }
            )
          }
          }
        />
        : <EmptyImagePlaceholder message="No items Added To this page yet" />
      }
      {!open && <div style={{ ...displayRight, flexWrap: 'wrap' }}>
        {areAllGridItemsCards &&
          <Chip color="primary" icon={<Refresh />} label='Refresh All' variant='outlined'
            onClick={() => props.refreshPageCards(props.tabID)} title="Refresh All " style={{ margin: 8 }}>
          </Chip>}
        {props.isPageEditor && <Chip color="primary" icon={<Edit style={{ padding: 4 }} />} label='Edit Layout' variant='outlined'
          onClick={() => setOpen(true)} title="Edit Grid Page" style={{ margin: 8 }}>
        </Chip>}
      </div>
      }
      {open && props.isPageEditor && GridEditorFooter()}
    </div>
  );

  function GridEditorFooter() {
    return <div style={{ marginLeft: 8, ...displayLeft, flexWrap: 'wrap' }}>
      <CardAddSelector
        handleAdd={(card) => props.saveGrid({ gridItems: [...gridItems, { ...card, gridItemID: fieldIDGenerator(), gridItemType: 'card' }] })} />
      <UserAppAddSelector
        handleAdd={(app) => props.saveGrid({ gridItems: [...gridItems, { ...app, gridItemID: fieldIDGenerator(), gridItemType: 'app' }] })} />
      <ChannelAddSelector
        handleAdd={(channel) => props.saveGrid({ gridItems: [...gridItems, { ...channel, gridItemID: fieldIDGenerator(), gridItemType: 'channel' }] })} />
      <SearchAddSelector
        handleAdd={(channel) => props.saveGrid({ gridItems: [...gridItems, { ...channel, gridItemID: fieldIDGenerator(), gridItemType: 'search' }] })} />
      <Chip variant="outlined" color="primary" style={{ margin: 8 }} id='addContentItem'
        label={iconTextAlign(<AddCircle />, 'Content')}
        onClick={(e) => {
          props.saveGrid({ gridItems: [...gridItems, { gridItemID: fieldIDGenerator(), gridItemType: 'content' }] })
        }} />
      <Chip variant="default" color="secondary" style={{ margin: 8 }} id='saveLayoutButton'
        label={iconTextAlign(<Save style={{ color: 'white' }} />, 'Save Layout')}
        onClick={(e) => {
          let gridLayout = localStorage.getItem(`gridLayout-${props.tabID}`);
          if (gridLayout) {
            gridLayout = JSON.parse(gridLayout);
            console.log('GridTabContainer saving layout', gridLayout.layouts);
            props.saveGrid({ gridLayout: (gridLayout && gridLayout.layouts) || null });
            setOpen(false);
          }
        }} />
      <Button variant="text" color="primary" onClick={(e) => setOpen(false)} style={{ marginLeft: 8 }}>
        <VisibilityOff style={{ marginRight: 8 }} />Hide Editor</Button>
    </div>;
  }
}
