import React from 'react';
import AppRenderContextProvider from './AppRenderContext';
import TitleAppBar from '../standard/TitleAppBar';
import AppRenderScreen from './AppRenderScreen';
import AppRenderFooter from './AppRenderFooter';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GetLocation from '../standard/GetLocation';

export function AppRender({ goToOnAppClose, app, profile, submitApp, hideAppBar }) {

  return (
    <div>
      { !hideAppBar &&
        <TitleAppBar>
          <IconButton edge="start" style={{ margin: 0, marginRight: 8, padding: 8, color: "white" }}
            onClick={goToOnAppClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          {app.appName}
        </TitleAppBar>}
      <AppRenderContextProvider data={app} profile={profile}>
        <AppRenderScreen />
        <AppRenderFooter goToOnAppClose={goToOnAppClose} submitApp={submitApp} profile={profile} />
      </AppRenderContextProvider>
      <GetLocation />
    </div>
  );
}
