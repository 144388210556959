import React from 'react'
import Loader from '../standard/Loader'
import { Typography, } from '@material-ui/core';
import PersonalVideo from '@material-ui/icons/PersonalVideo';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import InboxCard from './InboxCard';
import EmptyImagePlaceholder from '../standard/EmptyImagePlaceholder';
import MoveToInbox from '@material-ui/icons/MoveToInboxTwoTone';
import { displayLeft } from '../../utils/styleclasses';

const InboxFeed = (props) => {
  return (
    <div >
      <div style={{ margin: 16, ...displayLeft }}>
        <MoveToInbox color='primary' style={{ fontSize: 30, marginRight: 16 }} />
        <Typography variant="h5" color="primary">My Inbox</Typography>
      </div>
      {
        (props.inbox && props.inbox.length > 0) ?
          <div>{props.inbox.map((message, index) => <InboxCard key={message.referenceID + index}
            from={message.sentByName}
            type={message.type}
            title={message.title}
            date={message.clickedAt && message.clickedAt.toDate()}
            referenceID={message.referenceID}
            reference={message.reference}
          // message={message} 
          />)}</div>
          : (props.inbox && props.inbox.length === 0) ?
            <EmptyImagePlaceholder imageType="bench"
              icon={<PersonalVideo style={{ marginRight: 8, fontSize: 40 }} />} message={"No messages yet"} /> : <Loader />
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    inbox: state.firestore.ordered && state.firestore.ordered.inbox,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID,
        subcollections: [{
          collection: 'userdata', doc: props.profile.uid,
          subcollections: [{ collection: 'inbox', }]
        }], limit: props.rowLimit, orderBy: ['clickedAt', 'desc'],
        storeAs: 'inbox'
      }
    ]
  }
  )
)(InboxFeed)