import React from 'react'
import FileCopy from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import AlertSnackbar from './AlertSnackbar';
import Chip from '@material-ui/core/Chip';

export default function CopyToClipChip(props) {
  const [alertState, setAlertState] = React.useState({
    open: false, vertical: 'bottom', horizontal: 'center', message: '',
  });

  const handleCopy = (id) => {
    console.log(id);
    navigator.clipboard.writeText(id);
    setAlertState({ ...alertState, message: `${id} copied`, open: true, alertTimeout: 500 });
  }


  return (
    <span key={props.id} style={{ display: 'flex-inline', alignItems: 'center', padding: 4 }} >
      {
        props.isChip === true ? <Chip variant='outlined' color='primary' style={{ margin: 4, ...props.style }} onClick={() => handleCopy(props.id)}
          label={props.name || props.id} icon={<FileCopy style={{ fontSize: 16 }} />}
        />

          :
          <Button variant='outlined' color='primary' style={{ margin: 4, ...props.style }} onClick={() => handleCopy(props.id)} >
            <FileCopy style={{ marginRight: 8 }} />{props.name || props.id}</Button>
      }
      <AlertSnackbar alertState={alertState} setAlertState={setAlertState} />
    </span>
  )
}
