import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

export default function ProfileSettings(props) {

  return (
    <div>
      <Card style={{ margin: 16, padding: 16 }}>
        <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
          Activity Feed Image Settings</Typography>
        <Divider style={{ marginBottom: 8 }} />
        <RadioGroup aria-label="position" name="position" value={props.profile.feedImageSettings || "low"}
          onChange={(e) => {
            console.log(e.target.value);
            props.updateProfile({ ...props.profile, feedImageSettings: e.target.value });
          }}  >
          <FormControlLabel
            value="high"
            control={<Radio color="primary" />}
            label="High Quality Images (Requires More Bandwidth)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="low"
            control={<Radio color="primary" />}
            label="Low Quality Images (Faster Downloads)"
            labelPlacement="end"
          />
        </RadioGroup>
      </Card>
      <Card style={{ margin: 16, padding: 16 }}>
        <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
          Camera Settings</Typography>
        <Divider style={{ marginBottom: 8 }} />
        <RadioGroup aria-label="position" name="position" value={props.profile.cameraToUse || "device"}
          onChange={(e) => {
            console.log(e.target.value);
            props.updateProfile({ ...props.profile, cameraToUse: e.target.value });
          }}  >
          <FormControlLabel
            value="inapp"
            control={<Radio color="primary" />}
            label="Use In-App Camera (Faster)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="device"
            control={<Radio color="primary" />}
            label="Use Device Camera"
            labelPlacement="end"
          />
        </RadioGroup>
      </Card>
      <Card style={{ margin: 16, padding: 16 }}>
        <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
          App Release Date</Typography>
        <Divider style={{ marginBottom: 8 }} />
        {localStorage.getItem('buildDate')}
      </Card>
      <Card style={{ margin: 16, padding: 16 }}>
        <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
          App Version</Typography>
        <Divider style={{ marginBottom: 8 }} />
        {localStorage.getItem('buildID')}
      </Card>
      <Card style={{ margin: 16, padding: 16 }}>
        <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
          User System UID</Typography>
        <Divider style={{ marginBottom: 8 }} />
        {props.profile.uid}
      </Card>
      {(props.profile && props.profile.uid && localStorage.getItem("FCMToken")) &&
        <Card style={{ margin: 16, padding: 16 }}>
          <Typography color="primary" variant="h5" style={{ marginBottom: 8 }}>
            FCM Token</Typography>
          <Divider style={{ marginBottom: 8 }} />
          {localStorage.getItem("FCMToken")}
        </Card>
      }

    </div>
  )
}
