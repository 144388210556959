import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { isJSON } from '../../../utils/utilityfunctions';
import { getJSONfromString } from '../../../utils/stringutils';

export default function MyResponsiveLine(props) {

  const name1Label = props.name1Label;

  const dataSeries = props.data && props.data.length > 0 && [...new Set(props.data.map(row => row[props.dataSeries]))]

  const data = dataSeries.map((series, i) => {
    const seriesData = props.data.filter(row => row[props.dataSeries] === series).map(row => ({ x: row[name1Label], y: row[props.value1Label], line: series }));
    return { id: series, data: seriesData }
  })
  const cardSettings = props.cardSettings ? isJSON(getJSONfromString(props.cardSettings)) : null

  const settings = {
    margin: { top: 50, right: 20, bottom: 200, left: 100 },
    xScale: { type: 'point' },
    yFormat: '>-.2f',
    axisTop: null,
    axisRight: null,
    axisBottom: {
      orient: 'bottom',
      tickSize: 0,
      tickPadding: 5,
      tickRotation: -75,
      legend: props.name1Label,
      legendOffset: 75,
      legendPosition: 'middle'
    },
    // tooltip: d => `${d.point.data.line}, ${d.point.data.x}, ${d.point.data.y}`,
    axisLeft: {
      orient: 'left',
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.value1Label,
      legendOffset: -60,
      legendPosition: 'middle'
    },
    pointSize: 10,
    pointColor: { theme: 'background' },
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    pointLabelYOffset: -12,
    useMesh: true,
    ...cardSettings,

    legends:
      [{
        anchor: 'bottom',
        direction: 'row',
        // justify: false,
        // translateX: 100,
        translateY: 150,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
        ,
        ...cardSettings && cardSettings.legends ? cardSettings.legends[0] : null
      }
      ],
  }

  return <ResponsiveLine data={data}    {...settings} />
}