import { Chip, Divider, TextField, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Save from '@material-ui/icons/Save';
import Share from '@material-ui/icons/Share';
import React, { Suspense } from 'react';
import { deletePageSendSchedule, updatePageSendSchedule } from '../../store/actions/formActions';
import { fieldIDGenerator } from '../../utils/constants';
import { iconTextAlign } from '../../utils/iconutils';
import { displayLeft, divLightCard } from '../../utils/styleclasses';
import EmptyImagePlaceholder from '../standard/EmptyImagePlaceholder';
import GroupSearchSelector from '../standard/GroupSearchSelector';
import PopoverButton from '../standard/PopoverButton';
import { TabPanel } from '../standard/TabPanel';
import UserSearchSelector from '../standard/UserSearchSelector';
import { TabSortableField } from './TabSortableField';
import { connect } from 'react-redux';
import { compose } from 'redux';
const SortableFields = React.lazy(() => import('../standard/SortableFields'))

export function PageSettings({ isPageEditor, isMyPage, tabValue, selectedPage, setSelectedPage, tabs, updatePage, pages, deletePage, isSubPage, uid, updateSchedule, deleteSchedule }) {
  return <TabPanel value={tabValue} index={0}>
    <Typography variant="h5" color="primary" style={{ width: '100%', margin: 16 }}> Page Settings </Typography>
    <Divider style={{ width: '100%', margin: 16 }} />
    <div style={displayLeft}>
      {!isMyPage && !isSubPage && <TextField value={selectedPage.pageLabel} style={{ margin: 16, width: 400 }} id='pageName'
        onChange={e => setSelectedPage({ ...selectedPage, pageLabel: e.target.value })} />}
      <Chip id='addTab'
        label={iconTextAlign(<Add />, "Add Tab")}
        onClick={() => setSelectedPage({
          ...selectedPage, tabs: [...selectedPage.tabs || [], { tabLabel: 'Tab ' + tabs.length, tabID: fieldIDGenerator(), tabType: 'embed', embedType: 'link', showOnMobile: false }]
        })}
        style={{ margin: 8 }} variant="outlined" color="primary" />
      {!isSubPage && <Chip id='addPage'
        label={iconTextAlign(<Add />, "Add Page")}
        onClick={() => {
          const newPage = { pageLabel: 'New Page', pageID: fieldIDGenerator(), groupList: ['all'], pageEditors: [uid], tabs: [{ tabID: fieldIDGenerator(), tabLabel: 'First Tab', tabType: 'embed', embedType: 'link' }] };
          updatePage(newPage, newPage.pageID);
          setSelectedPage(newPage);

        }}
        style={{ margin: 8 }} variant="outlined" color="primary" />}
      {!isSubPage && !isMyPage && deletePage && <PopoverButton buttonProps={{ style: { marginRight: 4 } }} showCloseIcon={true} closePopoverOnClick={true}
        useChip={true} style={{ margin: 4 }} icon={<Delete />} variant="outlined" text="Delete Page" id='deletePage'
        popoverContent={
          <div style={{ padding: 4, display: 'flex-wrap' }}>
            <Typography variant="h5" color="error" style={{ textAlign: 'left', margin: 8, marginRight: 30, width: '100%' }}>
              Confirm Page Deletion
            </Typography>
            <Chip
              id='confirmDeletePage'
              variant="outlined" clickable color="error"
              style={{ padding: 15, margin: 5 }}
              onClick={() => {
                if (!isMyPage) {
                  deletePage(selectedPage.pageID);
                  setSelectedPage(pages.find(row => row.pageID === 'myPage'));
                }
              }}
              label='Delete'
            />
          </div>
        } />

      }
      <Chip id='savePage' label={iconTextAlign(<Save />, "Save Page", { color: 'fff' })}
        onClick={() => updatePage(selectedPage, selectedPage.pageID)}
        style={{ margin: 8 }} color="primary" />
    </div>
    {!isSubPage && !isMyPage && <div style={{ ...divLightCard, margin: 16, padding: 16, display: 'flex-wrap' }}>

      <GroupSearchSelector quickEdit={true} style={{ width: '100%' }}
        // inputLabel={<Share color="primary" />}
        inputLabel={iconTextAlign(<Share />, <Typography style={{ width: 100 }} color="primary">Groups</Typography>)}
        selectedValues={typeof selectedPage.groupList === 'undefined' ? ['all'] : selectedPage.groupList}
        handleSave={(groupList) => setSelectedPage({ ...selectedPage, groupList })} />
      <Divider style={{ margin: 16 }} />
      <UserSearchSelector
        id={'sharePageToUsers'}
        selectedValues={typeof selectedPage.userList === 'undefined' ? [] : selectedPage.userList} style={{ width: '100%' }}
        quickEdit={true}
        inputLabel={iconTextAlign(<Share />, <Typography style={{ width: 100 }} color="primary">Users</Typography>)}
        handleSave={(userList) => setSelectedPage({ ...selectedPage, userList: userList })} />
      <Divider style={{ margin: 16 }} />
      <UserSearchSelector
        id={'pageEditors'}
        selectedValues={selectedPage.pageEditors} style={{ width: '100%' }}
        quickEdit={true} inputLabel={<Typography style={{ width: 130 }} color="primary">Page Editors</Typography>}
        handleSave={(userList) => setSelectedPage({ ...selectedPage, pageEditors: userList })} keepFirstValue={true} />
    </div>}

    {tabs && tabs.length > 0 ? <div style={{ maxWidth: 1000, ...divLightCard, ...displayLeft, margin: 16, padding: 16, display: 'block' }}>
      <Typography variant="h5" color="primary" style={{ width: '100%' }}> Tab Settings </Typography>
      <Suspense fallback={<div> Loading...</div>}>
        <SortableFields
          fieldList={tabs.map(row => ({ ...row, fieldID: row.tabID }))}
          valueBundle={{ ...selectedPage, isSubPage }}
          handleBundle={{
            updateSchedule, deleteSchedule,
            handleSettings: (tabValue) => { },
            handleChange: (fieldName, value, tabID) => setSelectedPage({ ...selectedPage, tabs: selectedPage && tabs.map(row => row.tabID === tabID ? { ...row, [fieldName]: value } : row) }),
            handleFieldSortCompletion: (tabs) => setSelectedPage({ ...selectedPage, tabs }),
            handleFieldDelete: (tabID) => setSelectedPage({ ...selectedPage, tabs: tabs.filter(row => row.tabID !== tabID) }),
            handleFieldCopy: (tabID) => {
              const tabToCopy = tabs.find(row => row.tabID === tabID);
              setSelectedPage({
                ...selectedPage, tabs: [...selectedPage.tabs, {
                  ...tabToCopy,
                  tabLabel: tabToCopy.tabLabel + ' Copy ' + (tabs.length + 1),
                  tabID: 'tab_' + fieldIDGenerator()
                }]
              });
            }
          }}
          SortableField={TabSortableField} />
      </Suspense>
    </div>
      : <EmptyImagePlaceholder message='No Custom Tabs created on this page yet' imageType='bench' />
    }
  </TabPanel>
}

const mapDispatchToProps = (dispatch) => ({
  updateSchedule: (schedule) => dispatch(updatePageSendSchedule(schedule)),
  deleteSchedule: (scheduleID) => dispatch(deletePageSendSchedule(scheduleID))
})

export default compose(connect(null, mapDispatchToProps))(PageSettings)