import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loader from '../standard/Loader'
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import DateRangePopover from '../standard/DateRangePopover';
import { Redirect } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { mergeArrays } from '../../utils/arrayutils'
import AppSearchSelector from '../standard/AppSearchSelector'
import UserSearchSelector from '../standard/UserSearchSelector'
import Settings from '@material-ui/icons/Settings';
import { updateSearch, deleteSearch } from '../../store/actions/formActions'
import PopoverButton from '../standard/PopoverButton'
import { TextField } from '@material-ui/core'
import { fieldIDGenerator } from '../../utils/constants'
import Delete from '@material-ui/icons/Delete';

const QueryBuilderBar = ({ apps, profile, auth, contentDisplayType, reportQuery, setReportQuery, disableAllUsers, usersRequired, allApps, searches, updateSearch, deleteSearch, embedView }) => {

  const [filters, setFilters] = React.useState({
    appID: '',
    uid: disableAllUsers ? profile.uid : 'all',
    fieldID: 'all',
    contentDisplayType,
    startDate: moment().subtract(1, 'weeks').startOf('day').toDate(),
    endDate: moment().subtract(1, 'days').endOf('day').toDate(),
    ...reportQuery
  });

  const setDateRange = (startDate, endDate, getDateRange) => setFilters({ ...filters, startDate, endDate });

  if (!auth.uid) return <Redirect to='/signin'></Redirect>
  if (typeof apps === 'undefined') return <Loader />
  if (!profile.viewreports) return (<Loader />)
  if (filters.appID === '') setFilters({ ...filters, appID: allApps ? 'all' : apps[0].appID })

  const selectedApp = apps && filters.appID && apps.find(app => app.appID === filters.appID)
  const allFields = selectedApp ? [...mergeArrays(selectedApp.screens.map(screen => screen.fieldList))] : []
  const imageFields = allFields.filter(field => field.fieldType.includes('camera')).sort((a, b) => (a.fieldLabel > b.fieldLabel ? 1 : -1))
  // const selectFields = allFields.filter(field => field.fieldType.includes('select')).sort((a, b) => (a.fieldLabel > b.fieldLabel ? 1 : -1))
  const RunSearch = () => <Chip style={{ width: '100%', margin: 8, padding: 8 }} color="secondary"
    disabled={(usersRequired && !filters.uid) || false} id="searchButton"
    onClick={() => { setReportQuery(filters) }}
    label="Search" />

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', }}>
        {!embedView && <AppSearchSelector
          allApps={allApps || false}
          selectedValues={filters.appID ? [filters.appID] : []}
          // style={{ minWidth: 400 }}
          quickEdit={true}
          inputLabel={""}
          handleSave={(appAssigned) => { setFilters({ ...filters, appID: (appAssigned && appAssigned[0] && appAssigned[0].value) || null, searchID: null, searchName: '' }) }}
        />}
        {!embedView && <UserSearchSelector
          allUsers={!disableAllUsers}
          hasDefault={false}
          selectedValues={filters.uid ? [filters.uid] : []}
          multiple={false}
          // style={{ minWidth: 400 }}
          quickEdit={true}
          inputLabel={""}
          handleSave={(userList) => { setFilters({ ...filters, uid: userList && userList[0] && userList[0].value, searchID: null, searchName: '' }) }} />}
        {selectedApp && contentDisplayType === 'gallery' &&
          <ImageFieldSelect {...{ filters, setFilters, imageFields, searchID: null, searchName: '' }} />}
        <DateRangePopover
          style={{ marginLeft: 8 }}
          startDate={filters.startDate}
          endDate={filters.endDate}
          setDateRange={setDateRange}
          iconSize={40}
          defaultDateRange={"Last 7 Days"} />

        {RunSearch()}
        {!embedView && SaveSearchSettings()}
      </div>
      {/* {selectFields.map(field =>
        <div key={field.fieldID} style={{ maxWidth: '30%', marginLeft: 8 }}>
          <ListItemsSearchSelector
            multiple={true}
            name={field.fieldLabel}
            optionListID={field.fieldSettings.selectOptions.selectedOptionListID}
            value={filters && filters.fieldFilters ? filters.fieldFilters[field.fieldID] || [] : []}
            onChange={(value) =>  {
              console.log('QueryBuilderBar', value);
              setFilters({ ...filters, fieldFilters: { ...filters.fieldFilters, [field.fieldID]: value && value.map(item => item.value) }, searchID: null, searchName: '' })
            }}
          />
        </div>)} */}
    </div >
  )

  function SaveSearchSettings() {
    return <PopoverButton buttonProps={{ style: { marginRight: 4 } }} showCloseIcon={true} useChip={true} style={{ margin: 4 }} text={<Settings />} variant="outlined"
      popoverContent={<div style={{ padding: 8, display: 'block', textAlign: 'left', width: 400 }}>
        <Typography variant="h5" color="error" style={{ textAlign: 'left', margin: 8, marginRight: 30 }}>Saved Searches</Typography>
        <Select value={filters.searchID || 'newSearch'} style={{ width: '100%', margin: 8 }}
          onChange={(e) => setFilters({
            ...filters, ...(!e.target.value || e.target.value === 'newSearch') ? { searchID: null, searchName: '' } :
              {
                ...(searches && searches.length > 0) ? searches.find(search => search.searchID === e.target.value) : {}
              }
          })}>
          <MenuItem value='newSearch'>Save As New Search</MenuItem>
          {searches && searches.length > 0 && searches.map(search => <MenuItem key={search.searchID} value={search.searchID}>{search.searchName}</MenuItem>)}
        </Select>
        <Typography variant='caption' style={{ margin: 8 }}>{filters.searchID ? 'Edit Search Name' : "New Search Name"}</Typography>
        <TextField value={filters.searchName} style={{ width: '100%', marginTop: 0, margin: 8 }} onChange={e => setFilters({ ...filters, searchName: e.target.value })}></TextField>
        {filters.searchID && <div>{RunSearch()}</div>}
        <Chip variant="default" color="secondary" clickable style={{ padding: 16, margin: 8, width: '45%' }}
          onClick={() => {
            const searchID = filters.searchID || fieldIDGenerator()
            updateSearch({ ...filters, searchID }, searchID)
            setFilters({ ...filters, searchID })
          }} label='Save Search' />
        {filters.searchID && <Chip variant="outlined" clickable color="error" style={{ padding: 16, margin: 8, width: '45%' }} onClick={() => {
          if (!filters.searchID)
            return null
          deleteSearch(filters.searchID)
          setFilters({ ...filters, searchID: null, searchName: '' })
        }} label={<Delete />} />}
      </div>} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (page, id) => dispatch(updateSearch(page, id)),
    deleteSearch: (id) => dispatch(deleteSearch(id)),
  }
}


const mapStateToProps = (state) => {
  return {
    apps: state.firestore.ordered.apps,
    searches: state.firestore.ordered.searches,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'apps' }],
        orderBy: ['appName', 'asc'], storeAs: 'apps'
      },
      {
        collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'searches' }],
        orderBy: ['searchName', 'asc'], storeAs: 'searches'
      },
    ]
  }
  )
)(QueryBuilderBar)


function ImageFieldSelect({ filters, setFilters, imageFields }) {
  return imageFields && imageFields.length > 0 ?
    <FormControl style={{ width: "100%", margin: 8, padding: 8, }}>
      <InputLabel>Field Name</InputLabel>
      <Select
        variant="standard"
        value={filters.fieldID || 'all'}
        style={{ width: "100%", margin: 8, }}
        onChange={(e) => setFilters({ ...filters, fieldID: e.target.value, })}>
        <MenuItem key={'all'} value={'all'}>All Image Fields</MenuItem>
        {imageFields.map(field => <MenuItem key={field.fieldID} value={field.fieldID}>{field.fieldLabel}</MenuItem>)}
      </Select>
    </FormControl>
    : <Typography style={{ width: "100%", margin: 8, padding: 8, }}> No Image Fields In App </Typography>
}
