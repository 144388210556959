import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { DynamicComponent } from '../../../utils/constants'
import GeoLocation from '../../standard/GeoLocation';
import { Divider } from '@material-ui/core';
import AppRenderSelectComponent from './AppRenderSelectComponent';
import AppRenderCardComponent from './AppRenderCardComponent';
import MapLocationPicker from '../../standard/MapLocationPicker';
import UserSearchSelector from '../../standard/UserSearchSelector';

function AppRenderElement({ field, componentProps, handleChange }) {

  switch (field.fieldType) {
    case 'text':
    case 'email':
    case 'phone':
      return TextElement()

    case 'integer':
    case 'number': return NumberElement()

    case 'select': return SelectElement()

    case 'switch':
    case 'checkbox':
      return SwitchElement()

    case 'time':
    case 'datetime':
    case 'date':
      return DateTimeElement()

    case 'year':
    case 'monthYear': return MonthYearElement()
    case 'locationPicker': return <MapLocationPicker height='300px' zoom={15} location={field.fieldValue || null}
      onChange={(location) => handleChange(field.fieldID, location)} />

    case 'location':
      return <div key={field.fieldID} style={{ width: "100%", padding: 10 }} name={field.fieldLabel} ><GeoLocation /> </div>;

    case 'card': return <AppRenderCardComponent field={field} name={field.fieldLabel} cardID={field.fieldSettings.cardSelector.value} dataPathPrefix="appdata-" />

    case 'userSelector': return <UserSearchSelector
      id={field.fieldID}
      key={field.fieldID}
      hasDefault={false}
      selectedValues={(field.fieldValue) || []}
      quickEdit={true}
      inputLabel={""}
      returnWithLabels={true}
      multiple={(field.fieldSettings.multiple && field.fieldSettings.multiple.value) || false}
      allUsers={(field.fieldSettings.allUsers && field.fieldSettings.allUsers.value) || false}
      handleSave={(userList) => handleChange(field.fieldID, userList.map(user => user.value))} />
    default: return null;
  }

  function MonthYearElement() {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}
      style={{ width: "100%", padding: 8, marginTop: 8 }}>
      <DatePicker
        views={field.fieldType === 'monthYear' ? ["month", "year"] : [field.fieldType]}
        id={field.fieldID}
        name={field.fieldLabel}
        style={{ width: "100%" }}
        onChange={(date) => {
          handleChange(field.fieldID,
            field.fieldType === 'monthYear' ?
              moment(date).format('MMM-YYYY').toString() : moment(date).format('YYYY').toString()
          );
        }}
        value={field.fieldValue ? (new Date(field.fieldValue)) : handleChange(field.fieldID,
          field.fieldType === 'monthYear' ?
            moment().format('MMM-YYYY').toString() : moment().format('YYYY').toString()
        )} />
    </MuiPickersUtilsProvider>;
  }


  function DateTimeElement() {
    const DateTimeComponent = DynamicComponent[field.fieldType];
    // if (!field.fieldValue) handleChange(field.fieldID, new Date());

    return <MuiPickersUtilsProvider utils={DateFnsUtils}
      style={{ width: "100%", padding: 8, marginTop: 8 }}>
      <DateTimeComponent
        id={field.fieldID}
        name={field.fieldLabel}
        style={{ width: "100%" }}
        onChange={(date) => { handleChange(field.fieldID, date) }}
        value={field.fieldValue ? ((field.fieldValue.toDate && field.fieldValue.toDate()) || field.fieldValue) : null} />
    </MuiPickersUtilsProvider>
  }

  function SwitchElement() {
    const CheckComponent = DynamicComponent[field.fieldType];
    return <div onClick={() => handleChange(field.fieldID, !field.fieldValue)} style={{ width: "30%" }}>
      <FormControlLabel
        required={componentProps.required}
        disabled={componentProps.disabled}
        error={componentProps.error}
        control=
        {<CheckComponent id={field.fieldID} checked={!!field.fieldValue} name={field.fieldLabel} arial-label={field.fieldLabel}
        />} />
      <Divider />
    </div>;
  }

  function SelectElement() {
    console.log('SelectElement', field, componentProps);

    return <FormControl
      label={field.fieldLabel}
      key={'selectFC' + field.fieldID}
      style={{ width: "100%" }}
      required={componentProps.required}
      disabled={componentProps.disabled}>
      <AppRenderSelectComponent
        disabled={componentProps.disabled}
        name={field.fieldLabel}
        optionListID=
        {(field.fieldSettings && field.fieldSettings.selectOptions && field.fieldSettings.selectOptions.selectedOptionListID)
          || null}
        field={field}
        onChange={(value) => { handleChange(field.fieldID, value) }}
      />
    </FormControl>
  }

  function TextElement() {
    const [value, setValue] = React.useState(field.fieldValue || '')
    return <TextField
      name={field.fieldLabel}
      id={field.fieldID}
      style={{ width: "100%" }}
      required={componentProps.required}
      disabled={componentProps.disabled}
      value={value}
      onChange={(e) => {
        console.log('Apprendercontext', e.target.value);
        handleChange(field.fieldID, e.target.value); setValue(e.target.value)
      }}
      inputProps={(field.fieldType === 'number') ? { type: 'number' } : { type: 'text' }}
      multiline={field.fieldSettings.numberOfLines && parseInt(field.fieldSettings.numberOfLines.value) > 0}
      rows={field.fieldSettings.numberOfLines ? parseInt(field.fieldSettings.numberOfLines.value) : 1}
    />
  }

  function NumberElement() {
    const [value, setValue] = React.useState(field.fieldValue || '')
    return <TextField
      name={field.fieldLabel}
      id={field.fieldID}
      style={{ width: "100%" }}
      required={componentProps.required}
      disabled={componentProps.disabled}
      value={value}
      onChange={(e) => {
        if (field.fieldSettings.minValue &&
          (field.fieldSettings.minValue.value || field.fieldSettings.minValue.value == '0') &&
          Number(e.target.value) < Number(field.fieldSettings.minValue.value)) return null
        if (field.fieldSettings.maxValue &&
          (field.fieldSettings.maxValue.value || field.fieldSettings.maxValue.value == '0') &&
          Number(e.target.value) > Number(field.fieldSettings.maxValue.value)) return null
        handleChange(field.fieldID, e.target.value); setValue(e.target.value)
      }}
      inputProps={{ type: 'number' }}
    />
  }
}

export default React.memo(AppRenderElement,
  (prevProps, nextProps) => {
    const arePropsUnchanged = JSON.stringify(prevProps.componentProps) === JSON.stringify(nextProps.componentProps);
    const areFieldValuesUnchanged =
      prevProps.field.fieldValue === nextProps.field.fieldValue && prevProps.field.fieldLabel === nextProps.field.fieldLabel;

    let areFieldSettingsUnchanged = true;
    if (prevProps.field.fieldSettings && nextProps.field.fieldSettings)
      if (prevProps.field.fieldSettings.selectOptions || nextProps.field.fieldSettings.selectOptions) {
        areFieldSettingsUnchanged = (prevProps.field.fieldSettings.selectOptions && nextProps.field.fieldSettings.selectOptions)
          && prevProps.field.fieldSettings.selectOptions.selectedOptionListID === nextProps.field.fieldSettings.selectOptions.selectedOptionListID
      } else if (prevProps.field.fieldSettings.cardSelector || nextProps.field.fieldSettings.cardSelector) {
        areFieldSettingsUnchanged =
          prevProps.field.fieldSettings.cardSelector.value === nextProps.field.fieldSettings.cardSelector.value
      }

    return arePropsUnchanged && areFieldValuesUnchanged && areFieldSettingsUnchanged
  })