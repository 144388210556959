import React from 'react'
import Loader from '../../standard/Loader'
import ClusterMap from '../../standard/ClusterMap'
// import { makeStyles } from '@material-ui/core/styles';
import ActivityCard from '../../newsfeed/ActivityCard'
import { Grid } from '@material-ui/core';
import EmptyImagePlaceholder from '../../standard/EmptyImagePlaceholder';
import LocationOn from '@material-ui/icons/LocationOn';


export default function MapContent(props) {
  const [selectedActivities, setSelectedActivities] = React.useState([]);

  const mapClickHandler = (activities) => {
    console.log('MapContent', { activities });
    setSelectedActivities(props.activityQueryResults.filter(activity => activities.includes(activity.activityID)))
  }

  console.log('MapContent props', props);

  if (typeof props.activityQueryResults === 'undefined') return <Loader />


  const markers = props.activityQueryResults.map(activity => {
    if (activity.activityMapLink && activity.activityMapLink.includes('http'))
      return {
        id: activity.activityID,
        lat: Number(activity.activityMapLink.substring(activity.activityMapLink.lastIndexOf("=") + 1, activity.activityMapLink.lastIndexOf("+"))),
        lng: Number(activity.activityMapLink.substring(activity.activityMapLink.lastIndexOf("+") + 1, activity.activityMapLink.length))
      };
    else
      return null;
  }).filter(activity => activity);

  console.log({ markers }, props.activityQueryResults);

  return (<div>
    <Grid container >
      <Grid item sm={12} md={6} style={{ padding: 8 }}>
        {props.activityQueryResults &&
          <ClusterMap value1Label={'props.card.value1Label'}
            // width={window.innerWidth / 2.3}
            name1Label={'props.card.name1Label'}
            mapClickHandler={mapClickHandler}
            containerElement={<div style={{ height: window.innerHeight - 177 }} />}
            markers={markers} />

        }</Grid>
      <Grid item sm={12} md={6} >
        {selectedActivities && selectedActivities.length > 0 ?
          <div className="slimscroll" style={{ overflowY: 'scroll', height: window.innerHeight - 177 }} >
            {selectedActivities.map(activity =>
              <ActivityCard key={activity.activityID} activity={activity} summary={false} hideFooter={false} fullImages={true} activityID={activity.activityID} />
            )}
          </div>
          : <EmptyImagePlaceholder message="Click on a marker on the map to view details"
            icon={<LocationOn style={{ marginRight: 8, fontSize: 40 }} color="primary" />} imageType="bench" />
        }
      </Grid>
    </Grid>


  </div >)
}
