import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography';
import throttle from 'lodash.throttle';

export default function GetLocation() {
  const getLocation = useCallback(throttle(() =>
    navigator.geolocation.getCurrentPosition(geo_success, geo_error, { enableHighAccuracy: false, maximumAge: 60000, timeout: 5000 })
    , 5000, { leading: true }), []);

  let successLocation = null, errorLocation = null;

  function geo_success(position) {
    console.log('geo_success');
    successLocation = `http://maps.google.com/maps?q=${position.coords.latitude}+${position.coords.longitude}`;
    sessionStorage.setItem('successLocation', successLocation);
    sessionStorage.setItem('loclatlng', JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude }));
    sessionStorage.removeItem('locationPermissionDenied');
  }

  function geo_error(error) {
    console.log('geo_error');
    errorLocation = `Location Data Not Available`;
    console.log(errorLocation, JSON.stringify(error), error, error.code === error.PERMISSION_DENIED);
    sessionStorage.setItem('locationPermissionDenied', error.message);
  }

  getLocation()
  return sessionStorage.getItem('locationPermissionDenied') ?
    <Typography style={{ padding: 8, margin: 8 }} variant={"caption"}>No Location</Typography> : null
}