import React from 'react'
import SingleActivityCard from '../tasks/SingleActivityCard'
import { Typography, Divider, Avatar, } from '@material-ui/core';
import { displayLeft } from '../../utils/styleclasses';
import TaskCardContainer from '../tasks/TaskCardContainer';
import { secondary } from '../../utils/themes';

const InboxCard = ({ from, type, title, date, reference, referenceID }) => {

  const [showDetails, setShowDetails] = React.useState(false);
  const [task, setTask] = React.useState(null);
  console.log('InboxCard', { from, type, title, date, reference, referenceID });

  if (type === 'task' && !task && reference && reference.get) { reference.get().then(doc => setTask({ ...doc.data(), taskID: referenceID })) }
  return (
    <div style={{ margin: 4 }} >
      <div style={{ ...displayLeft, cursor: "pointer" }} onClick={() => setShowDetails(!showDetails)}>
        <Avatar style={{ margin: 8, backgroundColor: secondary }}>{from && from.charAt(0)}</Avatar>
        <Typography style={{ textAlign: 'left', width: "70%" }}>{title}</Typography>
        <Typography style={{ textAlign: 'right', width: "30%" }} variant="caption">
          {date && date.toLocaleString()}
        </Typography>
      </div>
      {!showDetails && <Divider />}
      {showDetails && <div>
        {
          type === 'activity' ?
            <SingleActivityCard style={{ margin: 0 }} hideFooter={false} footerOptions={{ hideTasks: true, hideShare: true, showNotesDefault: true }}
              displayActivities={1} type="all" activityID={referenceID} />
            :
            type === 'task' && task ?
              <TaskCardContainer task={task} footerOptions={{ showNotesDefault: true }} />
              : null
        }</div>}
    </div>
  )
}

export default (InboxCard)