import { makeStyles } from '@material-ui/core/styles';
import Background from '../components/img/Background.png'
import { secondary, primary } from './themes';

export const smallPrimaryIconStyle = { fontSize: '25px', display: 'block', color: primary, justifyContent: 'center', alignItems: 'center', margin: 4 }

export const dialogSize = window.innerWidth < 825 ?
  { fullScreen: true } : { fullWidth: true, maxWidth: "md", disableBackdropClick: true }

export const divLightCard = { backgroundColor: 'white', borderRadius: '10px', borderStyle: 'solid', borderWidth: '1px', borderColor: '#D3D3D3', }

export const displayLeft =
  { display: "flex", textAlign: "left", justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle' }

export const displayCenter =
  { display: "flex", textAlign: "center", justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }

export const displayRightStart =
  { display: "flex", textAlign: "right", justifyContent: 'right', alignItems: 'start', verticalAlign: 'middle' }


export const displayRight =
  { display: "flex", textAlign: "right", justifyContent: 'right', alignItems: 'center', verticalAlign: 'middle' }

export const imageDisplayDiv = {
  justifyContent: "center",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "10px",
  margin: 0,
  padding: 0
}

export const secondaryBackgroundWhiteText = {
  backgroundColor: secondary,
  color: '#FFFFFF',
};

export const primaryBackgroundWhiteText = {
  backgroundColor: primary,
  color: '#FFFFFF',
};


export const warningColor = {
  color: '#E53935',
  // color: '#FFFFFF',
};

export const alertColor = {
  backgroundColor: '#FB8C00',
  color: '#FFFFFF',
};

export const primaryLight = {
  backgroundColor: '#FB8C00'
}

export const useStyles = makeStyles(theme => ({
  Paper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
  },
  Button: {
    margin: theme.spacing(1),
    width: "95%",
    borderRadius: 25
  },
  WarningButton: {
    // margin: theme.spacing(1),
    width: "95%",
    borderRadius: 25,
    ...warningColor
  },
}));

export const fabFloatingStyle = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
  zIndex: 1600
};

export const floatingAppBarStyle = {
  margin: 0,
  top: 0,
  right: 'auto',
  bottom: 'auto',
  left: 0,
  position: 'fixed',
  zIndex: 1600
};

export const paperStyle = {
  flexGrow: 1,
  padding: 10,
}

export const noPaddingStyleForTable = { cellStyle: { padding: 0, margin: 0, paddingLeft: 8 }, headerStyle: { padding: 0, margin: 0, paddingLeft: 8 }, };

export const noPaddingDenseStyleForTable = { cellStyle: { padding: 0, margin: 0, paddingLeft: 8, height: 40 }, headerStyle: { padding: 0, margin: 0, paddingLeft: 8, height: 40 }, rowStyle: { padding: 0, margin: 0, height: 40 }, };

export const centerStyleForTable = { cellStyle: { textAlign: 'center', padding: 0, margin: 0, paddingLeft: 8 }, headerStyle: { textAlign: 'center', padding: 0, margin: 0, paddingLeft: 8 }, };

export const noPaddingStyleForLastColumn = { cellStyle: { padding: 0, margin: 0, paddingLeft: 8, paddingRight: 8 }, headerStyle: { padding: 0, margin: 0, paddingLeft: 8, paddingRight: 8 }, };

export const zeroPaddingStyleForTable = { cellStyle: { padding: 0, margin: 0, paddingLeft: 0 }, headerStyle: { padding: 0, margin: 0, paddingLeft: 0 }, };

export const landingPageDivStyle = (heightAdjustment) => {
  return {
    width: "calc(100vw-10px)",
    // backgroundSize: 'contain',
    height: window.innerHeight - (heightAdjustment || 64),
    maxWidth: '100vw',
    maxHeight: '100vh',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }

};