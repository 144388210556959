import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { primary, secondary } from '../../utils/themes'
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { commonIcons } from '../../utils/iconutils';
import { Typography } from '@material-ui/core';

export default function AlertSnackbar(props) {
  const handleClose =
    () => { props.setAlertState({ ...props.alertState, open: false, }) }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.alertState.vertical === 'bottom' ? 'bottom' : 'top',
        horizontal: props.alertState.horizontal || 'center'
      }}
      open={props.alertState.open}
      onClose={handleClose}
      autoHideDuration={props.alertState.alertTimeout ? props.alertState.alertTimeout : 1500}
    >
      <SnackbarContent
        style={{
          backgroundColor: props.alertState.backgroundColor === 'primary' ? primary : props.alertState.backgroundColor === 'secondary' ? secondary : props.alertState.backgroundColor
        }}
        message={
          <div>
            <div style={{ verticalAlign: 'middle', display: 'flex' }}>
              {props.alertState.icon && <span style={{ marginRight: 8 }}>{commonIcons[props.alertState.icon]}</span>}
              <Typography>{props.alertState.message}</Typography>
            </div>
            {props.alertState.progressBar ?
              props.alertState.progressBar !== 'full' ?
                <LinearProgress size={40} />
                : <LinearProgress size={40} variant="determinate" value={100} />
              : null}
          </div>
        }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  )
}
