import React from 'react';
import { geolocated } from 'react-geolocated';

class GeoLocation extends React.Component {

  render() {
    return !this.props.isGeolocationAvailable
      ? <div>Your browser does not support Geolocation</div>
      : !this.props.isGeolocationEnabled
        ? <div>Geolocation is not enabled</div>
        : this.props.coords
          ? <div>
            <a href={`http://maps.google.com/maps?q=${this.props.coords.latitude},${this.props.coords.longitude}`}> Maps Link </a><table>
              <tbody>
                <tr><td>Latitude</td><td>{this.props.coords.latitude}</td></tr>
                <tr><td>Longitude</td><td>{this.props.coords.longitude}</td></tr>
              </tbody>
            </table>
          </div>
          : <div>Getting the location data&hellip; </div>;
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(GeoLocation);