import React from 'react';
import Settings from '@material-ui/icons/Settings';
import { TextField, Select, MenuItem, FormControl, InputLabel, Typography, Switch } from '@material-ui/core';
import PopoverButton from '../standard/PopoverButton';
import { displayLeft } from '../../utils/styleclasses';
import SchedulePageSend from '../dashboard/SchedulePageSend';

export const TabSortableField = (fieldID, tab, valueBundle, handleBundle) => {
  console.log('TabSortableField', { fieldID, tab, valueBundle, handleBundle });
  return (<div key={fieldID} style={displayLeft}>
    <TextField label="Tab Label" type="text"
      value={tab.tabLabel} style={{ width: "45%", margin: 8 }}
      error={tab.tabLabel.length === 0 ? true : false}
      helperText={tab.tabLabel.length === 0 ? 'Tab must have a label' : ''}
      onChange={(e) => { handleBundle.handleChange("tabLabel", e.target.value, fieldID); }} />
    <FormControl style={{ width: tab.tabType !== 'page' ? '35%' : "45%", margin: 8 }}>
      <InputLabel>Field Type</InputLabel>
      <Select value={tab.tabType || 'Grid'} name='tabType'
        onChange={(e) => handleBundle.handleChange("tabType", e.target.value, fieldID)}>
        <MenuItem value="embed">Embed </MenuItem>
        <MenuItem value="grid">Grid </MenuItem>
        <MenuItem value="page">Sub Pages & Tabs </MenuItem>
      </Select>
    </FormControl>
    {/* Disabling page scheduling for subpages. Can be re-enabled after changing subpage storage format to /organizations/orgID/pages */}
    {tab.tabType !== 'page' && !(tab.tabType === 'grid' && valueBundle.isSubPage) &&
      <PopoverButton
        buttonProps={{ style: { marginRight: 4, marginTop: 8 } }}
        useDialog={tab.tabType === 'grid' ? true : false}
        useChip={true} style={{ margin: 4 }}
        showCloseIcon={true}
        text={<Settings />} variant="outlined" popoverContent={<div style={{ padding: 4, width: '100%', textAlign: 'left', display: 'flex-wrap' }}>
          <Typography variant="h6" color="primary" style={{ textAlign: 'left', margin: 8, marginRight: 30 }}>
            {tab.tabLabel} - Tab Settings
        </Typography>
          {tab.tabType === 'grid' && <> <SchedulePageSend
            pageIDTabID={`pageID${valueBundle.pageID}-tabID${tab.tabID}`}
            updateSchedule={(schedule) => {
              console.log('TabSortableField SchedulePageSend', valueBundle);
              const newSchedule = { pageID: valueBundle.pageID, tabID: tab.tabID, pageIDTabID: `pageID${valueBundle.pageID}-tabID${tab.tabID}`, ...schedule };
              handleBundle.updateSchedule(newSchedule)
            }}
            deleteSchedule={(scheduleID) => handleBundle.deleteSchedule(scheduleID)}
          />
          </>}

          {tab.tabType === 'embed' && <>
            <Select value={tab.embedType || 'link'} style={{ margin: 8, width: 380 }}
              onChange={(e) => handleBundle.handleChange("embedType", e.target.value, fieldID)}>
              <MenuItem value="link">Embed Link</MenuItem>
            </Select>
            <TextField defaultValue={tab.embedLink} style={{ margin: 8, width: 380, }}
              onBlur={e => handleBundle.handleChange("embedLink", e.target.value, fieldID)} /></>}
        </div>} />}
    < div style={{ marginLeft: 16 }}>
      <Typography variant='caption' >Mobile Enable</Typography >
      <Switch checked={tab.showOnMobile || false} value={tab.showOnMobile || false}
        onChange={(e) => handleBundle.handleChange("showOnMobile", e.target.checked, fieldID)} color="secondary" />
    </div >
  </div >);
};
