import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux'
import { signOut, } from '../../store/actions/authActions'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import LoadingSplashScreen from './LoadingSplashScreen';
import * as Sentry from '@sentry/browser';
// import ReactGA from 'react-ga';
import MobileAppRender from './MobileAppRender';
import { checkProfileAndRedirect, setOrgIDToLocalStorageAndCleanup } from './appSignedInRoutesUtils';
import { getFirebase } from 'react-redux-firebase'

const WebAppRender = lazy(() => import('./WebAppRender'))

const AppSignedInRoutesContainer = ({ auth, orgDocRequested, profile, orgSettingsOrdered, signOut, children, location }) => {


  React.useEffect(() => { if (profile) setupProfileOnSentryLogRocket(profile) }, [profile])

  // profile && updateAnalytics(profile) ANALYTICS TURN ON
  const orgSettings = orgSettingsOrdered ? (orgSettingsOrdered.length === 0 ? undefined : orgSettingsOrdered[0]) : null

  const redirect = checkProfileAndRedirect(auth, profile, orgDocRequested, orgSettings)
  if (redirect) return redirect

  setOrgIDToLocalStorageAndCleanup(profile);

  const isSmallScreen = localStorage.getItem('isSmallScreen') === 'true' ? true : false;
  if (profile && profile.active && orgSettings) {
    return isSmallScreen ?
      <MobileAppRender {...{ children, profile, }} pathname={location.pathname} /> :
      <Suspense fallback={<LoadingSplashScreen message="Downloading" />}>
        <WebAppRender {...{ profile, auth, signOut, orgSettings, isSmallScreen, children }} />
      </Suspense>
  }
  return <LoadingSplashScreen message="Syncing Data" />
}

const mapStateToProps = (state, ownProps) => {
  console.log('AppSignedInRoutesContainer - Redux State in mapStateToProps', state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    orgDocRequested: state.firestore.status.requested.orgSettings,
    orgSettingsOrdered: state.firestore.ordered.orgSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    // updateProfileTourStatus: (profile) => { dispatch(updateProfileTourStatus(profile)) },
    // sendFCMToken: (uid, FCMToken) => dispatch(sendFCMToken(uid, FCMToken)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID || 'NoOrg', storeAs: 'orgSettings' },
    ]
  }))(AppSignedInRoutesContainer)


function setupProfileOnSentryLogRocket(profile) {
  if (profile) {
    const userID = `${profile.orgID}-${profile.uid}-${profile.firstName}-${profile.lastName}`;
    if (!window.location.hostname.includes('localhost')) {
      Sentry.configureScope((scope) => {
        scope.setUser({ "id": userID });
        scope.setTag("BuildDate", localStorage.getItem('buildDate'));
        scope.setTag("BuildID", localStorage.getItem('buildID'));
      });

    }
  }
}

// ANALYTICS TURN ON
// async function updateAnalytics(profile) {
//   const userID = `${profile.orgID}-${profile.uid}-${profile.firstName}-${profile.lastName}`;
//   const analytics = getFirebase().analytics;
//   analytics().setUserId(userID);
//   const userProperties = { userID, orgID: profile.orgID, userName: `${profile.firstName}-${profile.lastName}` };
//   analytics().setUserProperties(userProperties);
//   analytics().logEvent('screen_view', { screen_name: window.location.pathname, ...userProperties });
// }
