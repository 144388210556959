
import localForage from "localforage";
import moment from 'moment';

//Converter from camera URI to Blob Assumed to work
export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  let byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  let ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const localhostCorsProxyCheck = () => {

  return window.location.href.includes('localhost') ? 'https://cors-anywhere.herokuapp.com/' : ''
}

export const isUploadComplete = () => {
  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  let counter = 0;
  return store.length()
  // .then(storeLength => { counter = storeLength; return storeHD.length() })
  // .then(storeHDLength => storeHDLength + counter)
}


export const isHDUploadComplete = () => {
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  let counter = 0;
  return storeHD.length()
  // .then(storeLength => { counter = storeLength; return storeHD.length() })
  // .then(storeHDLength => storeHDLength + counter)
}

export const deleteAllPendingUploads = () => {
  // const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });
  // store.clear();
  storeHD.clear();
}

export const getPendingUploadList = () => {
  let pendingUploadList = [];

  const store = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload" });
  const storeHD = localForage.createInstance({ name: 'Opstack-DB', storeName: "pending-upload-HD" });

  return store.length()
    .then((numberOfEntriesInIndexedDB) => {
      if (numberOfEntriesInIndexedDB > 0) {
        store.iterate((value, key, iterationNumber) => {
          pendingUploadList.push(
            {
              appName: value.appName || '',
              fieldLabel: value.fieldLabel || '',
              submittedAt: value.submittedAt ? moment(value.submittedAt).format('YYYY-MMM-DD h:mm A') : '',
              submissionID: value.submissionID
            }
          )
        })
      }
    })
    .then(() => storeHD.length())
    .then((numberOfEntriesInIndexedDB) => {
      if (numberOfEntriesInIndexedDB > 0) {
        storeHD.iterate((value, key, iterationNumber) => {
          pendingUploadList.push(
            {
              appName: value.appName || '',
              fieldLabel: (value.fieldLabel) ? value.fieldLabel + ' High Resolution' : '',
              submittedAt: value.submittedAt ? moment(value.submittedAt).format('YYYY-MMM-DD h:mm A') : '',
              submissionID: value.submissionID
            }
          )
        })
      }
    })
    .then(() => pendingUploadList)
}

export function isJSON(jsonString) {
  try {
    let o = JSON.parse(jsonString);
    if (o && typeof o === "object") { return o; }
  }
  catch (e) { }
  return false;
};

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return decodeURIComponent(pair[1]).replace(/\+/g, " "); }
  }
  return (false);
}

export const getDateFromTimestamp = (timestamp) => (timestamp.seconds && timestamp.nanoseconds) ?
  new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds / (10 ^ 6))) : console.error('Invalid Timestamp')

// export const consoleLogSettings = () => {
//   const isLocalhost = Boolean(
//     window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
//   );

//   // var DEBUG = false;
//   if (!isLocalhost) {
//     if (!window.console) window.console = {};
//     var methods = ["log", "debug", "warn", "info"];
//     for (var i = 0; i < methods.length; i++) {
//       console[methods[i]] = function () { };
//     }
//   }
// }