import React from 'react'
import isEqual from 'lodash.isequal';
import TableFilterWrapper from '../../resources/tables/TableFilterWrapper';

function TableEditorField({ field, appLookupMatchField }) {

  if (doTableFieldsExist(field)) {
    const tableID = field.fieldSettings.tableSelector.value
    const tableLookupMatchField = field.fieldSettings.tableSelector.tableStructure.tableLookupMatchFieldID
    const tableValueFieldID = field.fieldSettings.tableSelector.tableStructure.tableValueFieldID || null

    let whereClause, limit = Math.min(Number((field.fieldSettings.maxRows && field.fieldSettings.maxRows.value) || 500), 500);

    if (tableLookupMatchField && appLookupMatchField && appLookupMatchField.fieldValue)
      whereClause = [[tableLookupMatchField, '==', appLookupMatchField.fieldValue]]

    if (tableLookupMatchField && field.fieldSettings.fullTableLookup && !field.fieldSettings.fullTableLookup.value && !whereClause)
      whereClause = [[tableLookupMatchField, '==', 'fullTableLookupIsDisabledAndMatchFieldValueIsUndefined']]

    if (field.fieldSettings.firstRow && field.fieldSettings.firstRow.value) limit = 1

    const options = {
      settings: (field.fieldSettings.settings && field.fieldSettings.settings.value) || false,
      hideExport: (field.fieldSettings.exportButton && !field.fieldSettings.exportButton.value) ? true : false,
      addRow: (field.fieldSettings.addRow && field.fieldSettings.addRow.value) || false,
      deleteRow: (field.fieldSettings.deleteRow && field.fieldSettings.deleteRow.value) || false,
      noFilter: (field.fieldSettings.noFilter && field.fieldSettings.noFilter.value) || false,
      editable: field.fieldSettings.readonly.value !== 'yes',
      noToolbar: !(field.fieldSettings.enableToolbar && field.fieldSettings.enableToolbar.value),
      tableValueFieldID: tableValueFieldID !== 'allColumns' ? tableValueFieldID : null,
      noHeader: field.fieldSettings.noHeader && field.fieldSettings.noHeader.value,
      selectiveEdit: field.fieldSettings.selectiveEdit && field.fieldSettings.selectiveEdit.value,
      editableFields: field.fieldSettings.selectiveEdit && field.fieldSettings.selectiveEdit.value
        && field.fieldSettings.tableSelector && field.fieldSettings.tableSelector.tableStructure
        && field.fieldSettings.tableSelector.tableStructure.editableFields,
      editableFieldExpressions: field.fieldSettings.selectiveEdit && field.fieldSettings.selectiveEdit.value
        && field.fieldSettings.tableSelector && field.fieldSettings.tableSelector.tableStructure
        && field.fieldSettings.tableSelector.tableStructure.editableFieldExpressions
    };

    return <div>
      <TableFilterWrapper tableID={tableID} whereClause={whereClause} limit={limit}
        options={options}
      />
    </div>
  }
  return null
}

export default React.memo(TableEditorField, (prevProps, nextProps) => isEqual(prevProps.field, nextProps.field) && isEqual(prevProps.appLookupMatchField, nextProps.appLookupMatchField))

export function doTableFieldsExist(field) {
  return field && field.fieldSettings && field.fieldSettings.tableSelector && field.fieldSettings.tableSelector.tableStructure && field.fieldSettings.tableSelector.value
}