import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Build from '@material-ui/icons/Build';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import React from 'react';
import { divLightCard } from '../../utils/styleclasses';
import LoadMoreParent from '../standard/LoadMoreParent';
import { a11yProps, TabPanel } from '../standard/TabPanel';
import ExpiredTaskListContainer from './ExpiredTaskListContainer';
import TaskCompletedContainer from './TaskCompletedContainer';
import TaskCreatedContainer from './TaskCreatedContainer';
import TaskListContainer from './TaskListContainer';

function TaskListTabs(props) {
  const [tab, setTab] = React.useState(
    {
      value: localStorage.getItem('taskTabValue') ?
        Number(localStorage.getItem('taskTabValue')) : 0,
      loadCreatedTasks: localStorage.getItem('taskTabValue') == 1
    });
  const [subTab, setSubTab] = React.useState({
    value: localStorage.getItem('taskSubTabValue') ?
      Number(localStorage.getItem('taskSubTabValue')) : 0,
    loadOpenTasks: true,
    loadDoneTasks: localStorage.getItem('taskSubTabValue') == 1,
    loadExpiredTasks: localStorage.getItem('taskSubTabValue') == 2,
  });

  const handleChange = (event, newValue) => {
    localStorage.setItem('taskTabValue', newValue);
    setTab({ loadCreatedTasks: true, value: newValue });
  }
  const handleSubTabChange = (event, newValue) => {
    localStorage.setItem('taskSubTabValue', newValue);
    setSubTab({ value: newValue, loadOpenTasks: true, loadDoneTasks: true, loadExpiredTasks: true });
  }

  return (
    <div>
      <AppBar position="sticky">
        <Tabs value={tab.value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <CheckCircle style={{ marginRight: 4 }} />My Tasks</span>}
            {...a11yProps(0)} />
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <Build style={{ marginRight: 4 }} />Created</span>}
            {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab.value} index={0} style={{ padding: 0 }}>
        <Tabs value={subTab.value} onChange={handleSubTabChange} aria-label="simple tabs example">
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <CheckBoxOutlineBlank style={{ marginRight: 4 }} />Open</span>}
            {...a11yProps(0)} />
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <CheckCircle color="secondary" style={{ marginRight: 4 }} />Done</span>}
            {...a11yProps(0)} />
          <Tab label={<span style={{ display: 'inline-flex' }}>
            <Error color="error" style={{ marginRight: 4 }} />Expired</span>}
            {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={subTab.value} index={0} style={{ padding: 0 }}>
          {subTab.loadOpenTasks && <TaskListContainer style={divLightCard} />}
        </TabPanel>
        <TabPanel value={subTab.value} index={1} style={{ padding: 0 }}>
          {subTab.loadDoneTasks && <LoadMoreParent initialRows={3} increment={3} ><TaskCompletedContainer /></LoadMoreParent>}
        </TabPanel>
        <TabPanel value={subTab.value} index={2} style={{ padding: 0 }}>
          {subTab.loadExpiredTasks && <LoadMoreParent initialRows={3} increment={3} ><ExpiredTaskListContainer /></LoadMoreParent>}
        </TabPanel>
      </TabPanel>
      <TabPanel value={tab.value} index={1}>
        {tab.value === 1 && <LoadMoreParent initialRows={3} increment={3}><TaskCreatedContainer /></LoadMoreParent>}
      </TabPanel>
    </div>
  );
}

export default TaskListTabs