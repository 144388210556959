import React from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import RenderCard from "../dashboardbuilder/card/RenderCard";
import { updateDashboardCard } from "../../store/actions/formActions";
import { useState } from "react";

const DashboardDirect = (props) => {
  console.log("DashboardDirect", props);

  const [isDataRequested, setIsDataRequested] = useState(false)
  const options = { skipDataRequest: false, profile: { ...props.profile, firstName: 'Opstack', lastName: 'Scheduled Dashboard' } };

  if (!isDataRequested) { props.updateDashboardCard(props.dashboardCardID, props.card, props.dashboardID, options); setIsDataRequested(true) }

  const dataPath = `organizations/${props.profile.orgID}/userdata/${props.profile.uid}/${props.dashboardID}/${props.dashboardCardID}/card-data`;
  console.log('DashboardDirect', { dataPath });
  let style = {}

  if (props.card && props.card.cardType === 'heatmap') { style = { height: 600 } }

  return (
    <div style={style} >
      {props.card &&
        <RenderCard dataPath={dataPath}
          handleFilterValueChange={() => { }}
          card={props.card}
          hideHeader={false}
          updateDashboardCard={(dashboardCardID, card, dashboardID) => props.updateDashboardCard(dashboardCardID, card, dashboardID, options)}
        />
      }
    </div >
  )

}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardCard: (dashboardCardID, card, dashboardID, options) => dispatch(updateDashboardCard(dashboardCardID, card, dashboardID, options)),
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('Dashboard mapStateToProps = (state)', state);
  return {
    profile: { orgID: ownProps.match.params.orgID, uid: ownProps.match.params.uid },
    dashboardID: ownProps.match.params.dashboardID,
    dashboardCardID: ownProps.match.params.dashboardCardID,
    card: state.firestore.data[ownProps.match.params.dashboardID] && state.firestore.data[ownProps.match.params.dashboardID][ownProps.match.params.dashboardCardID],
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      { collection: 'organizations', doc: props.profile.orgID, subcollections: [{ collection: 'userdata', doc: props.profile.uid, subcollections: [{ collection: props.dashboardID, }] }], storeAs: props.dashboardID },
    ]
  }
  )
)(DashboardDirect)
