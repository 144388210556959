import React from 'react';

// const fetch = require("isomorphic-fetch");
const { compose, withProps, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} = require("react-google-maps");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const ClusterMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBTJRUhiJva6404aQH-wImKcks-znWzfWs&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: (props) => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
      console.log(`Current clicked markers length: ${clickedMarkers.length}`)
      console.log(clickedMarkers)
      props.mapClickHandler(clickedMarkers.map(marker => marker.getTitle()))
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {

  return <GoogleMap
    defaultZoom={3}
    defaultCenter={{ lat: 25.0391667, lng: 121.525 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers && props.markers.filter(marker => marker).map(marker => (
        <Marker
          key={marker.id || Math.random()}
          title={marker.id}
          id={marker.id}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={() => {
            props.mapClickHandler(marker.id) //HANDLER FOR MAP VIEW CLICKING
          }}
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
}
);

export default ClusterMap